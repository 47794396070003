export const PageKeyReducer = (
  state = { key: "", pagestate: false },
  action
) => {
  switch (action.type) {
    case "PAGE_KEY_CHECK":
      return {
        key: action.key,
        pagestate: !state.pagestate,
      };
    default:
      return state;
  }
};
