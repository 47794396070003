let InitialState = {
    totalNumber: 0,
    duplicate_removed: 0,
    TotalCouponCode: 0,
    PresentCouponCode: [],
    fileName: '',
    Final_duplicate_removed: 0,
    state_checker: false,
    state_checker_uploaded: false,
  },
  Coupon_code_reducer2 = (state = InitialState, action) => {
    let Coupons = action.PresentCouponCode
    switch (action.type) {
      case 'ADD_COUPON_CODE_FROM_DASHBOARD':
        let fileName = ''
        if (action.fileName) {
          fileName = action.fileName
        }
        return {
          ...state,
          totalNumber: action.totalNumber,
          duplicate_removed: action.duplicate_removed,
          TotalCouponCode: action.TotalCouponCode,
          PresentCouponCode: Coupons,
          fileName: fileName,
          Final_duplicate_removed: action.Final_duplicate_removed,
          state_checker: true,
          state_checker_uploaded: false,
        }
      case 'UPLOADED_COUPON_CODE_FROM_DASHBOARD':
        // let Coupons = action.PresentCouponCode;
        let fileName1 = ''
        if (action.fileName) {
          fileName1 = action.fileName
        }
        return {
          ...state,
          totalNumber: action.totalNumber,
          duplicate_removed: action.duplicate_removed,
          TotalCouponCode: action.TotalCouponCode,
          PresentCouponCode: Coupons,
          fileName: fileName1,
          Final_duplicate_removed: action.Final_duplicate_removed,
          state_checker: false,
          state_checker_uploaded: true,
        }

      // case "UPLOADED_COUPON_CODE_REMOVE_FROM_DASHBOARD":
      //   return {
      //     ...state,
      //     totalNumber: 0,
      //     duplicate_removed: 0,
      //     TotalCouponCode: 0,
      //     PresentCouponCode: [],
      //     fileName: "",
      //     Final_duplicate_removed: 0,
      //     state_checker: false,
      //     state_checker_uploaded: false,
      //   };
      case 'UPLOADED_COUPON_CODE_REMOVE_FROM_DASHBOARD':
        let fileName2 = ''
        if (action.fileName) {
          fileName2 = action.fileName
        }
        return {
          ...state,
          totalNumber: 0,
          duplicate_removed: 0,
          TotalCouponCode: 0,
          PresentCouponCode: [],
          fileName: fileName2,
          Final_duplicate_removed: 0,
          state_checker: false,
        }
      case 'DELETE_FROM_TOP_ICON':
        let fileName3 = ''
        if (action.fileName) {
          fileName3 = action.fileName
        }
        return {
          ...state,
          totalNumber: action.totalNumber,
          duplicate_removed: action.duplicate_removed,
          TotalCouponCode: action.TotalCouponCode,
          PresentCouponCode: [],
          fileName: fileName3,
          Final_duplicate_removed: action.Final_duplicate_removed,
          state_checker: false,
        }

      case 'DELETE_FROM_BOTTOM_ICON':
        let fileName4 = ''
        if (action.fileName) {
          fileName4 = action.fileName
        }
        return {
          ...state,
          totalNumber: 0,
          duplicate_removed: 0,
          TotalCouponCode: 0,
          PresentCouponCode: Coupons,
          fileName: fileName4,
          Final_duplicate_removed: 0,
          state_checker: false,
        }
      default:
        return state
    }
  }
export { Coupon_code_reducer2 }
