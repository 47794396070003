import React, { Fragment, Component } from 'react'
import SweetAlert from 'sweetalert-react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
// import bg1 from '../../assets/utils/images/originals/city.jpg'
import bg2 from '../../assets/utils/images/originals/citydark.jpg'
// import bg3 from '../../assets/utils/images/originals/citynights.jpg'
import validator from 'validator'
import { Col, Row, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { Loginaction } from '../../redux/actions/Login2'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import swal from 'sweetalert'
// import * as swal1 from '@sweetalert/with-react'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import LockIcon from '@material-ui/icons/Lock'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import LaddaButton, {
  // EXPAND_LEFT,
  EXPAND_RIGHT,
  // EXPAND_UP,
  // EXPAND_DOWN,
  // SLIDE_LEFT,
  // SLIDE_RIGHT,
  // SLIDE_UP,
  // SLIDE_DOWN,
  // ZOOM_IN,
  // ZOOM_OUT,
} from 'react-ladda'
import './index.css'
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Email: '',
      Password: '',
      PasswordError: null,
      EmailError: null,
      EmailErrorChecker: null,
      PasswordErrorChecker: null,
      sucess: null,
      ButtonLoading: false,
      ButtonState: true,
      PasswordSwitch: true,
      Apiresponse: {
        message: '',
        state: null,
      },
    }
  }
  componentDidMount() {}
  componentDidUpdate(PrevProps, PrevState) {
    if (PrevProps.state.LoginReducer11 !== this.props.state.LoginReducer11) {
      var { LoginReducer11 } = this.props.state

      if (LoginReducer11.response !== undefined) {
        if (LoginReducer11.response.success === true) {
          this.setState({
            Apiresponse: {
              message: '',
              state: false,
              ButtonLoading: false,
            },
          })

          if (LoginReducer11.response.user !== null || LoginReducer11.response.user !== undefined) {
            if (LoginReducer11.response.user.role === 'admin') {
              this.props.history.push('/dashboard/1')
            }
            if (LoginReducer11.response.user.role === 'superadmin') {
              this.props.history.push('/super-admin')
            }
          }
        } else {
          // swal({
          //   title: LoginReducer11.response.message,

          //   icon: "warning",
          // });
          swal({
            title: 'Incorrect details',
            text: LoginReducer11.response.message,
            icon: 'warning',
            buttons: {
              catch: {
                text: 'Enter again',
                value: 'ok',
              },
            },
          })
          // swal1({
          //   content: (
          //     <div>
          //       <div className="swal-icon swal-icon--warning">
          //         <span className="swal-icon--warning__body">
          //           <span className="swal-icon--warning__dot"></span>
          //         </span>
          //       </div>
          //       <div className="swal-title">
          //         <br></br>
          //         {LoginReducer11.response.message}
          //       </div>
          //       {/* <div className="swal-title">
          //         {data.original.Name}
          //       </div> */}
          //       <div><br></br></div>
          //       <div className="swal-text">
          //         Please  <a href="/signup">Sign up</a>
          //       </div>
          //       {/* <div className="swal-footer">
          //       <div className="swal-button-container">
          //         <button className="swal-button swal-button--cancel">
          //           CANCEL
          //         </button>
          //       </div>
          //       <div className="swal-button-container">
          //         <button className="swal-button swal-button--catch">
          //           OKAY
          //         </button>
          //       </div>
          //     </div> */}
          //     </div>
          //   ),
          //   buttons: {
          //     cancel: "Cancel",
          //     // catch: {
          //     //   text: "Confirm",
          //     //   value: "delete",
          //     // },
          //   },
          // });

          this.setState({
            ButtonLoading: false,
            Apiresponse: {
              // message: LoginReducer11.response.message,
              // state: true,
            },
          })
        }
      } else {
        swal({
          title: 'Somthing went wrong',

          icon: 'warning',
        })
        this.setState({
          ButtonLoading: false,
          Apiresponse: {
            // message: "Something went wrong",
            // state: true,
          },
        })
      }
    }
  }

  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true,
    }
    return (
      <Fragment>
        <SweetAlert
          title={this.state.Apiresponse.message}
          confirmButtonColor=""
          show={this.state.Apiresponse.state}
          text=""
          type="error"
          onConfirm={() =>
            this.setState({
              Apiresponse: { state: false },
              Email: '',
              Password: '',
            })
          }
        />

        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage:
                          'url(' +
                          ` https://images.pexels.com/photos/4665707/pexels-photo-4665707.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940` +
                          ')',
                      }}
                    />
                    <div className="slider-content">
                      <h3>Perfect Balance</h3>
                      <p>
                        ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of unified React Boostrap Components
                        and Elements.
                      </p>
                    </div>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage:
                          'url(' +
                          `https://images.pexels.com/photos/3354647/pexels-photo-3354647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940` +
                          ')',
                      }}
                    />
                    <div className="slider-content">
                      <h3>Scalable, Modular, Consistent</h3>
                      <p>
                        Easily exclude the components you don't require. Lightweight, consistent Bootstrap based styles across all elements and
                        components
                      </p>
                    </div>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                    <div
                      className="slide-img-bg opacity-6"
                      style={{
                        backgroundImage: 'url(' + bg2 + ')',
                      }}
                    />
                    <div className="slider-content">
                      <h3>Complex, but lightweight</h3>
                      <p>We've included a lot of components that cover almost all use cases for any type of application.</p>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <h4 className="mb-0">
                  <div>Welcome back,</div>
                  <span>Please sign in to your account.</span>
                </h4>
                <h6 className="mt-3">
                  No account?{' '}
                  <Link
                    // href="http://localhost:3001/signup"
                    to="/signup"
                    // onClick={(e) => e.preventDefault()}
                    className="text-primary"
                  >
                    Sign up now
                  </Link>
                </h6>
                <Row className="divider" />
                <div>
                  <Row
                  //  form
                  >
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Email</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <AccountBoxIcon />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="email"
                            name="email"
                            id="exampleEmail"
                            placeholder="Email here..."
                            value={this.state.Email}
                            invalid={this.state.EmailErrorChecker}
                            onClick={() => {
                              this.setState({
                                EmailErrorChecker: false,
                                PasswordErrorChecker: false,
                              })
                            }}
                            onChange={(e) => {
                              this.setState({
                                Email: e.target.value,
                                ButtonState: false,
                              })
                            }}
                          />
                        </InputGroup>
                        <div>{this.state.EmailErrorChecker === true ? <div style={{ color: 'red' }}>{this.state.EmailError}</div> : <div></div>}</div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="examplePassword">Password</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <LockIcon />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            type={this.state.PasswordSwitch ? 'Password' : 'text'}
                            name="password"
                            id="examplePassword"
                            placeholder="Password here..."
                            value={this.state.Password}
                            invalid={this.state.PasswordErrorChecker}
                            onChange={(e) => {
                              this.setState({
                                Password: e.target.value,
                                ButtonState: false,
                              })
                            }}
                            onClick={() => {
                              this.setState({
                                EmailErrorChecker: false,
                                PasswordErrorChecker: false,
                              })
                            }}
                          />
                          <InputGroupAddon
                            addonType="append"
                            onClick={() => {
                              this.setState({
                                PasswordSwitch: !this.state.PasswordSwitch,
                              })
                            }}
                          >
                            <InputGroupText>{this.state.PasswordSwitch ? <VisibilityIcon /> : <VisibilityOffIcon />}</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <div>
                          {this.state.PasswordErrorChecker === true ? <div style={{ color: 'red' }}>{this.state.PasswordError}</div> : <div></div>}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <FormGroup check>
                      <Input type="checkbox" name="check" id="exampleCheck" />
                      <Label for="exampleCheck" check>
                        Keep me logged in
                      </Label>
                    </FormGroup> */}
                  <Row className="divider" />
                  <div className="d-flex align-items-center">
                    <div className="ml-auto">
                      {/* <Link
                        // to="/signup"
                        onClick={(e) => e.preventDefault()}
                        className="btn-lg btn btn-link"
                      >
                        Recover Password
                      </Link>{" "} */}
                      <LaddaButton
                        data-style={EXPAND_RIGHT}
                        loading={this.state.ButtonLoading}
                        disabled={this.state.ButtonState}
                        className="btn-wide mb-2 mr-2 btn-icon btn btn-primary"
                        color="primary"
                        size="lg"
                        onClick={(e) => {
                          this.setState({ ButtonLoading: true })
                          if (this.state.Email === '') {
                            this.setState({
                              EmailErrorChecker: true,
                              EmailError: 'This field is required',
                              ButtonLoading: false,
                            })

                            if (this.state.Password === '') {
                              this.setState({
                                PasswordErrorChecker: true,
                                PasswordError: 'This field is required',
                                ButtonLoading: false,
                              })
                            }
                          } else {
                            if (this.state.Password === '') {
                              this.setState({
                                PasswordErrorChecker: true,
                                PasswordError: 'This field is required',
                                ButtonLoading: false,
                              })

                              if (!validator.isEmail(this.state.Email)) {
                                this.setState({
                                  EmailErrorChecker: true,
                                  EmailError: 'Invalid Email',
                                  Email: '',
                                  ButtonLoading: false,
                                })
                              }
                            } else {
                              if (this.state.Email !== '' && this.state.Password !== '') {
                                if (!validator.isEmail(this.state.Email)) {
                                  this.setState({
                                    EmailErrorChecker: true,
                                    EmailError: 'Invalid Email',
                                    Email: '',
                                    ButtonLoading: false,
                                  })
                                } else {
                                  this.props.dispatch(Loginaction(this.state.Email, this.state.Password))
                                }
                              }
                            }
                          }
                        }}
                      >
                        Log In
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
