import React, { Component } from 'react'
import Loader11 from '../../../Loader'
import { LoaderActions_loadingtrue, LoaderActions_loadingfalse } from '../../../../redux/actions/Loader'
import {
  CardBody,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Card,
  Col,
  Row,
  CardHeader,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda'
import ProjectSettings from '../../../../global/Global'
import validator from 'validator'
import $ from 'jquery'
import swal from 'sweetalert'
// import { InputLabel, FormControl } from '@material-ui/core'
// import EditIcon from '@material-ui/icons/Edit'
// import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { connect } from 'react-redux'
// import InputAdornment from '@material-ui/core/InputAdornment'
import { withRouter } from 'react-router-dom'
// import Feedback from 'react-bootstrap/esm/Feedback'

class UserCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      User: this.props.state.LoginReducer11,
      token: this.props.state.LoginReducer11.response.token,
      Name: '',
      email: '',
      tempEmail: '',
      emailInvalid: false,
      emailValid: false,
      EmailState: true,
      businessname_error_state: false,
      businessName: '',
      businessname_error_text: '',
      Newpassword: '',
      Newpasswordconfirm: '',

      NameErrorState: false,
      Name_error_text: '',
      email_error_state: false,
      email_error_text: '',
      password_error_state: false,
      password_error_text: '',
      new_password_error_text: '',
      new_password_error_state: false,
      SavebuttondisableState: true,
      businessNameReadState: true,
      // NameState: true,
      // EmailState: true,
      // SavebuttondisableState: true,
      // CurrentPasswordvalid: false,
      // CurrentPassworinvalid: false,

      // LoadingState: false,
      // PasswordReadState: true,

      // passwordMatchErrorState: false,
      // ButtonLoading: false,
      // passwordmatcholdpasswordstate: false,
      // Newpasswordlengthstate: false,
      // passwordMatchErrormessageState: false,
    }
  }
  UserDetailsUpdate = () => {
    this.Loader_visible()
    fetch(ProjectSettings.baseURL + '/api/user/admins', {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((e) => {
        return e.json()
      })
      .then((e) => {
        e.responseData.forEach((e) => {
          if (e._id === this.props.match.params.id) {
            this.setState(
              {
                Name: e.name,
                email: e.email,
                businessName: e.businessName,
                tempEmail: e.email,
              },
              () => {
                this.Loader_hide()
              }
            )
          }
        })
      })
      .catch((e) => {
        swal({
          title: 'Something went wrong',
          icon: 'warning',
        })
      })
  }
  componentDidMount() {
    if (this.state.User === null) {
      this.props.history.push('/login')
    } else {
      if (this.state.User.response.user.role !== 'superadmin') {
        this.props.history.push('/login')
      }
      this.UserDetailsUpdate()
    }

    $(document).ready(() => {
      document.querySelectorAll('.card-body').forEach((e) => {
        e.classList.add('card-body-user')
      })
    })
  }
  Loader_visible = () => {
    this.props.dispatch(LoaderActions_loadingtrue())
  }

  Loader_hide = () => {
    setTimeout(() => {
      this.props.dispatch(LoaderActions_loadingfalse())
    }, 500)
  }
  render() {
    return (
      <div className="outer-div">
        <Loader11 />
        <div className="inner-div">
          <Card className="col-md-12 app-inner-layout__content">
            <CardHeader id="headingOne">
              <Button block color="link" className="text-left m-0 p-0" aria-controls="collapseOne">
                <h3 className="form-heading">
                  Update User
                  <p></p>
                </h3>
              </Button>
            </CardHeader>

            <CardBody>
              <FormGroup>
                <Row form style={{}}>
                  <Col md={12}>
                    <Label for="Name">Name</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="Name"
                        id="Name"
                        value={this.state.Name}
                        // invalid={true}
                        invalid={this.state.NameErrorState}
                        onChange={(e) => {
                          this.setState({
                            Name: e.target.value,
                            NameErrorState: false,
                            SavebuttondisableState: false,
                          })
                        }}
                      ></Input>
                      <FormFeedback
                        style={{
                          display: this.state.NameErrorState ? `block` : `none`,
                        }}
                      >
                        {this.state.Name_error_text}
                      </FormFeedback>
                      <InputGroupAddon addonType="append"></InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
              {/* add business name field changes */}

              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="exampleEmail2">Business Name</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="businessName"
                        id="exampleEmail2"
                        value={this.state.businessName}
                        readOnly={this.state.businessNameReadState}
                        invalid={this.state.businessname_error_state}
                        autoComplete="off"
                        onChange={(e) => {
                          this.setState({
                            SavebuttondisableState: false,
                            businessName: e.target.value,
                            businessname_error_state: false,
                          })
                        }}
                      />
                      <FormFeedback
                        style={{
                          display: this.state.businessname_error_state ? `block` : `none`,
                        }}
                      >
                        {this.state.businessname_error_text}
                      </FormFeedback>
                      <InputGroupText
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (this.state.businessNameReadState === true) {
                            this.setState({
                              SavebuttondisableState: false,
                              businessNameReadState: false,
                            })
                          }
                        }}
                      >
                        edit
                      </InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="exampleEmail2">Email</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="email"
                        id="exampleEmail2"
                        value={this.state.email}
                        valid={this.state.emailValid}
                        invalid={this.state.emailInvalid}
                        readOnly={this.state.EmailState}
                        onChange={(e) => {
                          this.setState(
                            {
                              // SavebuttondisableState: false,
                              email: e.target.value,
                            },
                            () => {
                              if (this.state.email === this.state.tempEmail) {
                                this.setState({
                                  emailInvalid: false,
                                  emailValid: true,
                                  SavebuttondisableState: false,
                                })
                              } else {
                                let str = this.state.email.trim()
                                if (str === '') {
                                  this.setState({
                                    emailInvalid: false,
                                    emailValid: false,
                                    SavebuttondisableState: true,
                                  })
                                } else {
                                  let API_END_POINT = ProjectSettings.baseURL + '/api/user/checkEmail'
                                  let bodyData = JSON.stringify({
                                    email: str,
                                  })
                                  fetch(API_END_POINT, {
                                    method: 'POST',
                                    headers: {
                                      Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                      'Content-type': 'application/json',
                                    },
                                    body: bodyData,
                                  })
                                    .then((e) => {
                                      return e.json()
                                    })
                                    .then((e) => {
                                      if (e.success === true) {
                                        this.setState(
                                          {
                                            emailValid: true,
                                            emailInvalid: false,
                                            SavebuttondisableState: false,
                                          },
                                          () => {
                                            if (this.state.email.trim().length === 0) {
                                              this.setState({
                                                emailValid: false,
                                                emailInvalid: false,
                                                SavebuttondisableState: true,
                                              })
                                            }
                                          }
                                        )
                                      } else {
                                        this.setState(
                                          {
                                            emailValid: false,
                                            emailInvalid: true,
                                            SavebuttondisableState: true,
                                          },
                                          () => {
                                            if (this.state.email.trim().length === 0) {
                                              this.setState({
                                                emailValid: false,
                                                emailInvalid: false,
                                                SavebuttondisableState: true,
                                              })
                                            }
                                          }
                                        )
                                      }
                                    })
                                    .catch((e) => {
                                      console.log('error', e)
                                    })
                                }
                              }
                            }
                          )
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (this.state.EmailState === true) {
                              this.setState({
                                SavebuttondisableState: false,
                                EmailState: false,
                              })
                            }
                          }}
                        >
                          edit
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormFeedback
                        style={{
                          display: this.state.emailInvalid ? `block` : `none`,
                        }}
                      >
                        Invalid email
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="examplePassword555">Current Password</Label>
                    <InputGroup>
                      <Input
                        valid={this.state.CurrentPasswordvalid}
                        invalid={this.state.CurrentPassworinvalid}
                        type="password"
                        name="password"
                        id="examplePassword555"
                        placeholder="password "
                        value={this.state.InputPassword}
                        onChange={(e) => {
                          this.setState(
                            { InputPassword: e.target.value },
                            () => {
                              setTimeout(() => {
                                this.setState({ LoadingState: true }, () => {
                                  console.log("Main -> render -> e", e);
                                  let User = {
                                    email: this.state.email,
                                    password: this.state.InputPassword,
                                  };

                                  fetch(
                                    "https://api.carwashsurveys.com/api/auth/signin",
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-type": "application/json",
                                      },
                                      body: JSON.stringify(User),
                                    }
                                  )
                                    .then((e) => {
                                      return e.json();
                                    })
                                    .then((e) => {
                                      console.log("Main -> render -> e", e);

                                      console.log(
                                        "Main -> render -> e.success",
                                        e.success
                                      );
                                      console.log(
                                        "Main -> render -> e.message",
                                        e.message
                                      );
                                      if (e.success !== undefined) {
                                        this.setState({
                                          CurrentPasswordvalid: true,
                                          CurrentPassworinvalid: false,
                                          LoadingState: false,
                                          PasswordReadState: false,
                                        });
                                      } else {
                                        console.log("Main -> render -> e", e);
                                        this.setState({
                                          CurrentPasswordvalid: false,
                                          CurrentPassworinvalid: true,
                                          LoadingState: false,
                                          PasswordReadState: true,
                                        });
                                      }
                                    })
                                    .catch((e) => {
                                      console.log("Main -> render -> e", e);
                                      swal({
                                        title: "something went wrong",
                                        icon: "warning",
                                      });
                                    });
                                });
                              }, 750);
                            }
                          );
                        }}
                      />

                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          style={{
                            display:
                              this.state.LoadingState === true
                                ? `block`
                                : `none`,
                          }}
                        >
                          <Spinner
                            color="primary"
                            className="spinner_special"
                            size="sm"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormFeedback>Incorrect password</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row> */}
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="examplePassword555">New Password</Label>
                    <Input
                      valid={false}
                      invalid={this.state.password_error_state}
                      value={this.state.Newpassword}
                      type="password"
                      name="password"
                      id="examplePassword555"
                      placeholder="New Password"
                      autoComplete="off"
                      onChange={(e) => {
                        this.setState({
                          Newpassword: e.target.value,
                          SavebuttondisableState: false,
                          password_error_state: false,
                        })
                      }}
                    />
                    <FormFeedback
                      style={{
                        display: this.state.password_error_state ? `block` : `none`,
                      }}
                    >
                      {this.state.password_error_text}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="examplePassword555">Confirm New Password</Label>
                    <Input
                      type="password"
                      name="password"
                      invalid={this.state.new_password_error_state}
                      id="examplePassword555"
                      value={this.state.Newpasswordconfirm}
                      placeholder="Confirm New Password"
                      onChange={(e) => {
                        this.setState({
                          Newpasswordconfirm: e.target.value,
                          SavebuttondisableState: false,
                          new_password_error_state: false,
                          password_error_state: false,
                        })
                      }}
                    />
                    <FormFeedback
                      style={{
                        display: this.state.new_password_error_state ? `block` : `none`,
                      }}
                    >
                      {this.state.new_password_error_text}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <LaddaButton
                data-style={ZOOM_IN}
                loading={this.state.ButtonLoading}
                color="primary"
                disabled={this.state.SavebuttondisableState}
                className="btn-wide mb-2 mr-2 btn-icon btn btn-primary btn-accept-modal btn-accept-account"
                onClick={() => {
                  const validPassword = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/)

                  this.setState({ ButtonLoading: true })
                  if (this.state.Name === '') {
                    this.setState({
                      ButtonLoading: false,
                      NameErrorState: true,
                      Name_error_text: 'Name cannot be eympty',
                    })
                  } else {
                    if (this.state.businessName === '') {
                      this.setState({
                        ButtonLoading: false,
                        businessname_error_state: true,
                        businessname_error_text: 'Business name cannot be eympty',
                      })
                    } else {
                      if (this.state.businessName.trim() === '') {
                        this.setState({
                          businessname_error_state: true,
                          businessname_error_text: 'Business name cannot be empty',
                        })
                      } else {
                        if (this.state.email === '') {
                          this.setState({
                            ButtonLoading: false,
                            email_error_state: true,
                            email_error_text: 'Email cannot be eympty',
                          })
                        } else {
                          if (!validator.isEmail(this.state.email)) {
                            this.setState({
                              ButtonLoading: false,
                              email_error_state: true,
                              email_error_text: 'Not a valid email',
                            })
                          } else {
                            if (this.state.Newpassword === '' && this.state.Newpasswordconfirm === '') {
                              let User = {
                                id: this.props.match.params.id,
                                name: this.state.Name,
                                email: this.state.email,
                                businessName: this.state.businessName,
                              }
                              const REGISTER_API_ENDPOINT = ProjectSettings.baseURL + '/api/user/profile'

                              const parameter = {
                                method: 'PUT',
                                headers: {
                                  Authorization: `Bearer ${this.state.token}`,
                                  'Content-type': 'application/json',
                                },
                                body: JSON.stringify(User),
                              }
                              fetch(REGISTER_API_ENDPOINT, parameter)
                                .then((e) => {
                                  return e.json()
                                })
                                .then((e) => {
                                  console.log('UserCreate -> e', e)
                                  this.setState(
                                    {
                                      ButtonLoading: false,
                                    },
                                    () => {
                                      swal({
                                        title: e.message,
                                        icon: 'success',
                                      }).then((e) => {
                                        if (e) {
                                          this.props.history.push('/super-admin')
                                        }
                                      })
                                      this.setState({
                                        ButtonLoading: false,
                                        // UseralreadyExisterror: {
                                        //   state: true,
                                        //   message: LoginReducer.response.message,
                                        //   type: "success",
                                        // },
                                      })
                                      this.UserDetailsUpdate()
                                    }
                                  )
                                })
                            } else {
                              if (this.state.Newpassword === '') {
                                this.setState({
                                  ButtonLoading: false,
                                  password_error_state: true,
                                  password_error_text: 'Field cannot be eympty',
                                })
                              } else {
                                if (this.state.Newpasswordconfirm === '') {
                                  this.setState({
                                    ButtonLoading: false,
                                    new_password_error_state: true,
                                    new_password_error_text: 'Field cannot be eympty',
                                  })
                                } else {
                                  if (this.state.Newpassword !== this.state.Newpasswordconfirm) {
                                    this.setState({
                                      ButtonLoading: false,
                                      new_password_error_state: true,
                                      new_password_error_text: 'Does not match the New Password',
                                    })
                                  } else {
                                    if (!validPassword.test(this.state.Newpassword) || !validPassword.test(this.state.Newpasswordconfirm)) {
                                      this.setState({
                                        ButtonLoading: false,
                                        new_password_error_state: true,
                                        new_password_error_text:
                                          'Password must have 7 characters, and must contain lower case letter, upper case letter, and number, and special character.',
                                      })
                                    } else {
                                      let User = {
                                        id: this.props.match.params.id,
                                        name: this.state.Name,
                                        email: this.state.email,
                                        businessName: this.state.businessName,
                                      }
                                      const REGISTER_API_ENDPOINT = ProjectSettings.baseURL + '/api/user/profile'

                                      const parameter = {
                                        method: 'PUT',
                                        headers: {
                                          Authorization: `Bearer ${this.state.token}`,
                                          'Content-type': 'application/json',
                                        },
                                        body: JSON.stringify(User),
                                      }
                                      fetch(REGISTER_API_ENDPOINT, parameter)
                                        .then((e) => {
                                          return e.json()
                                        })
                                        .then((e) => {
                                          let LoginReducer = e
                                          if (LoginReducer !== null) {
                                            if (LoginReducer.success === true) {
                                              if (this.state.Newpassword !== '') {
                                                fetch(ProjectSettings.baseURL + '/api/user/password', {
                                                  method: 'POST',

                                                  headers: {
                                                    Authorization: `Bearer ${this.state.token}`,
                                                    'Content-type': 'application/json',
                                                  },
                                                  body: JSON.stringify({
                                                    password: this.state.Newpasswordconfirm,
                                                    user_id: this.props.match.params.id,
                                                  }),
                                                })
                                                  .then((e) => {
                                                    return e.json()
                                                  })
                                                  .then((e) => {
                                                    this.setState(
                                                      {
                                                        ButtonLoading: false,
                                                      },
                                                      () => {
                                                        swal({
                                                          title: LoginReducer.message,
                                                          icon: 'success',
                                                        }).then((e) => {
                                                          if (e) {
                                                            this.props.history.push('/super-admin')
                                                          }
                                                        })
                                                        this.setState({
                                                          ButtonLoading: false,
                                                          // UseralreadyExisterror: {
                                                          //   state: true,
                                                          //   message: LoginReducer.response.message,
                                                          //   type: "success",
                                                          // },
                                                        })
                                                        this.UserDetailsUpdate()
                                                      }
                                                    )
                                                  })
                                                  .catch((e) => {
                                                    this.setState({
                                                      ButtonLoading: false,
                                                    })
                                                    swal({
                                                      title: 'something went wrong',
                                                      icon: 'error',
                                                    })
                                                  })
                                              } else {
                                                this.UserDetailsUpdate()
                                              }
                                            } else {
                                              swal({
                                                title: LoginReducer.message,
                                                icon: 'warning',
                                              })
                                              this.setState({
                                                ButtonLoading: false,
                                                // UseralreadyExisterror: {
                                                //   state: true,
                                                //   message: LoginReducer.response.message,
                                                //   type: "error",
                                                // },
                                              })
                                            }
                                          }
                                        })
                                        .catch((e) => {
                                          console.log('UserCreate -> e', e)
                                          swal({
                                            title: 'Something went wrong',
                                            icon: 'warning',
                                          })
                                        })
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }}
              >
                {' '}
                Save
              </LaddaButton>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserCreate))
