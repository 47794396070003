import { put, call } from 'redux-saga/effects'
import ProjectSettings from '../../global/Global'
const LoginUserServices = (request) => {
  console.log('registerUserService -> request', request)
  let User = {
    email: 'ramesh@carwash.com',

    // email: request.email,
    // password: request.password,
  }
  console.log('registerUserService -> User', User)
  const REGISTER_API_ENDPOINT = ProjectSettings.baseURL + '/api/auth/signin'

  const parameter = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(User),
  }

  return fetch(REGISTER_API_ENDPOINT, parameter)
    .then((response) => {
      console.log('registerUserService -> response', response)
      return response.json()
    })
    .then((json) => {
      console.log('registerUserService -> json', json)
      return json
    })
}

export function* LoginSage11(payload) {
  console.log('payload', payload)
  try {
    const response = yield call(LoginUserServices, payload)
    yield put({ type: 'LOGIN_USER_SUCCESFUL', response })
  } catch (e) {
    put({ type: 'LOGIN_USER_ERROR', e })
  }
}
