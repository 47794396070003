export const UserReducer11 = (
  state = {
    name: '',
    email: '',
    businessName: '',
  },
  action
) => {
  switch (action.type) {
    case 'USER_UPDATED':
      return {
        ...state,
        name: action.name,
        email: action.email,
        businessName: action.businessName,
      }

    default:
      return state
  }
}
