import React, { Fragment } from 'react'
// import { Route, NavLink } from 'react-router-dom'

import AppHeader from '../../../Layout/AppHeader'
import AppSidebar from '../../../Layout/AppSidebar'

// import SurveyCreator from "../../Survey"
// import SurveyCreator from "../../Survey/test1"
// import SurveyCreator from "../../Survey/test2"
// import SurveyCreator from "../../Survey/test3"
// import SurveyCreator from "../../Survey/test5"
import SurveyEditortest from '../surveyEdittest'
// import SurveyPage from "../../Survey/test6"
export default function SurveyEditor11() {
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <SurveyEditortest />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
