import React, { Fragment } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${
    '' /* ${(props) =>
    props.location === 'Singup'
      ? css`
          left: 80%;
          height: fit-content;
          width: fit-content;
          background: white;
        `
      : css`
          padding: 95px;
          position: relative;
          left: 80%;
          height: fit-content;
          width: fit-content;
          background: white;
        `} */
  }

  padding: 10px;
  position: absolute;
  left: 80%;
  height: fit-content;
  width: fit-content;
  background: white;
  display: ${(e) => (e === true ? `block` : `none`)};
  z-index: 99;
`

export default function ToolTip(props) {
  // const [Display, setDisplay] = React.useState('')
  React.useEffect(() => {})

  return (
    <Fragment>
      <div
        className="triangle"
        style={{
          display: props.location === 'Singup' ? `block` : `none`,
          position: 'absolute',
          height: '20px',
          width: '15px',
          background: 'white',
          top: '55%',
          left: '-5px',
          clipPath: 'polygon(0 50%, 100% 0, 100% 100%)',
        }}
      ></div>
      <Wrapper
        {...props}
        style={{
          display: props.props === true ? `block` : `none`,
        }}
      >
        {props.message}
      </Wrapper>
    </Fragment>
  )
}
