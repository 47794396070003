import React, { Component, Fragment } from "react";
// import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../redux/ThemeOptions";

import { MainNav, ComponentsNav, WidgetsNav } from "./Navitems1";
import "./navbar.css";

class Nav extends Component {
  state = {};

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    return (
      <Fragment>
        {/* <div className="sidenavbar">
      <NavLink  className = "Navbar sideNavbar" to="Dashboard" >
      <div className="sideNavbar_inner">

      <img  src={svg} alt="..."></img>
      </div>
      <div className = "svg_text">

Dashboard 
</div>
</div>
      </NavLink>

      <NavLink className = "Navbar sideNavbar" to="my-survey">
      <div className="sideNavbar_inner">
      <div className="svg_icon">

      </div>
      <div className = "svg_text">
Surveys
</div>
</div>
</NavLink>

<NavLink className = "Navbar sideNavbar" to="account-settings">
<div className="sideNavbar_inner">

<div className="svg_icon">
</div>

<div className = "svg_text">

Team members
</div>
</div>
</NavLink>

<NavLink className = "Navbar sideNavbar" to="team-members">
<div className="sideNavbar_inner">
<div className="svg_icon">


</div>
<div className = "svg_text">
Account Settings
</div>
</div>
</NavLink>
   </div> */}

        <MetisMenu
          content={MainNav}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />

        <MetisMenu
          content={ComponentsNav}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />

        {/* <MetisMenu
          content={FormsNav}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        /> */}

        <MetisMenu
          content={WidgetsNav}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Nav);
