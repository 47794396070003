import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router'
import './header.css'
import { connect } from 'react-redux'

import { CouponCodeaction } from '../../../redux/actions/Coupon_Code'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
// import Reader from './Reader'
import SweetAlert from 'sweetalert-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
// import { Create_surveyaction } from '../../../redux/actions/Create_survey'
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SurveyButtonClick: false,
      showState: false,
      Dropdown: false,
      Checked: false,
      Error_Message: '',
      ErrorState: false,
      SurveyName: '',
      InsideModalState: false,
    }
  }
  componentDidMount() {
    this.props.dispatch(CouponCodeaction([], false))
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.Checked !== prevState.Checked) {
      if (this.state.Checked === true) {
        this.setState({ InsideModalState: true })
      }
    }
  }

  render() {
    return (
      <>
        <SweetAlert
          title={this.state.Error_Message}
          confirmButtonColor=""
          show={this.state.ErrorState}
          text=""
          type="error"
          onConfirm={() => this.setState({ ErrorState: false, Checked: false })}
        />
        <div>
          {/* <SessioTimeoutModal /> */}
          <Modal
            isOpen={this.state.showState}
            toggle={() => this.setState({ showState: false })}

            // className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
            <ModalBody>
              <div className="modal_form">
                <div className="create_survey_form" style={{ flexDirection: 'column' }}>
                  <input
                    style={{ Background: '#f8f9fa', width: '100%' }}
                    type="text"
                    placeholder="Survey Name"
                    onChange={(e) => {
                      this.setState({ SurveyName: e.target.value })
                    }}
                    value={this.state.SurveyName}
                  ></input>
                  {this.state.SurveyName === '' ? <div style={{ color: 'red' }}>Cannot be empty</div> : <div></div>}
                </div>
                {/* <div className="create_survey_form">
                  <div className="create_survey_form_inner">
                    <ButtonDropdown
                      isOpen={this.state.Dropdown}
                      toggle={() => {
                        this.setState({ Dropdown: !this.state.Dropdown });
                      }}
                      color="primary"
                    >
                      <DropdownToggle caret>Draft</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem disabled>draft</DropdownItem>

                        <DropdownItem disabled>Active</DropdownItem>

                        <DropdownItem disabled>Complete</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                </div>
                <div className="create_survey_form">
                  <div className="create_survey_form_inner">
                    <div
                      style={{
                        diaplay: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        style={{ margin: "0px" }}
                        control={
                          <Checkbox
                            checked={this.state.Checked}
                            onChange={() => {
                              this.setState({
                                Checked: !this.state.Checked,
                              });
                            }}
                            color="primary"
                          />
                        }
                        label="Allow Coupon Code"
                      />

                      <Modal
                        isOpen={this.state.InsideModalState}
                        toggle={() =>
                          this.setState({ InsideModalState: false })
                        }
                      >
                        <ModalHeader>Nested Modal title</ModalHeader>
                        <ModalBody>
                          <Reader
                            // CouponCode={this.state.Surveyfrommid.CouponCode}
                            // CouponCode={["asdfbdjklsnfnsdf", "asdfbadsbfkjsdbf"]}
                            Array_sending={(e) => {
                              console.log("e", e);
                              if (e === null) {
                                // this.props.Error = "kindly upload a valid file";
                                this.setState({
                                  AddCouponError: "kindly upload a valid file",
                                });
                              } else {
                                console.log("render -> e", e);
                                this.props.dispatch(
                                  CouponCodeaction(e, this.state.Checked)
                                );
                                // this.setState({ Coupon_codeNumber: e });
                              }
                            }}
                            ButtonCLickedState={this.state.AddCouponClicked}
                          />
                          <div>
                            Coupon code Available{" "}
                            {Object.keys(this.props.state.Coupon_code_reducer)
                              .length === 0
                              ? Object.keys(
                                  this.props.state.Coupon_code_reducer
                                ).length
                              : this.props.state.Coupon_code_reducer.Coupon
                                  .length}
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="link"
                            onClick={() =>
                              this.setState({
                                InsideModalState: false,
                                Checked: false,
                              })
                            }
                          >
                            {" "}
                            Cancel{" "}
                          </Button>
                          <Button
                            color="primary"
                            onClick={() =>
                              this.setState({ InsideModalState: false })
                            }
                          >
                            {" "}
                            Done{" "}
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                    {this.state.Checked === true ? (
                      Object.keys(this.props.state.Coupon_code_reducer)
                        .length === 0 &&
                      this.props.state.Coupon_code_reducer.constructor ===
                        Object ? (
                        <Reader
                          // CouponCode={this.state.Surveyfrommid.CouponCode}
                          // CouponCode={["asdfbdjklsnfnsdf", "asdfbadsbfkjsdbf"]}
                          Array_sending={(e) => {
                            console.log("e", e);
                            if (e === null) {
                              // this.props.Error = "kindly upload a valid file";
                              this.setState({
                                AddCouponError: "kindly upload a valid file",
                              });
                            } else {
                              console.log("render -> e", e);
                              this.props.dispatch(
                                CouponCodeaction(e, this.state.Checked)
                              );
                              // this.setState({ Coupon_codeNumber: e });
                            }
                          }}
                          ButtonCLickedState={this.state.AddCouponClicked}
                        />
                      ) : (
                        <div></div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div className="create_survey_form">
                  <div className="create_survey_form_inner">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        Coupon code Available{" "}
                        {Object.keys(this.props.state.Coupon_code_reducer)
                          .length === 0
                          ? Object.keys(this.props.state.Coupon_code_reducer)
                              .length
                          : this.props.state.Coupon_code_reducer.Coupon.length}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button color="link" onClick={() => this.setState({ showState: false })}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  if (
                    Object.keys(this.props.state.Coupon_code_reducer).length === 0 &&
                    this.props.state.Coupon_code_reducer.constructor === Object &&
                    this.state.Checked === true
                  ) {
                    this.setState({
                      Error_Message: 'File Uploaded is inValid, by clicking OK the Add coupon Option will be Deselected',
                      ErrorState: true,
                    })
                  } else {
                    if (this.state.SurveyName === '') {
                      this.setState({
                        Error_Message: 'name cannot be empty',
                        ErrorState: true,
                      })
                    } else {
                      this.setState({
                        showState: false,
                      })

                      this.props.history.push(`/create-survey/${this.state.SurveyName}`)
                    }
                  }
                }}
              >
                Create-Survey
              </Button>{' '}
            </ModalFooter>
          </Modal>
        </div>
        <div className="header-outer">
          <div className="header-inner">
            <div className="header-topic">Surveys</div>
            <div className="header-right-side">
              {/* <Button
                className="mb-2 mr-2 btn-icon btn-shadow btn-outline-2x"
                outline
                color="light"
              >
                <i className="pe-7s-edit btn-icon-wrapper"> </i>
                Filters
              </Button> */}

              <Button
                className="btn-wide mb-2 mr-2 btn-hover-shine btn-icon btn-special-primary-hover"
                color="primary"
                onClick={(e) => {
                  this.setState({
                    SurveyButtonClick: !this.state.SurveyButtonClick,
                    // showState: true,
                  })
                  this.props.history.push('/create-survey')
                }}
              >
                <i className="pe-7s-plus btn-icon-wrapper"> </i>
                Create New Survey
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))

// export default Header;
