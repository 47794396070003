import React from 'react'

// import CheckBoxIcon from '@material-ui/icons/CheckBox'
import InfoDiv from './infoDiv'
import Button from '@material-ui/core/Button'
import IconComponent from './IconComponent'
import './infodiv.css'
// import $ from 'jquery'
export default class Questions extends React.Component {
  constructor(props) {
    super()
    this.state = {
      QuestionList: [
        {
          type: 'Single Input',
          className: '.svd_toolbox_item_icon-text',
          state: false,
          iconClass: 'TextFieldsIcon',
        },

        {
          type: 'Checkbox',
          className: '.svd_toolbox_item_icon-checkbox',
          state: false,
          iconClass: 'CheckBoxIcon',
        },
        {
          type: 'Radiogroup',
          className: '.svd_toolbox_item_icon-radiogroup',
          state: false,
          iconClass: 'BallotIcon',
        },
        // {
        //   type: "Dropdown",
        //   className: ".svd_toolbox_item_icon-radiogroup",
        //   iconClass: "ArrowDropDownCircleIcon",
        //   state: false,
        // },
        {
          type: 'Comment',
          className: '.svd_toolbox_item_icon-comment',
          state: false,
          iconClass: 'CommentIcon',
        },
        {
          type: 'Rating',
          className: '.svd_toolbox_item_icon-rating',
          state: false,
          iconClass: 'StarsIcon',
        },
        {
          type: 'Net Promoter Score',
          className: '.svd_toolbox_item_icon-default',
          state: false,
          iconClass: 'EmojiEmotionsIcon',
        },
        {
          type: 'Customer Satisfaction Score',
          className: '.svd_toolbox_item_icon-csc',
          state: false,
          iconClass: 'FavoriteIcon',
        },
        {
          type: 'Customer Effort Score',
          className: '.svd_toolbox_item_icon-ces',
          state: false,
          iconClass: 'FitnessCenterIcon',
        },
        {
          type: 'Image picker',
          className: '.svd_toolbox_item_icon-imagepicker',
          state: false,
          iconClass: 'ImageIcon',
        },

        {
          type: 'Boolean',
          className: '.svd_toolbox_item_icon-boolean',
          state: false,
          iconClass: 'TuneIcon',
        },

        // {
        //   type: "Image",
        //   className: ".svd_toolbox_item_icon-image",
        //   state: false,
        //   iconClass: "ImageIcon",
        // },

        // {
        //   type: "Html",
        //   className: ".svd_toolbox_item_icon-html",
        //   state: false,
        //   iconClass: "CodeIcon",
        // },

        // {
        //   type: "Signature Pad",
        //   className: ".svd_toolbox_item_icon-signaturepad",
        //   state: false,
        //   iconClass: "Signature",
        // },

        // {
        //   type: "Expression (read-only)",
        //   className: ".svd_toolbox_item_icon-expression",
        //   state: false,
        //   iconClass: "CheckBoxIcon807890",
        // },

        {
          type: 'file',
          className: '.svd_toolbox_item_icon-file',
          state: false,
          iconClass: 'AttachFileIcon',
        },

        // {
        //   type: "Matrix",
        //   className: ".svd_toolbox_item_icon-matrix",
        //   state: false,
        //   iconClass: "CheckBoxIcon980890",
        // },

        // {
        //   type: "Matrix (multiple choice)",
        //   className: ".svd_toolbox_item_icon-matrixdropdown",
        //   state: false,
        //   iconClass: "CheckBoxIcon890890",
        // },

        // {
        //   type: "Matrix (dynamic rows)",
        //   className: ".svd_toolbox_item_icon-matrixdynamic",
        //   state: false,
        //   iconClass: "CheckBoxIconasdasdasd",
        // },

        {
          type: 'Multiple Text',
          className: '.svd_toolbox_item_icon-multipletext',
          state: false,
          iconClass: 'CheckBoxIcondfgggg',
        },
      ],

      width: null,
      display: 'none',
      height: null,
      index: null,
      top: null,
      windowscrolloffset: null,
    }
  }
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    // $(document).ready(() => {
    // console.log(
    //   "InfoDiv -> componentDidMount ->       document.querySelector('.side_question_navbar_inner').getBoundingClientRect().right",
    //   document
    //     .querySelector(".side_question_navbar_question_wrapper")
    //     .getBoundingClientRect().right
    // );
    // this.state.width = document
    //   .querySelector(".side_question_navbar_question_wrapper")
    //   .getBoundingClientRect().right;

    if (prevState.index !== this.state.index) {
      this.setState({
        height: document.querySelector(`#question_type${this.state.index}`).getBoundingClientRect().top,
        windowscrolloffset: window.pageYOffset,
      })
      this.setState({
        width: document.querySelector('.side_question_navbar_question_wrapper').getBoundingClientRect().right,
      })
    }

    if (prevState.width !== this.state.width) {
      // this.setState({
      //   width: document
      //     .querySelector(".side_question_navbar_question_wrapper")
      //     .getBoundingClientRect().right,
      // });
    }
    // });
  }

  render() {
    return (
      <div>
        <div className="side_question_navbar">
          {this.state.QuestionList.map((e, i) => (
            <div id={`question_type${i}`} className="side_question_navbar_inner">
              <div
                onMouseEnter={() => {
                  let newarray = [...this.state.QuestionList]
                  newarray[i].state = true

                  this.setState({
                    QuestionList: newarray,
                    display: 'flex',
                    index: i,
                  })
                }}
                onMouseLeave={() => {
                  let newarray = [...this.state.QuestionList]
                  newarray[i].state = false

                  this.setState({
                    QuestionList: newarray,
                    display: 'none',
                    index: i,
                  })
                }}
                onClick={() => {
                  document.querySelector(`${e.className}`).click()
                }}
                className="side_question_navbar_question"
              >
                <div className="side_question_navbar_question_wrapper">
                  <div className="side_question_navbar_question_wrapper_left_side">
                    <div className="svg_icon">
                      <IconComponent
                        // iconclass={this.state.QuestionList[i].Iconclass}
                        iconClass={this.state.QuestionList[i]}
                      />
                    </div>
                    <div className="question_type">{e.type}</div>
                  </div>
                  <div className="side_question_navbar_question_wrapper_right_side">
                    {this.state.QuestionList[i].state === true && window.innerWidth >= 1250 ? (
                      <div className="Button_add">
                        <Button
                          variant="outlined"
                          style={{
                            height: '20px',
                            width: '20px',
                            color: '#676a6c',
                            backgroundColor: 'white',
                            fontSize: '10px',
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            left: this.state.width + 20,
            top: this.state.height + this.state.windowscrolloffset - 70,
            display: this.state.display,
          }}
          className="info_div_wrapper"
        >
          {this.state.QuestionList.map((e, i) => (
            <InfoDiv
              // infoDivClick={(e) => {
              //   let newarray = [...this.state.QuestionList];
              //   newarray[i].state = false;

              //   this.setState({
              //     QuestionList: newarray,
              //   });
              // }}
              index={i}
              height={this.state.height}
              left={this.props.width}
              offsetTop={this.state.windowscrolloffset}
              props={this.state.QuestionList[i].state}
              class={this.state.QuestionList[i].type}
            />
          ))}
        </div>
      </div>
    )
  }
}
