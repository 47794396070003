import { all } from 'redux-saga/effects'
// import { watchLogin, watchForLogout } from "./loginSaga";
import { watchUserAuthentication, watcherAddCoupon, watcherAddSurvey, watcherLoginUser } from './watcher'
function* rootSaga() {
  yield all([watchUserAuthentication(), watcherAddCoupon(), watcherAddSurvey(), watcherLoginUser()])
  // yield all([
  //   watchLogin(),
  //   watchForLogout(),
  // ])
}

export { rootSaga }
