import React, { Component } from 'react'
import './dasboardQuestion.css'
import * as Survey from 'survey-react'
import * as SurveyKo from 'survey-knockout'
import * as SurveyJSCreator from 'survey-creator'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
// import svg1 from '../../../../assets/utils/images/verified.svg'
import 'survey-creator/survey-creator.css'
// import * as RModal from 'react-modal'
// import variable from '../../../../global/font'
// import {
//   CouponCodeaction2,
//   CouponCodeaction21,
//   CouponCodeaction_remove_codes_top,
//   CouponCodeaction_remove_codes_bottom,
// } from '../../../../redux/actions/Coupon_code2'
// import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2'

import Cloud_icon from '../../../../assets/cloud_icon.png'
import tick_icon from '../../../../assets/tick_icon.png'
import tag_icon from '../../../../assets/tag_icon.png'
import Loader11 from '../../../Loader'
import ToolTip from '../../../../Component/tooltip' //../../Component/tooltip";
import Switch from '@material-ui/core/Switch'
// import Cloud_image from '../../../../assets/cloud-computing.svg'
// import Tick from '../../../../assets/checked.svg'
// import tag from '../../../../assets/tag.svg'
import delete_image from '../../../../assets/delete.svg'
import save_icon from '../../../../assets/save_icon.svg'
// import { SurveyOptionActions, SurveyOPtionAction1 } from '../../../../redux/actions/SurveyOption'
import LaddaButton, {
  // EXPAND_LEFT,
  // EXPAND_RIGHT,
  // EXPAND_UP,
  // EXPAND_DOWN,
  // SLIDE_LEFT,
  // SLIDE_RIGHT,
  // SLIDE_UP,
  // SLIDE_DOWN,
  ZOOM_IN,
  // ZOOM_OUT,
} from 'react-ladda'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  // ButtonDropdown,
  UncontrolledButtonDropdown,
  // Tooltip,
  Progress,
  FormFeedback,
  Input,
} from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import { LoaderActions_loadingtrue, LoaderActions_loadingfalse } from '../../../../redux/actions/Loader'

import 'react-toastify/dist/ReactToastify.css'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import 'jquery-ui/themes/base/all.css'
import 'nouislider/distribute/nouislider.css'
import 'select2/dist/css/select2.css'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

import 'jquery-bar-rating/dist/themes/css-stars.css'
import 'jquery-bar-rating/dist/themes/fontawesome-stars.css'
import { connect } from 'react-redux'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker.js'
import 'select2/dist/js/select2.js'
import 'jquery-bar-rating'
import { withRouter } from 'react-router-dom'

//import "icheck/skins/square/blue.css";
import 'pretty-checkbox/dist/pretty-checkbox.css'
import * as widgets from 'surveyjs-widgets'
import CSVReader from 'react-csv-reader'
import Questions from './QuestionBar'
import './test4.css'
import ProjectSettings from '../../../../global/Global'

//
import SweetAlert from 'sweetalert-react'
import swal from 'sweetalert'
import { SurveySave } from '../../../../redux/actions/SurveyJsonActions'
import { FormGroup } from '@material-ui/core'
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
}

class Reader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Duplicate_removed: 0,
      status: null,
      Error: false,
      Error_Message: '',
      CSV_data: null,
      FileInfo: '',
      TotalNumberArray: 0,
      TotalArrayNukber: 0,
      progress_value: 0,
    }
  }
  progress_value = () => {
    setTimeout(() => {
      this.setState(
        {
          progress_value: this.state.progress_value + 50,
        },
        () => {
          setTimeout(() => {
            this.setState({
              progress_value: this.state.progress_value + 50,
            })
          }, 1000)
        }
      )
    }, 1000)
  }
  progress_function = (trigger) => {
    if (trigger) {
      this.setState({ progress_value: 0 }, () => {
        this.progress_value()
      })
    }
  }

  delete_function_bottom = () => {
    this.setState(
      {
        TotalArrayNukber: [],
        FileInfo: '',
        progress_value: 0,
      },
      () => {
        this.props.coupon_delete()
        this.props.Array_sending(null)
      }
    )
  }

  componentDidUpdate(prevProps, prevState) {}
  componentDidMount() {
    if (Object.keys(this.props.Info).length > 0) {
      this.setState({
        TotalArrayNukber: this.props.Info.Coupon_code_processed,
        Duplicate_removed: this.props.Info.Duplicate_removed,
        FileInfo: this.props.Info.file_info,
        progress_value: 100,
      })
    }
  }
  handleForce = (data, fileInfo) => {
    this.setState({ FileInfo: fileInfo.name, CSV_data: data }, () => {
      this.progress_function('trigger')
    })

    var couponcodeArray = []

    var User_input_csv_header = Object.keys(data[0])[0]
    var User_input_csv_header_lower_case = User_input_csv_header.toLowerCase()

    if (User_input_csv_header_lower_case !== 'coupon_code') {
      this.setState({
        Error: true,
        Error_Message: `First column titile ${User_input_csv_header} is not equal to Coupon_Code as instructed`,
      })
      swal({
        icon: 'error',
        title: 'error',
        text: `First column titile ${User_input_csv_header} is not equal to Coupon_Code as instructed`,
      })
    } else {
      data.forEach((e, i) => {
        couponcodeArray.push(e.coupon_code)
      })

      let array_without_duplicates = couponcodeArray.filter((value, index) => couponcodeArray.indexOf(value) === index)

      this.setState({
        TotalArrayNukber: couponcodeArray.length,
        Duplicate_removed: couponcodeArray.length - array_without_duplicates.length,
      })

      this.setState({ TotalNumberArray: array_without_duplicates.length })
      this.props.Array_sending(array_without_duplicates)
      this.props.Info_sending({
        Coupon_code_processed: this.state.TotalArrayNukber,
        Duplicate_removed: this.state.Duplicate_removed,
        new_codes: this.state.TotalNumberArray,
        file_info: this.state.FileInfo,
      })
    }
  }
  handlefileUpload = (file) => {
    let _filename = file.target.files[0].name

    var reader = new FileReader()
    let couponcodeArray = []
    if (file.target.files.length > 0) {
      reader.readAsText(file.target.files[0])
      reader.onloadstart = (event) => {
        console.log('reader.onloadstart -> event', event)
      }
      reader.onprogress = (event) => {}
      reader.onloadend = (event) => {
        var allTextLines = event.target.result.split(/\r\n|\n/)
        var lines = allTextLines.map((data) => data.split(';'))

        let fileHeader = lines[0].toString()

        if (fileHeader.toLowerCase().trim() !== 'coupon code') {
          swal({
            icon: 'error',
            title: 'Error',
            text: `CSV file should have only one column with the title Coupon Code.`,
          })
        } else {
          this.setState({ FileInfo: _filename }, () => {
            this.progress_function('trigger')
          })
          let data = lines.flat()

          data.shift()
          data = data.filter((e) => e !== '')
          data.forEach((e, i) => {
            couponcodeArray.push(e)
          })
          let _temp = new Set(couponcodeArray)
          let temp = Array.from(_temp)

          // let array_without_duplicates = couponcodeArray.filter((value, index) => couponcodeArray.indexOf(value) === index)

          this.setState({
            TotalArrayNukber: couponcodeArray.length,
            Duplicate_removed: couponcodeArray.length - temp.length,
          })

          this.setState({ TotalNumberArray: temp.length })
          this.props.Array_sending(temp)
          this.props.Info_sending({
            Coupon_code_processed: this.state.TotalArrayNukber,
            Duplicate_removed: this.state.Duplicate_removed,
            new_codes: this.state.TotalNumberArray,
            file_info: this.state.FileInfo,
          })
        }
      }
    }
  }

  render() {
    return (
      <div
        className="container"
        style={{
          flexDirection: 'column',
        }}
      >
        {/* <SweetAlert
          title={this.state.Error_Message}
          confirmButtonColor=""
          show={this.state.Error}
          text=""
          type="error"
          onConfirm={() => this.setState({ Error: false })}
        /> */}

        <div className="row_container outer1">
          <div className="row_container1 ">
            <div>
              <button
                size="lg"
                color="primary"
                onClick={() => {
                  document.querySelector('#special_selector_file').click()
                }}
                className="Font_style special_button manage_coupon_code"
                style={{
                  width: '111%',
                }}
              >
                <img style={{ height: '40px', width: '60px' }} src={Cloud_icon} alt={''} />
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: '1000',
                    paddingBottom: '10px',
                  }}
                >
                  Select CSV file to upload
                </span>
              </button>
              <input
                type="file"
                id="special_selector_file"
                style={{
                  display: 'none',
                }}
                onChange={(e) => this.handlefileUpload(e)}
                onClick={(e) => {
                  e.target.value = null
                }}
                accept=".csv"
              />
              <CSVReader
                cssClass="react-csv-input"
                cssInputClass="input_class "
                cssLabelClass="Label_class"
                onFileLoaded={this.handleForce}
                parserOptions={papaparseOptions}
              />
            </div>
          </div>
          <div className="row_container1 ">
            <div
              className="wrapper-div"
              style={{
                marginLeft: '45px',
              }}
            >
              <div className="Font_style11">
                {this.state.TotalArrayNukber > 0 && this.state.progress_value === 100 ? (
                  <div
                    className="Font_style11 second_padding"
                    style={{
                      paddingLeft: '0px',
                    }}
                  >
                    Coupon codes processed:{' '}
                    <span
                      style={{
                        color: '#00000',
                      }}
                    >
                      {this.state.TotalArrayNukber}
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              {this.state.TotalArrayNukber > 0 && this.state.progress_value === 100 ? (
                <div className="Font_style11 second_padding">
                  Duplicate removed :{' '}
                  <span
                    style={{
                      color: '#00000',
                    }}
                  >
                    {JSON.stringify(this.state.Duplicate_removed)}
                  </span>
                </div>
              ) : (
                <div></div>
              )}

              {this.state.TotalArrayNukber > 0 && this.state.progress_value === 100 ? (
                <div className="Font_style11 second_padding" style={{ paddingBottom: '5px' }}>
                  Total new codes added: {this.state.TotalArrayNukber - this.state.Duplicate_removed}
                </div>
              ) : (
                <div></div>
              )}

              {this.state.TotalArrayNukber > 0 && this.state.progress_value === 100 ? (
                <div className="Font_style11 button_style">
                  Total codes available:{' '}
                  <span
                    style={{
                      color: '#00000',
                    }}
                  >
                    {this.state.TotalNumberArray}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <div className="row_container ">
          <div
            style={{
              display: 'flex',
            }}
          >
            {this.state.FileInfo === undefined || this.state.FileInfo === '' ? (
              <span className="Font_style left-align">No file chosen</span>
            ) : (
              <>
                <span className="Font_style left-align" style={{ fontSize: '13px' }}>
                  {this.state.progress_value === 100 ? (
                    <text>
                      {this.state.FileInfo.length > 17 ? 'File: ' + this.state.FileInfo.substring(0, 15) + '....' : 'File: ' + this.state.FileInfo}
                    </text>
                  ) : (
                    'Uploading'
                  )}
                </span>
                <div style={{ position: 'absolute', right: '45%' }}>
                  {this.state.progress_value === 100 ? (
                    <img
                      className="delete_icon"
                      onClick={() => {
                        this.delete_function_bottom()
                      }}
                      src={delete_image}
                      alt={''}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row_container">
          <div
            className="inner progress-inner"
            style={{
              display: this.state.FileInfo ? (this.state.FileInfo.length > 0 ? `block` : `none`) : `none`,
              marginTop: '5px',

              position: 'relative',
            }}
          >
            <Progress className="progress_special" color="warning" value={this.state.progress_value} />
            <img
              style={{
                display: this.state.progress_value === 100 ? `block` : `none`,
                height: '15px',
                width: '20px',
                paddingBottom: '5px',
              }}
              src={tick_icon}
              alt={''}
            />
          </div>
        </div>
      </div>
    )
  }
}
SurveyJSCreator.StylesManager.applyTheme('modern')
Survey.StylesManager.applyTheme('modern')

widgets.icheck(SurveyKo, $)
widgets.prettycheckbox(SurveyKo)
widgets.select2(SurveyKo, $)
widgets.inputmask(SurveyKo)
widgets.jquerybarrating(SurveyKo, $)
widgets.jqueryuidatepicker(SurveyKo, $)
widgets.nouislider(SurveyKo)
widgets.select2tagbox(SurveyKo, $)
// widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo)
widgets.ckeditor(SurveyKo)
widgets.autocomplete(SurveyKo, $)
widgets.bootstrapslider(SurveyKo)

// var model = new Survey.Model(json);

class SurveyCreator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SurveyPreview: null,
      showState: false,
      closeState: true,
      number: 0,
      addCoupon: false,
      showInfodiv: false,
      savedSurveyjson: null,
      status: null,
      FileUploadeState: null,
      saveSurveyName: false,
      surveyName: '',
      surveyNameInvalid: false,
      surveyNameValid: false,
      SurveyState: 'Draft',
      Coupon_codeNumber: null,
      Coupon_code_details: {},
      SurveyEventListener: false,
      AddCouponClicked: false,
      AddCouponError: null,
      Surveyfrommid: null,
      Newsurveyid: null,
      Checked: false,
      SavedSurveyId: null,
      InsideModalState: false,
      EdgeCaseError: null,
      EdgeCaseErrorState: false,
      QuestionChecker: 0,
      BackErrorState: false,
      BackError: '',
      BackConfirm: false,
      SurveyQuestionNumber: 0,
      FileUploadError: false,
      FileUploadMessage: '',
      NameError: '',
      color: 'warning',
      NameErrorState: false,
      Currentpage: {},
      ButtonLoading: false,
      toggle_state: false,
      ID: 'testing',
      email_question_add: false,
      error_case: false,
      error_case_count: 0,
      Hover_state: false,
      Hover_state_button: false,
      FileSize: 0,
      LogoState: '',
      NoCouponCodeError: {
        state: false,
        error: '',
      },
      aarraay: [
        'the survey does',
        'success',
        {
          buttons: {
            cancel: 'cancel',
          },
        },
      ],
      questionCounter: 1,
    }
  }
  surveyCreator

  // componentWillMount() {
  //   // if (this.props.state.LoginReducer11 === null) {
  //   //   this.props.history.push('/login')
  //   // }
  //   // if(this.props.state.Coupon_code_reducer.checkedState)
  //   // let { SaveSurvey } = this.props.state;
  //   // let { LoginReducer11 } = this.props.state;
  //   // console.log(
  //   //   "SurveyCreator -> componentWillMount -> LoginReducer11",
  //   //   LoginReducer11
  //   // );

  //   // console.log(
  //   //   "SurveyCreator -> componentWillMount -> SaveSurvey",
  //   //   SaveSurvey
  //   // );
  //   // let Survey1 = [];
  //   // let params = this.props.match.params;
  //   // SaveSurvey.forEach((e) => {
  //   //   if (e.id !== null) {
  //   //     Survey1.push({
  //   //       json: e.json,
  //   //       id: e.id,
  //   //       name: e.name,
  //   //     });
  //   //   }
  //   // });

  //   // const found = Survey1.find((element) => element.id == params.id);
  //   // if (found === null || found === undefined) {
  //   //   this.setState({ Newsurveyid: params.id });
  //   // }
  //   // this.setState({ Surveyfrommid: found });

  //   // console.log(
  //   //   "SurveyCreator -> componentWillMount -> this.state",
  //   //   this.state
  //   // );
  // }

  AddedDomchanges = () => {
    document.querySelectorAll('.svda_current_type').forEach((e) => {
      e.style.display = 'none'
    })
  }
  Loader_visible = () => {
    this.props.dispatch(LoaderActions_loadingtrue())
  }

  Loader_hide = () => {
    setTimeout(() => {
      this.props.dispatch(LoaderActions_loadingfalse())
    }, 500)
  }

  Save_survey = () => {
    if (this.state.Checked === true && this.state.Coupon_codeNumber.length) {
      // swal({
      //   title: "Somthing went wrong",
      //   icon: "error",
      // });
    }

    // let body111 = {
    //   surveyName: this.state.surveyName,
    //   surveyJson: this.state.savedSurveyjson,
    //   couponCode: this.state.Coupon_codeNumber,
    //   status: this.state.SurveyState,
    // }
    this.setState(
      {
        ButtonLoading: true,
      },
      () => {
        if (this.state.FileSize > 50000) {
          let Date_local = new Date()

          let Base64Logo = this.surveyCreator.JSON.logo
          this.surveyCreator.JSON = {
            ...this.surveyCreator.JSON,
            logoPosition: 'top',
            logo: '',
          }
          let body11 = JSON.stringify({
            surveyName: this.state.surveyName,
            surveyJson: this.surveyCreator.JSON,
            couponCode: this.state.Coupon_codeNumber,
            status: this.state.SurveyState,
            TimeStampLocal: Date_local,
            userId: this.props.match.params.id,

            CouponCodeStatus: this.state.Checked === true ? 'Active' : 'Inactive',
          })

          let API_END_POINT = ProjectSettings.baseURL + '/api/survey/admin'

          fetch(API_END_POINT, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
              'Content-type': 'application/json',
            },
            body: body11,
          })
            .then((response) => {
              return response.json()
            })
            .then((json) => {
              this.setState(
                {
                  SavedSurveyId: json.responseData.surveyId,
                },
                async () => {
                  let ext = Base64Logo.split(',')[0].split(';')[0].split('/')[1]
                  let date = Date.now()
                  date = date + '_' + 'secret' + '.' + ext
                  const file = await this.dataURLtoFile(Base64Logo, date)
                  let Form = new FormData()
                  Form.append('surveyId', this.state.SavedSurveyId)
                  Form.append('profileImage', file)
                  for (let pair of Form) {
                    console.log('updateProfile -> data', pair)
                  }
                  const API = ProjectSettings.baseURL + '/api/survey/CreatelogoImg'
                  fetch(API, {
                    method: 'POST',
                    headers: {
                      // 'Content-type': 'multipart/form-data',
                      Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                    },
                    body: Form,
                  })
                    .then((response) => {
                      return response.json()
                    })
                    .then((response) => {
                      console.log('SurveyCreator -> Save_survey -> response', response)

                      if (this.state.SurveyState !== `Draft`) {
                        let body11 = JSON.stringify({
                          surveyId: this.state.SavedSurveyId,
                          status: this.state.SurveyState,
                        })
                        fetch(ProjectSettings.baseURL + '/api/survey/status', {
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                            'Content-type': 'application/json',
                          },
                          body: body11,
                        })
                          .then((e) => {
                            return e.json()
                          })
                          .then((e) => {})
                          .catch((e) => {
                            this.setState({
                              ButtonLoading: false,
                            })
                          })
                      }
                      this.setState({ saveSurveyName: false })
                      // this.surveyCreator.JSON = null
                      swal({
                        icon: 'success',
                        title: 'Survey Saved Successfully',
                      })
                      // this.props.history.push('/dashboard/1')
                    })
                }
              )
            })
            .catch((e) => {
              swal({
                icon: 'warning',
                title: 'Something Went Wrong',
              })
              this.setState({
                ButtonLoading: false,
              })
            })
        } else {
          let Date_local = new Date()
          let API_END_POINT = ProjectSettings.baseURL + '/api/survey/admin'

          let body11 = JSON.stringify({
            surveyName: this.state.surveyName,
            surveyJson: this.surveyCreator.JSON,
            couponCode: this.state.Coupon_codeNumber,
            status: this.state.SurveyState,
            TimeStampLocal: Date_local,
            userId: this.props.match.params.id,

            CouponCodeStatus: this.state.Checked === true ? 'Active' : 'Inactive',
          })
          fetch(API_END_POINT, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
              'Content-type': 'application/json',
            },
            body: body11,
          })
            .then((response) => {
              return response.json()
            })
            .then((json) => {
              this.setState(
                {
                  SavedSurveyId: json.responseData.surveyId,
                },
                () => {
                  if (this.state.SurveyState !== `Draft`) {
                    let body11 = JSON.stringify({
                      surveyId: this.state.SavedSurveyId,
                      status: this.state.SurveyState,
                    })
                    fetch(ProjectSettings.baseURL + '/api/survey/status', {
                      method: 'POST',
                      headers: {
                        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                        'Content-type': 'application/json',
                      },
                      body: body11,
                    })
                      .then((e) => {
                        return e.json()
                      })
                      .then((e) => {})
                      .catch((e) => {
                        this.setState({
                          ButtonLoading: false,
                        })
                      })
                  }
                  this.setState({ saveSurveyName: false })
                  // this.surveyCreator.JSON = null
                  swal({
                    icon: 'success',
                    title: 'Survey Saved Successfully',
                  })
                  // this.props.history.push('/dashboard/1')
                }
              )
            })
            .catch((e) => {
              swal({
                icon: 'warning',
                title: 'Something Went Wrong',
              })
              this.setState({
                ButtonLoading: false,
              })
            })
        }
      }
    )

    // let Date_local = new Date()
    // let body11 = JSON.stringify({
    //   surveyName: this.state.surveyName,
    //   surveyJson: this.surveyCreator.JSON,
    //   couponCode: this.state.Coupon_codeNumber,
    //   status: this.state.SurveyState,
    //   TimeStampLocal: Date_local,
    //   CouponCodeStatus: this.state.Checked === true ? 'Active' : 'Inactive',
    //   userId: this.props.match.params.id,
    // })

    // let API_END_POINT = ProjectSettings.baseURL + '/api/survey/admin'

    // fetch(API_END_POINT, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
    //     'Content-type': 'application/json',
    //   },
    //   body: body11,
    // })
    //   .then((response) => {
    //     return response.json()
    //   })
    //   .then((json) => {
    //     console.log('SurveyCreator -> Save_survey -> json', json)
    //     this.setState(
    //       {
    //         SavedSurveyId: json.responseData.surveyId,
    //       },
    //       () => {
    //         if (this.state.SurveyState !== `Draft`) {
    //           let body11 = JSON.stringify({
    //             surveyId: this.state.SavedSurveyId,
    //             status: this.state.SurveyState,
    //           })
    //           fetch(ProjectSettings.baseURL + '/api/survey/status', {
    //             method: 'POST',
    //             headers: {
    //               Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
    //               'Content-type': 'application/json',
    //             },
    //             body: body11,
    //           })
    //             .then((e) => {
    //               return e.json()
    //             })
    //             .then((e) => {})
    //             .catch((e) => {
    //               this.setState({
    //                 ButtonLoading: false,
    //               })
    //             })
    //         }
    //       }
    //     )

    //     this.setState({ saveSurveyName: false })
    //     this.surveyCreator.JSON = null
    //     let url = `/User-View/${this.props.match.params.id}/1`
    //     this.props.history.push(url)
    //   })
    //   .catch((e) => {
    //     console.log('SurveyCreator -> Save_survey -> e', e)
    //     swal({
    //       icon: 'warning',
    //       title: 'Something Went Wrong',
    //     })
    //     this.setState({
    //       ButtonLoading: false,
    //     })
    //   })
  }
  RealDomchanges = () => {
    // document.querySelectorAll(".svd_container *").forEach((container) => {
    //   container.style.fontFamily = variable.font;
    // });
    // document.querySelector('.svda-title-action__show-hide').click() //svg icon for adding logo option
    var parent_element_add_logo = document.querySelector('.svda-title-actions').parentElement
    parent_element_add_logo.appendChild(document.querySelector('.svg_icon_add_logo'))

    document.querySelectorAll('.svd-designer-tabbed-container__tab-header').forEach((e, i) => {
      if (i === 0) {
        e.innerText = 'Question Toolbox'
      }
      if (i === 1) {
        e.innerText = 'Settings'
      }
    })
    if (document.querySelector('.svd-designer-tabbed-container__tab-header').innerText === 'Add Question') {
      console.log('SurveyCreator -> RealDomchanges -> something')
      document.querySelector('.svd-designer-tabbed-container__tab-header').innerText = 'Question Toolbox'
    }

    document.querySelectorAll('.btn-primary').forEach((e) => {
      e.style.borderRadius = '0px'
      // e.style.backgroundColor = "#3185d6";
      e.style.border = '0.5px solid #3185d6'
    })
    // document.querySelectorAll('.svda_question_action')[0].click() //file option to add logo
    document.querySelector('svd-pages-editor').appendChild(document.querySelector('#special_button'))
    document.querySelector('.svd_toolbar').appendChild(document.querySelector('#Coupon_code_button'))
    document.querySelectorAll('.svd-page-selector').forEach((e, i) => {
      if (e.id !== 'special_button') {
        e.style.display = 'none'
      }
    })
    document.querySelector('.svda-title-actions').style.display = 'none' //removing the add icon default icon
    document.querySelector('.app-main__inner').style.padding = '0px 0px 0px 0px'
    document.querySelector('.svd_commercial_container').style.display = 'none'
    document.querySelector('.svd_container .svd_content').style.padding = '0px 0px 0px 0px'
    document.querySelector('.svd-designer-container').style.width = '100%'
    document.querySelector('.svd-designer-container--left-side .svd_toolbox').style.margin = '0px'
    document.querySelector('.svd-designer-tabbed-container__tab-header').innerHTML = 'Add Question'
    document.querySelector(
      '.svd-designer-container--left-side .svd-icon-container--left-open, .svd-designer-container--left-side .svd-icon-container--left-close'
    ).style.display = 'none'
    document.querySelector('.svd-icon-container--left-close').style.display = 'none'
    document.querySelector('svd-splitter div.svd-splitter').style.display = 'none'
    document.querySelector('.svd-icon-container--right-close').style.display = 'none'
    document.querySelector('.svd-svg-icon').style.display = 'none'
    document.querySelector('.svd_editors').style.padding = '90px 0px 0px 0px'
    document.querySelector('.svd-vertical-container__cell-content').style.overflowY = 'hidden'
    document.querySelector('.svd-designer-container').style.display = 'block'
    var new_side_container = document.querySelector('.side_question_navbar')
    var side_container = document.querySelector('.svd_container .svd_toolbox')
    document.querySelector('.svd_editors').style.width = '50%'
    document.querySelector('.svd_editors').style.maxWidth = '50%'
    var side_container_parent = side_container.parentElement
    side_container.style.display = 'none'
    side_container_parent.appendChild(new_side_container)
    document.querySelector('svd-splitter').style.width = '0px'
    var vertical_container = document.querySelectorAll('.svd-vertical-container')
    for (var i = 0; i < vertical_container.length; i++) {
      vertical_container[1].style.backgroundColor = 'white'
      vertical_container[2].style.backgroundColor = 'white'
      vertical_container[3].style.backgroundColor = 'transparent'
      vertical_container[3].style.padding = '0px 30px 0px 30px'
    }
    document.querySelector('.svd_selected_page').style.backgroundColor = 'white'
    document.querySelectorAll('.svd_container [draggable]').forEach((span) => {
      span.style.padding = '10px 20px'
    })
    // let ListDiv = document.querySelector('.navbar-default')
    // document.querySelector(".navbar-default").style.display = "flex";
    // document.querySelector(".navbar-default").style.justifyContent =
    //   "space-between";
    document.querySelector('svd-splitter').remove()
    // document
    //   .querySelector(".navbar-default")
    //   .appendChild(document.querySelector(".Inside_list_div"));
    // document.querySelector(".Inside_list_div").style.display = "flex";
    // document.querySelector(".Inside_list_div").style.justifyContent =
    //   "flex-start";
    document.querySelector('#svd-survey-settings').style.display = 'none'
    // document
    //   .querySelector(".Inside_list_div")
    //   .append(ListDiv.children[0], ListDiv.children[1]);
    // document
    //   .querySelector(".navbar-default")
    //   .appendChild(document.querySelector(".Save_button"));
  }
  beforeUnload = (e) => {
    e.preventDefault()
    e.stopPropagation()
    return 'skandknskdnasndklnasnd'
  }
  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  componentDidMount() {
    this.Loader_visible()
    if (this.props.state.LoginReducer11 === null) {
      this.props.history.push('/login')
    }

    // if (this.props.location.pathname === "/create-survey") {
    //   window.onbeforeunload = (e) => {
    //     console.log(
    //       "SurveyCreator -> window.onpopstate -> this.props.location.pathname",
    //       this.props.location.pathname
    //     );

    //     return "are you sure";
    //   };
    // }

    // window.addEventListener("click", (e) => {
    // });
    // window.addEventListener("popstate", (e) => {

    //   console.log(
    //     "SurveyCreator -> componentDidMount -> this.props",
    //     this.props
    //   );

    //   console.log(
    //     "SurveyCreator -> componentDidMount -> window.location.href",
    //     window.location.href
    //   );
    //   if (this.props.history.location.pathname !== "/dashboard") {
    //     const leavePage = window.confirm("you want to go ahead ?");
    //     if (leavePage) {
    //       // this.componentWillUnmount();
    //       window.removeEventListener("popstate", (e) => {
    //       });

    //       this.props.history.push("/dashboard");
    //     } else {
    //       this.props.history.push("/create-survey");
    //       this.props.dispatch(SurveyOptionActions(this.surveyCreator.JSON));
    //     }
    //   }
    // });

    // window.addEventListener("onpopstate", (e) => {
    //   e.preventDefault();
    //   // if(this.props.location.pathname === "/create-survey")

    //   const leavePage = window.confirm("you want to go ahead ?");
    //   if (leavePage) {
    //     // this.componentWillUnmount();
    //     document.removeEventListener("onpopstate");
    //     this.props.history.push("/dashboard");
    //   } else {
    //     this.props.history.push("/create-survey");
    //     this.props.dispatch(SurveyOptionActions(this.surveyCreator.JSON));
    //   }
    // });

    console.log('SurveyCreator -> componentDidMount -> this.props.state', this.props.state)

    $(document).ready(() => {
      this.RealDomchanges()
      this.AddedDomchanges()
    })

    let options = {
      showEmbededSurveyTab: false,
      showJSONEditorTab: false,
      showTestSurveyTab: true,
      showLogicTab: true,
      showSurveyTitle: 'always',
      showPageTitle: 'never',
      showPageDescription: 'never',
    }
    SurveyKo.Serializer.findProperty('page', 'title').visible = false
    SurveyKo.Serializer.findProperty('page', 'description').visible = false

    // SurveyKo.Serializer.addProperty("rating", {
    //   name: "tag11:number",
    //   default: 1,
    //   category: "general",
    //   edit: false,
    //   visibleIf: function (obj) {
    //     let returnVariable;
    //     if (obj.getType() === "rating") {
    //       if (obj.tag11 === 200) {
    //         returnVariable = true;
    //       } else {
    //         returnVariable = false;
    //       }
    //     } else {
    //       returnVariable = false;
    //     }
    //     return returnVariable;
    //   },
    // });

    this.surveyCreator = new SurveyJSCreator.SurveyCreator(null, options)

    // this.surveyCreator.toolbox.addProperty("question", {
    //   name: "tag:number",
    //   default: 1,
    //   category: "general",
    //   enabled: false,
    // });
    // this.surveyCreator.saveSurveyFunc = this.saveMySurvey;

    this.surveyCreator.showToolbox = 'left'
    this.surveyCreator.showPropertyGrid = 'left'
    this.surveyCreator.rightContainerActiveItem('toolbox')
    this.surveyCreator.survey.getPropertyValue('showQuestionNumbers', 'on')
    this.surveyCreator.inplaceEditForValues = 'true'

    this.surveyCreator.survey.cssValue.checkbox = {
      ...this.surveyCreator.survey.cssValue.checkbox,
      item: this.surveyCreator.survey.cssValue.checkbox.item + ' sv_checkbox_css_dashboard',
    }
    this.surveyCreator.survey.cssValue.rating = {
      ...this.surveyCreator.survey.cssValue.rating,
      item: this.surveyCreator.survey.cssValue.rating.item + ' sv_rating_css_dashboard',
      itemText: this.surveyCreator.survey.cssValue.rating.itemText + ' sv-rating__item-text_dashboard',
    }
    this.surveyCreator.survey.cssValue.radiogroup = {
      ...this.surveyCreator.survey.cssValue.radiogroup,
      item: this.surveyCreator.survey.cssValue.radiogroup.item + ' sv-radio_dashboard',
    }

    this.surveyCreator.survey.css.checkbox = {
      ...this.surveyCreator.survey.css.checkbox,
      item: this.surveyCreator.survey.css.checkbox.item + ' sv_checkbox_css_dashboard',
    }
    this.surveyCreator.survey.css.rating = {
      ...this.surveyCreator.survey.css.rating,
      item: this.surveyCreator.survey.css.rating.item + ' sv_rating_css_dashboard',
      itemText: this.surveyCreator.survey.css.rating.itemText + ' sv-rating__item-text_dashboard',
    }
    this.surveyCreator.survey.css.radiogroup = {
      ...this.surveyCreator.survey.css.radiogroup,
      item: this.surveyCreator.survey.css.radiogroup.item + ' sv-radio_dashboard',
    }

    this.surveyCreator.survey.cssValue.radiogroup.item = this.surveyCreator.survey.cssValue.radiogroup.item + ' sv-radio_dashboard'
    this.surveyCreator.survey.css.radiogroup.item = this.surveyCreator.survey.css.radiogroup.item + ' sv-radio_dashboard'
    console.log('SurveyCreator -> componentDidMount -> this.surveyCreator', this.surveyCreator)
    console.log(
      'SurveyCreator -> componentDidMount -> this.surveyCreator.survey.cssValue.radiogroup.item',
      this.surveyCreator.survey.cssValue.radiogroup.item
    )

    this.surveyCreator.onQuestionAdded.add((sender, option) => {
      // this.setState({
      //   questionCounter: this.surveyCreator.getAllQuestions().length,
      // });
      var q = option.question

      // var t = q.getType()
      // if (t === "radiogroup") {
      //   let newProperty = {
      //     maxRateDescription: "Not At All Likely",
      //     minRateDescription: "Extremely Likely",
      //     maxdescription: "maxdescription_css_radiogroup",
      //     minDescripion: "mindescription_css_radiogroup",
      //   };

      //   Object.assign(q, newProperty);
      // }
      sender.survey.cssValue.checkbox = {
        ...sender.survey.cssValue.checkbox,
        item: sender.survey.cssValue.checkbox.item + ' sv_checkbox_css_dashboard',
      }
      sender.survey.cssValue.rating = {
        ...sender.survey.cssValue.rating,
        item: sender.survey.cssValue.rating.item + ' sv_rating_css_dashboard',
        itemText: sender.survey.cssValue.rating.itemText + ' sv-rating__item-text_dashboard',
      }
      sender.survey.cssValue.radiogroup = {
        ...sender.survey.cssValue.radiogroup,
        item: sender.survey.cssValue.radiogroup.item + ' sv-radio_dashboard',
      }
      this.surveyCreator.survey.getAllQuestions().forEach((e, i) => {
        e.name = 'Question' + (i + 1)
      })

      this.setState({ questionCounter: this.state.questionCounter + 1 })
      if (this.state.email_question_add === true) {
        q.tag = guid()
        q.name = 'Question' + this.state.questionCounter

        q.isRequired = true
        q.inputType = 'email'
        q.title = 'Email'
        this.setState({
          email_question_add: false,
        })
      } else {
        q.name = 'Question' + this.state.questionCounter

        q.tag = guid()
        if (q.title.includes('The company made it easy for')) {
          q.SpecialProperty = true
        }
        if (q.title.includes('How would you rate your experience')) {
          q.SpecialProperty = true
        }
      }
    })

    // this.surveyCreator.toolbarItems.push({
    //   id: "custom-preview",
    //   visible: true,
    //   title: "Survey Preview",
    //   json: this.surveyCreator,
    //   action: (obj) => {
    //     var testSurveyModel = new Survey.Model(obj.json.JSON);

    //     this.setState({ SurveyPreview: testSurveyModel, showState: true });
    //   },
    // });

    // this.surveyCreator.tabs().unshift({
    //   name: "survey-templates", //the unique tab name
    //   title: "Survey Templates", //the tab title
    //   template: "custom-tab-survey-templates", //you will see the knockout template in the HTML tab
    //   action: (item) => {
    //     //change the active tab to this one on clicking the tab
    //     this.surveyCreator.makeNewViewActive("survey-templates");
    //   },
    //   data: {
    //     title: "Survey templates list",
    //     surveys: loadedSurveyTemplates(), //to make code clean, get the array of templates name and jsons from another function
    //     load: (item) => {
    //       console.log(
    //         "SurveyCreator -> componentDidMount -> typeof item.json",
    //         typeof item.json
    //       );
    //       //load the item json into creator
    //       this.surveyCreator.JSON = item.json;
    //       //make the 'designer' tab active
    //       this.surveyCreator.makeNewViewActive("designer");
    //     },
    //   },
    // });
    // function loadedSurveyTemplates() {
    //   return [
    //     {
    //       name: "NPS",
    //       json: getNPSJSON(),
    //     },
    //     {
    //       name: "Dummy checkbox survey",
    //       json: getDummyCheckboxJSON(),
    //     },
    //     {
    //       name: "Empty Survey",
    //       json: {},
    //     },
    //   ];
    // }

    // function getNPSJSON() {
    //   return {
    //     completedHtml:
    //       "<h3>Thank you for your feedback.</h3><h5>Your thoughts and ideas will help us to create a great product!</h5>",
    //     completedHtmlOnCondition: [
    //       {
    //         expression: "{nps_score} > 8",
    //         html:
    //           "<h3>Thank you for your feedback.</h3><h5>We glad that you love our product. Your ideas and suggestions will help us to make our product even better!</h5>",
    //       },
    //       {
    //         expression: "{nps_score} < 7",
    //         html:
    //           "<h3>Thank you for your feedback.</h3><h5> We are glad that you share with us your ideas.We highly value all suggestions from our customers. We do our best to improve the product and reach your expectation.</h5>\n",
    //       },
    //     ],
    //     pages: [
    //       {
    //         name: "page1",
    //         elements: [
    //           {
    //             type: "rating",
    //             name: "nps_score",
    //             title:
    //               "On a scale of zero to ten, how likely are you to recommend our product to a friend or colleague?",
    //             isRequired: true,
    //             rateMin: 0,
    //             rateMax: 10,
    //             minRateDescription: "(Most unlikely)",
    //             maxRateDescription: "(Most likely)",
    //           },
    //           {
    //             type: "checkbox",
    //             name: "promoter_features",
    //             visibleIf: "{nps_score} >= 9",
    //             title: "What features do you value the most?",
    //             isRequired: true,
    //             validators: [
    //               {
    //                 type: "answercount",
    //                 text: "Please select two features maximum.",
    //                 maxCount: 2,
    //               },
    //             ],
    //             hasOther: true,
    //             choices: [
    //               "Performance",
    //               "Stability",
    //               "User Interface",
    //               "Complete Functionality",
    //             ],
    //             otherText: "Other feature:",
    //             colCount: 2,
    //           },
    //           {
    //             type: "comment",
    //             name: "passive_experience",
    //             visibleIf: "{nps_score} > 6  and {nps_score} < 9",
    //             title: "What is the primary reason for your score?",
    //           },
    //           {
    //             type: "comment",
    //             name: "disappointed_experience",
    //             visibleIf: "{nps_score} notempty",
    //             title:
    //               "What do you miss and what was disappointing in your experience with us?",
    //           },
    //         ],
    //       },
    //     ],
    //     showQuestionNumbers: "off",
    //   };
    // }

    // function getDummyCheckboxJSON() {
    //   return {
    //     questions: [
    //       {
    //         type: "checkbox",
    //         name: "car",
    //         title: "What car are you driving?",
    //         isRequired: true,
    //         hasNone: true,
    //         colCount: 4,
    //         choices: [
    //           "Ford",
    //           "Vauxhall",
    //           "Volkswagen",
    //           "Nissan",
    //           "Audi",
    //           "Mercedes-Benz",
    //           "BMW",
    //           "Peugeot",
    //           "Toyota",
    //           "Citroen",
    //         ],
    //       },
    //     ],
    //   };
    // }

    // function DefaultQuesrtion() {
    //   return {
    //     pages: [
    //       {
    //         elements: [
    //           {
    //             title: "Please enter your name and e-mail",
    //             questions: [
    //               {
    //                 type: "text",
    //                 name: "name",
    //                 title: "Name:",
    //                 isRequired: true,
    //                 popupdescription:
    //                   "Please, type your name as 'Given Name' 'Family Name'.",
    //               },
    //               {
    //                 type: "text",
    //                 name: "email",
    //                 title: "Your e-mail",
    //                 isRequired: true,
    //                 popupdescription:
    //                   "Please, make sure you do not misspell your e-mail.",
    //               },
    //             ],
    //           },
    //         ],
    //       },
    //     ],
    //   };
    // }

    // this.surveyCreator.toolbarItems.push({
    //   id: "Coupon-add",
    //   visible: true,
    //   title: "Add Coupon",
    //   Default_question: DefaultQuesrtion(),
    //   json: this.surveyCreator,

    //   action: (obj) => {
    //     this.setState({ addCoupon: true });

    //     let Default_question = {
    //       pages: [
    //         {
    //           name: "page3",
    //           elements: [
    //             {
    //               title: "Please enter your name and e-mail",
    //               questions: [
    //                 {
    //                   type: "text",
    //                   name: "name",
    //                   title: "Name:",
    //                   isRequired: true,
    //                   popupdescription:
    //                     "Please, type your name as 'Given Name' 'Family Name'.",
    //                 },
    //                 {
    //                   type: "text",
    //                   name: "email",
    //                   title: "Your e-mail",
    //                   isRequired: true,
    //                   popupdescription:
    //                     "Please, make sure you do not misspell your e-mail.",
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //       ],
    //     };

    //     this.surveyCreator.survey.getAllQuestions().forEach((e) => {
    //     });
    //     console.log(
    //       "SurveyCreator -> componentDidMount ->  this.surveyCreator.survey.getQuestionByName('Name:')",
    //       this.surveyCreator.survey.getQuestionByName("Name:")
    //     );
    //     if (this.surveyCreator.survey.getQuestionByName("Name:") === null) {
    //       this.surveyCreator.survey.addNewPage("last_page");
    //       this.surveyCreator.survey.pages.forEach((e) => {
    //         if (e.name === "last_page") {
    //           e.addNewQuestion("text", "Your e-mail:");
    //           e.addNewQuestion("text", "Name:");
    //         }
    //       });
    //     } else {
    //     }

    // console.log(
    //   "SurveyCreator -> componentDidMount ->  this.surveyCreator.survey.getQuestionByName('Name:')",
    //   this.surveyCreator.survey.getQuestionByName("Name:")
    // );
    // console.log(
    //   "SurveyCreator -> componentDidMount ->  this.surveyCreator.survey.getQuestionByName('Name:')",
    //   this.surveyCreator.survey.getQuestionByName("Your e-mail:")
    // );

    // console.log(
    //   "SurveyCreator -> componentDidMount -> this.surveyCreator.survey.getAllQuestions",
    //   this.surveyCreator.survey.pages
    // );
    //   },
    // });

    // function createButtonForPopup(options, showPopup) {
    //   //Return if there is no description to show in popup
    //   if (!options.question.popupdescription) return

    //   //Add a button;
    //   var btn = document.createElement('button')
    //   btn.type = 'button'
    //   btn.className = 'btn btn-info btn-xs'

    //   btn.style.position = 'absolute'
    //   btn.style.marginLeft = '20px'

    //   btn.innerHTML = 'More Info'
    //   if (showPopup) {
    //     var popupdescription = options.question.popupdescription
    //     btn.onclick = function () {
    //       alert(popupdescription)
    //     }
    //   }
    //   var header = options.htmlElement.querySelector('h5')
    //   var span = document.createElement('span')
    //   span.innerHTML = '  '
    //   header.appendChild(span)
    //   header.appendChild(btn)
    // }
    // function renderQuestionInDesigner(survey, options) {
    //   createButtonForPopup(options, false)
    // }
    // function renderQuestionInTestSurvey(survey, options) {
    //   createButtonForPopup(options, true)
    // }

    // this.surveyCreator.onSurveyInstanceCreated.add((sender, options) => {
    //   //If we are creating a surface for designer surface
    //   if (options.reason == "designer") {
    //     options.survey.onAfterRenderQuestion.add(renderQuestionInDesigner);
    //   }
    //   //If we are creating a surface for "Test Survey" tab
    //   if (options.reason == "test") {
    //     options.survey.onAfterRenderQuestion.add(renderQuestionInTestSurvey);
    //   }
    // });

    this.surveyCreator.render('surveyCreatorContainer')
    // this.SurveyfromDashboard();

    // this.surveyCreator.onUploadFile.add((sender, option) => {

    //   console.log(
    //     "SurveyCreator -> componentDidMount -> option.value",
    //     option.value
    //   );
    //   option.files.forEach((e) => {

    //     if (e.size > 100000) {
    //       this.setState({
    //         FileUploadMessage: "the file uplpoaded should be less than 100kb",
    //         FileUploadError: true,
    //       });
    //     } else {
    //       // option.callback("sucess", this.surveyCreator.JSON.logo = option.files)
    //       return;
    //     }
    //   });
    //   option.callback("sucess", () => {
    //     return;
    //   });
    // });

    this.surveyCreator.onModified.add((sender, options) => {
      sender.survey.cssValue.checkbox = {
        ...sender.survey.cssValue.checkbox,
        item: sender.survey.cssValue.checkbox.item + ' sv_checkbox_css_dashboard',
      }
      sender.survey.cssValue.rating = {
        ...sender.survey.cssValue.rating,
        item: sender.survey.cssValue.rating.item + ' sv_rating_css_dashboard',
        itemText: sender.survey.cssValue.rating.itemText + ' sv-rating__item-text_dashboard',
      }
      if (options.type === 'DO_DROP') {
        this.surveyCreator.survey.getAllQuestions().forEach((e, i) => {
          e.name = 'Question' + (i + 1)
        })
      }
      if (options.type === 'OBJECT_DELETED' && options.target !== undefined) {
        this.surveyCreator.survey.getAllQuestions().forEach((e, i) => {
          e.name = 'Question' + (i + 1)
        })
        this.setState({ questionCounter: this.state.questionCounter - 1 })
      }

      this.AddedDomchanges()

      if (options.type === 'PROPERTY_CHANGED' && options.name === 'logo' && options.newValue !== undefined) {
        if (options.newValue !== undefined || options.newValue !== null) {
          let fileSize = (options.newValue.length * 3) / 4 - 2
          if (fileSize > 50000) {
            // // this.setState({
            // //   FileUploadMessage: "the file uplpoaded should be less than 50kb",
            // //   FileUploadError: true,
            // // });
            // swal({
            //   title: 'Error',
            //   text: 'the file uploaded should be less than 50kb',
            //   icon: 'error',
            //   button: {
            //     confirm: {
            //       text: 'OK',
            //       value: 'ok',
            //     },
            //   },
            // }).then((e) => {
            //   if (e === true) {
            //     this.setState({
            //       FileSize: fileSize,
            //       LogoState: 'Add',
            //     })
            //     this.surveyCreator.JSON = {
            //       ...this.surveyCreator.JSON,
            //       logoPosition: 'top',
            //     }
            //     // this.surveyCreator.JSON = {
            //     //   ...this.surveyCreator.JSON,
            //     //   logo: '',
            //     // }
            //   }
            // })
            Swal.fire({
              title: 'Warning',
              text: 'the file uploaded should be less than 50kb for better performance',
              showCancelButton: true,
              confirmButtonText: `It's ok`,
              customClass: {
                cancelButton: `swal-button swal-button--cancel swal_button_cancel`,
                confirmButton: `swal-button swal-button--catch swal_button_accept`,
              },
            }).then(async (e) => {
              console.log('SurveyEditortest -> componentDidMount -> e', e)
              if (e.isConfirmed) {
                if (e.isConfirmed === true) {
                  this.setState({
                    FileSize: fileSize,
                    LogoState: 'Edit',
                  })
                }
              } else {
                this.setState(
                  {
                    FileSize: 0,
                  },
                  () => {
                    this.surveyCreator.JSON = {
                      ...this.surveyCreator.JSON,
                      logoPosition: 'top',
                      logo: '',
                    }
                  }
                )
              }
            })
          } else {
            this.surveyCreator.JSON = {
              ...this.surveyCreator.JSON,
              logoPosition: 'top',
            }
          }
        }
      }
    })
    this.props.dispatch(SurveySave(this.surveyCreator.JSON))

    //Set the name property different from the default value
    //and set the tag property to a generated GUID value.
    this.surveyCreator.onSurveyInstanceCreated.add((sender, options) => {
      // sender.survey.addNewPage("last page", 2);
      // sender = {
      //   ...sender,
      //   pageEditMode: "questionPerPage",
      // };
    })

    this.surveyCreator.onElementAllowOperations.add((sender, option) => {
      option.allowChangeType = false
    })
    this.surveyCreator.onPageAdded.add((sender, option) => {
      console.log('SurveyCreator -> componentDidMount -> this.state.Currentpage', this.state.Currentpage)

      let length = sender.pagesEditorModel.pages.length

      sender.pagesEditorModel.pages.forEach((e, i) => {
        e[length] = {
          ...e[length],
          questions: e.questions.push(this.state.Currentpage),
        }
      })
    })
    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    }
    this.surveyCreator.toolbox.addItem({
      name: 'NPS',
      isCopied: true,
      iconName: 'icon-default',
      title: 'NPS Question',
      json: {
        type: 'rating',
        name: 'nps_score',
        title: 'On a scale of zero to ten, how likely are you to recommend our product to a friend or colleague?',

        isRequired: true,
        tag11: 200,
        rateValues: [
          {
            value: '10',
            text: '10',
          },
          {
            value: '9',
            text: '9 ',
          },
          {
            value: '8',
            text: '8',
          },
          {
            value: '7',
            text: '7',
          },
          {
            value: '6',
            text: '6',
          },
          {
            value: '5',
            text: '5',
          },
          {
            value: '4',
            text: '4',
          },
          {
            value: '3',
            text: '3',
          },
          {
            value: '2',
            text: '2',
          },
          {
            value: '1',
            text: '1',
          },
          {
            value: '0',
            text: '0',
          },
        ],
        rateMax: 11,
        minRateDescription: 'Extremely Likely ',
        maxRateDescription: 'Not At All Likely',
      },

      // json: {
      //   type: "rating",
      //   name: "nps_score",
      //   title:
      //     "On a scale of zero to ten, how likely are you to recommend our product to a friend or colleague?",
      //   isRequired: true,
      //   tag11: 200,
      //   rateMin: 0,
      //   rateMax: 10,
      //   minRateDescription: "(Extremely Likely)",
      //   maxRateDescription: "(Not At All Likely)",
      // },
    })
    this.surveyCreator.toolbox.addItem({
      name: 'CSC',
      isCopied: true,
      iconName: 'icon-csc',
      title: 'CSC Question',
      json: {
        type: 'radiogroup',
        name: 'customer_satisfaction',
        title: 'How would you rate your experience?',
        isRequired: true,
        choices: [
          {
            value: 'Very Satisfied',
          },
          {
            value: 'Satisfied',
          },
          {
            value: 'Neutral',
          },
          {
            value: 'Unsatisfied',
          },
          {
            value: 'Very Unsatisfied',
          },
        ],
      },
    })
    this.surveyCreator.toolbox.addItem({
      name: 'CES',
      isCopied: true,
      iconName: 'icon-ces',
      title: 'CES Question',
      json: {
        type: 'radiogroup',
        name: 'customer_satisfaction',
        title: 'The company made it easy for me to handle my issue.',
        uxclassname: 'special_CES',
        isRequired: true,
        choices: [
          {
            value: ' Strongly Agree',
          },
          {
            value: ' Agree',
          },
          {
            value: 'Somewhat Agree',
          },
          {
            value: 'Neutral',
          },
          {
            value: 'Somewhat Disagree',
          },
          {
            value: 'Disagree',
          },
          {
            value: 'Strongly Disagree',
          },
        ],
      },
    })

    this.surveyCreator.tabs().forEach((e) => {})
    this.surveyCreator.tabs().forEach((e) => {})
    this.surveyCreator.tabs().pop()
    this.surveyCreator.tabs().pop()
    console.log('SurveyCreator -> componentDidMount -> this.surveyCreator.toolbarItems', this.surveyCreator.tabs())
    this.surveyCreator.tabs().forEach((e) => {})

    this.Loader_hide()
  }

  // handleArray = (e) => {
  // };
  // buttonStateChecker = (prevState) => {

  //   console.log(
  //     "SurveyCreator -> buttonStateChecker -> this.state",
  //     this.state
  //   );
  // };
  componentDidUpdate(prevProps, prevState) {
    document.querySelectorAll('.svd-page').forEach((e) => {
      e.style.backgroundColor = 'transparent'
    })

    if (this.state.Checked !== prevState.Checked) {
      if (this.state.Checked === true) {
        this.setState({ InsideModalState: true })
      }
    }

    if (this.props.state.SurveyOptionReducer.state_change !== prevProps.state.SurveyOptionReducer.state_change) {
      this.surveyCreator.JSON = this.props.state.SurveyOptionReducer.json
    }

    try {
      if (this.state.Surveyfrommid.json !== undefined || this.state.Surveyfrommid.json !== null) {
        if (this.surveyCreator.JSON !== this.state.Surveyfrommid.json) {
          // this.surveyCreator.JSON = this.state.Surveyfrommid.json;
        }

        // }
      }
    } catch (e) {}

    // console.log(
    //   "SurveyCreator -> componentDidMount -> this.props.state",
    //   this.props.state
    // );
    // const { Coupon_code_reducer } = this.props.state;
    // console.log(
    //   "SurveyCreator -> componentDidUpdate -> Coupon_code_reducer",
    //   Coupon_code_reducer
    // );
  }

  EdgecaseTesting = () => {
    // const QuestionListsearch = (e) => {}

    if (this.surveyCreator.survey.getAllQuestions().length > 0) {
      var QuestionChecker = 0

      this.setState({
        error_case_count: 0,
      })
      this.surveyCreator.survey.getAllQuestions().forEach((e) => {
        let email = e.title.toLowerCase()
        if (email.includes('email')) {
          var testing123 = e.getType()

          if (testing123 === 'text') {
            QuestionChecker = QuestionChecker + 1
          }
        }
      })

      if (QuestionChecker === 0) {
        // swal("the survey does", "success", {
        //   buttons: {
        //     cancel: "cancel",
        //   },
        // });
        swal({
          title: 'Warning',
          text:
            //"The survey does not have a question to support coupon code delivery kindly add a question with name email",
            'This survey does not have a required question requesting an email address. A required question requesting an email address will be added to the end of the survey.',
          icon: 'warning',
          buttons: {
            cancel: 'Cancel',
            catch: {
              text: 'Add Question',
              value: 'Add',
            },
            default: {
              text: 'Edit Survey',
              value: 'edit',
            },
          },
        }).then((value) => {
          if (value === 'Add') {
            this.setState({
              email_question_add: true,
              Checked: true,
              ButtonLoading: false,
            })
            this.surveyCreator.survey.addNewPage('last_page')
            this.surveyCreator.survey.pages.forEach((e, i) => {
              if (e.name === 'last_page') {
                e.addNewQuestion('text', ' What is your email')
              }
            })

            this.setState({
              error_case_count: 0,
            })
          }
          if (value === 'edit') {
            this.setState({ saveSurveyName: false, ButtonLoading: false })
          }
        })
        // this.setState({
        //   EdgeCaseError:
        //     "the survey does not have a question to support Coupon Code delivery kindly add a question with name Email",
        //   EdgeCaseErrorState: true,
        // });
      } else {
        this.setState({
          Checked: !this.state.Checked,
        })
      }

      // if (this.state.QuestionChecker === 0) {
      //   this.setState({
      //     EdgeCaseError:
      //       "the survey does not have a question to support Coupon Code delivery kindly add a question with name Email",
      //     EdgeCaseErrorState: true,
      //   });
      // } else {
      //   this.setState({
      //     Checked: !this.state.Checked,
      //   });
      // }
    } else {
      swal({
        icon: 'error',
        title: 'No questions found',
        buttons: {
          catch: {
            text: 'Edit',
            value: 'ok',
          },
          cancel: 'Cancel',
        },
      }).then((e) => {
        if (e) {
          this.setState({
            EdgeCaseErrorState: false,
            Checked: false,
            saveSurveyName: false,
          })
        }
      })
    }
  }
  SurveyfromDashboard = () => {
    try {
      if (Object.keys(this.props.state.SurveyOptionReducer).length > 0) {
        this.surveyCreator.JSON = this.props.state.SurveyOptionReducer.json
      }
      if (this.props.state.SurveyOptionReducer !== {}) {
        this.surveyCreator.JSON = this.props.state.SurveyOptionReducer.json
      }
    } catch (e) {}
  }

  render() {
    return (
      <>
        <Loader11 />
        <Button
          outline
          id="Coupon_code_button"
          className="btn-transition"
          color="primary"
          style={{
            float: 'right',
            marginTop: '0.5em',
            width: '140px',
          }}
          onClick={() => {
            this.setState({ addCoupon: true })
          }}
        >
          Add Coupon Code
        </Button>
        {/* <SweetAlert
          title="Your Unsaved Changes will be lost"
          confirmButtonColor=""
          show={this.state.BackErrorState}
          showCancelButton={true}
          confirmButtonText={"go back"}
          cancelButtonText={"go to dashboard"}
          text={this.state.BackError}
          type="error"
          onCancel={() => {
            this.setState({ BackErrorState: false, BackConfirm: true }, () => {
              // this.props.history.push("/dashboard");
            });
          }}
          onConfirm={() =>
            this.setState({
              BackErrorState: false,
              BackConfirm: false,
            })
          }
        /> */}
        <SweetAlert
          {...this.state.aarraay}
          title="Something went wrong"
          confirmButtonColor=""
          show={this.state.EdgeCaseErrorState}
          showCancelButton={true}
          confirmButtonText={'Edit'}
          text={this.state.EdgeCaseError}
          type="error"
          onCancel={() => {
            this.setState({ EdgeCaseErrorState: false, Checked: false })
          }}
          onConfirm={() =>
            this.setState({
              EdgeCaseErrorState: false,
              Checked: false,
              saveSurveyName: false,
            })
          }
        />
        <Button
          id="special_button"
          className="svd-page-selector btn-primary sv-btn btn btn-special-primary-hover"
          style={{
            width: '140px',
          }}
          // className="svd-page-selector btn-primary sv-btn"
          onClick={() => {
            this.setState({ status: false })
            this.setState({ saveSurveyName: true })
            this.setState({
              SurveyQuestionNumber: this.surveyCreator.survey.getAllQuestions().length,
            })
          }}
        >
          Save Survey
        </Button>
        <div className="Inside_list_div"></div>
        <SweetAlert
          title={this.state.FileUploadMessage}
          confirmButtonColor=""
          show={this.state.FileUploadError}
          text=""
          type="error"
          onConfirm={() =>
            this.setState({ FileUploadError: false }, () => {
              this.surveyCreator.JSON = {
                ...this.surveyCreator.JSON,
                logo: '',
              }
            })
          }
        />
        {/* <SweetAlert
          title={this.state.Error_Message}
          confirmButtonColor=""
          show={this.state.ErrorState}
          text=""
          type="error"
          onConfirm={() => this.setState({ ErrorState: false, Checked: false })}
        /> */}
        <div>
          <ToastContainer
            position="bottom-right"
            color="error"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Modal
            isOpen={this.state.saveSurveyName}
            toggle={() => this.setState({ saveSurveyName: false })}

            // className={this.props.className}
          >
            <ModalHeader toggle={this.toggle} className="Modal_header Modal_header1 Modal_header_special">
              <img src={save_icon} alt={''} />
              Save Survey
            </ModalHeader>
            <ModalBody className="Modal_body Modal_body1">
              <div className="modal_form">
                <div className="create_survey_form" style={{ marginBottom: '13px' }}>
                  <div className="Survey_left_part">
                    <div className="Survey_left_part_inner">
                      <div className="Survey_left_part_inner1">
                        <label>Save as:</label>
                      </div>
                    </div>
                  </div>
                  <div className="Survey_right_part">
                    <FormGroup>
                      <Input
                        valid={this.state.surveyNameValid}
                        invalid={this.state.surveyNameInvalid}
                        style={{
                          Background: '#f8f9fa',
                          width: '100%',
                          height: '40px',
                          fontSize: '14px',
                        }}
                        type="text"
                        placeholder="Survey Name"
                        value={this.state.surveyName}
                        onChange={(e) => {
                          this.setState({ surveyName: e.target.value }, () => {
                            let str = this.state.surveyName.trim()
                            if (str === '') {
                              this.setState({
                                surveyNameInvalid: false,
                                surveyNameValid: false,
                              })
                            } else {
                              let API_END_POINT = ProjectSettings.baseURL + '/api/survey/checkSurveyName'
                              let bodyData = JSON.stringify({
                                surveyName: str,
                                userId: this.props.match.params.id,
                              })
                              fetch(API_END_POINT, {
                                method: 'POST',
                                headers: {
                                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                  'Content-type': 'application/json',
                                },
                                body: bodyData,
                              })
                                .then((e) => {
                                  return e.json()
                                })
                                .then((e) => {
                                  if (e.success === true) {
                                    this.setState(
                                      {
                                        surveyNameValid: true,
                                        surveyNameInvalid: false,
                                      },
                                      () => {
                                        if (this.state.surveyName.trim().length === 0) {
                                          this.setState({
                                            surveyNameValid: false,
                                            surveyNameInvalid: false,
                                          })
                                        }
                                      }
                                    )
                                  } else {
                                    this.setState(
                                      {
                                        surveyNameValid: false,
                                        surveyNameInvalid: true,
                                      },
                                      () => {
                                        if (this.state.surveyName.trim().length === 0) {
                                          this.setState({
                                            surveyNameValid: false,
                                            surveyNameInvalid: false,
                                          })
                                        }
                                      }
                                    )
                                  }
                                })
                                .catch((e) => {
                                  console.log('error', e)
                                })
                            }
                          })
                        }}
                      />
                      <FormFeedback
                        style={{
                          display: this.state.surveyNameInvalid ? `block` : `none`,
                          position: 'absolute',
                          top: '65px',
                        }}
                      >
                        Survey name already exists
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </div>
                <div className="create_survey_form">
                  <div className="create_survey_form_inner">
                    <div className="Survey_left_part">Survey status:</div>
                    <div className="Survey_right_part">
                      <UncontrolledButtonDropdown
                        style={{
                          width: '100%',
                        }}
                        isOpen={this.state.Dropdown}
                        onMouseEnter={() => {
                          this.setState({
                            Hover_state: true,
                          })
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            Hover_state: false,
                          })
                        }}
                        toggle={() => {
                          this.setState({ Dropdown: !this.state.Dropdown })
                        }}
                      >
                        <ToolTip
                          props={this.state.Hover_state}
                          message={'Open Surveys can receive responses Closed surveys can not receive responses'}
                        />
                        <DropdownToggle className="btn-icon btn-icon-only btn btn-link" color={this.state.color} caret>
                          {this.state.SurveyState === 'Draft' ? `Closed` : `Open`}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={(e) => {
                              this.setState({
                                SurveyState: `Draft`,
                                color: 'warning',
                              })
                            }}
                          >
                            Closed
                          </DropdownItem>

                          <DropdownItem
                            onClick={(e) => {
                              this.setState({
                                SurveyState: `Active`,
                                color: 'success',
                              })
                            }}
                          >
                            Open
                          </DropdownItem>

                          {/* <DropdownItem
                            onClick={(e) => {
                              this.setState({ SurveyState: `Complete` });
                            }}
                          >
                            Complete
                          </DropdownItem> */}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                  </div>
                </div>
                <div className="create_survey_form">
                  <div className="create_survey_form_inner">
                    <div className="Survey_left_part">Coupon code status:</div>
                    <div className="Survey_right_part">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            diaplay: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                // checked={this.state.Checked}
                                color="primary"
                                checked={this.state.Checked}
                                size="small"
                                onChange={() => {
                                  if (this.state.Coupon_codeNumber === null || this.state.Coupon_codeNumber === []) {
                                    this.setState(
                                      {
                                        NoCouponCodeError: {
                                          state: true,
                                          error: 'No Coupon Code Available ',
                                          count: 1,
                                          // addCoupon: true,
                                        },
                                        addCoupon: true,
                                      },
                                      () => {
                                        if (this.state.NoCouponCodeError.count === 1) {
                                          setTimeout(() => {
                                            this.setState({
                                              NoCouponCodeError: {
                                                count: 0,
                                                state: false,
                                              },
                                            })
                                          }, 1000)
                                        }
                                      }
                                    )
                                    // toast("No coupon code Available");

                                    this.setState({
                                      Checked: false,
                                    })
                                  } else {
                                    if (this.state.Checked === true) {
                                      this.setState({
                                        Checked: !this.state.Checked,
                                      })
                                    } else {
                                      this.setState({
                                        NoCouponCodeError: {
                                          state: false,
                                          error: 'No Coupon Code Available',
                                        },
                                      })
                                      this.EdgecaseTesting()
                                    }
                                  }
                                }}
                              />
                            }
                            // label="Allow Coupon Code"
                            label={this.state.Checked === true ? `Active` : `Inactive`}
                          />
                        </div>
                        <div className="warning_span">
                          {this.state.NoCouponCodeError.state === true ? `${this.state.NoCouponCodeError.error}` : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="create_survey_form" style={{ paddingBottom: '0px' }}>
                  <div className="create_survey_form_inner">
                    <div className="Survey_left_part">Coupon codes available:</div>
                    <div style={{ width: 'fit-content' }} className="Survey_right_part special_wrapper">
                      {this.state.Coupon_codeNumber === null ? (
                        <div
                          style={{
                            fontWeight: '300',
                            marginRight: '20px',
                            opacity: this.state.Coupon_codeNumber === null ? `0.4` : `1`,
                          }}
                        >
                          {' '}
                          {` 0`}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontWeight: '300',
                            marginRight: '20px',
                          }}
                        >
                          {' '}
                          {` ${this.state.Coupon_codeNumber.length}`}
                        </div>
                      )}
                      <div>
                        <Button
                          outline
                          color="primary"
                          onClick={() => {
                            this.setState({ addCoupon: true })
                          }}
                        >
                          Add +
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>

            <ModalFooter className="Modal_footer Modal_footer1">
              <Button color="link" className="btn-cancel-modal" onClick={() => this.setState({ saveSurveyName: false, ButtonLoading: false })}>
                Cancel
              </Button>
              <div
                onMouseEnter={() => {
                  this.setState({
                    Hover_state_button: true,
                  })
                }}
                onMouseLeave={() => {
                  this.setState({
                    Hover_state_button: false,
                  })
                }}
              >
                <LaddaButton
                  color="primary"
                  data-style={ZOOM_IN}
                  className="btn btn-primary btn-accept-modal"
                  // id={this.state.ID}
                  loading={this.state.ButtonLoading}
                  disabled={
                    this.state.SurveyQuestionNumber !== 0 && this.state.surveyName !== '' && this.state.surveyNameInvalid === false ? false : true
                  }
                  style={{
                    fontSize: '13px',
                  }}
                  onClick={() => {
                    this.setState({
                      ButtonLoading: true,
                    })
                    if (this.surveyCreator.survey.getAllQuestions().length > 0) {
                      if (this.state.surveyName === '') {
                        this.setState({
                          NameError: 'Kindly Enter a Name',
                          NameErrorState: true,
                        })
                      }
                      if (this.state.Checked === true) {
                        // let question_array = []
                        this.surveyCreator.getAllQuestions().forEach((e) => {
                          if (e.title.toLowerCase().includes('email')) {
                            this.state.error_case_count = 1
                            this.setState({
                              error_case_count: this.state.error_case_count + 1,
                            })
                          }
                        })
                        if (this.state.error_case_count > 0) {
                          this.Save_survey()
                        } else {
                          this.EdgecaseTesting()
                        }
                      } else {
                        this.Save_survey()
                      }
                    } else {
                      this.setState({
                        EdgeCaseError: 'No questions found',
                        EdgeCaseErrorState: true,
                      })
                    }
                  }}
                >
                  Save
                </LaddaButton>
                {this.state.SurveyQuestionNumber !== 0 && this.state.surveyName !== '' ? null : (
                  <ToolTip props={this.state.Hover_state_button} message={'No questions found'} />
                )}
              </div>
            </ModalFooter>
          </Modal>
        </div>

        <button
          className="svg_icon_add_logo btn btn-primary btn-accept-modal"
          style={{
            position: 'absolute',
            maxWidth: '90%',
            top: '5px',
            right: '50%',
            cursor: 'pointer',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            width: '110px',
            justifyContent: 'space-around',
            borderRadius: '4px',
          }}
          onClick={() => document.querySelectorAll('.svda_question_action')[0].click()}
        >
          <AddCircleIcon />
          <span>Add logo</span>
        </button>

        <Questions />

        <Modal isOpen={this.state.showState} toggle={() => this.setState({ showState: false })} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
          <ModalBody>
            {this.state.SurveyPreview === null ? (
              <div>SHOW THIS {this.state.showState}</div>
            ) : (
              <Survey.Survey model={this.state.SurveyPreview} StylesManager="modern" />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={() => this.setState({ showState: false })}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => this.setState({ showState: false })}>
              Do Something
            </Button>{' '}
          </ModalFooter>
        </Modal>

        {/* Survey Preview div */}
        {/* <Modal
          size="lg"
          show={this.state.showState}
          onHide={() => this.setState({ showState: false })}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">Custom Modal Styling</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.SurveyPreview === null ? (
              <div>SHOW THIS {this.state.showState}</div>
            ) : (
              <Survey.Survey model={this.state.SurveyPreview} StylesManager="modern" />
            )}
          </Modal.Body>
        </Modal> */}

        {/* coupon modal div */}
        <Modal isOpen={this.state.addCoupon} toggle={() => this.setState({ addCoupon: false })} className={this.props.className}>
          <ModalHeader className="Modal_header Modal_header1" toggle={this.toggle}>
            <img style={{ width: '100px', height: '70px' }} src={tag_icon} alt={''} />
            <span
              style={{
                fontWeight: '600',
              }}
            >
              Manage Coupon Codes
            </span>
          </ModalHeader>
          <ModalBody className="Modal_body Modal_body1">
            <Reader
              Info={(this.state.Coupon_codeNumber, this.state.Coupon_code_details)}
              Info_sending={(e) => {
                console.log('e', e)
                this.setState({ Coupon_code_details: e })
              }}
              coupon_delete={(e) => {
                this.setState(
                  {
                    Coupon_codeNumber: null,
                    Coupon_code_details: {},
                  },
                  () => {
                    if (this.state.Checked === true) {
                      this.setState({
                        Checked: false,
                      })
                    }
                  }
                )
              }}
              Array_sending={(e) => {
                if (e === null) {
                  // this.props.Error = "kindly upload a valid file";
                  this.setState({
                    AddCouponError: 'kindly upload a valid file',
                    Coupon_codeNumber: [],
                  })
                } else {
                  this.setState({ Coupon_codeNumber: e })
                }
              }}
              ButtonCLickedState={this.state.AddCouponClicked}
            />
          </ModalBody>
          <ModalFooter className="Modal_footer Modal_footer1">
            <Button color="link" className="btn-cancel-modal btn" onClick={() => this.setState({ addCoupon: false })}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn-accept-modal"
              disabled={this.state.Coupon_codeNumber === null ? true : false}
              onClick={() => {
                this.setState({
                  addCoupon: false,
                  AddCouponClicked: !this.state.AddCouponClicked,
                })
              }}
              style={{
                // padding: "10px 35px",
                // fontSize: "18px",
                width: '65px',
              }}
            >
              OK
            </Button>{' '}
          </ModalFooter>
        </Modal>

        <script type="text/html" id="custom-tab-survey-templates">
          {`
          <div id='test'>TEST</div>
          <h3 data-bind='text: title'></h3>
          <table class='table' style='width:300px'>
        <thead>
            <th>Template Name</th>
            <th></th>
        </thead>
        <tbody data-bind='foreach: surveys'>
        <tr>
                <td data-bind='text:name'></td>
                <td>
                <button data-bind='click:$parent.load($data)'>Load...</button>

                </td>
            </tr>
    
          
    
        </tbody>
        </table>
          
          `}
        </script>

        <div
          id="surveyCreatorContainer"
          onClick={(e) => {
            this.setState({
              SurveyEventListener: !this.state.SurveyEventListener,
            })
          }}
        />
      </>
    )
  }
  saveMySurvey = () => {
    // fetch("http://localhost:3004/", {
    //   method: "GET",
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .catch((error) => console.error("Error:", error));

    // fetch("http://localhost:3004/something", {
    //   method: "POST",
    //   body: JSON.stringify(this.surveyCreator.JSON),
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .then((res) => res.json())
    //   .catch((error) => console.error("Error:", error));
    console.log(this.surveyCreator.JSON)
    this.setState({ savedSurveyjson: this.surveyCreator.JSON })
    this.setState({ status: false })
    this.setState({ saveSurveyName: true })

    console.log('SurveyCreator -> saveMySurvey -> this.state.props', this.props.state)
  }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyCreator))
