// import {
//   REGISTER_USER,
//   REGISTER_USER_SUCCESS,
//   REGISTER_USER_ERROR,
// } from "../../contants/Login1";

export const LoginReducer = (state = [], action) => {
  let response = action.response

  switch (action.type) {
    case 'REGISTER_USER_SUCCESS':
      return { ...state, response }

    case 'REGISTER_USER_ERROR':
      return { ...state, response }

    default:
      return state
  }
}
