import { ADD_SURVEY, TOGGLE_SURVEY } from '../../contants/Survey'
// import getCurrentDate from '../../utils/date'
// import state from "sweetalert/typings/modules/state";
// var date = getCurrentDate().date;
// var Month = getCurrentDate().monthLetter;
// var year = getCurrentDate().year;

const initialState = [
  {
    id: null,
    json: null,
    time: [],
    status: false,
    coupon: null,
  },
]

export const SaveSurvey = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SURVEY:
      return [
        ...state,
        {
          id: action.id,
          json: action.json,

          status: action.status,
          name: action.name,
          coupon: action.coupon,
        },
      ]

    case TOGGLE_SURVEY:
      state.forEach((survey) => {})
      return null

    case 'SURVEY_TOGGLE':
      console.log(action)
      let newArray = []
      // let index = null
      newArray = [...state]
      newArray.forEach((e, i) => {
        if (action.id === e.id) {
          newArray[i] = {
            name: action.name,
            json: action.json,
            id: e.id,
            status: e.status,
            coupon: e.coupon,
          }
        }
      })

      state = []
      // state = newArray;

      return [...state, ...newArray]

    case 'DELETE_SURVEY':
      let newArray1 = []
      newArray1 = [...state]
      var some = newArray1.filter((value, index, arr) => {
        return value.id !== action.id
      })
      console.log('SaveSurvey ->  newArray.filter((value, index,arr) => { return value.id !== action.id})', some)
      var some1 = []
      some.forEach((e) => {
        if (e.id !== null) {
          some1.push(e)
        }
      })

      state = some1

      // return state;
      break
    default:
      return state
  }
}
