import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CSVReader from 'react-csv-reader'
// import SweetAlert from 'sweetalert-react'
// import { Button } from 'reactstrap'
import swal from 'sweetalert'
// import Cloud_image from '../../assets/cloud-computing.svg'
import delete_image from '../../assets/delete.svg'
// import Tick from '../../assets/checked.svg'
// import save_icon from '../../assets/save_icon.svg'
import Cloud_icon from '../../assets/cloud_icon.png'
import tick_icon from '../../assets/tick_icon.png'
import ReactTooltip from 'react-tooltip'

import { Progress } from 'reactstrap'
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
}

class Reader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Duplicate_removed: 0,
      status: null,
      Error: false,
      Error_Message: null,
      CSV_data: null,
      FileInfo: '',
      TotalNumberArray: [],
      TotalArrayNukber: 0,
      progress_value: 0,
      Present_code_sent: 0,
      present_code: [],
      present_code_sent_left: [],
      delete_temp: 0,
      couponcodeProcessed: [],

      // Duplicate_removed: 0,
      // status: null,
      // Error: false,
      // Error_Message: null,
      // CSV_data: null,
      // FileInfo: "",
      // TotalNumberArray: 0,
      // AvailableCode: 0,
      // TotalArrayNumber: 0,
      // TotalavailableArray: 0,
      // TotalavailableRawArray: 0,
    }
  }
  progress_value = () => {
    setTimeout(() => {
      this.setState(
        {
          progress_value: this.state.progress_value + 50,
        },
        () => {
          setTimeout(() => {
            this.setState({
              progress_value: this.state.progress_value + 50,
            })
          }, 1000)
        }
      )
    }, 1000)
  }
  progress_function = (trigger) => {
    if (trigger) {
      this.setState({ progress_value: 0 }, () => {
        this.progress_value()
      })
    }
  }

  delete_function_bottom = () => {
    this.setState(
      {
        TotalArrayNukber: [],
        FileInfo: '',
        progress_value: 0,
      },
      () => {
        this.props.coupon_delete()
        this.props.default_state()
        this.props.bottom_delete()
        if (this.state.present_code.length > 0) {
          console.log('delete_function_bottom -> inside')
          let coupon_code_Left = this.props.Top_array
          this.props.Array_sending(coupon_code_Left)
          this.props.Top_info_send({
            total_coupon_code: this.state.TotalNumberArray,
          })
        } else {
          this.props.Array_sending(null)
        }
      }
    )
  }
  async componentDidMount() {
    console.log('componentDidMount -> this.props', this.props)
    let totalCouponCode = this.props.sent_survey.concat(this.props.total_coupon_code_left)
    console.log('componentDidMount -> totalCouponCode', totalCouponCode)
    if (this.props.top_state === true) {
      console.log('componentDidMount -> this.props.CouponCode', this.props.CouponCode)

      if (Object.keys(this.props.Info).length > 0) {
        console.log('componentDidMount -> this.props.CouponCode', this.props.CouponCode)

        this.setState(
          {
            TotalArrayNukber: this.props.Info.Coupon_code_processed,
            Duplicate_removed: this.props.Info.Duplicate_removed,
            FileInfo: this.props.Info.file_info,
            TotalNumberArray: this.props.Top_array,
            progress_value: 100,
            Present_code_sent: this.props.Top_array.length,
            present_code: this.props.Top_array,
            present_code_sent_left: this.props.CouponCode,
          },
          () => {}
        )
      } else {
        console.log('componentDidMount -> this.props.CouponCode', this.props.CouponCode)

        this.setState({
          Present_code_sent: this.props.Top_array.length,
          present_code: this.props.Coupon_code_afer_upload,
          present_code_sent_left: this.props.CouponCode,
        })
      }
    } else {
      if (this.props.bottom_state === true) {
        let promise = new Promise((resolve, reject) => {
          this.setState(
            {
              present_code: totalCouponCode,
              Present_code_sent: this.props.CouponCode.length,
            },
            () => {
              resolve(true)
            }
          )
        })
        let temp = await promise
      } else {
        if (this.props.default_state_display === true) {
          console.log('componentDidMount -> this.props.CouponCode', this.props.CouponCode)

          this.setState({
            Present_code_sent: this.props.Top_array.length,
            present_code: totalCouponCode,
            present_code_sent_left: this.props.CouponCode,
          })
        }
        if (this.props.default_state_display === false) {
          console.log('componentDidMount -> this.props.CouponCode', this.props.CouponCode)

          this.setState({
            Present_code_sent: 0,
            present_code: [],
            present_code_sent_left: this.props.CouponCode,
          })
        }
        if (this.props.default_upload === true) {
          console.log('componentDidMount -> this.props.CouponCode', this.props.CouponCode)

          this.setState({
            Present_code_sent: this.props.Top_array.length,
            present_code: this.props.Coupon_code_afer_upload,
            present_code_sent_left: this.props.CouponCode,
          })
        }
      }
    }
  }

  delete_function = () => {
    swal({
      icon: 'error',
      title: 'Are you sure',
      text: `All the present coupon code will be deleted`,
      buttons: {
        catch: {
          text: 'ok',
          value: 'ok',
        },
        cancel: 'Cancel',
      },
    }).then((e) => {
      if (e) {
        this.setState(
          {
            present_code: [],
            Present_code_sent: 0,
          },
          () => {
            this.props.top_state_send()
            this.props.default_state()
            //if nothing is uploaded by user
            if (this.state.TotalArrayNukber === 0) {
              this.setState({
                Duplicate_removed: this.state.delete_temp,
                TotalNumberArray: this.state.couponcodeProcessed,
              })

              this.props.Array_sending(this.state.couponcodeProcessed)
              this.props.Info_sending({
                Coupon_code_processed: 0,
                Duplicate_removed: 0,
                new_codes: [],
                file_info: '',
              })
            } else {
              //if something is uploaded by user
              if (this.state.couponcodeProcessed !== []) {
                this.props.Array_sending(this.state.couponcodeProcessed)

                this.setState({
                  Duplicate_removed: this.state.TotalArrayNukber - this.state.couponcodeProcessed.length,
                  TotalNumberArray: this.state.couponcodeProcessed,
                })
                this.props.Info_sending({
                  Coupon_code_processed: this.state.TotalArrayNukber,
                  Duplicate_removed: this.state.TotalArrayNukber - this.state.couponcodeProcessed.length,
                  new_codes: this.state.couponcodeProcessed,
                  file_info: this.state.FileInfo,
                })
              } else {
                this.props.Array_sending(this.state.TotalNumberArray)

                this.setState({
                  Duplicate_removed: this.state.TotalArrayNukber - this.state.TotalNumberArray.length,
                })
                this.props.Info_sending({
                  Coupon_code_processed: this.state.TotalArrayNukber,
                  Duplicate_removed: this.state.TotalArrayNukber - this.state.TotalNumberArray.length,
                  new_codes: this.state.TotalNumberArray,
                  file_info: this.state.FileInfo,
                })
              }
            }
          }
        )
      }
    })
  }

  handleForce = (data, fileInfo) => {
    var couponcodeArray = []

    var User_input_csv_header = Object.keys(data[0])[0]
    var User_input_csv_header_lower_case = User_input_csv_header.toLowerCase()

    if (User_input_csv_header_lower_case !== 'coupon_code') {
      this.setState({
        Error: true,
        Error_Message: `First column titile ${User_input_csv_header} is not equal to Coupon_Code as instructed`,
      })
      swal({
        icon: 'error',
        title: 'error',
        text: `First column titile ${User_input_csv_header} is not equal to Coupon_Code as instructed`,
      })
    } else {
      this.setState(
        {
          FileInfo: fileInfo.name,
          CSV_data: data,
        },
        () => {
          this.progress_function('trigger')
        }
      )
      data.forEach((e, i) => {
        couponcodeArray.push(e.coupon_code)
      })

      //   function difference(arr1, arr2) {
      //     const a1 = flatten(arr1, true);
      //     const a2 = flatten(arr2, true);

      //     const a = [];
      //     const diff = [];
      //     for (var i = 0; i < a1.length; i++) a[a1[i]] = false;
      //     for (i = 0; i < a2.length; i++)
      //       if (a[a2[i]] === true) {
      //         delete a[a2[i]];
      //       } else a[a2[i]] = true;
      //     for (const k in a) diff.push(k);
      //     return diff;
      //   }

      //   var flatten = (a, shallow, r) => {
      //     if (!r) {
      //       r = [];
      //     }
      //     if (shallow) {
      //       return r.concat(...a);
      //     }
      //     for (var i = 0; i < a.length; i++) {
      //       if (a[i].constructor == Array) {
      //         flatten(a[i], shallow, r);
      //       } else {
      //         r.push(a[i]);
      //       }
      //     }
      //     return r;
      //   };

      let temp = new Set(couponcodeArray)

      this.state.present_code.forEach((e) => {
        temp.add(e)
      })

      let temp1 = Array.from(temp)

      let temp3 = this.state.present_code.length + couponcodeArray.length - temp1.length

      let array_without_duplicates = couponcodeArray.filter((value, index) => couponcodeArray.indexOf(value) === index)

      // let already_present_code = this.state.present_code_sent_left

      //   let temp = difference(array_without_duplicates, already_present_code);
      //   console.log("handleForce -> temp", temp);

      let array51 = array_without_duplicates.filter((obj) => {
        return this.state.present_code.indexOf(obj) === -1
      })

      let _new_codes = this.state.present_code.length > 0 ? array51.concat(this.state.present_code) : array51

      let final_filter = _new_codes.filter((value, index) => _new_codes.indexOf(value) === index)

      this.setState(
        {
          TotalArrayNukber: couponcodeArray.length,
          Duplicate_removed: temp3,
          couponcodeProcessed: Array.from(temp),
          delete_temp: temp.length - couponcodeArray.length,
          TotalNumberArray: temp1,
        },
        () => {
          this.props.Array_sending(final_filter)
          this.props.default_state()
          this.props.default_upload_state()
          this.props.Info_sending({
            Coupon_code_processed: this.state.TotalArrayNukber,
            Duplicate_removed: couponcodeArray.length - final_filter.length,
            new_codes: final_filter,
            file_info: this.state.FileInfo,
          })
        }
      )
    }
  }

  handlefileUpload = (file) => {
    let _filename = file.target.files[0].name

    var reader = new FileReader()

    if (file.target.files.length > 0) {
      reader.readAsText(file.target.files[0])
      reader.onloadstart = (event) => {}
      reader.onprogress = (event) => {}
      reader.onloadend = (event) => {
        var allTextLines = event.target.result.split(/\r\n|\n/)
        var lines = allTextLines.map((data) => data.split(';'))

        let fileHeader = lines[0].toString()

        if (fileHeader.toLowerCase().trim() !== 'coupon code') {
          swal({
            icon: 'error',
            title: 'Error',
            text: `CSV file should have only one column with the title Coupon Code.`,
          })
        } else {
          this.setState({ FileInfo: _filename }, () => {
            this.progress_function('trigger')
          })

          let CouponcodeUploaded = lines.flat()
          CouponcodeUploaded.shift()
          CouponcodeUploaded = CouponcodeUploaded.filter((e) => e !== '')

          this.setState(
            {
              FileInfo: _filename,
              CSV_data: lines.flat(),
            },
            () => {}
          )
          let temp = new Set(CouponcodeUploaded)
          this.state.present_code.forEach((e) => {
            temp.add(e)
          })
          let temp1 = Array.from(temp)

          let temp3 = this.state.present_code.length + CouponcodeUploaded.length - temp1.length

          let array_without_duplicates = CouponcodeUploaded.filter((value, index) => CouponcodeUploaded.indexOf(value) === index)

          let array51 = array_without_duplicates.filter((obj) => {
            return this.state.present_code.indexOf(obj) === -1
          })

          let _new_codes = this.state.present_code.length > 0 ? array51.concat(this.state.present_code) : array51

          let final_filter = _new_codes.filter((value, index) => _new_codes.indexOf(value) === index)

          this.setState(
            {
              TotalArrayNukber: CouponcodeUploaded.length,
              Duplicate_removed: temp3,
              couponcodeProcessed: Array.from(temp),
              delete_temp: temp.length - CouponcodeUploaded.length,
              TotalNumberArray: temp1,
            },
            () => {
              this.props.Array_sending(final_filter)
              this.props.default_state()
              this.props.default_upload_state()
              this.props.Info_sending({
                Coupon_code_processed: this.state.TotalArrayNukber,
                Duplicate_removed: CouponcodeUploaded.length - final_filter.length,
                new_codes: final_filter,
                file_info: this.state.FileInfo,
              })
            }
          )
        }
      }
    }
  }

  componentDidUpdate() {}

  render() {
    return (
      <div
        className="container"
        style={{
          flexDirection: 'column',
        }}
      >
        <div
          className="row_container first"
          style={{
            fontWeight: '550',
            marginBottom: '20px',
          }}
        >
          {this.props.sent_survey !== 0 ? (
            <>
              <div>Coupon codes available:</div>{' '}
              <span
                style={{
                  color: '#000000',
                  marginLeft: '5px',
                }}
              >
                {`${this.state.Present_code_sent} / ${this.state.present_code.length}`}
              </span>
            </>
          ) : (
            <>
              <div>Coupon codes available:</div>
              <span
                style={{
                  color: '#000000',
                  marginLeft: '5px',
                }}
              >{`0 / ${this.state.present_code.length}`}</span>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: '6px',
            }}
          >
            {this.state.present_code.length > 0 ? (
              <img
                className="delete_icon"
                onClick={() => {
                  this.delete_function()
                }}
                src={delete_image}
                alt={''}
              />
            ) : null}
          </div>
        </div>
        <div className="row_container outer1">
          <div className="row_container1 ">
            <div>
              <button
                size="lg"
                color="primary"
                data-tip="click to upload coupon code"
                data-for="sadFace"
                onClick={() => {
                  document.querySelector('#special_upload_file').click()
                }}
                className="Font_style special_button manage_coupon_code"
                style={{
                  width: '111%',
                }}
              >
                <img style={{ height: '40px', width: '60px' }} src={Cloud_icon} alt={''} />
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: '1000',
                    paddingBottom: '10px',
                  }}
                >
                  Select CSV file to upload
                </span>
              </button>
              <input
                type="file"
                id="special_upload_file"
                style={{
                  display: 'none',
                }}
                onChange={(e) => this.handlefileUpload(e)}
                onClick={(e) => {
                  e.target.value = null
                }}
                accept=".csv"
              />
              <ReactTooltip id="sadFace" place="bottom" effect="solid"></ReactTooltip>
              <CSVReader
                cssClass="react-csv-input"
                cssInputClass="input_class "
                cssLabelClass="Label_class"
                onFileLoaded={this.handleForce}
                parserOptions={papaparseOptions}
              />
            </div>
          </div>
          <div className="row_container1 ">
            <div
              className="wrapper-div"
              style={{
                marginLeft: '45px',
              }}
            >
              <div className="Font_style11">
                {this.state.TotalArrayNukber > 0 && this.state.progress_value === 100 ? (
                  <div
                    className="Font_style11 second_padding"
                    style={{
                      paddingLeft: '0px',
                    }}
                  >
                    Coupon code processed : {this.state.TotalArrayNukber}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              {this.state.TotalArrayNukber > 0 && this.state.progress_value === 100 ? (
                <div className="Font_style11 second_padding">Duplicate removed : {JSON.stringify(this.state.Duplicate_removed)}</div>
              ) : (
                <div></div>
              )}

              {this.state.TotalArrayNukber > 0 && this.state.progress_value === 100 ? (
                <div className="Font_style11 second_padding" style={{ paddingBottom: '5px' }}>
                  Total new codes added: {this.state.TotalArrayNukber - this.state.Duplicate_removed}
                </div>
              ) : (
                <div></div>
              )}

              {this.state.TotalArrayNukber > 0 && this.state.progress_value === 100 ? (
                <div className="Font_style11 button_style">Total codes available: {this.state.TotalNumberArray.length}</div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <div className="row_container ">
          <div
            style={{
              display: 'flex',
            }}
          >
            {this.state.FileInfo === undefined || this.state.FileInfo === '' ? (
              <span className="Font_style left-align">No file chosen</span>
            ) : (
              <>
                <span className="Font_style left-align" style={{ fontSize: '13px' }}>
                  {this.state.progress_value === 100 ? (
                    <text>
                      {this.state.FileInfo.length > 17 ? 'File: ' + this.state.FileInfo.substring(0, 15) + '....' : 'File: ' + this.state.FileInfo}
                    </text>
                  ) : (
                    'Uploading'
                  )}
                </span>
                <div style={{ position: 'absolute', right: '45%' }}>
                  {this.state.progress_value === 100 ? (
                    <img
                      className="delete_icon"
                      onClick={() => {
                        this.delete_function_bottom()
                      }}
                      src={delete_image}
                      alt={''}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row_container">
          <div
            className="inner progress-inner"
            style={{
              display: this.state.FileInfo ? (this.state.FileInfo.length > 0 ? `block` : `none`) : `none`,
              marginTop: '5px',

              position: 'relative',
            }}
          >
            <Progress className="progress_special" color="warning" value={this.state.progress_value} />
            <img
              style={{
                display: this.state.progress_value === 100 ? `block` : `none`,
                height: '15px',
                width: '20px',
                paddingBottom: '5px',
              }}
              src={tick_icon}
              alt={''}
            />
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reader))
