export const LoginReducer11 = (state = [], action) => {
  let response = action.response
  switch (action.type) {
    case 'LOGIN_USER_ERROR':
      return { ...state, response }
    case 'LOGIN_USER_SUCCESFUL':
      return { ...state, response }

    case 'LOGOUT_ACTION':
      return (state = [])

    case 'UPDATE_TOKEN':
      let _state = {}
      _state = state
      _state.response.token = action.payload
      return _state
    default:
      return state
  }
}
