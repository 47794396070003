import React, { Fragment } from "react";

import AppHeader from "../../../Layout/AppHeader";
import AppSidebarsuperAdmin from "../../../Layout/AppSidebar/AppSidebarsuperAdmin";
import UserEdit from "./options";

export default function Component() {
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebarsuperAdmin />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <UserEdit />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
