import { REGISTER_USER } from '../../contants/Login1'

export const registerUserAction = (Name, Email, Password, businessName) => {
  return {
    type: REGISTER_USER,
    Name,
    Email,
    Password,
    businessName,
  }
}
