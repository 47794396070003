let ProjectSettings = {}

console.log('process.env.ENV', process.env.ENV)

console.log('process.env.REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL)
// console.log("process.env.NODE_ENV", process.env.staging.NODE_ENV);

ProjectSettings = {
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: 'http://localhost:4010',
  // baseURL: `https://api.carwashsurveys.com`,
}

export default ProjectSettings
