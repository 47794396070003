import { put, call } from "redux-saga/effects";
import ProjectSettings from "../../global/Global";
const LoginUserService = (request) => {
  let User = {
    email: request.email,
    password: request.password,
  };
  // const REGISTER_API_ENDPOINT = "http://localhost:3000/api/auth/signin";
  const REGISTER_API_ENDPOINT = ProjectSettings.baseURL + "/api/auth/signin";

  const parameter = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(User),
  };

  return fetch(REGISTER_API_ENDPOINT, parameter)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((e) => {
      console.log("LoginUserService -> e", e);
    });
};

export function* LoginSaga111(payload) {
  try {
    const response = yield call(LoginUserService, payload);
    // Account does not exists
    // Invalid credentials
    if (response.success === !false) {
      yield put({ type: "LOGIN_USER_SUCCESFUL", response });
    } else {
      yield put({ type: "LOGIN_USER_ERROR", response });
    }
  } catch (error) {
    yield put({ type: "LOGIN_USER_ERROR", error });
  }
}
