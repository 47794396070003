import React from 'react'
// import $ from "jquery";
import { PopoverBody } from 'reactstrap'
class InfoDiv extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: null,
    }
  }

  render() {
    return (
      <div className="info_div">
        {this.props.props === true ? (
          <>
            <div
              style={{
                height: 'fit-content',
                width: '200px',
                background: 'white',
              }}
            >
              <PopoverBody>
                Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
                {this.props.class}
              </PopoverBody>
            </div>
          </>
        ) : (
          <div></div>
        )}
      </div>
    )
  }
}

export default InfoDiv
