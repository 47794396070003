import React from 'react'
import Header from './header'
import Loader11 from '../../Loader'
// import Pagination from '../../Pagination'
import {
  // Row,
  Col,
  Card,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Button,
  // ButtonDropdown,
} from 'reactstrap'
import LaddaButton, {
  // EXPAND_LEFT,
  // EXPAND_RIGHT,
  // EXPAND_UP,
  // EXPAND_DOWN,
  // SLIDE_LEFT,
  // SLIDE_RIGHT,
  // SLIDE_UP,
  // SLIDE_DOWN,
  ZOOM_IN,
  // ZOOM_OUT,
} from 'react-ladda'
import ProjectSettings from '../../../global/Global'
// import { Prompt } from 'react-router-dom'
import * as Survey from 'survey-react'
import DraftsIcon from '@material-ui/icons/Drafts'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
// import DoneAllIcon from '@material-ui/icons/DoneAll'
import { LoaderActions_loadingtrue, LoaderActions_loadingfalse } from '../../../redux/actions/Loader'
import { CouponCodeaction_remove_codes } from '../../../redux/actions/Coupon_code2'
import AssessmentIcon from '@material-ui/icons/Assessment'
import FileCopyIcon from '@material-ui/icons/FileCopy'
// import { CSVLink, CSVDownload } from 'react-csv'
// import * as SurveyKo from 'survey-knockout'
import * as SurveyJSCreator from 'survey-creator'
import SweetAlert from 'sweetalert-react'
// import { DeleteSurvey } from '../../../redux/actions/Survey'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withRouter } from 'react-router-dom'
import { LineChart, Line, ResponsiveContainer } from 'recharts'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './index.css'
import { connect } from 'react-redux'
import * as swal1 from '@sweetalert/with-react'
//demo.dashboardpack.com/architectui-html-pro/components-notifications.html
import ReactTable from 'react-table'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import CSVReader from 'react-csv-reader'
// import { useTable, useSortBy, ReactTable } from "react-table";
// import { useSelector, useDispatch } from "react-redux";
import Reader from '../../Manage_coupon-code_dashboard'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import swal from 'sweetalert'
// import { fromUnixTime } from 'date-fns'
// import { type } from 'jquery'
// import SurveyEditor11 from '../../SuperAdmin/UserView/Survey/Main'
// import tag from '../../../assets/tag.svg'
import tag_icon from '../../../assets/tag_icon.png'
import Start_img from '../../../assets/undraw_customer_survey_f9ur.svg'
import Swal from 'sweetalert2'
// import ReactTable1 from '../../ReactTable'

class Options extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showState: false,
      DeleteNotification: false,
      DeleteSuveyDetails: null,
      url: window.location.href,
      copied: false,
      UpdateNotification: false,
      UpdateUserDetails: {},
      ResultSurvey: [],
      EdgeCaseError: null,
      EdgeCaseErrorState: false,
      QuestionChecker: 0,
      addCoupon: false,
      AddCouponSurveyDetails: {},
      Coupon_codeNumber: [],
      AddCouponClicked: false,
      // Delete_check: false,
      // Delete_check_top: false,
      ButtonLoading: false,
      surveyName: '',
      surveyNameInvalid: false,
      surveyNameValid: false,
      ResponseLength: 0,
      PageNumber: 0,
      pages: 0,
      sorted: [],
      PageSize: 10,
      columnss: [
        {
          Header: (row) => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  width: 'fit-content',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingLeft: '39px',
                  // paddingLeft:"50px",
                  marginLeft: '13px',
                  fontSize: '14px',
                  // fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  // backgroundColor:"cyan",
                  color: '#4a4a4a',
                }}
              >
                {/* {JSON.stringify(ProjectSettings.baseURL)} */}
                Survey Name
              </div>
            </div>
          ),
          accessor: 'name',

          Cell: (row) => {
            // let date
            if (row.original.TimeStampLocal) {
              let date = row.original.TimeStampLocal.split('T')[0].split('-').reverse()
              let NumberIndex = Math.floor(date.splice(1, 1))
              let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

              date.push(month[JSON.parse(NumberIndex) - 1])
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    this.props.history.push(`/edit-survey/${row.original.id}`)
                  }}
                >
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      fontSize: '15px',
                      color: '#757575',
                      paddingBottom: '5px',
                    }}
                  >
                    {row.original.Name}
                  </div>
                  <div style={{ fontSize: '12px', opacity: '0.8' }}>
                    {/* {`Last Modified:${date[0]}-${date[2]}-${date[1]}`} */}
                    <div>Last Modified:</div>
                    <div> {date ? `${date[0]}-${date[2]}-${date[1]}` : ''}</div>
                  </div>
                </div>
              )
            } else {
              let date
              if (row.original.updatedAt) {
                date = row.original.updatedAt.split('T')[0].split('-').reverse()
                let NumberIndex = Math.floor(date.splice(1, 1))
                let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

                date.push(month[JSON.parse(NumberIndex) - 1])
              }

              return row.original.updatedAt !== undefined ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    this.props.history.push(`/edit-survey/${row.original.id}`)
                  }}
                >
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      fontSize: '15px',
                      color: '#757575',
                      paddingBottom: '5px',
                    }}
                  >
                    {row.original.Name}
                  </div>
                  <div style={{ fontSize: '12px', opacity: '0.8' }}>
                    {/* {`Last Modified:${date[0]}-${date[2]}-${date[1]}`} */}
                    <div>Last Modified:</div>
                    <div> {date ? `${date[0]}-${date[2]}-${date[1]}` : ''}</div>
                  </div>
                </div>
              ) : (
                <div></div>
              )
            }
          },
        },
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontSize: '14px',
                  textAlign: 'center',
                  // fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                  // paddingLeft: "50px",
                }}
              >
                Codes Sent
              </div>
            </div>
          ),
          accessor: 'codesredeemed',

          Cell: (row) => {
            return (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {/* {JSON.stringify(this.state.AddCouponSurveyDetails)} */}
                <div
                  style={{
                    height: '100%',
                    width: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  {row.original.totalCode && row.original.totalCode.length > 1 ? (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          // let TotalCode = row.original.totalCode.concat(row.original.Codes_redeemed)
                          let TotalCode = row.original.totalCode
                          this.setState(
                            {
                              addCoupon: true,
                              AddCouponSurveyDetails: {
                                id: row.original.id,
                                name: row.original.Name,
                                json: row.original.json,
                                CouponCode: TotalCode,
                                delete_check: false,

                                Sent_code: row.original.Codes_redeemed,
                                Coupon_code_status: row.original.CouponCodeStatus,
                              },
                            },
                            () => {}
                          )
                        }}
                      >
                        <div> {!row.original.Codes_redeemed ? 0 : row.original.Codes_redeemed.length}</div>

                        <div>{row.original.totalCode.length + row.original.Codes_redeemed.length}</div>
                      </div>
                      <Progress
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          // let TotalCode = row.original.totalCode.concat(row.original.Codes_redeemed)
                          let TotalCode = row.original.totalCode

                          this.setState(
                            {
                              addCoupon: true,
                              AddCouponSurveyDetails: {
                                id: row.original.id,
                                name: row.original.Name,
                                json: row.original.json,
                                CouponCode: TotalCode,
                                Sent_code: row.original.Codes_redeemed,
                                Coupon_code_status: row.original.CouponCodeStatus,
                                // delete_check:false,
                              },
                            },
                            () => {}
                          )
                        }}
                        className="mb-3 progress-bar-animated-alt"
                        value={row.original.Codes_redeemed.length}
                        max={row.original.totalCode.length + row.original.Codes_redeemed.length}
                      >
                        {Math.floor(
                          (row.original.Codes_redeemed.length / (row.original.Codes_redeemed.length + row.original.totalCode.length)) * 100
                        ) > 40 ? (
                          <div>
                            {Math.floor(
                              (row.original.Codes_redeemed.length / (row.original.Codes_redeemed.length + row.original.totalCode.length)) * 100
                            )}{' '}
                            %
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Progress>
                    </div>
                  ) : (
                    <div
                      style={{ textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState(
                          {
                            addCoupon: true,
                            AddCouponSurveyDetails: {
                              id: row.original.id,
                              name: row.original.Name,
                              json: row.original.json,
                              Sent_code: [],
                              CouponCode: [],
                              // delete_check:false,
                            },
                          },
                          () => {}
                        )
                      }}
                    >
                      No Coupon Codes
                    </div>
                  )}
                </div>
              </div>
            )
          },
        },
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  fontSize: '14px',
                  // fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                }}
              >
                Coupon Code Status
              </div>
            </div>
          ),
          accessor: 'Couponcodestatus',
          Cell: (row) => {
            // function ActiveInactive(state) {
            //   ACtiveInactiveTrueFalse(state)
            // }

            const ACtiveInactiveTrueFalse = (state) => {
              const Oppositestatus = (s) => {
                if (s.includes('Active')) {
                  return 'Inactive'
                }
                // else if (s.includes("Inactive")) return "Inactive"
                else return 'Active'
              }

              if (row.original.totalCode !== null && row.original.totalCode.length !== 0) {
                let totalCouponcode = row.original.Codes_redeemed.concat(row.original.totalCode)
                let body1 = {
                  surveyId: row.original.id,
                  surveyName: row.original.Name,
                  surveyJson: row.original.json,
                  CouponCodeStatus: Oppositestatus(row.original.CouponCodeStatus),
                  couponCode: totalCouponcode,
                }

                var QuestionChecker = 0
                if (row.original.CouponCodeStatus === 'Inactive') {
                  let model = new Survey.Model(row.original.json)

                  model.getAllQuestions().forEach((e, i) => {
                    let email = e.title.toLowerCase()
                    if (email.includes('email')) {
                      QuestionChecker = QuestionChecker + 1
                    }
                  })

                  if (QuestionChecker === 0) {
                    swal({
                      title: 'Warning',
                      text:
                        // "The survey does not have a question to support Coupon Code delivery, kindly add a question with name Email",
                        'This survey does not have a required question requesting an email address. A required question requesting an email address will be added to the end of the survey.',
                      icon: 'warning',
                      buttons: {
                        cancel: 'Cancel',
                        catch: {
                          text: 'Add Question',
                          value: 'Add',
                        },
                        default: {
                          text: 'Edit Survey',
                          value: 'edit',
                        },
                      },
                    })
                      .then((value) => {
                        if (value === 'Add') {
                          let Model11 = new SurveyJSCreator.SurveyCreator(null)

                          Model11.JSON = row.original.json

                          Model11.survey.addNewPage('last_page')
                          Model11.survey.pages.forEach((e, i) => {
                            if (e.name === 'last_page') {
                              e.addNewQuestion('text', 'Email')
                            }
                          })
                          // let new_array = []
                          Model11.getAllQuestions().forEach((e, i) => {
                            if (e.title.toLowerCase().includes('email')) {
                              e.isRequired = true
                              e.inputType = 'email'
                              e.title = 'Email'

                              e.name = 'Email' + (i + 1)
                            }
                          })

                          let body11 = {
                            surveyId: row.original.id,
                            surveyName: row.original.Name,
                            surveyJson: Model11.JSON,
                            CouponCodeStatus: totalCouponcode.length === 0 ? 'Inactive' : Oppositestatus(row.original.CouponCodeStatus),
                            couponCode: totalCouponcode,
                          }

                          let body1212 = JSON.stringify({ ...body11 })
                          fetch(ProjectSettings.baseURL + '/api/survey', {
                            method: 'PUT',
                            headers: {
                              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                              'Content-type': 'application/json',
                            },
                            body: body1212,
                          })
                            .then((e) => {
                              return e.json()
                            })
                            .then((e) => {})
                            .then(() => {
                              this.UpdatingSurvey('ADD_QUESTION', body11)
                              // this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token)
                            })
                            .catch((e) => {
                              swal({
                                title: 'Warning',
                                text: 'Something went wrong',
                                icon: 'warning',
                                timer: 2000,
                              })
                            })
                        }
                        if (value === 'edit') {
                          this.props.history.push(`/edit-survey/${row.original.id}`)
                        }
                      })
                      .catch((e) => {
                        swal({
                          title: 'Warning',
                          text: 'Something went wrong',
                          icon: 'warning',
                          timer: 2000,
                        })
                      })
                  } else {
                    fetch(ProjectSettings.baseURL + '/api/survey', {
                      method: 'PUT',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                      },

                      body: JSON.stringify(body1),
                    })
                      .then((r) => {
                        return r.json()
                      })
                      .then((e) => {
                        this.UpdatingSurvey('COUPON_CODE_STATUS_UPDATE', body1)
                        this.setState({ UpdateNotification: false })
                      })
                      .catch((e) => {})

                    // this.setState({
                    //   UpdateUserDetails: {
                    //     body1,
                    //   },
                    //   UpdateNotification: true,
                    // });
                  }
                } else {
                  fetch(ProjectSettings.baseURL + '/api/survey', {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                    },

                    body: JSON.stringify(body1),
                  })
                    .then((r) => {
                      return r.json()
                    })
                    .then((e) => {
                      this.UpdatingSurvey('COUPON_CODE_STATUS_UPDATE', body1)
                      this.setState({ UpdateNotification: false })
                    })
                    .catch((e) => {})
                  // this.setState({
                  //   UpdateUserDetails: {
                  //     body1,
                  //   },
                  //   UpdateNotification: true,
                  // });
                }
              } else {
                this.setState({
                  addCoupon: true,
                  AddCouponSurveyDetails: {
                    id: row.original.id,
                    name: row.original.Name,
                    json: row.original.json,
                    Sent_code: [],
                    CouponCode: [],
                    // delete_check:false,
                  },
                })
              }
            }

            var state123 = TrueFalse(row.original.CouponCodeStatus)
            function TrueFalse(s) {
              if (s) {
                return s.includes('Active')
              } else {
                return false
              }
            }

            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={state123}
                      // disabled={
                      //   row.original.totalCode &&
                      //   row.original.totalCode.length > 1
                      //     ? false
                      //     : true
                      // }
                      onChange={() => {
                        ACtiveInactiveTrueFalse(row.original.CouponCodeStatus)
                      }}
                    />
                  }
                  label={row.original.CouponCodeStatus}
                />
              </div>
            )
          },
        },

        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  fontSize: '14px',
                  // fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                }}
              >
                Survey Status
              </div>
            </div>
          ),
          accessor: 'status',
          Cell: (row) => {
            // let Status = row.original.status

            let color
            if (row.original.status === 'Active') {
              color = 'success'
            }
            if (row.original.status === 'Draft') {
              color = 'warning'
            }
            if (row.original.status === 'Completed') {
              color = 'info'
            }

            return (
              <div
                style={{
                  height: '65%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <UncontrolledButtonDropdown style={{ width: '60%', paddingTop: '0px', margin: '0px' }}>
                  <DropdownToggle className="btn-icon btn-icon-only btn btn-link" color={color} caret>
                    {row.original.status === 'Active' ? `Open` : `Closed`}
                  </DropdownToggle>
                  <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
                    <DropdownItem
                      onClick={(e) => {
                        let body11
                        body11 = {
                          surveyId: row.original.id,
                          status: 'Draft', //'Draft', 'Completed','Active', 'Inactive'
                        }

                        fetch(ProjectSettings.baseURL + '/api/survey/status', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                          },
                          body: JSON.stringify(body11),
                        })
                          .then((e) => {
                            return e.json()
                          })
                          .then((e) => {
                            this.UpdatingSurvey('SURVEY_STATE_UPDATE_CLOSE', body11)
                          })
                          .catch((e) => {})
                      }}
                    >
                      <DraftsIcon className="dropdown-icon" />
                      <span> Closed</span>
                    </DropdownItem>

                    <DropdownItem
                      onClick={(e) => {
                        let body11
                        body11 = {
                          surveyId: row.original.id,
                          status: 'Active', //'Draft', 'Completed','Active', 'Inactive'
                        }

                        fetch(ProjectSettings.baseURL + '/api/survey/status', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                          },
                          body: JSON.stringify(body11),
                        })
                          .then((e) => {
                            return e.json()
                          })
                          .then((e) => {
                            this.UpdatingSurvey('SURVEY_STATE_UPDATE_OPEN', body11)
                          })
                          .catch((e) => {})
                      }}
                    >
                      <NotificationsActiveIcon className="dropdown-icon" />
                      <span> Open</span>
                    </DropdownItem>

                    {/* <DropdownItem
                      onClick={(e) => {
                        let body11;
                        body11 = {
                          surveyId: row.original.id,
                          status: "Completed", //'Draft', 'Completed','Active', 'Inactive'
                        };

                        fetch(
                          "ProjectSettings.baseURL/api/survey/status",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                            },
                            body: JSON.stringify(body11),
                          }
                        )
                          .then((e) => {
                            return e.json();
                          })
                          .then((e) => {
                            this.sendingSurveyListtodb(
                              this.props.state.LoginReducer11.response.token
                            );
                          })
                          .catch((e) => {});
                      }}
                    >
                      <DoneAllIcon className="dropdown-icon" />
                      <span> Completed</span>
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            )
          },
        },
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontSize: '14px',
                  // fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                  paddingLeft: '15px',
                  textAlign: 'initial',
                }}
              >
                Responses
              </div>
            </div>
          ),
          accessor: 'responses',

          Cell: (data) => {
            // if (this.props.state.LoginReducer11.length === 0) {
            //   this.props.history.push("/login");
            // } else {
            // let RespnseData = {
            //   responseData: [],
            //   responseDate: null,
            // }
            let Returndata = []
            const ResponseCollection = (e) => {
              let data111 = { surveyResponse: [], SurveyresponseDate: [] }
              e.forEach((e1) => {
                data111.surveyResponse.push(e1)
                let ResponseDate = new Date(e1.updatedAt)
                data111.SurveyresponseDate.push(ResponseDate)
              })

              let last = { name: [], uv: [] }
              for (var i = 0; i < 8; i++) {
                last.name.push(new Date(Date.now() - i * 24 * 60 * 60 * 1000))
                last.uv.push(0)
              }

              data111.SurveyresponseDate.forEach((e, i1) => {
                last.name.forEach((e1, i) => {
                  let ResponseDate = new Date(e)
                  let LoopDate = new Date(e1)

                  if (ResponseDate.getDate() === LoopDate.getDate()) {
                    last.uv[i] = last.uv[i] + 1
                  }
                })
              })

              // last.name.forEach((e, i) => {
              //   Returndata.push({ name: e });
              // });
              // console.log(
              //   "Options -> ResponseCollection -> Returndata",
              //   Returndata
              // );

              // last.uv.forEach((e, i) => {
              //   Returndata.push({ name: last.name[i], uv: e });
              // });
              let Reverselastname = last.name.reverse()

              let Reverselastuv = last.uv.reverse()

              Reverselastuv.forEach((e, i) => {
                Returndata.push({ name: Reverselastname[i], uv: e })
              })

              return Returndata
            }
            // let API_END_POINT = `ProjectSettings.baseURL/api/survey/results/${data.original.id}`;

            // fetch(API_END_POINT, {
            //   method: "GET",
            //   headers: {
            //     Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
            //     "Content-type": "application/json",
            //   },
            // })
            //   .then((e) => {
            //     return e.json();
            //   })
            //   .then((e) => {
            //     RespnseData = {
            //       responseData: e.responseData,
            //     };
            //   })
            //   .then(() => {
            //     this.setState({});
            //   })
            //   .catch((e) => {
            //   });

            // const data1 = [
            //   { name: "Page A", uv: 4000 },
            //   { name: "Page B", uv: 3000 },
            //   { name: "Page C", uv: 2000 },
            //   { name: "Page D", uv: 2780 },
            //   { name: "Page E", uv: 1890 },
            //   { name: "Page F", uv: 2390 },
            //   { name: "Page G", uv: 3490 },
            //   { name: "Page C", uv: 2000 },
            //   { name: "Page D", uv: 4780 },
            //   { name: "Page E", uv: 2890 },
            //   { name: "Page F", uv: 1390 },
            //   { name: "Page G", uv: 3490 },
            // ];

            // const data1 = [
            //   { name: 'Page A', uv: 4 },
            //   { name: 'Page B', uv: 3 },
            //   { name: 'Page C', uv: 2 },
            //   { name: 'Page D', uv: 2 },
            //   { name: 'Page E', uv: 1 },
            //   { name: 'Page F', uv: 2 },
            //   { name: 'Page G', uv: 3 },
            //   { name: 'Page C', uv: 2 },
            //   { name: 'Page D', uv: 4 },
            //   { name: 'Page E', uv: 2 },
            //   { name: 'Page F', uv: 1 },
            //   { name: 'Page G', uv: 3 },
            // ]
            if (data.original.Resultjson) {
              var something11 = ResponseCollection(data.original.Resultjson)
            }
            // console.log(
            //   "Options -> constructor -> data.original",
            //   data.original
            // );
            // function JSON2CSV(objArray) {
            //   var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
            //   var str = ''
            //   var line = ''

            //   for (var i = 0; i < array.length; i++) {
            //     var line = ''

            //     for (var index in array[i]) {
            //       line += array[i][index] + ','
            //     }

            //     line = line.slice(0, -1)
            //     str += line + '\r\n'
            //   }
            //   return str
            // }

            function specialTransform(object) {
              // console.log(
              //   "Options -> specialTransform -> object",
              //   typeof object
              // );
              if (typeof object === 'object') {
                // let prelimilary_string = JSON.stringify(object)
                // let tessting = JSON.stringify(object)

                return JSON.stringify(object)
                  .split(',')
                  .join(';')
                  .split('[')
                  .join('')
                  .split(']')
                  .join('')
                  .split(`"`)
                  .join('')
                  .split('{')
                  .join('')
                  .split('}')
                  .join('')
              } else {
                let prelimilary_string = JSON.stringify(object)

                prelimilary_string.split(',').join('')
                prelimilary_string.replace('[')
                prelimilary_string.replace(']')
                // console.log(
                //   "Options -> specialTransform -> prelimilary_string",
                //   prelimilary_string
                // );
                return JSON.stringify(object)
                  .split(',')
                  .join(';')
                  .split('[')
                  .join('')
                  .split(']')
                  .join('')
                  .split(`"`)
                  .join('')
                  .split('{')
                  .join('')
                  .split('}')
                  .join('')
              }
            }
            function convertToCSV(objArray) {
              // console.log(
              //   "Options -> convertToCSV -> objArray",
              //   typeof objArray
              // );
              var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
              var str = ''
              for (var i = 0; i < array.length; i++) {
                var line = ''
                for (var index in array[i]) {
                  // console.log(
                  //   "Options -> convertToCSV -> index",
                  //   JSON.stringify(index)
                  // );
                  if (line !== '') line += ','
                  // console.log(
                  //   "Options -> convertToCSV -> array[i][index]",
                  //   array[i][index]
                  // );

                  // console.log(
                  //   "Options -> convertToCSV ->   typeof  array[i][index]",
                  //   typeof array[i][index]
                  // );
                  array[i][index] = specialTransform(array[i][index])
                  // typeof array[i][index] === "object"
                  //   ? JSON.stringify(array[i][index])
                  //   : array[i][index];

                  line += array[i][index]
                  // console.log(
                  //   "Options -> convertToCSV -> line",
                  //   JSON.stringify(line)
                  // );
                }

                str += line + '\r\n'
              }

              return str
            }

            function exportCSVFile(headers, items, fileTitle) {
              // if (headers) {
              //   items.unshift(headers);
              // }

              // Convert Object to JSON
              var jsonObject = JSON.stringify(items)

              var csv = convertToCSV(jsonObject)
              // csv.replace("]");
              // csv.replace("[");
              // csv.replace("{");
              // csv.replace("}");
              // console.log(
              //   "Options -> exportCSVFile -> csv",
              //   JSON.stringify(csv)
              // );
              // var csv1 = JSON2CSV(jsonObject)

              var exportedFilenmae = fileTitle + '.csv' || 'export.csv'

              var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
              if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae)
              } else {
                var link = document.createElement('a')
                if (link.download !== undefined) {
                  // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob)
                  link.setAttribute('href', url)
                  link.setAttribute('download', exportedFilenmae)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
              }
            }
            // var something11
            var data11 = []
            var headers11 = []

            return (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginLeft: '20px',
                }}
                onClick={() => {
                  if (data.original.Resultjson.length > 0) {
                    swal({
                      title: 'Download Responses?',
                      // text: "save as",
                      // content: "input",
                      icon: 'warning',

                      buttons: {
                        cancel: 'Cancel',
                        catch: {
                          text: 'OK',
                          value: 'ok',
                        },
                      },
                    }).then((value) => {
                      if (value === 'ok') {
                        data.original.Resultjson.forEach((e) => {
                          data11.push({
                            date: e.updatedAt,
                            result: e.resultJson,
                          })
                          headers11.push(
                            {
                              label: 'result',
                              key: e.resultJson,
                            },
                            {
                              label: 'date',
                              key: e.updatedAt,
                            }
                          )
                        })

                        let finalResult = []
                        // let couter = 0
                        let dateArray = []
                        let ResultArray = []

                        data11.forEach((e1, i2) => {
                          Object.keys(e1).forEach((e) => {
                            if (e === 'date') {
                              let Key = e

                              if (finalResult.length > 0) {
                              } else {
                                finalResult.push({
                                  key: Key,
                                })
                              }
                            }
                            if (e === 'result') {
                              e1.result.forEach((e2, i1) => {
                                if (i2 === 0) {
                                }
                                finalResult[0][`question${i1}`] = e2.Title
                              })
                            }
                          })

                          let date = e1.date
                          dateArray.push({
                            date,
                          })
                          let result = e1.result
                          ResultArray.push({
                            result,
                          })
                        })

                        dateArray.forEach((e, i) => {
                          finalResult.push({
                            key: e.date,
                          })
                        })
                        ResultArray.forEach((e, i2) => {
                          e.result.forEach((e1, i1) => {
                            let result = e1.result
                            finalResult[i2 + 1][`question${i1}`] = result
                          })
                        })

                        exportCSVFile([], finalResult, 'download')
                      }
                      // console.log(
                      //   "Options -> constructor -> headers11",
                      //   headers11
                      // );
                    })
                  } else {
                    swal({
                      title: 'There are no survey responses',
                      icon: 'warning',
                      timer: 20000,
                    })
                  }
                }}
              >
                <div
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '35px',
                      height: '22px',
                      borderRadius: '14px',
                      justifyContent: 'center',
                      backgroundColor: '#f0f4f6',
                      fontWeight: 'normal',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                      color: '#5f5f5f',
                      paddingTop: '2px',
                    }}
                  >
                    {data.original.Responses}
                  </div>
                </div>

                <ResponsiveContainer height="100%" width="60%">
                  <LineChart data={something11} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                    <Line type="monotone" dataKey="uv" stroke="#3ac47d" dot={false} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )
          },
        },
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  // paddingRight: "39px",
                  fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                }}
              >
                More Actions
              </div>
            </div>
          ),
          accessor: 'actions',
          Cell: (data) => {
            // function JSON2CSV(objArray) {
            //   var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
            //   var str = ''
            //   let line = ''

            //   for (var i = 0; i < array.length; i++) {
            //     line = ''

            //     for (var index in array[i]) {
            //       line += array[i][index] + ','
            //     }

            //     line = line.slice(0, -1)
            //     str += line + '\r\n'
            //   }
            //   return str
            // }

            function specialTransform(object) {
              // console.log(
              //   "Options -> specialTransform -> object",
              //   typeof object
              // );
              if (typeof object === 'object') {
                // let prelimilary_string = JSON.stringify(object)
                // let tessting = JSON.stringify(object)

                return JSON.stringify(object)
                  .split(',')
                  .join(';')
                  .split('[')
                  .join('')
                  .split(']')
                  .join('')
                  .split(`"`)
                  .join('')
                  .split('{')
                  .join('')
                  .split('}')
                  .join('')
              } else {
                let prelimilary_string = JSON.stringify(object)

                prelimilary_string.split(',').join('')
                prelimilary_string.replace('[')
                prelimilary_string.replace(']')
                // console.log(
                //   "Options -> specialTransform -> prelimilary_string",
                //   prelimilary_string
                // );
                return JSON.stringify(object)
                  .split(',')
                  .join(';')
                  .split('[')
                  .join('')
                  .split(']')
                  .join('')
                  .split(`"`)
                  .join('')
                  .split('{')
                  .join('')
                  .split('}')
                  .join('')
              }
            }
            function convertToCSV(objArray) {
              // console.log(
              //   "Options -> convertToCSV -> objArray",
              //   typeof objArray
              // );
              var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
              var str = ''
              for (var i = 0; i < array.length; i++) {
                let line = ''
                for (var index in array[i]) {
                  // console.log(
                  //   "Options -> convertToCSV -> index",
                  //   JSON.stringify(index)
                  // );
                  if (line !== '') line += ','
                  // console.log(
                  //   "Options -> convertToCSV -> array[i][index]",
                  //   array[i][index]
                  // );

                  // console.log(
                  //   "Options -> convertToCSV ->   typeof  array[i][index]",
                  //   typeof array[i][index]
                  // );
                  array[i][index] = specialTransform(array[i][index])
                  // typeof array[i][index] === "object"
                  //   ? JSON.stringify(array[i][index])
                  //   : array[i][index];

                  line += array[i][index]
                  // console.log(
                  //   "Options -> convertToCSV -> line",
                  //   JSON.stringify(line)
                  // );
                }

                str += line + '\r\n'
              }

              return str
            }

            function exportCSVFile(headers, items, fileTitle) {
              // if (headers) {
              //   items.unshift(headers);
              // }

              // Convert Object to JSON
              var jsonObject = JSON.stringify(items)

              var csv = convertToCSV(jsonObject)
              // csv.replace("]");
              // csv.replace("[");
              // csv.replace("{");
              // csv.replace("}");
              // console.log(
              //   "Options -> exportCSVFile -> csv",
              //   JSON.stringify(csv)
              // );
              // var csv1 = JSON2CSV(jsonObject)

              var exportedFilenmae = fileTitle + '.csv' || 'export.csv'

              var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
              if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae)
              } else {
                var link = document.createElement('a')
                if (link.download !== undefined) {
                  // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob)
                  link.setAttribute('href', url)
                  link.setAttribute('download', exportedFilenmae)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
              }
            }
            var data11 = []
            var headers11 = []
            return (
              <div className="d-block w-100 text-center">
                <UncontrolledButtonDropdown>
                  <DropdownToggle className="btn-icon btn-icon-only btn btn-link" color="link">
                    <MoreVertIcon style={{ color: 'black' }} />
                  </DropdownToggle>
                  <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
                    <DropdownItem
                      onClick={(e) => {
                        Swal.fire({
                          title: 'Duplicate survey',
                          input: 'text',
                          inputLabel: 'Survey name',
                          inputPlaceholder: 'Enter survey name',
                          showCancelButton: true,
                          reverseButtons: true,
                          showLoaderOnConfirm: true,
                          buttonsStyling: false,
                          confirmButtonText: 'Confirm',
                          customClass: {
                            cancelButton: `swal-button swal-button--cancel swal_button_cancel`,
                            confirmButton: `swal-button swal-button--catch swal_button_accept`,
                          },
                          preConfirm: (name) => {
                            if (name.trim().length === 0) {
                              Swal.showValidationMessage(`Please input a name`)
                            } else {
                              let API_END_POINT = ProjectSettings.baseURL + '/api/survey/checkSurveyName'
                              let _name = name.trimLeft()
                              let bodyData = JSON.stringify({
                                surveyName: _name,
                              })
                              return fetch(API_END_POINT, {
                                method: 'POST',
                                headers: {
                                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                  'Content-type': 'application/json',
                                },
                                body: bodyData,
                              })
                                .then((e) => e.json())
                                .then((e) => {
                                  if (e.success === false) {
                                    Swal.showValidationMessage(`Survey name already exist`)
                                  } else {
                                    return e
                                  }
                                })

                                .catch((e) => {})
                            }
                          },
                        }).then((e) => {
                          if (e.value) {
                            if (e.value.success === true) {
                              // let CouponCode
                              // if (data.original.totalCode === null) {
                              //   CouponCode = []
                              // } else {
                              //   CouponCode = data.original.totalCode.concat(data.original.Codes_redeemed)
                              // }
                              let Date_local = new Date()
                              let body1 = {
                                surveyName: e.value.name,
                                surveyId: data.original.id,
                                surveyJson: data.original.json,
                                CouponCodeStatus: 'Inactive',
                                status: 'Draft',
                                couponCode: [],
                                TimeStampLocal: Date_local,
                                Resultjson: [],
                              }
                              let API_END_POINT = ProjectSettings.baseURL + '/api/survey'
                              fetch(API_END_POINT, {
                                method: 'POST',
                                headers: {
                                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                  'Content-type': 'application/json',
                                },
                                body: JSON.stringify(body1),
                              })
                                .then((response) => {
                                  return response.json()
                                })
                                .then((json) => {
                                  Swal.fire({
                                    title: `Survey Duplicated Succesfully`,

                                    icon: 'success',
                                  })

                                  this.UpdatingSurvey('DUPLICATE_DASBOARD', json.responseData, body1.surveyId)
                                  // this.sendingSurveyListtodb()
                                })
                            }
                          }
                        })
                      }}
                    >
                      <FileCopyIcon className="dropdown-icon" />
                      <span>Duplicate</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={(e) => {
                        this.props.history.push(`/edit-survey/${data.original.id}`)
                        // setSurveyId(data.original.id);
                      }}
                    >
                      <EditIcon className="dropdown-icon" />
                      <span>Edit</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={(e) => {
                        this.setState({
                          showState: true,
                          ShareSurvey: {
                            id: data.original.id,
                            surveyState: data.original.status,
                          },
                        })
                      }}
                    >
                      <ShareIcon className="dropdown-icon" />
                      <span>Copy Url</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={(e) => {
                        if (data.original.Responses > 0) {
                          fetch(`${ProjectSettings.baseURL}/api/survey/resultJson/${data.original.id}`, {
                            methond: 'GET',
                            headers: {
                              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                            },
                          })
                            .then((response) => {
                              return response.json()
                            })
                            .then((res) => {
                              res.forEach((e) => {
                                data11.push({
                                  date: e.updatedAt,
                                  result: e.resultJson,
                                })
                                headers11.push(
                                  {
                                    label: 'result',
                                    key: e.resultJson,
                                  },
                                  {
                                    label: 'date',
                                    key: e.updatedAt,
                                  }
                                )
                              })

                              let finalResult = []
                              // let couter = 0
                              let dateArray = []
                              let ResultArray = []

                              data11.forEach((e1, i2) => {
                                Object.keys(e1).forEach((e) => {
                                  if (e === 'date') {
                                    let Key = e

                                    if (finalResult.length > 0) {
                                    } else {
                                      finalResult.push({
                                        key: Key,
                                      })
                                    }
                                  }
                                  if (e === 'result') {
                                    e1.result.forEach((e2, i1) => {
                                      if (i2 === 0) {
                                      }
                                      finalResult[0][`question${i1}`] = e2.Title
                                    })
                                  }
                                })

                                let date = e1.date
                                dateArray.push({
                                  date,
                                })
                                let result = e1.result
                                ResultArray.push({
                                  result,
                                })
                              })

                              dateArray.forEach((e, i) => {
                                finalResult.push({
                                  key: e.date,
                                })
                              })
                              ResultArray.forEach((e, i2) => {
                                e.result.forEach((e1, i1) => {
                                  let result = e1.result
                                  finalResult[i2 + 1][`question${i1}`] = result
                                })
                              })

                              exportCSVFile([], finalResult, 'download')
                            })
                          // swal({
                          //   title: "Download Responses?",
                          //   // text: "save as",
                          //   // content: "input",
                          //   icon:"warning",
                          //   buttons: {
                          //     cancel: "Cancel",
                          //     catch: {
                          //       text: "Okay",
                          //       value: "ok",
                          //     },

                          //   },
                          // }).then((value) => {
                          // if (value === "ok") {

                          // }
                          // console.log(
                          //   "Options -> constructor -> headers11",
                          //   headers11
                          // );
                          // });
                        } else {
                          swal({
                            title: 'There are no survey responses',
                            icon: 'warning',
                            timer: 20000,
                          })
                        }
                      }}
                    >
                      <AssessmentIcon className="dropdown-icon" />
                      <span>Download</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={(e) => {
                        this.setState({
                          DeleteSuveyDetails: {
                            id: data.original.id,
                          },
                        })
                        // this.setState({ DeleteNotification: true });
                        // var span_special = document.createElement("span");
                        // span_special.innerHTML = `${data.original.Name}`;

                        // swal({
                        //   // html: true,

                        //   title: `Are you sure you want to delete survey \n${data.original.Name}\n`,

                        //   text: `
                        //     Survey and all associated responses and report will be deleted`,
                        //   icon: "warning",
                        //   buttons: {
                        //     catch: {
                        //       text: "Confirm",
                        //       value: "delete",
                        //     },
                        //     cancel: "Cancel",
                        //   },
                        // }).then((value) => {
                        //   if (value === "delete") {
                        //     const body11 = {
                        //       surveyId: this.state.DeleteSuveyDetails.id,
                        //     };
                        //     console.log(
                        //       "Options -> constructor -> body11",
                        //       body11
                        //     );
                        //     fetch(
                        //       "ProjectSettings.baseURL/api/survey/delete",
                        //       {
                        //         method: "POST",
                        //         headers: {
                        //           Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                        //           "Content-type": "application/json",
                        //         },
                        //         body: JSON.stringify(body11),
                        //       }
                        //     )
                        //       .then((response) => {
                        //         response.json();
                        //       })
                        //       .then((json) => {})
                        //       .then(() => {
                        //         this.sendingSurveyListtodb(
                        //           this.props.state.LoginReducer11.response.token
                        //         );
                        //         this.setState({ DeleteNotification: false });
                        //       })
                        //       .catch((e) => {
                        //       });
                        //   }
                        // });

                        swal1({
                          content: (
                            <div>
                              <div className="swal-icon swal-icon--warning">
                                <span className="swal-icon--warning__body">
                                  <span className="swal-icon--warning__dot"></span>
                                </span>
                              </div>
                              <div className="swal-title">Are you sure you want to delete the survey?</div>
                              <div className="swal-title">{data.original.Name}</div>
                              <div className="swal-text">The survey and all associated responses and reports will be deleted.</div>
                              {/* <div className="swal-footer">
                              <div className="swal-button-container">
                                <button className="swal-button swal-button--cancel">
                                  CANCEL
                                </button>
                              </div>
                              <div className="swal-button-container">
                                <button className="swal-button swal-button--catch">
                                  OKAY
                                </button>
                              </div>
                            </div> */}
                            </div>
                          ),
                          buttons: {
                            cancel: 'Cancel',
                            catch: {
                              text: 'Confirm',
                              value: 'delete',
                            },
                          },
                        }).then((value) => {
                          if (value === 'delete') {
                            const body11 = {
                              surveyId: this.state.DeleteSuveyDetails.id,
                            }

                            fetch(ProjectSettings.baseURL + '/api/survey/delete', {
                              method: 'POST',
                              headers: {
                                Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                'Content-type': 'application/json',
                              },
                              body: JSON.stringify(body11),
                            })
                              .then((response) => {
                                response.json()
                              })
                              .then((json) => {
                                return json
                              })
                              .then((json) => {
                                this.UpdatingSurvey('DELETE_DASHBOARD', json, body11.surveyId)
                                // this.sendingSurveyListtodb()
                                this.setState({ DeleteNotification: false })
                              })
                              .catch((e) => {})
                          }
                        })
                      }}
                    >
                      <DeleteIcon className="dropdown-icon" />
                      <span>Delete</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            )
          },
        },
      ],
      Survey: [],
      SurveyFetch: [],
      ShareSurvey: {},
      NoSurveyFound: false,
    }
  }
  surveyCreator

  Fetchdata = (NewData) => {
    this.setState({
      Survey: NewData,
    })
  }

  UpdatingSurvey = (action, survey_body, _id) => {
    let NewData = []

    if (action === 'DELETE_DASHBOARD') {
      NewData = this.state.Survey
      NewData.forEach((e, i) => {
        if (e.id === _id) {
          NewData.splice(i, 1)
        }
      })

      this.SurveyListCreator(NewData, true)
    }

    if (action === 'DUPLICATE_DASBOARD') {
      NewData = this.state.Survey
      NewData.forEach((e, i) => {
        if (e.id === _id) {
          NewData.splice(i + 1, 0, {
            Name: survey_body.surveyName,
            updatedAt: survey_body.updatedAt,
            status: survey_body.status,
            Responses: 0,
            Codes_redeemed: survey_body.couponCodeUsed === null ? [] : survey_body.couponCodeUsed,
            json: survey_body.surveyJson,
            id: survey_body.surveyId,
            totalCode: survey_body.couponCode === null ? [] : survey_body.couponCode,
            CouponCodeStatus: survey_body.CouponCodeStatus,
            Resultjson: [],
            TimeStampLocal: survey_body.TimeStampLocal,
            delete_check: false,
          })
        }
      })

      // this.setState({
      //   Survey: NewData,
      // })
      this.SurveyListCreator(NewData, true)
    }
    if (action === 'COUPON_CODE_UPDATE_ADMIN') {
      NewData = this.state.Survey

      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          // e.status = e.status
          e.TimeStampLocal = survey_body.TimeStampLocal

          e.totalCode = survey_body.couponCode
          e.CouponCodeStatus = survey_body.CouponCodeStatus
          e.Codes_redeemed = survey_body.couponCodeUsed === undefined ? e.Codes_redeemed : survey_body.couponCodeUsed
        }
      })
      this.setState({
        Survey: NewData,
      })
    }

    if (action === 'SURVEY_STATE_UPDATE_CLOSE') {
      NewData = this.state.Survey
      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          e.status = survey_body.status
        }
      })
      this.setState({
        Survey: NewData,
      })
    }
    if (action === 'SURVEY_STATE_UPDATE_OPEN') {
      NewData = this.state.Survey
      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          e.status = survey_body.status
        }
      })
      this.setState({
        Survey: NewData,
      })
    }
    if (action === 'ADD_QUESTION') {
      NewData = this.state.Survey

      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          e.surveyJson = survey_body.surveyJson
          e.CouponCodeStatus = survey_body.CouponCodeStatus
        }
      })
      this.setState({
        Survey: NewData,
      })
    }
    if (action === 'COUPON_CODE_STATUS_UPDATE') {
      NewData = this.state.Survey
      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          e.CouponCodeStatus = survey_body.CouponCodeStatus
        }
      })

      this.setState({
        Survey: NewData,
      })
    }
  }

  Loader_visible = () => {
    this.props.dispatch(LoaderActions_loadingtrue())
  }

  Loader_hide = () => {
    setTimeout(() => {
      this.props.dispatch(LoaderActions_loadingfalse())
    }, 500)
  }

  sendingSurveyListtodb = (token, state) => {
    this.Loader_visible()
    this.setState(
      {
        PageNumber: this.props.match.params.id,
        PageSize: !token ? this.state.PageSize : token.pageSize,
      },
      () => {
        fetch(
          `${ProjectSettings.baseURL}/api/survey/admin/${this.props.state.LoginReducer11.response.user._id}/${this.props.match.params.id}?pagesize=${this.state.PageSize}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({ ResponseLength: data.Totalresponse, pages: data.TotalPage }, () => {
              this.setState({ SurveyFetch: [...data.responseData] }, () => {
                if ([...data.responseData].length === 0) {
                  this.setState({ NoSurveyFound: true }, () => {
                    this.Loader_hide()
                  })
                }
              })
            })

            this.SurveyListCreator(this.state.SurveyFetch)
          })

          .catch((e) => {
            swal({
              icon: 'error',
              title: 'failed to fetch',
            }).then((e) => {
              this.props.history.push('/login')
            })
          })
      }
    )
  }

  SurveyListCreator = (survey, updatesurveyList) => {
    var Survey1 = []
    if (updatesurveyList === true) {
      survey.forEach((e, i) => {
        Survey1.push({
          Name: e.Name,
          updatedAt: e.updatedAt,
          status: e.status,
          Responses: e.Responses,
          Codes_redeemed: e.Codes_redeemed === null ? [] : e.Codes_redeemed,
          json: e.json,
          id: e.id,
          totalCode: e.totalCode === null ? [] : e.totalCode,
          CouponCodeStatus: e.CouponCodeStatus,
          Resultjson: e.Resultjson,
          TimeStampLocal: e.TimeStampLocal,
          delete_check: false,
        })
      })
    } else {
      survey.forEach((e, i) => {
        // var ResultNumber = 0
        if (e.id !== null) {
          if (e.TimeStampLocal) {
            Survey1.push({
              Name: e.surveyName === null ? '' : e.surveyName,
              updatedAt: e.updatedAt === null ? '' : e.updatedAt,
              status: e.status === null ? '' : e.status,
              Responses: e.responseLength === null ? 0 : e.responseLength,
              Codes_redeemed: e.couponCodeUsed === null ? [] : e.couponCodeUsed,
              json: e.surveyJson === null ? {} : e.surveyJson,
              id: e.surveyId === null ? '' : e.surveyId,
              totalCode: e.couponCode === null ? [] : e.couponCode,
              CouponCodeStatus: e.CouponCodeStatus === null ? '' : e.CouponCodeStatus,
              Resultjson: e.surveyResults === null ? {} : e.surveyResults,
              TimeStampLocal: e.TimeStampLocal === null ? '' : e.TimeStampLocal,
              delete_check: false,
            })
          } else {
            Survey1.push({
              Name: e.surveyName === null ? '' : e.surveyName,
              updatedAt: e.updatedAt === null ? '' : e.updatedAt,
              status: e.status === null ? '' : e.status,
              Responses: e.responseLength === null ? 0 : e.responseLength,
              Codes_redeemed: e.couponCodeUsed === null ? [] : e.couponCodeUsed,
              json: e.surveyJson === null ? {} : e.surveyJson,
              id: e.surveyId === null ? '' : e.surveyId,
              totalCode: e.couponCode === null ? [] : e.couponCode,
              CouponCodeStatus: e.CouponCodeStatus === null ? '' : e.CouponCodeStatus,
              Resultjson: e.surveyResults === null ? {} : e.surveyResults,
              TimeStampLocal: e.TimeStampLocal === null ? '' : e.TimeStampLocal,
              delete_check: false,
            })
          }
        }
      })
    }
    this.setState({ Survey: Survey1 }, () => {
      this.Loader_hide()
    })
  }
  unblock = () => {}

  componentDidUpdate(PrevProps, PrevState) {
    if (this.props.state.LoginReducer11 === null || this.props.state.LoginReducer11.length === 0) {
      this.props.history.push('/login')
    }
  }

  componentDidMount() {
    if (this.props.state.LoginReducer11 === null) {
      this.props.history.push('/login')
    } else {
      try {
        // this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token, this.state.PageNumber)
      } catch (e) {}
    }

    // window.addEventListener("beforeunload", this.beforeUnload.bind(this));
    this.surveyCreator = new SurveyJSCreator.SurveyCreator(null)
  }
  // var Survey = useSelector((state) => state.SaveSurvey);

  //   window.onbeforeunload = function (e) {
  //     return "Are you sure";
  //   };
  // }
  // beforeUnload = (e) => {

  //   e.preventDefault();
  //   e.stopPropagation();
  //   return "skandknskdnasndklnasnd";
  // };

  Duplicateswal = async () => {
    const { value: name } = await Swal.fire({
      title: 'Duplicate survey',
      input: 'text',
      inputLabel: 'Survey name',
      inputPlaceholder: 'Enter survey name',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: (name) => {},
    })
    if (name) {
      const test = await Swal.fire(`Entered email: ${name}`)
      console.log(test)
    }
  }

  render() {
    if (this.state.NoSurveyFound === true) {
      return (
        <>
          <div
            style={{
              height: '80vh',
              width: '80vw',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <img
                src={Start_img}
                style={{
                  height: '60vh',
                  width: '60vw',
                }}
                alt={''}
              ></img>
              <div>
                <span>
                  Create simple and effective surveys to understand your customer experience. Engage your customers to make them a loyal returning
                  customers.
                </span>
              </div>

              <Button
                color="primary"
                className="btn-wide mb-2 mr-2 btn-icon"
                onClick={() => {
                  this.props.history.push('/create-survey')
                }}
                style={{
                  maxWidth: '200px',
                }}
              >
                <i className="pe-7s-plus btn-icon-wrapper"> </i>
                Create New Survey
              </Button>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div>
            {/* <button onClick={toast("wow")} /> */}
            {/* <Modal
              isOpen={this.state.addCoupon}
              toggle={() => this.setState({ addCoupon: false })}
              className={this.props.className}
            >
              <ModalHeader className="Modal_header" toggle={this.toggle}>
                Manage coupon code
              </ModalHeader>
              <ModalBody className="Modal_body">
                <Reader
                  Array_sending={(e) => {

                    if (e === null) {
                      // this.props.Error = "kindly upload a valid file";
                      this.setState({
                        AddCouponError: "kindly upload a valid file",
                      });
                    } else {
                      this.setState({ Coupon_codeNumber: e });
                    }
                  }}
                  ButtonCLickedState={this.state.AddCouponClicked}
                />
              </ModalBody>
              <ModalFooter className="Modal_footer">
                <Button
                  color="link"
                  onClick={() => this.setState({ addCoupon: false })}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={
                    this.state.Coupon_codeNumber === null ? true : false
                  }
                  onClick={() => {
                    this.setState({
                      addCoupon: false,
                      AddCouponClicked: !this.state.AddCouponClicked,
                    });
                  }}
                  style={{
                    padding: "10px 35px",
                    fontSize: "18px",
                  }}
                >
                  Done
                </Button>{" "}
              </ModalFooter>
            </Modal> */}

            <Modal isOpen={this.state.addCoupon} toggle={() => this.setState({ addCoupon: false })} className={this.props.className}>
              <ModalHeader className="Modal_header Modal_header1" toggle={this.toggle}>
                <img style={{ width: '100px', height: '70px' }} src={tag_icon} alt={''} />
                <span
                  style={{
                    fontWeight: '600',
                  }}
                >
                  Manage Coupon Codes
                </span>
              </ModalHeader>
              {/* <ModalBody className="Modal_body Modal_body1">
                <Reader
                  SURVEY={this.state.AddCouponSurveyDetails.id}
                  sent_survey={this.state.AddCouponSurveyDetails.Sent_code}
                  // Delete_check_top  = {(e)=>{
                  //   this.setState({Delete_check_top : e})
                  // }}
                  Delete_check={(e) => {
                    // let temp=this.state.AddCouponSurveyDetails;
                    // console.log("SURVEY",this.state.Survey)
                    // temp.delete_check=true
                    // this.setState({ AddCouponSurveyDetails:temp });
                    console.log("render -> properties", e);

                    console.log("render -> this.state", this.state.Survey);
                    let tempSurvey = this.state.Survey;

                    let filterstate = this.state.Survey.filter(
                      (val) => e.properties === val.id
                    );

                    let tempObj = filterstate[0];

                    tempObj.delete_check = true;
                    tempSurvey[
                      tempSurvey.findIndex((i) => filterstate[0] === i)
                    ] = tempObj;
                    console.log("render -> tempSurvey", tempSurvey);
                    this.setState({ Survey: tempSurvey }, () => {
                      console.log(
                        "render -> this.state.Survey",
                        this.state.Survey
                      );
                    });
                    // this.setState({ Delete_check: e });
                  }}
                  coupon_delete={(e) => {
                    console.log("coupon_delete render -> e");
                    this.setState({ Coupon_codeNumber: [] });
                  }}
                  coupon_delete_top={(e) => {
                    console.log("coupon_delete_top render -> e", e);
                    if (
                      e.state.Coupon_code_reducer2.PresentCouponCode.length > 0
                    ) {
                      this.setState({
                        Coupon_codeNumber:
                          e.state.Coupon_code_reducer2.PresentCouponCode,
                      });
                    } else {
                      this.setState({
                        Coupon_codeNumber: [],
                      });
                    }
                  }}
                  Delete_present_code={(e) => {
                    console.log("delete_present_code render -> e", e);

                    console.log("render -> this.state", this.state);
                  }}
                  Array_sending={(e) => {
                    console.log("render -> e array_sending", e);

                    if (e === null) {
                      // this.props.Error = "kindly upload a valid file";
                      this.setState({
                        AddCouponError: "kindly upload a valid file",
                      });
                    } else {
                      this.setState({ Coupon_codeNumber: e });
                    }
                  }}
                  CouponCode_available={
                    this.state.AddCouponSurveyDetails.CouponCode
                  }
                  ButtonCLickedState={this.state.AddCouponClicked}
                />
              </ModalBody> */}

              <ModalBody className="Modal_body Modal_body1">
                <Reader
                  SURVEY={this.state.AddCouponSurveyDetails.id}
                  sent_survey={this.state.AddCouponSurveyDetails.Sent_code}
                  // Delete_check_top  = {(e)=>{
                  //   this.setState({Delete_check_top : e})
                  // }}
                  Delete_check={(e) => {
                    let tempSurvey = this.state.Survey

                    let filterstate = this.state.Survey.filter((val) => e.properties === val.id)

                    let tempObj = filterstate[0]

                    tempObj.delete_check = true
                    tempSurvey[tempSurvey.findIndex((i) => filterstate[0] === i)] = tempObj
                    this.setState({ Survey: tempSurvey }, () => {})
                  }}
                  coupon_delete={(e) => {
                    this.setState({ Coupon_codeNumber: [] }, () => {})
                  }}
                  coupon_delete_top={(e) => {
                    this.setState({
                      Coupon_codeNumber: [],
                    })
                  }}
                  Delete_present_code={(e) => {}}
                  Array_sending={(e) => {
                    if (e === null) {
                      // this.props.Error = "kindly upload a valid file";
                      this.setState({
                        AddCouponError: 'kindly upload a valid file',
                      })
                    } else {
                      this.setState({ Coupon_codeNumber: e })
                    }
                  }}
                  CouponCode_available={this.state.AddCouponSurveyDetails.CouponCode}
                  ButtonCLickedState={this.state.AddCouponClicked}
                />
              </ModalBody>
              <ModalFooter className="Modal_footer Modal_footer1">
                <Button
                  color="link"
                  className="btn-cancel-modal btn"
                  onClick={() =>
                    this.setState({ addCoupon: false, Coupon_codeNumber: [] }, () => {
                      this.props.dispatch(CouponCodeaction_remove_codes())
                    })
                  }
                >
                  Cancel
                </Button>
                <LaddaButton
                  color="primary"
                  data-style={ZOOM_IN}
                  className="btn btn-primary btn-accept-modal"
                  loading={this.state.ButtonLoading}
                  style={{
                    fontSize: '13px',
                    width: '65px',
                  }}
                  onClick={() => {
                    this.setState({
                      ButtonLoading: true,
                    })
                    let CouponCodeStatus = ''
                    // let surveyStatus
                    this.state.Survey.forEach((e) => {
                      if (e.id === this.state.AddCouponSurveyDetails.id) {
                        CouponCodeStatus = e.CouponCodeStatus
                        // surveyStatus = e.status
                      }
                    })

                    let coupon_code = []

                    let deleteCheckStatus = false
                    for (let e of this.state.Survey) {
                      // this.state.Survey.forEach((e) => {

                      // console.log("🚀 ~ file: index.js ~ line 2227 ~ Options ~ //this.state.Survey.forEach ~ this.state.AddCouponSurveyDetails.id", this.state.AddCouponSurveyDetails.id)
                      if (e.id === this.state.AddCouponSurveyDetails.id) {
                        if (e.delete_check === true) {
                          deleteCheckStatus = true
                          if (this.state.Coupon_codeNumber.length === 0) {
                            coupon_code = []
                            CouponCodeStatus = 'Inactive'
                          } else if (this.state.Coupon_codeNumber.length !== 0) {
                            coupon_code = this.state.Coupon_codeNumber
                          }
                          // break;
                        } else {
                          if (this.state.Coupon_codeNumber.length !== 0) {
                            coupon_code = this.state.Coupon_codeNumber
                          }
                          if (this.state.Coupon_codeNumber.length === 0) {
                            coupon_code = this.props.state.Coupon_code_reducer2.PresentCouponCode
                          }
                        }
                      }
                    }
                    let date = new Date()
                    let finalDate = date.toISOString()
                    let body = {
                      surveyId: this.state.AddCouponSurveyDetails.id,
                      surveyName: this.state.AddCouponSurveyDetails.name,
                      couponCode: coupon_code,
                      TimeStampLocal: finalDate,
                      surveyJson: this.state.AddCouponSurveyDetails.json,
                      CouponCodeStatus: CouponCodeStatus,
                    }
                    if (deleteCheckStatus === true) {
                      body.couponCodeUsed = []
                    }
                    let API_END_POINT = ProjectSettings.baseURL + '/api/survey'

                    fetch(API_END_POINT, {
                      method: 'PUT',
                      headers: {
                        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                        'Content-type': 'application/json',
                      },
                      body: JSON.stringify(body),
                    })
                      .then((response) => {
                        return response.json()
                      })
                      .then((e) => {
                        if (e.success) {
                          this.UpdatingSurvey('COUPON_CODE_UPDATE_ADMIN', body)

                          this.setState(
                            {
                              addCoupon: false,
                              AddCouponClicked: !this.state.AddCouponClicked,
                              ButtonLoading: false,
                              Coupon_codeNumber: [],
                            },
                            () => {
                              this.props.dispatch(CouponCodeaction_remove_codes())
                            }
                          )
                        } else {
                          this.setState({
                            ButtonLoading: false,
                          })
                          swal({
                            icon: 'error',
                            title: 'File size exceeded',

                            buttons: {
                              cancel: 'Cancel',
                            },
                          })
                        }
                      })
                      .catch((e) => {})
                  }}
                >
                  OK
                </LaddaButton>
              </ModalFooter>
            </Modal>

            <SweetAlert
              title="Something went wrong"
              confirmButtonColor=""
              show={this.state.EdgeCaseErrorState}
              showCancelButton={true}
              confirmButtonText={'Edit'}
              text={this.state.EdgeCaseError}
              type="error"
              onCancel={() => {
                this.setState({ EdgeCaseErrorState: false, Checked: false })
              }}
              onConfirm={() => {
                this.setState({ EdgeCaseErrorState: false, Checked: false })
                this.props.history.push(`/edit-survey/${this.state.UpdateUserDetails.body1.surveyId}`)
              }}
            />
            <SweetAlert
              title="Are you sure"
              confirmButtonColor=""
              show={this.state.UpdateNotification}
              onOutsideClick={() => {
                this.setState({ UpdateNotification: false })
              }}
              onConfirm={() => {
                fetch(ProjectSettings.baseURL + '/api/survey', {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                  },

                  body: JSON.stringify(this.state.UpdateUserDetails.body1),
                })
                  .then((r) => {
                    return r.json()
                  })
                  .then((e) => {
                    this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token)
                    this.setState({ UpdateNotification: false })
                  })
                  .catch((e) => {})
              }}
            />
            <ToastContainer
              position="bottom-right"
              color="success"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          <Modal
            style={{
              display: 'flex',
            }}
            isOpen={this.state.showState}
            centered={true}
            toggle={() => this.setState({ showState: false })}
            modalClassName={'Share_modal'}
          >
            <ModalHeader toggle={this.toggle}>Share Survey</ModalHeader>
            <ModalBody>
              <div>Link</div>

              <div>{window.location.href.replace(`dashboard/${this.props.match.params.id}`, `survey/${this.state.ShareSurvey.id}`)}</div>
              {this.state.ShareSurvey.surveyState === 'Draft' ? <div>Kindly change the survey status to Open</div> : <div></div>}
            </ModalBody>
            <ModalFooter>
              <Button className="btn-cancel-modal" color="link" onClick={() => this.setState({ showState: false })}>
                Cancel
              </Button>
              <CopyToClipboard
                text={window.location.href.replace(`dashboard/${this.props.match.params.id}`, `survey/${this.state.ShareSurvey.id}`)}
                onCopy={() => {
                  toast('Copied to Clipboard')

                  this.setState({ copied: true })
                }}
              >
                <Button color="primary" className="btn-accept-modal" onClick={() => this.setState({ showState: false })}>
                  Copy
                </Button>
              </CopyToClipboard>
            </ModalFooter>
          </Modal>
          <Loader11 />
          <SweetAlert
            title="The survey and all associated responses and reports will be deleted."
            confirmButtonColor=""
            show={this.state.DeleteNotification}
            showCancelButton={true}
            cancelButtonText={'Cancel'}
            confirmButtonText={'Confirm'}
            onCancel={() => {
              this.setState({ DeleteNotification: false })
            }}
            onOutsideClick={() => {
              this.setState({ DeleteNotification: false })
            }}
            // onClick={(e) => {
            //   this.setState({ DeleteNotification: false });
            // }}
            onConfirm={(e) => {
              const body11 = {
                surveyId: this.state.DeleteSuveyDetails.id,
              }
              fetch(ProjectSettings.baseURL + '/api/survey/delete', {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                  'Content-type': 'application/json',
                },
                body: JSON.stringify(body11),
              })
                .then((response) => {
                  response.json()
                })
                .then((json) => {})
                .then(() => {
                  this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token)
                  this.setState({ DeleteNotification: false })
                })
                .catch((e) => {})

              // this.props.dispatch(
              //   DeleteSurvey(
              //     this.state.DeleteSuveyDetails.id,
              //     this.state.DeleteSuveyDetails.json,
              //     this.state.DeleteSuveyDetails.status,
              //     this.state.DeleteSuveyDetails.Name
              //   )
              // );
              // this.sendingSurveyListtodb();
              // this.updateTableondelete();
              // this.setState({ DeleteNotification: false });
            }}
          />
          <div className="option-header">
            <Header />
            <div className="sticky-table">
              <Col md="12">
                <Card className="main-card mb-3">
                  {/* {JSON.stringify(this.state.Survey)} */}

                  <CardBody>
                    <ReactTable
                      // PaginationComponent={Pagination}
                      data={this.state.Survey}
                      columns={this.state.columnss}
                      defaultPageSize={10}
                      pageSizeOptions={[10, 15]}
                      resizable={false}
                      pages={this.state.pages}
                      manual
                      page={parseInt(this.props.match.params.id) - 1}
                      onFetchData={this.sendingSurveyListtodb}
                      // onFetchData={() => {
                      // if (this.props.state.LoginReducer11 === null) {
                      //   this.props.history.push('/login')
                      // } else {
                      //   try {
                      //     this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token, this.state.PageNumber)
                      //   } catch (e) {}
                      // }fi
                      // }}
                      onPageChange={(pageInedx) => {
                        console.log('pageInedx', pageInedx)
                        this.props.history.push(`/dashboard/${pageInedx + 1}`)
                        this.setState(
                          {
                            PageNumber: pageInedx + 1,
                          },
                          () => {
                            this.sendingSurveyListtodb()
                          }
                        )
                      }}
                      // onFetchData={this.Fetchdata}
                      className="-striped -highlight"
                    />
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </>
      )
    }
  }
}
const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Options))
