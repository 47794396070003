import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router'
import './header.css'
import { connect } from 'react-redux'
// import { Create_surveyaction } from "../../../redux/actions/Create_survey";
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SurveyButtonClick: false,
    }
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { Survey_creatorreducer } = this.props.state
    console.log('Header -> componentDidUpdate -> Survey_creatorreducer', Survey_creatorreducer)
    if (prevState.SurveyButtonClick !== this.state.SurveyButtonClick) {
      this.props.history.push(`/create-survey`)
    }
  }

  render() {
    return (
      <div className="header-outer">
        <div className="header-inner">
          <div className="header-topic">Users</div>
          <div className="header-right-side">
            {/* <Button
              className="mb-2 mr-2 btn-icon btn-shadow btn-outline-2x"
              outline
              color="light"
            >
              <i className="pe-7s-edit btn-icon-wrapper"> </i>
              Filters
            </Button> */}

            <Button
              className="btn-wide mb-2 mr-2 btn-icon"
              color="primary"
              onClick={(e) => {
                this.setState({
                  SurveyButtonClick: !this.state.SurveyButtonClick,
                })
                this.props.history.push(`/User-Create`)
                // this.props.dispatch(Create_surveyaction(e));
              }}
            >
              <i className="pe-7s-plus btn-icon-wrapper"> </i>
              Create New User
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))

// export default Header;
