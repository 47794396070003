import React from "react";
import Loader from "react-loaders";
import { connect } from "react-redux";
import "./index.css";
class Loader11 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: null,
    };
  }
  componentDidMount() {
    this.setState({ Loader: this.props.state.Loader_reducer });
  }

  componentDidUpdate(PrevState, PrevProps) {
    if (this.props.state.Loader_reducer !== PrevState.state.Loader_reducer) {
      this.setState({ Loader: this.props.state.Loader_reducer });
    }
  }

  render() {
    if (this.state.Loader === true) {
      return (
        <div className="font-icon-wrapper float-left  loader_div loader_visible">
          <div className="loader-wrapper d-flex justify-content-center align-items-center loader_wrapper_div">
            <Loader type="ball-clip-rotate" />
            <p>Loading</p>
          </div>
        </div>
      );
    }

    if (this.state.Loader === null) {
      return (
        <div className="font-icon-wrapper float-left  loader_div loader_visible">
          <div className="loader-wrapper d-flex justify-content-center align-items-center loader_wrapper_div">
            <Loader type="ball-clip-rotate" />
            <p>Loading</p>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="font-icon-wrapper float-left  loader_div loader_visible fade-out"
          style={{ display: "none" }}
        >
          <div className="loader-wrapper d-flex justify-content-center align-items-center loader_wrapper_div">
            <Loader type="ball-clip-rotate" />
            <p>Loading</p>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return { state };
};
const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader11);
