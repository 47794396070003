import React from 'react'
import * as Survey from 'survey-react'
import * as widgets from 'surveyjs-widgets'
import 'survey-react/survey.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import 'jquery-ui/themes/base/all.css'
import 'nouislider/distribute/nouislider.css'
import 'select2/dist/css/select2.css'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'
import { Button } from 'reactstrap'
import 'jquery-bar-rating/dist/themes/css-stars.css'
import * as SurveyJSCreator from 'survey-creator'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker.js'
import 'select2/dist/js/select2.js'
import 'jquery-bar-rating'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import 'pretty-checkbox/dist/pretty-checkbox.css'
// import { json } from "./survey_json.js";
import './index.css'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import CheckIcon from '@material-ui/icons/Check'
import gsap from 'gsap'
import './singlepage.css'
// import { CSSTransition } from 'react-transition-group'
// import velocity from 'velocity-react'
// import { dom } from '@fortawesome/fontawesome-svg-core'
// import variable from '../../global/font'
import { carwash_logo } from '../../assets/LOGO_TRANSPARENCY.png'
import ProjectSettings from '../../global/Global'
import LaddaButton, { ZOOM_IN } from 'react-ladda'
//import "icheck/skins/square/blue.css";
window['$'] = window['jQuery'] = $
//require("icheck");

// export { MyQuestion } from "./MyQuestion";

Survey.StylesManager.applyTheme('modern')

//widgets.icheck(Survey, $);
widgets.prettycheckbox(Survey)
widgets.select2(Survey, $)
widgets.inputmask(Survey)
widgets.jquerybarrating(Survey, $)
widgets.jqueryuidatepicker(Survey, $)
widgets.nouislider(Survey)
widgets.select2tagbox(Survey, $)
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey)
widgets.ckeditor(Survey)
widgets.autocomplete(Survey, $)
widgets.bootstrapslider(Survey)

// function onValueChanged(result) {
//   this.DOMcheckerfunction();
// }

class SurveyPublic extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: null,
      Survey: {
        // couponCode : null,
        // couponCodeUsed : null,
        // surveyJson : null,
        // SurveyResult : null,
        // createdBy : null,
      },
      SurveyStatus: null,
      CouponCodeStatus: null,
      error: null,
      Email: null,
      ClickEvent: false,
      inProp1: false,
      window_width: 0,
      window_height: 0,
      Submit_mobile: false,
      Start_click: false,
      FirstPageStarter: false,
      survey_submit: false,
      survey_mail_sent: false,
      counter: false,
      runtime_checker: 0,
      survey_logo: '',
      ButtonLoading: false,
      buttondisable: false,
      ClickEventCount: 0,
    }
  }

  // Mailer = (e) => {
  //   if (this.state.CouponCodeStatus === "Active") {
  //     this.state.model.getAllQuestions().forEach((e) => {
  //       if (e.getType() === "text") {
  //         if (e.title.toLowerCase().includes("email")) {
  //           let body = {
  //             surveyId: this.props.match.params.code,
  //             email: e.propertyHash.value,
  //           };

  //           fetch("https://api.carwashsurveys.com/api/survey/complete", {
  //             method: "POST",
  //             headers: {
  //               "Content-type": "application/json",
  //             },
  //             body: JSON.stringify({
  //               surveyId: this.props.match.params.code,
  //               email: e.propertyHash.value,
  //             }),
  //           })
  //             .then((e) => {
  //               return e.json();
  //             })
  //             .then((e) => {})
  //             .catch((e) => {
  //               // // console.log("SurveyPublic -> Mailer -> e", e);
  //             });
  //         }
  //       }
  //     });
  //   }
  // };

  title_removing_pagechange = () => {
    $(document).ready(() => {
      if (document.querySelector('.sv-page__title')) {
        if (document.querySelector('.sv-footer__next-btn') || document.querySelector('.sv-footer__complete-btn')) {
          document.querySelector('.sv-page__title').style.display = 'none'
        }
      }
    })
  }

  onComplete = (result) => {
    $(document).ready(() => {
      // // console.log("SurveyPublic -> onComplete -> document");
      if (document.querySelector('.special_bottom_mobile')) {
        try {
          document.querySelector('.special_bottom_mobile').style.display = 'none'
          document.querySelector('.special_completeButton').style.display = 'block'
          document.querySelector('.special_completeButton').style.backgroundColor = '#55bcbc'
        } catch (e) {}
      }
    })
    // this.Mailer();
    let Name = []
    let Title = []

    let type = []
    // let Result = []

    let ResultObj = []

    this.state.model.getAllQuestions().forEach((e) => {
      Name.push(e.name)
      Title.push(e.title)
      type.push(e.getType())
    })

    // let data11
    let data111 = []
    let data = []
    // console.log('SurveyPublic -> onComplete -> Name', Name)
    // console.log('SurveyPublic -> onComplete -> Title', Title)
    // console.log('SurveyPublic -> onComplete -> result', result.data)

    for (const [key, value] of Object.entries(result.data)) {
      console.log(value)
      let key_lowercase = key.toLowerCase()
      if (key_lowercase.includes('comment')) {
        let question_details = key.split('-')
        result.data[question_details[0]].push(result.data[key])

        // let answer_detail = result.data[question_details[0]]
        // let comment = result.data[key]
        result.data[key] = undefined
      }
    }

    result.data = JSON.parse(JSON.stringify(result.data))
    console.log('SurveyPublic -> onComplete -> reuslt', result.data)

    for (let i = 0; i < Name.length; i++) {
      if (result.data[Name[i]]) {
        let key = Name[i]
        // // console.log("SurveyPublic -> onComplete -> key", key);
        data.push({ key: result.data[key] })
      } else {
        data.push({ key: null })
      }

      // // console.log("SurveyPublic -> onComplete -> data", data);
    }

    // // console.log("data", data);
    // // console.log("data", data.length);

    for (let i = 0; i < data.length; i++) {
      let Value = data[i].key
      // let key = Name[i]
      data111.push({
        result: Value,
        Name: Name[i],
        type: type[i],
        Title: Title[i],
      })
    }

    // console.log('data11', data111)

    this.state.model.getAllQuestions().forEach((e, i) => {
      ResultObj.push({
        Name: Name[i],
        type: type[i],
        Title: Title[i],
        result: data111,
      })
    })
    // // console.log("SurveyPublic -> onComplete -> ResultObj", ResultObj);
    if (this.state.survey_submit === false) {
    }
    this.setState({ Submit_mobile: true, survey_submit: true }, () => {
      fetch(ProjectSettings.baseURL + '/api/survey/saveSurveyResult', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          surveyId: this.props.match.params.code,
          resultJson: data111,
        }),
      })
        .then((e) => {
          return e.json()
        })
        .then((e) => {})
        .catch((e) => {})
    })
  }
  onValueChanged = (result) => {
    // console.log("SurveyPublic -> onValueChanged -> result", result);
    this.DOMcheckerfunction()
    if (window.innerWidth < 500) {
      if (this.state.Survey.surveyJson.questionsOnPageMode === 'questionPerPage') {
        this.SingleQuestionDOMChecker()
      } else {
        this.setState({ ClickEvent: !this.state.ClickEvent })
      }
    }
  }

  DOMcheckerfunction = () => {
    try {
      $(document).ready(() => {
        // console.log(
        //   "SurveyPublic -> DOMcheckerfunction -> document.querySelector('.sv-footer__start-btn')",
        //   document.querySelector('.sv-footer__start-btn')
        // )
        if (this.state.model) {
          if (this.state.model.questionsOnPageMode === 'questionPerPage') {
            if (document.querySelector('.sv-footer__start-btn')) {
              // console.log(
              //   "SurveyPublic -> DOMcheckerfunction -> document.querySelector('.sv-footer__start-btn')",
              //   document.querySelector('.sv-footer__start-btn')
              // )

              document.querySelector('.sv-footer__start-btn').parentNode.appendChild(document.querySelector('.start_button'))
              document.querySelector('.start_button').style.display = 'block'
              // console.log(
              //   "SurveyPublic -> DOMcheckerfunction -> document.querySelector('.sv-footer__start-btn')",
              //   document.querySelector('.start_button')
              // )
            } else {
              try {
                document.querySelector('.start_button').style.display = 'none'
              } catch (e) {}
            }
          }
        }
        if (document.querySelector('.sv-question__num')) {
          // document.querySelector(".sv-question__num").style.display = "none";
        }
        document.querySelectorAll('.sv-page__title').forEach((e) => {
          e.classList.add('Title_css')
        })
        document.querySelectorAll('.sv-container-modern__title').forEach((e) => {
          e.classList.add('Title_css', 'Main_header')
        })

        if (document.querySelector('.sv-question__title')) {
          // console.log(
          //   "SurveyPublic -> DOMcheckerfunction -> document.querySelector('.sv-question__title').classList",
          //   document.querySelector('.sv-question__title').classList.contains('Title_css')
          // )
          if (document.querySelector('.sv-question__title').classList.contains('Title_css')) {
            document.querySelector('.sv-question__header').classList.remove('Title_css')
          }
        }
        document.querySelectorAll('.sv-description').forEach((e) => {
          e.classList.add('description_css')
        })
        document.querySelectorAll('.sv-item').forEach((e) => {
          e.classList.add('item_css')
        })
        document.querySelectorAll('.sv-selectbase__label').forEach((e) => {
          e.classList.add('selectbase__label_css')
        })
        document.querySelectorAll('.sv-boolean').forEach((e) => {
          e.classList.add('boolean_css')
        })
        document.querySelectorAll('.sv-imagepicker').forEach((e) => {
          e.classList.add('imagepicker_css')
        })
        document.querySelectorAll('.sv-rating').forEach((e) => {
          e.classList.add('rating_css')
        })
        document.querySelectorAll('.sv-multipletext').forEach((e) => {
          e.classList.add('multipletext_css')
        })
        document.querySelectorAll('.sv-item__svg').forEach((e) => {
          e.classList.add('item__svg_css')
        })
        document.querySelectorAll('.sv-body__page').forEach((e) => {
          e.classList.add('Body_page')
        })
        document.querySelectorAll('.sv-radio__decorator').forEach((e) => {
          e.classList.add('radio__decorator_css')
        })

        document.querySelectorAll('sv-imagepicker__item--inline').forEach((e) => {
          e.classList.add('imagepicker_inner')
        })
        document.querySelectorAll('.sv-selectbase__label').forEach((e) => {
          e.classList.add('selectbase__label_css')
        })
        document.querySelectorAll('.check').forEach((e) => {
          e.style.display = 'none'
        })
        document.querySelectorAll('.sv-footer__start-btn').forEach((e) => {
          e.parentNode.style.display = 'flex'
          e.parentNode.style.justifyContent = 'center'
        })
        // if (document.querySelector(".sv-root-modern")) {
        //   if (
        //     document.querySelector(".sv-root-modern").getBoundingClientRect()
        //       .height > window.innerHeight
        //   ) {
        //   } else {
        //     if (document.querySelector(".container11_outer_not_single")) {
        //       document.querySelector(
        //         ".container11_outer_not_single"
        //       ).style.display = "flex";
        //     }
        //   }
        // }
        if (document.querySelector('.Main_header')) {
          if (document.querySelector('.sv-footer__next-btn')) {
            document.querySelector('.Main_header').style.display = 'none'
          }
          if (document.querySelector('.sv-footer__prev-btn')) {
            document.querySelector('.Main_header').style.display = 'none'
          }
          if (document.querySelector('.sv-footer__complete-btn')) {
            document.querySelector('.Main_header').style.display = 'none'
          }
          if (document.querySelector('.sv-completedpage')) {
            document.querySelector('.Main_header').style.display = 'none'
          }
        }

        if (document.querySelector('#root').getBoundingClientRect().height < window.innerHeight) {
          // document.querySelector("#root").style.height = "100vh";
        }
      })
    } catch (e) {}
  }
  toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
      var reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  async componentDidMount() {
    const API_ENDPOINT = `${ProjectSettings.baseURL}/api/survey/details/${this.props.match.params.code}`
    const API_ENDPOINT_1 = `${ProjectSettings.baseURL}/api/survey/GetLogoimg/${this.props.match.params.code}`
    const response = await fetch(API_ENDPOINT)
    const LogoResponse = await fetch(API_ENDPOINT_1)
    const LogoResponse_1 = await LogoResponse.json()
    console.log('SurveyPublic -> componentDidMount -> LogoResponse_1', LogoResponse_1)

    console.log('SurveyPublic -> componentDidMount -> LogoResponse', LogoResponse)
    console.log('SurveyPublic -> componentDidMount -> response', response)
    const response1 = await response.json()

    console.log('SurveyPublic -> componentDidMount -> response1', response1)

    if (response1.success) {
      let SurveyJSON
      SurveyJSON = response1.responseData

      if (LogoResponse_1.success) {
        let ImgUrl = LogoResponse_1.message.logo.base64
        let FinalUrl = ImgUrl.replace('public', 'static')
        console.log('SurveyPublic -> componentDidMount -> ${ProjectSettings.baseURL}${FinalUrl}', `${ProjectSettings.baseURL}${FinalUrl}`)

        let promise = new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest()
          xhr.onload = function (status) {
            if (status.currentTarget.status !== 200) {
              console.log('SurveyPublic -> xhr.onload -> no_logo_found')
            }
            var reader = new FileReader()
            reader.onloadend = function () {
              resolve(reader.result)
            }
            reader.readAsDataURL(xhr.response)
          }
          xhr.open('GET', `${ProjectSettings.baseURL}${FinalUrl}`)
          xhr.responseType = 'blob'
          xhr.send()
        })
        promise.then((result) => {
          SurveyJSON.surveyJson = {
            ...SurveyJSON.surveyJson,
            logo: result,
          }

          this.setState(
            {
              // Survey: response1.responseData,
              Survey: SurveyJSON,

              SurveyStatus: response1.responseData.status,
              CouponCodeStatus: response1.responseData.CouponCodeStatus,
              Mycss: {
                headerText: 'sv-header__text Header_text_css',

                navigation: {
                  // start:
                  //   "start_button btn-wide btn-special-primary-user-hover special_start_button_questiontype",
                  // start: "start_button btn-wide btn-special-primary-user-hover",
                },
              },
              MyCss1: {
                footer: 'body_footer',
                row: 'row_single',
                container: 'sv-container-modern body_single',
                navigation: {
                  start: 'start_button1 btn-wide btn-special-primary-user-hover',
                },
              },
            },
            async () => {
              var Model11 = new SurveyJSCreator.SurveyCreator(null)

              if (this.state.Survey.createdBy.status === 'Inactive' || this.state.SurveyStatus !== 'Active') {
                this.setState({ error: true })
              } else {
                Model11.JSON = this.state.Survey.surveyJson
                this.setState({
                  survey_logo: Model11.survey.logo,
                })
                // console.log('SurveyPublic -> componentDidMount -> Model11.currentPage', Model11.survey.currentPage)
                // Model11.currentPage().add((sender, option) => {
                // // //   console.log("SurveyPublic -> componentDidMount -> e", sender);
                // });
                // // console.log("SurveyPublic -> componentDidMount -> Model11", Model11);
                let LOGO = Model11.survey.logo

                console.log('SurveyPublic -> componentDidMount -> this.state', this.state)
                if (this.state.Survey.surveyId === '144gbl5l5fki64q0jl') {
                  console.log('SurveyPublic -> componentDidMount -> this.state', this.state)
                  if (this.state.CouponCodeStatus === 'Active') {
                    if (LOGO !== '') {
                      // // console.log("SurveyPublic -> componentDidMount -> LOGO", LOGO);
                      Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                    } else {
                      Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                    }
                  }
                } else {
                  if (this.state.CouponCodeStatus === 'Active') {
                    console.log('SurveyPublic -> componentDidMount -> this.state', this.state)
                    if (LOGO !== '') {
                      // // console.log("SurveyPublic -> componentDidMount -> LOGO", LOGO);
                      Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><br>Please visit our website and stay in touch.</div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                    } else {
                      Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><br>Please visit our website and stay in touch.</div></h5></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover" onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                    }
                  } else {
                    if (LOGO !== '') {
                      // // console.log("SurveyPublic -> componentDidMount -> LOGO", LOGO);
                      Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                    } else {
                      Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                    }
                  }
                }

                var Model = new Survey.Model(Model11.JSON)
                // var myCss = {}

                // // // console.log("SurveyPublic -> componentDidMount -> Model", Model);
                // Model.FirstPageStarter = true;
                // // // console.log("SurveyPublic -> componentDidMount -> Model", Model);

                Model.onUpdateQuestionCssClasses.add((survey, option) => {
                  this.title_removing_pagechange()
                  if (option.question.getType() === 'text') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_text`
                    classes.description = `${classes.description}  description_css description_css_text`
                    classes.label = `${classes.label} selectbase__label_css selectbase__label_css_text`
                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_text`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_text`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_text`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_text`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_text`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_text`
                  }
                  if (option.question.getType() === 'radiogroup') {
                    let classes = option.cssClasses

                    classes.title = `${classes.title} Title_css Title_css_radiogroup`
                    classes.description = `${classes.description} description_css description_css_radiogroup`
                    classes.root = `${classes.root} sv-selectbase_css_radiogroup`
                    classes.label = `${classes.label} selectbase__label_css selectbase__label_css_radiogroup`
                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_radiogroup`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_radiogroup`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_radiogroup`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_radiogroup`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_radiogroup`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_radiogroup`
                    classes.other = `${classes.other} sv-question__other_css question__other_css_radiogroup`
                    classes.clearButton = `${classes.clearButton} Clear_button_css Clear_button_css_radiogroup`
                    classes.item = `${classes.item} Item_css Item_css_radiogroup`
                    classes.itemHover = `${classes.itemHover} Hover_radipgroup_css`
                    classes.materialDecorator = `${classes.materialDecorator} svg_hidden_css`
                    classes.itemChecked = `${classes.itemChecked} CHecked_item_radiogroup`

                    classes.controlLabel = `${classes.controlLabel} controlLabelradiogroup_css`
                  }
                  if (option.question.getType() === 'checkbox') {
                    // // console.log("SurveyPublic -> componentDidMount -> option", option);

                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_checkbox`
                    classes.description = `${classes.description} description_css description_css_checkbox`

                    classes.root = `${classes.root} sv-selectbase_checkbox`
                    classes.label = `${classes.label} selectbase__label_css selectbase__label_css_checkbox`
                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_checkbox`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_checkbox`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_checkbox`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_checkbox`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_checkbox`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_checkbox`
                    classes.controlLabel = `${classes.controlLabel} control_label_css`
                    classes.other = `${classes.other} sv-question__other_css question__other_css+checkbox`
                    classes.clearButton = `${classes.clearButton} Clear_button_css Clear_button_css_checkbox`
                    classes.item = `${classes.item} Item_css Item_css_checkbox`
                  }
                  if (option.question.getType() === 'comment') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_comment`
                    classes.description = `${classes.description} description_css description_css_comment`
                    classes.root = `${classes.root} Text_area_comment`
                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_comment`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_comment`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_comment`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_comment`
                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_comment`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_comment`
                  }
                  if (option.question.getType() === 'rating') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_rating`
                    classes.description = `${classes.description} description_css description_css_rating`
                    classes.root = `${classes.root} sv-rating rating_special_root`
                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_rating`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_rating`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_rating`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_rating`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_rating`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_rating`

                    classes.selected = `${classes.selected} selected_css selected_css_rating`
                    classes.itemText = `${classes.itemText} itemText_css itemText_css_rating`
                    classes.item = `${classes.item} Item_css Item_css_rating`

                    classes.maxText = `${classes.maxText} maxText_css maxText_css_rating`
                    classes.minText = `${classes.minText} minText_css minText_css_rating`
                    if (option.question.name === 'general_question') {
                      classes.itemText = `${classes.itemText} itemText_css itemText_css_rating itemText_css_rating_special`
                    }
                    if (option.question.name === 'customer_satisfaction') {
                      classes.itemText = `${classes.itemText} itemText_css itemText_css_rating itemText_css_rating_special`
                    }
                  }

                  if (option.question.getType() === 'image') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_image`
                    classes.description = `${classes.description} description_css description_css_image`

                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_image`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_image`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_image`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_image`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_image`
                    classes.content = `${classes.content} Content_css Content_css_image`
                  }
                  if (option.question.getType() === 'boolean') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_boolean`
                    classes.description = `${classes.description} description_css description_css_boolean`

                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_boolean`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_boolean`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_boolean`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_boolean`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_boolean`
                  }
                  if (option.question.getType() === 'imagepicker') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_imagepicker`
                    classes.root = `${classes.root} sv-imagepicker`
                    classes.description = `${classes.description} description_css description_css_imagepicker`
                    classes.label = `${classes.label} selectbase__label_css selectbase__label_css_imagepicker`
                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_imagepicker`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_imagepicker`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_imagepicker`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_imagepicker`
                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_imagepicker`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_imagepicker`
                    classes.item = `${classes.item} Item_css Item_css_imagepicker`

                    classes.itemChecked = `${classes.itemChecked} Checked_css Checked_css_imagepicker`
                    classes.itemControl = `${classes.itemControl} Control_css Control_css_imagepicker`
                    classes.itemHover = `${classes.itemHover} Hover_Css Hover_Css_imagepicker`
                    classes.itemText = `${classes.itemText} Text_css Text_css_imagepicker`
                  }
                  if (option.question.getType() === 'matrix') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_matrix`
                    classes.description = `${classes.description} description_css description_css_matrix`
                    classes.root = `${classes.root} table`
                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_matrix`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_matrix`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_matrix`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_matrix`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_matrix`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_matrix`
                  }
                  if (option.question.getType() === 'file') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_file`
                    classes.description = `${classes.description} description_css description_css_file`
                    classes.wrapper = `${classes.wrapper} wrapper_css_file`
                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_file`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_file`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_file`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_file`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_file`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_file`

                    classes.fileDecorator = `${classes.fileDecorator} fileDecorator_css_file`
                    classes.chooseFile = `${classes.chooseFile} ChooseFile_css_file`
                    classes.preview = `${classes.preview} preview_css_file`
                  }
                  if (option.question.getType() === 'dropdown') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_dropdown`
                    classes.description = `${classes.description} description_css description_css_dropdown`

                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_dropdown`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_dropdown`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_dropdown`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_dropdown`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_dropdown`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_dropdown`
                  }
                  if (option.question.getType() === 'multipletext') {
                    let classes = option.cssClasses
                    classes.title = `${classes.title} Title_css Title_css_multipletext`
                    classes.description = `${classes.description} description_css description_css_multipletext`
                    classes.root = `${classes.root} Multipletext_css`

                    classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_multipletext`
                    classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_multipletext`
                    classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_multipletext`
                    classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_multipletext`

                    classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_multipletext`
                    classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_multipletext`
                  }
                })
                $(document).ready(() => {
                  this.setState({ ClickEvent: !this.state.ClickEvent })
                })
                const animate = (animitionType, duration) => {
                  // // console.log("SurveyPublic -> animate -> animitionType", animitionType);
                  if (!duration) duration = 1000

                  // gsap.to();

                  var element = document.querySelector('.container11_not_single_question')
                  if (animitionType === 'slideDown') {
                    gsap.fromTo(
                      element,
                      {
                        position: 'relative',
                        left: '100vw',
                      },
                      {
                        left: '0vw',
                        position: 'relative',

                        duration: 0.5,
                      }
                    )
                  }

                  if (animitionType === 'slideUp') {
                    gsap.fromTo(
                      element,
                      {},

                      {
                        position: 'relative',
                        left: '-100vw',

                        duration: 0.5,
                      }
                    )
                  }
                  if (animitionType === 'slideDown1') {
                    gsap.fromTo(
                      element,
                      {
                        position: 'relative',
                        left: '0vw',
                      },

                      {
                        position: 'relative',
                        left: '100vw',

                        duration: 0.5,
                      }
                    )

                    // gsap.to(element, 0.5, {
                    //   position: "relative",
                    //   left: "100vw",
                    //   overflow: "hidden",
                    // });
                  }
                  if (animitionType === 'slideUp1') {
                    gsap.fromTo(
                      element,
                      {
                        position: 'relative',

                        left: '-100vw',
                      },
                      {
                        position: 'relative',
                        left: '0vw',
                        duration: 0.5,
                      }
                    )
                  }
                }

                var doAnimantion = true

                Model.onCurrentPageChanging.add((sender, options) => {
                  // // console.log("SurveyPublic -> componentDidMount -> options", options);
                  // // console.log("SurveyPublic -> componentDidMount -> sender", sender);

                  window.scrollTo(0, 0)
                  if (!doAnimantion) return
                  else {
                    options.allowChanging = false
                    setTimeout(function () {
                      doAnimantion = false
                      sender.currentPage = options.newCurrentPage
                      doAnimantion = true
                    }, 500)
                    // // console.log("SurveyPublic -> componentDidMount -> options", options);
                    // // console.log("SurveyPublic -> componentDidMount -> sender", sender);
                  }
                  if (options.isNextPage) {
                    // // console.log("SurveyPublic -> componentDidMount -> nextpage");

                    animate('slideUp')
                  }
                  if (options.isPrevPage) {
                    // // console.log("SurveyPublic -> componentDidMount -> prevpage");

                    animate('slideDown1')
                  }
                })
                Model.onCurrentPageChanged.add((sender, option) => {
                  // // console.log("SurveyPublic -> componentDidMount -> option", option);
                  // // console.log("SurveyPublic -> componentDidMount -> sender", sender);
                  window.scrollTo(0, 0)
                  if (option.isPrevPage) {
                    // animate("slideDown1");

                    // // console.log("SurveyPublic -> componentDidMount -> prevpage");
                    this.setState({ ClickEvent: !this.state.ClickEvent })
                    animate('slideUp1')
                  }
                  if (option.isNextPage) {
                    // // console.log("SurveyPublic -> componentDidMount -> nextpage");
                    this.setState({ ClickEvent: !this.state.ClickEvent })
                    animate('slideDown')
                  }
                })

                Model.onCompleting.add((sender, options) => {
                  console.log('SurveyPublic -> componentDidMount -> options', options)
                  // // console.log("SurveyPublic -> componentDidMount -> options", options);
                  // // console.log("SurveyPublic -> componentDidMount -> sender", sender);
                  window.scrollTo(0, 0)
                  // const response = this.Mailer();
                  if (!doAnimantion) return
                  options.allowComplete = false
                  if (this.state.CouponCodeStatus === 'Active') {
                    if (this.state.survey_mail_sent === false) {
                      this.setState(
                        {
                          survey_mail_sent: true,
                        },
                        () => {
                          let email = ''
                          let Id = ''
                          // let API = 'http://localhost:5000/api/survey/complete'
                          let API_PROD = ProjectSettings.baseURL + '/api/survey/complete'
                          this.state.model.getAllQuestions().forEach((e) => {
                            if (e.getType() === 'text') {
                              if (e.title.toLowerCase().includes('email')) {
                                Id = this.props.match.params.code
                                email = e.propertyHash.value
                              }
                            }
                          })
                          fetch(API_PROD, {
                            method: 'POST',
                            headers: {
                              'Content-type': 'application/json',
                            },
                            body: JSON.stringify({
                              surveyId: Id,
                              email: email,
                            }),
                          })
                            .then((e) => {
                              return e.json()
                            })
                            .then((e) => {
                              if (e.message === 'Duplicate Email found') {
                                setTimeout(() => {
                                  console.log('SurveyPublic -> componentDidMount -> setTimeout')
                                  doAnimantion = false
                                  Model.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Our records indicate that you have already responded to our survey. Only one survey response is permitted.  We appreciate you taking the time to provide us your responses.	
                   <br> Thank you. <br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                                  sender.doComplete()
                                  doAnimantion = true
                                  options.allowComplete = true
                                  animate('slideDown')
                                }, 500)
                                animate('slideUp')
                              } else {
                                setTimeout(() => {
                                  doAnimantion = false
                                  // Model.survey.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`;
                                  sender.doComplete()
                                  doAnimantion = true
                                  options.allowComplete = true
                                  animate('slideDown')
                                }, 500)
                                animate('slideUp')
                              }
                            })
                        }
                      )
                    }
                  } else {
                    setTimeout(() => {
                      doAnimantion = false
                      // Model.survey.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`;
                      sender.doComplete()
                      doAnimantion = true
                      options.allowComplete = true
                      animate('slideDown')
                    }, 500)
                    animate('slideUp')
                  }
                  // // console.log("SurveyPublic -> componentDidMount -> options", options);
                  // // console.log("SurveyPublic -> componentDidMount -> sender", sender);
                })

                // Model.onCompleting.add((sender, options) => {
                //   console.log("SurveyPublic -> componentDidMount -> sender");
                //   window.scrollTo(0, 0);
                //   if (!doAnimantion) return;
                //   options.allowComplete = false;
                //   if (this.state.CouponCodeStatus === "Active") {
                //     let email = "";
                //     let Id = "";
                //     let API = "http://localhost:5000/api/survey/complete";
                //     let API_PROD = "https://api.carwashsurveys.com/api/survey/complete";
                //     this.state.model.getAllQuestions().forEach((e) => {
                //       if (e.getType() === "text") {
                //         if (e.title.toLowerCase().includes("email")) {
                //           Id = this.props.match.params.code;
                //           email = e.propertyHash.value;
                //         }
                //       }
                //     });

                //     fetch(API_PROD, {
                //       method: "POST",
                //       headers: {
                //         "Content-type": "application/json",
                //       },
                //       body: JSON.stringify({
                //         surveyId: Id,
                //         email: email,
                //       }),
                //     })
                //       .then((e) => {
                //         return e.json();
                //       })
                //       .then((e) => {
                //         console.log("SurveyPublic -> componentDidMount -> e", e);

                //         console.log("SurveyPublic -> componentDidMount -> setTimeout");
                //         if (e.message === "Duplicate Email found") {
                //           setTimeout(() => {
                //             console.log(
                //               "SurveyPublic -> componentDidMount -> setTimeout"
                //             );

                //             doAnimantion = false;

                //             Model.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Our records indicate that you have already responded to our survey. Only one survey response is permitted.  We appreciate you taking the time to provide us your responses.

                //             Thank you. <br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`;

                //             sender.doComplete();

                //             doAnimantion = true;
                //             options.allowComplete = true;
                //             animate("slideDown");
                //           }, 500);
                //           animate("slideUp");
                //         } else {
                //           setTimeout(() => {
                //             console.log(
                //               "SurveyPublic -> componentDidMount -> setTimeout"
                //             );

                //             doAnimantion = false;

                //             console.log(
                //               "SurveyPublic -> componentDidMount -> sender",
                //               sender
                //             );

                //             // Model.survey.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`;

                //             sender.doComplete();

                //             doAnimantion = true;
                //             options.allowComplete = true;
                //             animate("slideDown");
                //           }, 500);
                //           animate("slideUp");
                //         }
                //       });
                //   } else {
                //     options.allowComplete = false;
                //     setTimeout(() => {
                //       doAnimantion = false;

                //       sender.doComplete();
                //       doAnimantion = true;
                //       options.allowComplete = true;
                //       animate("slideDown");
                //     }, 500);

                //     animate("slideUp");
                //   }
                // });

                if (Model.firstPageIsStarted === false) {
                  setTimeout(() => {
                    // this.setState({ FirstPageStarter: true });
                    this.setState(
                      {
                        model: Model,
                      },
                      () => {
                        this.setState({
                          window_width: window.innerWidth,
                          window_height: window.innerHeight,
                        })
                        setTimeout(() => {
                          this.setState({ FirstPageStarter: true })
                        }, 1000)

                        if (window.height < 500) {
                          this.MobileDomChecker()
                          this.SpecialClasses()
                          this.NpsQuestioncase()

                          this.TextAlignmentCss()
                          this.NPS_MOBIlE_ISSUE('ajsbdkjasbdjkasbjkdbsajkdbasds')
                        } else {
                          this.SingleQuestionDOMChecker()
                          this.SpecialClasses()
                          this.NpsQuestioncase()
                          this.TextAlignmentCss()
                          this.NPS_MOBIlE_ISSUE('asdbkasbjkdsad')
                        }
                      }
                    )
                  }, 2000)
                } else {
                  // this.setState({ FirstPageStarter: true });
                }
                setTimeout(() => {
                  this.setState(
                    {
                      model: Model,
                    },
                    () => {
                      this.setState({
                        window_width: window.innerWidth,
                        window_height: window.innerHeight,
                        FirstPageStarter: true,
                      })

                      if (window.height < 500) {
                        this.MobileDomChecker()
                        this.SpecialClasses()
                        this.NpsQuestioncase()
                        this.TextAlignmentCss()
                        this.NPS_MOBIlE_ISSUE('seomrnsknsaasdasdasdasdasd')

                        this.DOMcheckerfunction()
                      } else {
                        this.DOMcheckerfunction()
                        this.SingleQuestionDOMChecker()
                        this.SpecialClasses()
                        this.NpsQuestioncase()
                        this.TextAlignmentCss()
                        this.NPS_MOBIlE_ISSUE('seomrnsknsa')
                      }
                    }
                  )
                }, 1000)
              }
            }
          )
        })
      } else {
        this.setState(
          {
            // Survey: response1.responseData,
            Survey: SurveyJSON,

            SurveyStatus: response1.responseData.status,
            CouponCodeStatus: response1.responseData.CouponCodeStatus,
            Mycss: {
              headerText: 'sv-header__text Header_text_css',

              navigation: {
                // start:
                //   "start_button btn-wide btn-special-primary-user-hover special_start_button_questiontype",
                // start: "start_button btn-wide btn-special-primary-user-hover",
              },
            },
            MyCss1: {
              footer: 'body_footer',
              row: 'row_single',
              container: 'sv-container-modern body_single',
              navigation: {
                start: 'start_button1 btn-wide btn-special-primary-user-hover',
              },
            },
          },
          async () => {
            console.log('SurveyPublic -> componentDidMount -> this.state', this.state)
            var Model11 = new SurveyJSCreator.SurveyCreator(null)

            if (this.state.Survey.createdBy.status === 'Inactive' || this.state.SurveyStatus !== 'Active') {
              this.setState({ error: true })
            } else {
              Model11.JSON = this.state.Survey.surveyJson
              this.setState({
                survey_logo: Model11.survey.logo,
              })
              // console.log('SurveyPublic -> componentDidMount -> Model11.currentPage', Model11.survey.currentPage)
              // Model11.currentPage().add((sender, option) => {
              // // //   console.log("SurveyPublic -> componentDidMount -> e", sender);
              // });
              // // console.log("SurveyPublic -> componentDidMount -> Model11", Model11);
              let LOGO = Model11.survey.logo

              console.log('SurveyPublic -> componentDidMount -> this.state', this.state)
              if (this.state.Survey.surveyId === '144gbl5l5fki64q0jl') {
                console.log('SurveyPublic -> componentDidMount -> this.state', this.state)
                if (this.state.CouponCodeStatus === 'Active') {
                  if (LOGO !== '') {
                    // // console.log("SurveyPublic -> componentDidMount -> LOGO", LOGO);
                    Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                  } else {
                    Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                  }
                }
              } else {
                if (this.state.CouponCodeStatus === 'Active') {
                  console.log('SurveyPublic -> componentDidMount -> this.state', this.state)
                  if (LOGO !== '') {
                    // // console.log("SurveyPublic -> componentDidMount -> LOGO", LOGO);
                    Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><br>Please visit our website and stay in touch.</div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                  } else {
                    Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><br>Please visit our website and stay in touch.</div></h5></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover" onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                  }
                } else {
                  if (LOGO !== '') {
                    // // console.log("SurveyPublic -> componentDidMount -> LOGO", LOGO);
                    Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                  } else {
                    Model11.survey.completedHtml = `<h3>Thank you for your feedback.</h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                  }
                }
              }

              var Model = new Survey.Model(Model11.JSON)
              // var myCss = {}

              // // // console.log("SurveyPublic -> componentDidMount -> Model", Model);
              // Model.FirstPageStarter = true;
              // // // console.log("SurveyPublic -> componentDidMount -> Model", Model);

              Model.onUpdateQuestionCssClasses.add((survey, option) => {
                this.title_removing_pagechange()
                if (option.question.getType() === 'text') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_text`
                  classes.description = `${classes.description}  description_css description_css_text`
                  classes.label = `${classes.label} selectbase__label_css selectbase__label_css_text`
                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_text`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_text`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_text`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_text`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_text`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_text`
                }
                if (option.question.getType() === 'radiogroup') {
                  let classes = option.cssClasses

                  classes.title = `${classes.title} Title_css Title_css_radiogroup`
                  classes.description = `${classes.description} description_css description_css_radiogroup`
                  classes.root = `${classes.root} sv-selectbase_css_radiogroup`
                  classes.label = `${classes.label} selectbase__label_css selectbase__label_css_radiogroup`
                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_radiogroup`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_radiogroup`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_radiogroup`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_radiogroup`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_radiogroup`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_radiogroup`
                  classes.other = `${classes.other} sv-question__other_css question__other_css_radiogroup`
                  classes.clearButton = `${classes.clearButton} Clear_button_css Clear_button_css_radiogroup`
                  classes.item = `${classes.item} Item_css Item_css_radiogroup`
                  classes.itemHover = `${classes.itemHover} Hover_radipgroup_css`
                  classes.materialDecorator = `${classes.materialDecorator} svg_hidden_css`
                  classes.itemChecked = `${classes.itemChecked} CHecked_item_radiogroup`

                  classes.controlLabel = `${classes.controlLabel} controlLabelradiogroup_css`
                }
                if (option.question.getType() === 'checkbox') {
                  // // console.log("SurveyPublic -> componentDidMount -> option", option);

                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_checkbox`
                  classes.description = `${classes.description} description_css description_css_checkbox`

                  classes.root = `${classes.root} sv-selectbase_checkbox`
                  classes.label = `${classes.label} selectbase__label_css selectbase__label_css_checkbox`
                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_checkbox`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_checkbox`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_checkbox`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_checkbox`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_checkbox`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_checkbox`
                  classes.controlLabel = `${classes.controlLabel} control_label_css`
                  classes.other = `${classes.other} sv-question__other_css question__other_css+checkbox`
                  classes.clearButton = `${classes.clearButton} Clear_button_css Clear_button_css_checkbox`
                  classes.item = `${classes.item} Item_css Item_css_checkbox`
                }
                if (option.question.getType() === 'comment') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_comment`
                  classes.description = `${classes.description} description_css description_css_comment`
                  classes.root = `${classes.root} Text_area_comment`
                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_comment`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_comment`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_comment`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_comment`
                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_comment`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_comment`
                }
                if (option.question.getType() === 'rating') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_rating`
                  classes.description = `${classes.description} description_css description_css_rating`
                  classes.root = `${classes.root} sv-rating rating_special_root`
                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_rating`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_rating`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_rating`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_rating`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_rating`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_rating`

                  classes.selected = `${classes.selected} selected_css selected_css_rating`
                  classes.itemText = `${classes.itemText} itemText_css itemText_css_rating`
                  classes.item = `${classes.item} Item_css Item_css_rating`

                  classes.maxText = `${classes.maxText} maxText_css maxText_css_rating`
                  classes.minText = `${classes.minText} minText_css minText_css_rating`
                  if (option.question.name === 'general_question') {
                    classes.itemText = `${classes.itemText} itemText_css itemText_css_rating itemText_css_rating_special`
                  }
                  if (option.question.name === 'customer_satisfaction') {
                    classes.itemText = `${classes.itemText} itemText_css itemText_css_rating itemText_css_rating_special`
                  }
                }

                if (option.question.getType() === 'image') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_image`
                  classes.description = `${classes.description} description_css description_css_image`

                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_image`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_image`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_image`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_image`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_image`
                  classes.content = `${classes.content} Content_css Content_css_image`
                }
                if (option.question.getType() === 'boolean') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_boolean`
                  classes.description = `${classes.description} description_css description_css_boolean`

                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_boolean`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_boolean`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_boolean`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_boolean`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_boolean`
                }
                if (option.question.getType() === 'imagepicker') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_imagepicker`
                  classes.root = `${classes.root} sv-imagepicker`
                  classes.description = `${classes.description} description_css description_css_imagepicker`
                  classes.label = `${classes.label} selectbase__label_css selectbase__label_css_imagepicker`
                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_imagepicker`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_imagepicker`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_imagepicker`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_imagepicker`
                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_imagepicker`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_imagepicker`
                  classes.item = `${classes.item} Item_css Item_css_imagepicker`

                  classes.itemChecked = `${classes.itemChecked} Checked_css Checked_css_imagepicker`
                  classes.itemControl = `${classes.itemControl} Control_css Control_css_imagepicker`
                  classes.itemHover = `${classes.itemHover} Hover_Css Hover_Css_imagepicker`
                  classes.itemText = `${classes.itemText} Text_css Text_css_imagepicker`
                }
                if (option.question.getType() === 'matrix') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_matrix`
                  classes.description = `${classes.description} description_css description_css_matrix`
                  classes.root = `${classes.root} table`
                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_matrix`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_matrix`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_matrix`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_matrix`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_matrix`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_matrix`
                }
                if (option.question.getType() === 'file') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_file`
                  classes.description = `${classes.description} description_css description_css_file`
                  classes.wrapper = `${classes.wrapper} wrapper_css_file`
                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_file`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_file`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_file`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_file`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_file`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_file`

                  classes.fileDecorator = `${classes.fileDecorator} fileDecorator_css_file`
                  classes.chooseFile = `${classes.chooseFile} ChooseFile_css_file`
                  classes.preview = `${classes.preview} preview_css_file`
                }
                if (option.question.getType() === 'dropdown') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_dropdown`
                  classes.description = `${classes.description} description_css description_css_dropdown`

                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_dropdown`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_dropdown`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_dropdown`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_dropdown`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_dropdown`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_dropdown`
                }
                if (option.question.getType() === 'multipletext') {
                  let classes = option.cssClasses
                  classes.title = `${classes.title} Title_css Title_css_multipletext`
                  classes.description = `${classes.description} description_css description_css_multipletext`
                  classes.root = `${classes.root} Multipletext_css`

                  classes.requiredText = `${classes.requiredText} requiredText_css requiredText_css_multipletext`
                  classes.titleOnAnswer = `${classes.titleOnAnswer} titleOnAnswer_css titleOnAnswer_css_multipletext`
                  classes.titleOnError = `${classes.titleOnError} titleOnError_css titleOnError_css_multipletext`
                  classes.titleRequired = `${classes.titleRequired} titleRequired_css titleRequired_css_multipletext`

                  classes.error.locationBottom = `${classes.error.locationBottom} locationBottom_css locationBottom_css_multipletext`
                  classes.error.locationTop = `${classes.error.locationTop} locationTop_css locationTop_multipletext`
                }
              })
              $(document).ready(() => {
                this.setState({ ClickEvent: !this.state.ClickEvent })
              })
              const animate = (animitionType, duration) => {
                // // console.log("SurveyPublic -> animate -> animitionType", animitionType);
                if (!duration) duration = 1000

                // gsap.to();

                var element = document.querySelector('.container11_not_single_question')
                if (animitionType === 'slideDown') {
                  gsap.fromTo(
                    element,
                    {
                      position: 'relative',
                      left: '100vw',
                    },
                    {
                      left: '0vw',
                      position: 'relative',

                      duration: 0.5,
                    }
                  )
                }

                if (animitionType === 'slideUp') {
                  gsap.fromTo(
                    element,
                    {},

                    {
                      position: 'relative',
                      left: '-100vw',

                      duration: 0.5,
                    }
                  )
                }
                if (animitionType === 'slideDown1') {
                  gsap.fromTo(
                    element,
                    {
                      position: 'relative',
                      left: '0vw',
                    },

                    {
                      position: 'relative',
                      left: '100vw',

                      duration: 0.5,
                    }
                  )

                  // gsap.to(element, 0.5, {
                  //   position: "relative",
                  //   left: "100vw",
                  //   overflow: "hidden",
                  // });
                }
                if (animitionType === 'slideUp1') {
                  gsap.fromTo(
                    element,
                    {
                      position: 'relative',

                      left: '-100vw',
                    },
                    {
                      position: 'relative',
                      left: '0vw',
                      duration: 0.5,
                    }
                  )
                }
              }

              var doAnimantion = true

              Model.onCurrentPageChanging.add((sender, options) => {
                // // console.log("SurveyPublic -> componentDidMount -> options", options);
                // // console.log("SurveyPublic -> componentDidMount -> sender", sender);

                window.scrollTo(0, 0)
                if (!doAnimantion) return
                else {
                  options.allowChanging = false
                  setTimeout(function () {
                    doAnimantion = false
                    sender.currentPage = options.newCurrentPage
                    doAnimantion = true
                  }, 500)
                  // // console.log("SurveyPublic -> componentDidMount -> options", options);
                  // // console.log("SurveyPublic -> componentDidMount -> sender", sender);
                }
                if (options.isNextPage) {
                  // // console.log("SurveyPublic -> componentDidMount -> nextpage");

                  animate('slideUp')
                }
                if (options.isPrevPage) {
                  // // console.log("SurveyPublic -> componentDidMount -> prevpage");

                  animate('slideDown1')
                }
              })
              Model.onCurrentPageChanged.add((sender, option) => {
                // // console.log("SurveyPublic -> componentDidMount -> option", option);
                // // console.log("SurveyPublic -> componentDidMount -> sender", sender);
                window.scrollTo(0, 0)
                if (option.isPrevPage) {
                  // animate("slideDown1");

                  // // console.log("SurveyPublic -> componentDidMount -> prevpage");
                  this.setState({ ClickEvent: !this.state.ClickEvent })
                  animate('slideUp1')
                }
                if (option.isNextPage) {
                  // // console.log("SurveyPublic -> componentDidMount -> nextpage");
                  this.setState({ ClickEvent: !this.state.ClickEvent })
                  animate('slideDown')
                }
              })

              Model.onCompleting.add((sender, options) => {
                console.log('SurveyPublic -> componentDidMount -> options', options)
                // // console.log("SurveyPublic -> componentDidMount -> options", options);
                // // console.log("SurveyPublic -> componentDidMount -> sender", sender);
                window.scrollTo(0, 0)
                // const response = this.Mailer();
                if (!doAnimantion) return
                options.allowComplete = false
                if (this.state.CouponCodeStatus === 'Active') {
                  if (this.state.survey_mail_sent === false) {
                    this.setState(
                      {
                        survey_mail_sent: true,
                      },
                      () => {
                        let email = ''
                        let Id = ''
                        // let API = 'http://localhost:5000/api/survey/complete'
                        let API_PROD = ProjectSettings.baseURL + '/api/survey/complete'
                        this.state.model.getAllQuestions().forEach((e) => {
                          if (e.getType() === 'text') {
                            if (e.title.toLowerCase().includes('email')) {
                              Id = this.props.match.params.code
                              email = e.propertyHash.value
                            }
                          }
                        })
                        fetch(API_PROD, {
                          method: 'POST',
                          headers: {
                            'Content-type': 'application/json',
                          },
                          body: JSON.stringify({
                            surveyId: Id,
                            email: email,
                          }),
                        })
                          .then((e) => {
                            return e.json()
                          })
                          .then((e) => {
                            if (e.message === 'Duplicate Email found') {
                              setTimeout(() => {
                                console.log('SurveyPublic -> componentDidMount -> setTimeout')
                                doAnimantion = false
                                Model.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Our records indicate that you have already responded to our survey. Only one survey response is permitted.  We appreciate you taking the time to provide us your responses.	
                 <br> Thank you. <br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`
                                sender.doComplete()
                                doAnimantion = true
                                options.allowComplete = true
                                animate('slideDown')
                              }, 500)
                              animate('slideUp')
                            } else {
                              setTimeout(() => {
                                doAnimantion = false
                                // Model.survey.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`;
                                sender.doComplete()
                                doAnimantion = true
                                options.allowComplete = true
                                animate('slideDown')
                              }, 500)
                              animate('slideUp')
                            }
                          })
                      }
                    )
                  }
                } else {
                  setTimeout(() => {
                    doAnimantion = false
                    // Model.survey.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`;
                    sender.doComplete()
                    doAnimantion = true
                    options.allowComplete = true
                    animate('slideDown')
                  }, 500)
                  animate('slideUp')
                }
                // // console.log("SurveyPublic -> componentDidMount -> options", options);
                // // console.log("SurveyPublic -> componentDidMount -> sender", sender);
              })

              // Model.onCompleting.add((sender, options) => {
              //   console.log("SurveyPublic -> componentDidMount -> sender");
              //   window.scrollTo(0, 0);
              //   if (!doAnimantion) return;
              //   options.allowComplete = false;
              //   if (this.state.CouponCodeStatus === "Active") {
              //     let email = "";
              //     let Id = "";
              //     let API = "http://localhost:5000/api/survey/complete";
              //     let API_PROD = "https://api.carwashsurveys.com/api/survey/complete";
              //     this.state.model.getAllQuestions().forEach((e) => {
              //       if (e.getType() === "text") {
              //         if (e.title.toLowerCase().includes("email")) {
              //           Id = this.props.match.params.code;
              //           email = e.propertyHash.value;
              //         }
              //       }
              //     });

              //     fetch(API_PROD, {
              //       method: "POST",
              //       headers: {
              //         "Content-type": "application/json",
              //       },
              //       body: JSON.stringify({
              //         surveyId: Id,
              //         email: email,
              //       }),
              //     })
              //       .then((e) => {
              //         return e.json();
              //       })
              //       .then((e) => {
              //         console.log("SurveyPublic -> componentDidMount -> e", e);

              //         console.log("SurveyPublic -> componentDidMount -> setTimeout");
              //         if (e.message === "Duplicate Email found") {
              //           setTimeout(() => {
              //             console.log(
              //               "SurveyPublic -> componentDidMount -> setTimeout"
              //             );

              //             doAnimantion = false;

              //             Model.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Our records indicate that you have already responded to our survey. Only one survey response is permitted.  We appreciate you taking the time to provide us your responses.

              //             Thank you. <br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`;

              //             sender.doComplete();

              //             doAnimantion = true;
              //             options.allowComplete = true;
              //             animate("slideDown");
              //           }, 500);
              //           animate("slideUp");
              //         } else {
              //           setTimeout(() => {
              //             console.log(
              //               "SurveyPublic -> componentDidMount -> setTimeout"
              //             );

              //             doAnimantion = false;

              //             console.log(
              //               "SurveyPublic -> componentDidMount -> sender",
              //               sender
              //             );

              //             // Model.survey.completedHtml = `<h3></h3><h5><div tabindex="0" class="component-description theme-question">Your promo code for a free car wash is on its way to your inbox!<br><span>Please visit our website and stay in touch.</span></div></h5><img class = "Logo_finalPage sv-logo__image" src=${LOGO} ></img><Button type ="button" class = "btn-wide btn btn-secondary special_completeButton btn-special-primary-user-hover"  onClick ="location.href = 'https://sudsdeluxe.com/'">Visit Our Website</Button`;

              //             sender.doComplete();

              //             doAnimantion = true;
              //             options.allowComplete = true;
              //             animate("slideDown");
              //           }, 500);
              //           animate("slideUp");
              //         }
              //       });
              //   } else {
              //     options.allowComplete = false;
              //     setTimeout(() => {
              //       doAnimantion = false;

              //       sender.doComplete();
              //       doAnimantion = true;
              //       options.allowComplete = true;
              //       animate("slideDown");
              //     }, 500);

              //     animate("slideUp");
              //   }
              // });

              if (Model.firstPageIsStarted === false) {
                setTimeout(() => {
                  // this.setState({ FirstPageStarter: true });
                  this.setState(
                    {
                      model: Model,
                    },
                    () => {
                      this.setState({
                        window_width: window.innerWidth,
                        window_height: window.innerHeight,
                      })
                      setTimeout(() => {
                        this.setState({ FirstPageStarter: true })
                      }, 1000)

                      if (window.height < 500) {
                        this.MobileDomChecker()
                        this.SpecialClasses()
                        this.NpsQuestioncase()

                        this.TextAlignmentCss()
                        this.NPS_MOBIlE_ISSUE('ajsbdkjasbdjkasbjkdbsajkdbasds')
                      } else {
                        this.SingleQuestionDOMChecker()
                        this.SpecialClasses()
                        this.NpsQuestioncase()
                        this.TextAlignmentCss()
                        this.NPS_MOBIlE_ISSUE('asdbkasbjkdsad')
                      }
                    }
                  )
                }, 2000)
              } else {
                // this.setState({ FirstPageStarter: true });
              }
              setTimeout(() => {
                this.setState(
                  {
                    model: Model,
                  },
                  () => {
                    this.setState({
                      window_width: window.innerWidth,
                      window_height: window.innerHeight,
                      FirstPageStarter: true,
                    })

                    if (window.height < 500) {
                      this.MobileDomChecker()
                      this.SpecialClasses()
                      this.NpsQuestioncase()
                      this.TextAlignmentCss()
                      this.NPS_MOBIlE_ISSUE('seomrnsknsaasdasdasdasdasd')

                      this.DOMcheckerfunction()
                    } else {
                      this.DOMcheckerfunction()
                      this.SingleQuestionDOMChecker()
                      this.SpecialClasses()
                      this.NpsQuestioncase()
                      this.TextAlignmentCss()
                      this.NPS_MOBIlE_ISSUE('seomrnsknsa')
                    }
                  }
                )
              }, 1000)
            }
          }
        )
      }
    } else {
      this.setState({ error: true })
    }
  }

  onCurrentPageChanged = () => {
    //
  }

  NPS_MOBIlE_ISSUE = (e) => {
    if (document.querySelector('.start_button')) {
      document.querySelector('.sv-container-modern').classList.add('special_starter_page')
    } else {
      if (document.querySelector('.sv-container-modern')) {
        if (document.querySelector('.sv-container-modern').classList.contains('special_starter_page')) {
          document.querySelector('.sv-container-modern').classList.remove('special_starter_page')
        }
      }
    }
    if (window.innerWidth < 501) {
      if (document.querySelector('.Title_css_rating')) {
        if (document.querySelector('.sv-question')) {
          if (this.state.Survey.surveyJson.questionsOnPageMode === 'questionPerPage') {
            let rating_question_details = document.querySelector('.Title_css_rating').getBoundingClientRect()
            // let question_details = document.querySelector('.sv-question').getBoundingClientRect()

            if (Math.abs(rating_question_details.y) < 10) {
              document.querySelector('.sv-question').classList.add('NPS_QUESTION')
            } else {
              // if (document.querySelector(".NPS_QUESTION")) {
              //   document
              //     .querySelector(".sv-question")
              //     .classList.remove("NPS_QUESTION");
              // }
            }
          }
        }
      }
    }
  }

  TextAlignmentCss = () => {
    let node = document.createElement('div')
    node.className = 'special_mindescription_rating'

    node.style.display = 'none'

    let node1 = document.createElement('div')
    node1.className = 'special_maxdescription_rating'

    node1.style.display = 'none'
    if (!document.querySelectorAll('.special_mindescription_rating').length && !document.querySelectorAll('.special_maxdescription_rating').length) {
      if (window.innerWidth < 801) {
        if (document.querySelector('.sv-rating__min-text')) {
          document.querySelector('.sv-rating__min-text').appendChild(node)
          document.querySelector('.sv-rating__min-text').style.display = 'none'
          var min_text = document.querySelector('.sv-rating__min-text').textContent
          document.querySelector('.sv-rating__min-text').parentNode.childNodes.forEach((e) => {
            if (e.classList.contains('itemText_css_rating')) {
              e.textContent = e.textContent + ' -' + min_text
            }
          })
        }
        if (document.querySelector('.sv-rating__max-text')) {
          document.querySelector('.sv-rating__max-text').appendChild(node1)
          document.querySelector('.sv-rating__max-text').style.display = 'none'
          var max_text = document.querySelector('.sv-rating__max-text').textContent
          document.querySelector('.sv-rating__max-text').parentNode.childNodes.forEach((e) => {
            if (e.classList.contains('itemText_css_rating')) {
              e.textContent = e.textContent + ' -' + max_text
            }
          })
        }
      } else {
        if (document.querySelector('.sv-rating__min-text')) {
          document.querySelector('.sv-rating__min-text').style.display = 'flex'
        }
        if (document.querySelector('.sv-rating__max-text')) {
          document.querySelector('.sv-rating__max-text').style.display = 'flex'
        }
      }
    }
  }

  SingleQuestionDOMChecker = () => {
    $(document).ready(() => {
      try {
        if (this.state.Survey.surveyJson.questionsOnPageMode === 'questionPerPage') {
          // document.querySelector(".sv-footer__next-btn").style.visibility =
          //   "hidden";

          // document.querySelector().style.visibility = "hidden"

          if (document.querySelector('.sv-footer__next-btn')) {
            document.querySelector('.sv-footer__next-btn').style.visibility = 'hidden'
            document.querySelector('.icon_inner_right_outer').style.display = 'flex'

            if (window.innerWidth < 500) {
              document.querySelector('.icon_inner_outer_mobile').style.display = 'flex'
              document.querySelector('.icon_inner_right_outer').style.display = 'none'
              document.querySelector('.sv-footer').style.visibility = 'none'
            }
            if (document.querySelector('.sv-header__text')) {
              document.querySelector('.sv-header__text').style.display = 'none'
            }
          } else {
            document.querySelector('.icon_inner_right_outer').style.display = 'none'
            if (window.innerWidth < 500) {
              document.querySelector('.icon_inner_outer_mobile').style.display = 'none'
              document.querySelector('.icon_inner_right_outer_mobile').style.display = 'none'
            }
          }

          if (document.querySelector('.sv-footer__prev-btn')) {
            document.querySelector('.sv-footer__prev-btn').style.visibility = 'hidden'
            document.querySelector('.icon_inner_outer').style.display = 'flex'
            if (window.innerWidth < 500) {
              document.querySelector('.icon_inner_right_outer_mobile').style.display = 'flex'
              document.querySelector('.icon_inner_outer').style.display = 'none'
              document.querySelector('.sv-footer').style.visibility = 'none'
            }
          } else {
            document.querySelector('.icon_inner_outer').style.display = 'none'
            if (window.innerWidth < 500) {
              document.querySelector('.icon_inner_right_outer_mobile').style.display = 'none'
            }
          }

          if (document.querySelector('.sv-footer__complete-btn')) {
            document.querySelector('.sv-footer__complete-btn').style.visibility = 'hidden'
            document.querySelector('.button_submit').style.display = 'block'
            if (window.innerWidth < 500) {
              document.querySelector('.icon_inner_outer_mobile_complete').style.display = 'flex'
              document.querySelector('.button_submit').style.display = 'none'
            }
          } else {
            document.querySelector('.button_submit').style.display = 'none'
            if (window.innerWidth < 500) {
              document.querySelector('.icon_inner_outer_mobile_complete').style.display = 'none'
            }
          }
        }
      } catch (e) {}
    })
  }
  MobileMulitpleQuestion = () => {
    $(document).ready(() => {
      if (document.querySelector('.sv-footer__next-btn')) {
        document.querySelector('.special_bottom_mobile').style.display = 'flex'
        document.querySelector('.icon_inner_outer_mobile').style.display = 'flex'
        // document.querySelector(".icon_inner_right_outer").style.display =
        //   "none";
        if (this.state.Survey.surveyJson.questionsOnPageMode === 'questionPerPage') {
          // document.querySelector(".Title_css").style.display = "none";
        }
        if (document.querySelector('.sv-progress')) {
        }
      } else {
        if (document.querySelector('.icon_inner_outer_mobile')) {
          document.querySelector('.icon_inner_outer_mobile').style.display = 'none'
        }
        if (document.querySelector('.icon_inner_right_outer_mobile')) {
          document.querySelector('.icon_inner_right_outer_mobile').style.display = 'none'
        }
      }
      if (document.querySelector('.sv-footer__prev-btn')) {
        document.querySelector('.icon_inner_right_outer_mobile').style.display = 'flex'
        // document.querySelector(".icon_inner_outer").style.display = "none";
      } else {
        if (document.querySelector('.icon_inner_right_outer_mobile')) {
          document.querySelector('.icon_inner_right_outer_mobile').style.display = 'none'
        }
      }
      if (document.querySelector('.sv-footer__complete-btn')) {
        document.querySelector('.icon_inner_outer_mobile_complete').style.display = 'flex'
        // document.querySelector(".button_submit").style.display = "none";
      } else {
        if (document.querySelector('.icon_inner_outer_mobile_complete')) {
          document.querySelector('.icon_inner_outer_mobile_complete').style.display = 'none'
        }
      }
    })
  }

  NpsQuestioncase = () => {
    if (document.querySelector('.form-group')) {
      if (!document.querySelector('special_form_group')) {
        document.querySelector('.form-group').classList.add('special_form_group')
      }
    }
    if (!document.querySelectorAll('.special_mindescription').length && !document.querySelectorAll('.special_maxdescription').length) {
      if (document.querySelector('.sv-selectbase_css_radiogroup')) {
        // // console.log("alknsdklasndlnasdnklasndklsakndknaksd");
        let node = document.createElement('div')
        node.className = 'special_mindescription'
        node.innerText = 'Not likely at all'
        node.style.display = 'none'
        let node1 = document.createElement('div')
        node1.className = 'special_maxdescription'
        node1.innerText = 'Extremely likely'
        node1.style.display = 'none'

        // let NodeLength = document.querySelector('.sv-selectbase_css_radiogroup').childNodes.length
        // if (window.innerWidth < 801) {
        //   document
        //     .querySelector(".sv-selectbase_css_radiogroup")
        //     .childNodes.forEach((e, i) => {
        //       // if (i === 1) {
        //       //   // // console.log("SurveyPublic -> SpecialClasses -> e[2]", e);

        //       // }

        //       if (i === 1) {
        //         node.style.display = "none";
        //         e.appendChild(node);
        //         e.childNodes.forEach((e) => {
        //           console.log(e);
        //           if (
        //             e.classList.contains("selectbase__label_css_radiogroup")
        //           ) {
        //             console.log(e.childNodes);
        //             e.childNodes.forEach((e) => {
        //               if (e.classList.contains("controlLabelradiogroup_css")) {
        //                 console.log(e);
        //                 e.childNodes.forEach((e) => {
        //                   e.textContent = e.textContent + " -Not likely at all";
        //                 });
        //               }
        //             });
        //           }
        //         });
        //       }
        //       if (i === NodeLength - 1) {
        //         // console.log(
        //         //   "SurveyPublic -> SpecialClasses -> e[NodeLength-1]",
        //         //   e
        //         // );
        //         node1.style.display = "none";
        //         e.appendChild(node1);
        //         e.childNodes.forEach((e) => {
        //           console.log(e);
        //           if (
        //             e.classList.contains("selectbase__label_css_radiogroup")
        //           ) {
        //             console.log(e.childNodes);
        //             e.childNodes.forEach((e) => {
        //               if (e.classList.contains("controlLabelradiogroup_css")) {
        //                 console.log(e);
        //                 e.childNodes.forEach((e) => {
        //                   e.textContent = e.textContent + " -Extremely Likely";
        //                 });
        //               }
        //             });
        //           }
        //         });
        //       }
        //     });
        // } else {
        //   let node = document.createElement("div");
        //   node.className = "special_mindescription";
        //   node.innerText = "Not likely at all";

        //   let node1 = document.createElement("div");
        //   node1.className = "special_maxdescription";
        //   node1.innerText = "Extremely likely";

        //   document
        //     .querySelector(".sv-selectbase_css_radiogroup")
        //     .childNodes.forEach((e, i) => {
        //       if (i === 1) {
        //         e.appendChild(node);
        //       }
        //       if (i === NodeLength - 1) {
        //         e.appendChild(node1);
        //       }
        //     });
        // }
      }
    }
  }

  SpecialClasses = () => {
    $(document).ready(() => {
      console.log('SurveyPublic -> componentDidUpdate -> this.state', this.state)

      if (document.querySelector('.selectbase__label_css_checkbox')) {
        if (document.querySelector('.sv-checkbox--checked')) {
          document.querySelectorAll('.sv-checkbox--checked').forEach((e) => {
            e.classList.add('rating_question_selected')
          })
        }
      }

      if (document.querySelector('.Item_css_radiogroup')) {
        if (document.querySelector('.sv-radio--checked')) {
          document.querySelectorAll('.sv-radio--checked').forEach((e) => {
            // // console.log("SurveyPublic -> SpecialClasses -> e", e);
            // // console.log("SurveyPublic -> SpecialClasses -> e", e.children);
            // if (e.children) {
            //   e.children.classList.add("special_selector_radiogroup");
            // }
          })
        }
      }
    })
  }

  MobileDomChecker = () => {
    try {
      $(document).ready(() => {
        if (document.querySelector('.sv-root-modern')) {
          var root_height = document.querySelector('.sv-root-modern').getBoundingClientRect().height

          document.querySelector('.sv-root-modern').style.height = 'fit-content'
          document.querySelector('.sv-root-modern').style.overflowY = 'auto'
          document.querySelector('.sv-root-modern').style.overflowX = 'hidden'

          if (root_height > window.innerHeight) {
            if (document.querySelector('.container11_outer')) {
              document.querySelector('.container11_outer').style.overflow = 'auto'
            }
            if (document.querySelector('.container11')) {
              document.querySelector('.container11').style.height = '100vh'
              document.querySelector('.container11').style.top = '0%'
            }
          } else {
            if (document.querySelector('.container11_outer')) {
              document.querySelector('.container11_outer').style.overflow = 'hidden'
            }
            if (document.querySelector('.container11')) {
              document.querySelector('.container11').style.height = 'fit-content'
            }

            // document.querySelector("#root").style.height = "100vh";
            // document.querySelector("#root").style.backgroundSize = "cover";
          }
        }
        if (this.state.Submit_mobile !== true) {
          if (document.querySelector('.sv-footer__next-btn')) {
            document.querySelector('.sv-footer').style.display = 'none'
            document.querySelector('.special_bottom_mobile').style.display = 'flex'
            document.querySelector('.icon_inner_outer_mobile').style.display = 'flex'
            document.querySelector('.icon_inner_right_outer').style.display = 'none'
            if (document.querySelector('.icon_inner_outer_mobile_complete')) {
              document.querySelector('.icon_inner_outer_mobile_complete').style.display = 'none'
            }
            if (this.state.Survey.surveyJson.questionsOnPageMode === 'questionPerPage') {
              if (document.querySelector('.Title_css')) {
                // document.querySelector(".Title_css").style.display = "none";
              }
            }
            if (document.querySelector('.sv-progress')) {
            }
          } else {
            if (document.querySelector('.icon_inner_outer_mobile')) {
              document.querySelector('.icon_inner_outer_mobile').style.display = 'none'
            }
            if (document.querySelector('.icon_inner_right_outer_mobile')) {
              document.querySelector('.icon_inner_right_outer_mobile').style.display = 'none'
            }
          }

          if (document.querySelector('.sv-footer__prev-btn')) {
            document.querySelector('.icon_inner_right_outer_mobile').style.display = 'flex'
            document.querySelector('.icon_inner_outer').style.display = 'none'
            document.querySelector('.sv-footer').style.display = 'none'
          } else {
            if (document.querySelector('.icon_inner_right_outer_mobile')) {
              document.querySelector('.icon_inner_right_outer_mobile').style.display = 'none'
            }
          }
          if (document.querySelector('.sv-footer__complete-btn')) {
            document.querySelector('.icon_inner_outer_mobile_complete').style.display = 'flex'
            document.querySelector('.icon_inner_outer_mobile_complete').style.display = 'flex'
            document.querySelector('.button_submit').style.display = 'none'
          } else {
            if (document.querySelector('.icon_inner_outer_mobile_complete')) {
              document.querySelector('.icon_inner_outer_mobile_complete').style.display = 'none'
            }
          }
        }
      })
    } catch (e) {}
  }
  componentDidUpdate(PrevProps, PrevState) {
    // // console.log("SurveyPublic -> componentDidUpdate -> PrevState", PrevState);

    try {
      if (PrevState.Model !== null) {
        this.NpsQuestioncase()
        this.TextAlignmentCss()

        if (window.innerWidth < 500) {
          if (document.querySelector('.sv-footer__next-btn')) {
            document.querySelector('.sv-footer').style.visibility = 'hidden'
          }
          if (document.querySelector('.sv-footer__prev-btn')) {
            document.querySelector('.sv-footer').style.visibility = 'hidden'
          }
          if (this.state.Survey.surveyJson.questionsOnPageMode === 'questionPerPage') {
            if (PrevState.Submit_mobile === this.state.Submit_mobile) {
              this.MobileDomChecker()
              this.DOMcheckerfunction()
              this.SpecialClasses()
              this.NPS_MOBIlE_ISSUE('asjdbkasbdjkasbdjkasbdjksabdjksabjkdas')
            } else {
            }
          } else {
            this.DOMcheckerfunction()
            this.MobileMulitpleQuestion()
            this.SpecialClasses()
          }
        } else {
          this.DOMcheckerfunction()
          this.SingleQuestionDOMChecker()
          this.SpecialClasses()
        }
      }
    } catch (e) {
      // // console.log("SurveyPublic -> componentDidUpdate -> e", e);
    }
  }

  render() {
    if (this.state.error === true) {
      return (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
          }}
        >
          <div style={{ margin: 'auto', textAlign: 'center' }}>Thank you for your feedback. This survey is currently closed</div>
        </div>
      )
    }

    if (this.state.model !== null && this.state.FirstPageStarter === true) {
      if (this.state.Survey.surveyJson.questionsOnPageMode === 'questionPerPage') {
        console.log('SurveyPublic -> render -> this.state', this.state)

        return (
          <>
            <div className="Outer_image"></div>
            <div className="Outer_image11"></div>
            <div
              // className="container11_outer container11_outer_not_single"
              className="container11_outer_not_single container11_singleQuestion"
              onClick={(e) => {
                console.log('SurveyPublic -> render -> e', e.target.className)
                console.log('SurveyPublic -> render -> e', typeof e.target.className)
                if (typeof e.target.className === 'string') {
                  console.log('SurveyPublic -> render -> e', e.target.className.split(' ')[0])

                  console.log("SurveyPublic -> render -> e.target.className.includes('sv-text')", e.target.className.includes('sv-text'))
                  if (e.target.className.includes('sv-text')) {
                  } else {
                    this.setState({ ClickEvent: !this.state.ClickEvent, ClickEventCount: this.state.ClickEventCount + 1 })
                  }
                } else {
                  this.setState({ ClickEvent: !this.state.ClickEvent, ClickEventCount: this.state.ClickEventCount + 1 })
                }
              }}
            >
              <Button
                className="start_button btn-wide btn-special-primary-user-hover"
                onClick={() => {
                  this.setState({ ClickEvent: !this.state.ClickEvent }, () => {
                    document.querySelector('.sv-footer__start-btn').click()
                  })
                }}
                style={{ display: 'none' }}
              >
                Let's Go
              </Button>
              <div
                // className="container11 container11_not_single_question"
                className="container11_not_single_question container11_singleQuestion11"
                style={{ background: 'transparent', margin: 'auto' }}
                onClick={() => {}}
              >
                <div className="icon back_icon">
                  <div className="icon_inner_outer">
                    <div
                      className="icon_inner"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        this.setState({ inProp1: true })
                        document.querySelector('.sv-footer__prev-btn').click()
                      }}
                    >
                      <ArrowForwardIosIcon
                        style={{
                          height: '1em',
                          width: '1em',
                          borderRadius: '50px',
                          color: 'white',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Single_question_container">
                  <Survey.Survey
                    model={this.state.model}
                    onClick={() => {
                      this.setState({ ClickEvent: !this.state.ClickEvent })
                    }}
                    onComplete={this.onComplete}
                    onValueChanged={this.onValueChanged}
                    css={this.state.Mycss}
                  />
                </div>

                <div className="icon next_icon">
                  <div className="button_submit">
                    <LaddaButton
                      data-style={ZOOM_IN}
                      loading={this.state.ButtonLoading}
                      disabled={this.state.buttondisable}
                      onClick={() => {
                        $(document).ready(() => {
                          if (document.querySelector('.sv-question__erbox')) {
                            this.setState({
                              ButtonLoading: false,
                              buttondisable: false,
                            })
                          } else {
                          }
                        })

                        this.setState(
                          {
                            ButtonLoading: true,
                            buttondisable: true,
                          },
                          () => {
                            document.querySelector('.sv-footer__complete-btn').click()
                          }
                        )
                      }}
                      className="btn-special-primary-user-hover btn-wide btn-submit btn"
                      style={{}}
                    >
                      Submit
                    </LaddaButton>
                  </div>
                  <div className="icon_inner_right_outer">
                    <div
                      className="icon_inner icon_inner_right"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        document.querySelector('.sv-footer__next-btn').click()
                        // this.setState({ ClickEvent: !this.state.ClickEvent })
                      }}
                    >
                      <ArrowForwardIosIcon
                        style={{
                          height: '1em',
                          width: '1em',
                          borderRadius: '50px',
                          color: 'white',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {window.innerWidth < 500 ? (
                <div className="special_bottom_mobile">
                  <div className="special_bottom_mobile_left_inner icon_inner_mobile">
                    <div
                      className="icon_inner_right_outer_mobile"
                      onClick={() => {
                        this.setState({ inProp1: true })
                        document.querySelector('.sv-footer__prev-btn').click()
                        // this.setState({ ClickEvent: !this.state.ClickEvent })
                      }}
                    >
                      <ChevronLeftIcon
                        style={{
                          color: 'white',
                          height: '1.5em',
                          width: '1.5em',
                        }}
                      />
                    </div>
                  </div>
                  <div className="special_bottom_mobile_middle">
                    <div className="logo"></div>
                  </div>
                  <div className="special_bottom_mobile_right_inner icon_inner_mobile">
                    <div
                      className="icon_inner_outer_mobile"
                      onClick={() => {
                        document.querySelector('.sv-footer__next-btn').click()
                        // this.setState({ ClickEvent: !this.state.ClickEvent })
                      }}
                    >
                      <ChevronRightIcon
                        style={{
                          color: 'white',
                          height: '1.5em',
                          width: '1.5em',
                        }}
                      />
                    </div>
                    <div
                      className="icon_inner_outer_mobile_complete"
                      onClick={() => {
                        document.querySelector('.sv-footer__complete-btn').click()
                        // this.setState({ ClickEvent: !this.state.ClickEvent })
                      }}
                    >
                      <CheckIcon
                        style={{
                          color: 'white',
                        }}
                        onClick={() => {
                          this.setState({ Submit_mobile: true }, () => {})
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </>
        )
      } else {
        return (
          <>
            <div className="Outer_image"></div>
            <div className="Outer_image11"></div>
            <div
              className="container11_outer_not_single"
              style={{
                display: 'flex',
              }}
              onClick={() => {
                // this.setState({ ClickEvent: !this.state.ClickEvent })
              }}
            >
              {/* <Button
                className="start_button btn-wide btn-special-primary-user-hover"
                style={{ display: "none" }}hea
                onClick={(e) => {
                  this.setState(
                    { ClickEvent: !this.state.ClickEvent, Start_click: true },
                    () => {
                      console.log(
                        "SurveyPublic -> render -> this.state",
                        this.state
                      );

                      document.querySelector(".sv-footer__start-btn").click();
                    }
                  );
                }}
              >
                Let's Go
              </Button> */}
              <div className="container11_not_single_question" style={{ background: 'transparent' }} onClick={() => {}}>
                <Survey.Survey
                  onClick={() => {
                    this.setState({ ClickEvent: !this.state.ClickEvent })
                  }}
                  model={this.state.model}
                  css={this.state.MyCss1}
                  onComplete={this.onComplete}
                  onValueChanged={this.onValueChanged}
                  onCurrentPageChanged={this.onCurrentPageChanged}
                />
              </div>
              {window.innerWidth < 500 ? (
                <div className="special_bottom_mobile">
                  <div className="special_bottom_mobile_left_inner icon_inner_mobile">
                    <div
                      className="icon_inner_right_outer_mobile"
                      onClick={() => {
                        this.setState({ inProp1: true })
                        document.querySelector('.sv-footer__prev-btn').click()
                      }}
                    >
                      <ArrowForwardIosIcon
                        style={{
                          color: 'white',
                        }}
                      />
                    </div>
                  </div>
                  <div className="special_bottom_mobile_middle">
                    <div className="logo"></div>
                  </div>
                  <div className="special_bottom_mobile_right_inner icon_inner_mobile">
                    <div
                      className="icon_inner_outer_mobile"
                      onClick={() => {
                        document.querySelector('.sv-footer__next-btn').click()
                      }}
                    >
                      <ArrowForwardIosIcon
                        style={{
                          color: 'white',
                        }}
                      />
                    </div>
                    <div
                      className="icon_inner_outer_mobile_complete"
                      onClick={() => {
                        document.querySelector('.sv-footer__complete-btn').click()
                      }}
                    >
                      <CheckIcon
                        style={{
                          color: 'white',
                        }}
                        onClick={() => {
                          this.setState({ Submit_mobile: true }, () => {})
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </>
        )
      }
    } else {
      // let displayState = 'block'
      // if (this.state.FirstPageStarter === true) {
      //   displayState = 'none'
      // }
      return (
        <div>
          <div className="Loader_class">
            {/* <div className="Loading_survey_image ">
              <img src={carwash_logo} alt={''}></img>
              {this.state.survey_logo === "" ? (
                <div>Loading...</div>
              ) : (
                <img src={carwash_logo}></img>
              )}
            </div> */}
            <div className="loader_text">Loading</div>
          </div>
        </div>
      )
    }
  }
}
const mapStateToProps = (state) => {
  return { state }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyPublic))
