import React, { Component } from 'react'
// import { Elastic } from 'react-burgers'
import { CardBody, FormGroup, Label, Input, FormFeedback, Card, Col, Row, CardHeader, Button, InputGroup, InputGroupAddon } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda'
import validator from 'validator'
import $ from 'jquery'
import swal from 'sweetalert'
// import { InputLabel, FormControl } from '@material-ui/core'
// import EditIcon from '@material-ui/icons/Edit'
// import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { connect } from 'react-redux'
// import InputAdornment from '@material-ui/core/InputAdornment'
import { withRouter } from 'react-router-dom'
// import Feedback from 'react-bootstrap/esm/Feedback'
import ProjectSettings from '../../../../global/Global'
class UserCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      User: this.props.state.LoginReducer11,
      token: this.props.state.LoginReducer11.response.token,
      Name: '',
      email: '',
      businessName: '',
      Newpassword: '',
      Newpasswordconfirm: '',

      businessNameErrorState: false,
      businessName_error_text: '',
      NameErrorState: false,
      Name_error_text: '',
      email_error_state: false,
      email_error_text: '',
      password_error_state: false,
      password_error_text: '',
      new_password_error_text: '',
      new_password_error_state: false,
      SavebuttondisableState: true,
      // NameState: true,
      // EmailState: true,
      // SavebuttondisableState: true,
      // CurrentPasswordvalid: false,
      // CurrentPassworinvalid: false,

      // LoadingState: false,
      // PasswordReadState: true,

      // passwordMatchErrorState: false,
      // ButtonLoading: false,
      // passwordmatcholdpasswordstate: false,
      // Newpasswordlengthstate: false,
      // passwordMatchErrormessageState: false,
    }
  }

  componentDidMount() {
    if (this.state.User === null) {
      this.props.history.push('/login')
    } else {
      if (this.state.User.response.user.role !== 'superadmin') {
        this.props.history.push('/login')
      }
    }

    $(document).ready(() => {
      document.querySelectorAll('.card-body').forEach((e) => {
        e.classList.add('card-body-user')
      })
    })
  }
  render() {
    return (
      <div className="outer-div">
        <div className="inner-div">
          <Card className="col-md-12 app-inner-layout__content">
            <CardHeader id="headingOne">
              <Button block color="link" className="text-left m-0 p-0" aria-controls="collapseOne">
                <h3 className="form-heading">
                  Create User
                  <p>Enter the user informations below</p>
                </h3>
              </Button>
            </CardHeader>

            <CardBody>
              <FormGroup>
                <Row form style={{}}>
                  <Col md={12}>
                    <Label for="Name">Name</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="Name"
                        id="Name"
                        value={this.state.Name}
                        // invalid={true}
                        invalid={this.state.NameErrorState}
                        onChange={(e) => {
                          this.setState({
                            Name: e.target.value,
                            NameErrorState: false,
                            SavebuttondisableState: false,
                          })
                        }}
                      ></Input>
                      <FormFeedback
                        style={{
                          display: this.state.NameErrorState ? `block` : `none`,
                        }}
                      >
                        {this.state.Name_error_text}
                      </FormFeedback>
                      <InputGroupAddon addonType="append"></InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>

              {/* new field businessname added below */}

              <FormGroup>
                <Row form style={{}}>
                  <Col md={12}>
                    <Label for="Name">Business Name</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="Name"
                        id="Name"
                        value={this.state.businessName}
                        // invalid={true}
                        invalid={this.state.businessNameErrorState}
                        onChange={(e) => {
                          this.setState({
                            businessName: e.target.value,
                            businessNameErrorState: false,
                            SavebuttondisableState: false,
                          })
                        }}
                      ></Input>
                      <FormFeedback
                        style={{
                          display: this.state.businessNameErrorState ? `block` : `none`,
                        }}
                      >
                        {this.state.businessName_error_text}
                      </FormFeedback>
                      <InputGroupAddon addonType="append"></InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="exampleEmail2">Email</Label>
                    <InputGroup>
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail2"
                        value={this.state.email}
                        invalid={this.state.email_error_state}
                        autoComplete="off"
                        onChange={(e) => {
                          this.setState({
                            SavebuttondisableState: false,
                            email: e.target.value,
                            email_error_state: false,
                          })
                        }}
                      />
                      <FormFeedback
                        style={{
                          display: this.state.email_error_state ? `block` : `none`,
                        }}
                      >
                        {this.state.email_error_text}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="examplePassword555">Current Password</Label>
                    <InputGroup>
                      <Input
                        valid={this.state.CurrentPasswordvalid}
                        invalid={this.state.CurrentPassworinvalid}
                        type="password"
                        name="password"
                        id="examplePassword555"
                        placeholder="password "
                        value={this.state.InputPassword}
                        onChange={(e) => {
                          this.setState(
                            { InputPassword: e.target.value },
                            () => {
                              setTimeout(() => {
                                this.setState({ LoadingState: true }, () => {
                                  console.log("Main -> render -> e", e);
                                  let User = {
                                    email: this.state.email,
                                    password: this.state.InputPassword,
                                  };

                                  fetch(
                                    "https://api.carwashsurveys.com/api/auth/signin",
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-type": "application/json",
                                      },
                                      body: JSON.stringify(User),
                                    }
                                  )
                                    .then((e) => {
                                      return e.json();
                                    })
                                    .then((e) => {
                                      console.log("Main -> render -> e", e);

                                      console.log(
                                        "Main -> render -> e.success",
                                        e.success
                                      );
                                      console.log(
                                        "Main -> render -> e.message",
                                        e.message
                                      );
                                      if (e.success !== undefined) {
                                        this.setState({
                                          CurrentPasswordvalid: true,
                                          CurrentPassworinvalid: false,
                                          LoadingState: false,
                                          PasswordReadState: false,
                                        });
                                      } else {
                                        console.log("Main -> render -> e", e);
                                        this.setState({
                                          CurrentPasswordvalid: false,
                                          CurrentPassworinvalid: true,
                                          LoadingState: false,
                                          PasswordReadState: true,
                                        });
                                      }
                                    })
                                    .catch((e) => {
                                      console.log("Main -> render -> e", e);
                                      swal({
                                        title: "something went wrong",
                                        icon: "warning",
                                      });
                                    });
                                });
                              }, 750);
                            }
                          );
                        }}
                      />

                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          style={{
                            display:
                              this.state.LoadingState === true
                                ? `block`
                                : `none`,
                          }}
                        >
                          <Spinner
                            color="primary"
                            className="spinner_special"
                            size="sm"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormFeedback>Incorrect password</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row> */}
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="examplePassword555">New Password</Label>
                    <Input
                      valid={false}
                      invalid={this.state.password_error_state}
                      value={this.state.Newpassword}
                      type="password"
                      name="password"
                      id="examplePassword555"
                      placeholder="New Password"
                      autoComplete="off"
                      onChange={(e) => {
                        this.setState({
                          Newpassword: e.target.value,
                          SavebuttondisableState: false,
                          password_error_state: false,
                        })
                      }}
                    />
                    <FormFeedback
                      style={{
                        display: this.state.password_error_state ? `block` : `none`,
                      }}
                    >
                      {this.state.password_error_text}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="examplePassword555">Confirm New Password</Label>
                    <Input
                      type="password"
                      name="password"
                      invalid={this.state.new_password_error_state}
                      id="examplePassword555"
                      value={this.state.Newpasswordconfirm}
                      placeholder="Confirm New Password"
                      onChange={(e) => {
                        this.setState({
                          Newpasswordconfirm: e.target.value,
                          SavebuttondisableState: false,
                          new_password_error_state: false,
                          password_error_state: false,
                        })
                      }}
                    />
                    <FormFeedback
                      style={{
                        display: this.state.new_password_error_state ? `block` : `none`,
                      }}
                    >
                      {this.state.new_password_error_text}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <LaddaButton
                data-style={ZOOM_IN}
                loading={this.state.ButtonLoading}
                color="primary"
                disabled={this.state.SavebuttondisableState}
                className="btn-wide mb-2 mr-2 btn-icon btn btn-primary btn-accept-modal btn-accept-account"
                onClick={() => {
                  const validPassword = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/)

                  this.setState({ ButtonLoading: true })
                  if (this.state.Name === '') {
                    this.setState({
                      ButtonLoading: false,
                      NameErrorState: true,
                      Name_error_text: 'Name cannot be empty',
                    })
                  } else {
                    if (this.state.businessName === '') {
                      this.setState({
                        ButtonLoading: false,
                        businessNameErrorState: true,
                        businessName_error_text: 'Business name cannot be empty',
                      })
                    } else {
                      if (this.state.email === '') {
                        this.setState({
                          ButtonLoading: false,
                          email_error_state: true,
                          email_error_text: 'Email cannot be empty',
                        })
                      } else {
                        if (!validator.isEmail(this.state.email)) {
                          this.setState({
                            ButtonLoading: false,
                            email_error_state: true,
                            email_error_text: 'Not a valid email',
                          })
                        } else {
                          if (this.state.Newpassword === '') {
                            this.setState({
                              ButtonLoading: false,
                              password_error_state: true,
                              password_error_text: 'Field cannot be empty',
                            })
                          } else {
                            if (this.state.Newpasswordconfirm === '') {
                              this.setState({
                                ButtonLoading: false,
                                new_password_error_state: true,
                                new_password_error_text: 'Field cannot be empty',
                              })
                            } else {
                              if (this.state.Newpassword !== this.state.Newpasswordconfirm) {
                                this.setState({
                                  ButtonLoading: false,
                                  new_password_error_state: true,
                                  new_password_error_text: 'Does not match the New Password',
                                })
                              } else {
                                if (!validPassword.test(this.state.Newpassword) || !validPassword.test(this.state.Newpasswordconfirm)) {
                                  this.setState({
                                    ButtonLoading: false,
                                    new_password_error_state: true,
                                    new_password_error_text:
                                      'Password must have 7 characters, and must contain lower case letter, upper case letter, and number, and special character.',
                                  })
                                } else {
                                  let User = {
                                    name: this.state.Name,
                                    email: this.state.email,
                                    password: this.state.Newpassword,
                                    businessName: this.state.businessName,
                                  }
                                  const REGISTER_API_ENDPOINT = ProjectSettings.baseURL + '/api/auth/signup'

                                  const parameter = {
                                    method: 'POST',
                                    headers: {
                                      'Content-type': 'application/json',
                                    },
                                    body: JSON.stringify(User),
                                  }
                                  fetch(REGISTER_API_ENDPOINT, parameter)
                                    .then((e) => {
                                      return e.json()
                                    })
                                    .then((e) => {
                                      let LoginReducer = e
                                      if (LoginReducer !== null) {
                                        if (LoginReducer.success === true) {
                                          swal({
                                            title: LoginReducer.message,
                                            icon: 'success',
                                          }).then((e) => {
                                            this.props.history.push(`/super-admin`)
                                          })
                                          this.setState({
                                            ButtonLoading: false,
                                            Name: '',
                                            email: '',

                                            Newpassword: '',
                                            Newpasswordconfirm: '',

                                            NameErrorState: false,
                                            Name_error_text: '',
                                            email_error_state: false,
                                            email_error_text: '',
                                            password_error_state: false,
                                            password_error_text: '',
                                            new_password_error_text: '',
                                            new_password_error_state: false,
                                            SavebuttondisableState: true,
                                          })
                                          // this.setState({
                                          //   UseralreadyExisterror: {
                                          //     state: true,
                                          //     message: LoginReducer.response.message,
                                          //     type: "success",
                                          //   },
                                          // });
                                        } else {
                                          swal({
                                            title: LoginReducer.message,
                                            icon: 'warning',
                                          })
                                          this.setState({
                                            ButtonLoading: false,
                                          })
                                          // this.setState({
                                          //   UseralreadyExisterror: {
                                          //     state: true,
                                          //     message: LoginReducer.response.message,
                                          //     type: "error",
                                          //   },
                                          // });
                                        }
                                      }
                                    })
                                    .catch((e) => {
                                      console.log('UserCreate -> e', e)
                                      swal({
                                        title: 'Something went wrong',
                                        icon: 'warning',
                                      })
                                    })
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }}
              >
                {' '}
                Save
              </LaddaButton>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserCreate))
