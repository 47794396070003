import React, { Component } from 'react'

import * as Survey from 'survey-react'
import * as SurveyKo from 'survey-knockout'
import * as SurveyJSCreator from 'survey-creator'
// import svg1 from '../../assets/utils/images/verified.svg'
import 'survey-creator/survey-creator.css'
// import * as RModal from 'react-modal'
// import Modal from "react-bootstrap/Modal";
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
import Swal from 'sweetalert2'

import Switch from '@material-ui/core/Switch'
import ToolTip from '../../Component/tooltip'
import ReactTooltip from 'react-tooltip'
import { LogoChecker } from '../utils'

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  // ButtonDropdown,
  // Progress,
  Input,
  UncontrolledButtonDropdown,
  FormGroup,
  FormFeedback,
} from 'reactstrap'
import LaddaButton, {
  // EXPAND_LEFT,
  // EXPAND_RIGHT,
  // EXPAND_UP,
  // EXPAND_DOWN,
  // SLIDE_LEFT,
  // SLIDE_RIGHT,
  // SLIDE_UP,
  // SLIDE_DOWN,
  ZOOM_IN,
  // ZOOM_OUT,
} from 'react-ladda'
import { LoaderActions_loadingtrue, LoaderActions_loadingfalse } from '../../redux/actions/Loader'
// import { SurveyOptionActions, SurveyOPtionAction1 } from '../../redux/actions/SurveyOption'
import ProjectSettings from '../../global/Global'
import Loader11 from '../Loader'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'jquery-ui/themes/base/all.css'
import 'nouislider/distribute/nouislider.css'
import 'select2/dist/css/select2.css'
// import "bootstrap-slider/dist/css/bootstrap-slider.css";

import 'jquery-bar-rating/dist/themes/css-stars.css'
import 'jquery-bar-rating/dist/themes/fontawesome-stars.css'
import { connect } from 'react-redux'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker.js'
import 'select2/dist/js/select2.js'
import 'jquery-bar-rating'
import { withRouter } from 'react-router-dom'

//import "icheck/skins/square/blue.css";
import 'pretty-checkbox/dist/pretty-checkbox.css'

import * as widgets from 'surveyjs-widgets'
// import CSVReader from 'react-csv-reader'
import Questions from './QuestionBar'
import './test4.css'

// import { addSurvey, SurveyEdit } from '../../redux/actions/Survey'
// import { Link } from 'react-router-dom'
// import { CouponCodeaction } from '../../redux/actions/Coupon_Code'
import Reader from '../Manage_coupon_code.js'
import SweetAlert from 'sweetalert-react'
import swal from 'sweetalert'
// import variable from '../../global/font'
// import Cloud_image from '../../assets/cloud-computing.svg'
// import delete_image from '../../assets/delete.svg'
// import Tick from '../../assets/checked.svg'
// import tag from '../../assets/tag.svg'
import tag_icon from '../../assets/tag_icon.png'

import save_icon from '../../assets/save_icon.svg'
SurveyJSCreator.StylesManager.applyTheme('modern')
Survey.StylesManager.applyTheme('modern')

widgets.icheck(SurveyKo, $)
widgets.prettycheckbox(SurveyKo)
widgets.select2(SurveyKo, $)
widgets.inputmask(SurveyKo)
widgets.jquerybarrating(SurveyKo, $)
widgets.jqueryuidatepicker(SurveyKo, $)
widgets.nouislider(SurveyKo)
widgets.select2tagbox(SurveyKo, $)
// widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo)
widgets.ckeditor(SurveyKo)
widgets.autocomplete(SurveyKo, $)
widgets.bootstrapslider(SurveyKo)

class SurveyEditortest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SurveyPreview: null,
      showState: false,
      closeState: true,
      number: 0,
      addCoupon: false,
      showInfodiv: false,
      savedSurveyjson: null,
      status: null,
      FileUploadeState: null,
      saveSurveyName: null,
      LogoUploaded: '',
      surveyName: '',
      surveyNameTemp: '',
      surveyNameValid: false,
      surveyNameInvalid: false,
      SurveyState: 'Draft',
      Coupon_codeNumber: null,
      SurveyEventListener: false,
      AddCouponClicked: false,
      LogoState: '',
      AddCouponError: null,
      Surveyfrommid: {},
      Newsurveyid: null,
      Checked: false,
      InitialCouponCode: null,
      _InitialCouponCode: null,
      SavedSurveyId: null,
      InsideModalState: false,
      EdgeCaseError: null,
      EdgeCaseErrorState: false,
      QuestionChecker: 0,
      SurveyQuestionNumber: 0,
      FileUploadError: false,
      FileUploadMessage: '',
      reloadState: false,
      backState: false,
      error_case: false,
      ButtonLoading: false,
      ButtonLoadingLogo: false,
      color: '',
      error_case_count: 0,
      NoCouponCodeError: {
        state: false,
        error: '',
      },
      default_upload_state_out: false,
      Coupon_code_details: {},
      questionCounter: 1,
      Coupon_state: '',
      lagest_question_number: 0,
      email_question_add: false,
      Coupon_code_details_top: {},
      FileSize: 0,
      top_delete_state: false,
      bottom_delete_state: false,
      intial_display_state: true,
      Hover_state_button: false,
    }
  }
  surveyCreator
  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
      var reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  LogoUpload = (file) => {
    let LogoUploaded = file.target.files[0]
    let reader = new FileReader()
    if (LogoUploaded) {
      // reader.readAsDataURL(file.target.files[0])
      reader.readAsText(file.target.files[0])
      reader.onloadend = async (event) => {
        this.Loader_visible()
        console.log('SurveyEditortest -> reader.onloadend -> event', event)
        if (event.total / 1024 > 50) {
          Swal.fire({
            title: 'Warning',
            text: 'the file uploaded should be less than 50kb for better performance',
            showCancelButton: true,
            confirmButtonText: `It's ok`,
            customClass: {
              cancelButton: `swal-button swal-button--cancel swal_button_cancel`,
              confirmButton: `swal-button swal-button--catch swal_button_accept`,
            },
          }).then(async (e) => {
            console.log('SurveyEditortest -> componentDidMount -> e', e)
            if (e.isConfirmed) {
              console.log('SurveyEditortest -> reader.onloadend -> fetchcall')

              let Form = new FormData()
              Form.append('surveyId', this.state.Surveyfrommid.surveyId)
              Form.append('profileImage', LogoUploaded)
              for (let pair of Form) {
                console.log('updateProfile -> data', pair)
              }

              const API = ProjectSettings.baseURL + '/api/survey/CreatelogoImg'
              const Response = await fetch(API, {
                method: 'POST',
                headers: {
                  // 'Content-type': 'multipart/form-data',
                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                },
                body: Form,
              })
              const Respons1 = await Response.json()
              console.log('SurveyEditortest -> reader.onloadend -> Respons1', Respons1)
              if (Respons1.success) {
                console.log('SurveyEditortest -> reader.onloadend -> Respons1', Respons1)

                this.setState(
                  {
                    LogoUploaded: event.target.result,
                  },
                  async () => {
                    console.log('SurveyEditortest -> reader.onloadend -> Respons1', Respons1)

                    let File = await this.toBase64(LogoUploaded)
                    console.log('SurveyEditortest -> reader.onloadend -> File', File)

                    this.surveyCreator.JSON = {
                      ...this.surveyCreator.JSON,
                      logo: File,
                    }
                    if (this.surveyCreator.JSON.logo === this.state.LogoUploaded) {
                      console.log('SurveyEditortest -> reader.onloadend -> Respons1', Respons1)

                      this.Loader_hide()
                      this.setState({
                        ButtonLoadingLogo: false,
                      })
                    }
                  }
                )
              } else {
                this.Loader_hide()
                this.setState({
                  ButtonLoadingLogo: false,
                })
                swal({
                  title: Respons1.message,
                  icon: 'error',
                })
              }

              // .then((response) => {

              // })
              // .catch((error) => {

              // })
            }
          })
        } else {
          let File = await this.toBase64(LogoUploaded)

          this.surveyCreator.JSON = {
            ...this.surveyCreator.JSON,
            logo: File,
          }
        }
        // let Result = event.target.result.toString('base64')
      }
    }
  }

  Save_survey = async () => {
    this.setState(
      {
        ButtonLoading: true,
        // savedSurveyjson: this.surveyCreator.JSON,
      },
      async () => {
        let Date_local = new Date()

        if (this.state.LogoState === 'Delete') {
          const DELETE_API = ProjectSettings.baseURL + `/api/survey/DeleteLomoimg/${this.state.Surveyfrommid.surveyId}`
          fetch(DELETE_API, {
            method: 'PUT',
            headers: {
              // 'Content-type': 'multipart/form-data',
              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
            },
          })
            .then((response) => {
              return response.json()
            })
            .then((r) => {
              console.log('SurveyEditortest -> Save_survey -> r', r)

              let body11 = JSON.stringify({
                surveyName: this.state.surveyName,
                surveyJson: this.surveyCreator.JSON,
                couponCode: this.state.InitialCouponCode,
                surveyId: this.state.Surveyfrommid.surveyId,
                status: this.state.SurveyState,
                TimeStampLocal: Date_local,
                CouponCodeStatus: this.state.Checked === true ? 'Active' : 'Inactive',
              })

              var API_END_POINT = ProjectSettings.baseURL + '/api/survey'

              fetch(API_END_POINT, {
                method: 'PUT',
                headers: {
                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                  'Content-type': 'application/json',
                  mode: 'cors',
                },
                body: body11,
              })
                .then((response) => {
                  return response.json()
                })
                .then((json) => {
                  this.setState({
                    SavedSurveyId: this.state.Surveyfrommid.surveyId,
                  })

                  this.setState({ saveSurveyName: false })
                  // this.surveyCreator.JSON = null
                  if (this.state.SurveyState !== `Draft`) {
                    let body11 = JSON.stringify({
                      surveyId: this.state.SavedSurveyId,
                      status: this.state.SurveyState,
                    })

                    fetch(ProjectSettings.baseURL + '/api/survey/status', {
                      method: 'POST',
                      headers: {
                        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                        'Content-type': 'application/json',
                      },
                      body: body11,
                    })
                      .then((e) => {
                        return e.json()
                      })
                      .then((e) => {})
                      .catch((e) => {})
                  }
                  this.setState(
                    {
                      ButtonLoading: false,
                    },
                    () => {
                      swal({
                        icon: 'success',
                        title: 'Survey Saved Successfully',
                      })
                    }
                  )
                  // this.props.history.push('/dashboard/1')
                })
                .catch((e) => {
                  this.setState({
                    ButtonLoading: false,
                  })
                  swal({
                    title: 'Something went wrong',
                    icon: 'error',
                  })
                })
            })
        }
        if (this.state.FileSize > 50000) {
          const DELETE_API = ProjectSettings.baseURL + `/api/survey/DeleteLomoimg/${this.state.Surveyfrommid.surveyId}`
          fetch(DELETE_API, {
            method: 'PUT',
            headers: {
              // 'Content-type': 'multipart/form-data',
              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
            },
          })
            .then((response) => {
              return response.json()
            })
            .then(async (response) => {
              console.log('SurveyEditortest -> componentDidMount -> response', response)

              let ext = this.surveyCreator.JSON.logo.split(',')[0].split(';')[0].split('/')[1]
              let date = Date.now()
              date = date + '_' + 'secret' + '.' + ext
              const file = await this.dataURLtoFile(this.surveyCreator.JSON.logo, date)
              let Form = new FormData()
              Form.append('surveyId', this.state.Surveyfrommid.surveyId)
              Form.append('profileImage', file)
              for (let pair of Form) {
                console.log('updateProfile -> data', pair)
              }
              const API = ProjectSettings.baseURL + '/api/survey/CreatelogoImg'
              fetch(API, {
                method: 'POST',
                headers: {
                  // 'Content-type': 'multipart/form-data',
                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                },
                body: Form,
              })
                .then((response) => {
                  return response.json()
                })
                .then((response) => {
                  console.log('SurveyEditortest -> componentDidMount -> response', response)
                  this.setState(
                    {
                      ButtonLoading: false,
                      // savedSurveyjson: this.surveyCreator.JSON,
                    },
                    () => {
                      this.surveyCreator.JSON = {
                        ...this.surveyCreator.JSON,
                        logoPosition: 'top',
                        logo: '',
                      }
                    }
                  )
                  let body11 = JSON.stringify({
                    surveyName: this.state.surveyName,
                    surveyJson: this.surveyCreator.JSON,
                    couponCode: this.state.InitialCouponCode,
                    surveyId: this.state.Surveyfrommid.surveyId,
                    status: this.state.SurveyState,
                    TimeStampLocal: Date_local,
                    CouponCodeStatus: this.state.Checked === true ? 'Active' : 'Inactive',
                  })

                  var API_END_POINT = ProjectSettings.baseURL + '/api/survey'

                  fetch(API_END_POINT, {
                    method: 'PUT',
                    headers: {
                      Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                      'Content-type': 'application/json',
                      mode: 'cors',
                    },
                    body: body11,
                  })
                    .then((response) => {
                      return response.json()
                    })
                    .then((json) => {
                      this.setState({
                        SavedSurveyId: this.state.Surveyfrommid.surveyId,
                      })

                      this.setState({ saveSurveyName: false })
                      // this.surveyCreator.JSON = null
                      if (this.state.SurveyState !== `Draft`) {
                        let body11 = JSON.stringify({
                          surveyId: this.state.SavedSurveyId,
                          status: this.state.SurveyState,
                        })

                        fetch(ProjectSettings.baseURL + '/api/survey/status', {
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                            'Content-type': 'application/json',
                          },
                          body: body11,
                        })
                          .then((e) => {
                            return e.json()
                          })
                          .then((e) => {})
                          .catch((e) => {})
                      }
                      this.setState(
                        {
                          ButtonLoading: false,
                        },
                        () => {
                          swal({
                            icon: 'success',
                            title: 'Survey Saved Successfully',
                          })
                        }
                      )

                      // this.props.history.push('/dashboard/1')
                    })
                    .catch((e) => {
                      this.setState({
                        ButtonLoading: false,
                      })
                      swal({
                        title: 'Something went wrong',
                        icon: 'error',
                      })
                    })
                })
            })
        } else {
          let body11 = JSON.stringify({
            surveyName: this.state.surveyName,
            surveyJson: this.surveyCreator.JSON,
            couponCode: this.state.InitialCouponCode,
            surveyId: this.state.Surveyfrommid.surveyId,
            status: this.state.SurveyState,
            TimeStampLocal: Date_local,
            CouponCodeStatus: this.state.Checked === true ? 'Active' : 'Inactive',
          })

          var API_END_POINT = ProjectSettings.baseURL + '/api/survey'

          fetch(API_END_POINT, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
              'Content-type': 'application/json',
              mode: 'cors',
            },
            body: body11,
          })
            .then((response) => {
              return response.json()
            })
            .then((json) => {
              this.setState({
                SavedSurveyId: this.state.Surveyfrommid.surveyId,
              })

              this.setState({ saveSurveyName: false })
              this.surveyCreator.JSON = null
              if (this.state.SurveyState !== `Draft`) {
                let body11 = JSON.stringify({
                  surveyId: this.state.SavedSurveyId,
                  status: this.state.SurveyState,
                })

                fetch(ProjectSettings.baseURL + '/api/survey/status', {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                    'Content-type': 'application/json',
                  },
                  body: body11,
                })
                  .then((e) => {
                    return e.json()
                  })
                  .then((e) => {})
                  .catch((e) => {})
              }
              this.setState(
                {
                  ButtonLoading: false,
                },
                () => {
                  swal({
                    icon: 'success',
                    title: 'Survey Saved Successfully',
                  })
                }
              )

              // this.props.history.push('/dashboard/1')
            })
            .catch((e) => {
              this.setState({
                ButtonLoading: false,
              })
              swal({
                title: 'Something went wrong',
                icon: 'error',
              })
            })
        }
      }
    )

    // let body111 = {
    //   surveyName: this.state.surveyName,
    //   surveyJson: this.surveyCreator.JSON,
    //   couponCode: this.state.InitialCouponCode,
    //   status: this.state.SurveyState,
    //   surveyId: this.state.Surveyfrommid.surveyId,
    //   CouponCodeStatus: this.state.Checked === true ? 'Active' : 'Inactive',
    // }
  }

  SurveyfromId = async (data) => {
    if (data.responseData.CouponCodeStatus === 'Active') {
      this.setState({ Coupon_state: true, Checked: true })
    } else {
      this.setState({ Coupon_state: false })
    }
    let temp = data.responseData
    if (temp.couponCode === null) {
      temp.couponCode = []
    }
    if (temp.couponCodeUsed === null) {
      temp.couponCodeUsed = []
    }
    this.setState(
      {
        Surveyfrommid: temp,
        InitialCouponCode: data.responseData.couponCode === null ? [] : data.responseData.couponCode,
        surveyName: data.responseData.surveyName,
        surveyNameTemp: data.responseData.surveyName,
        SurveyState: data.responseData.status,
      },
      () => {
        this.surveyCreator.JSON = this.state.Surveyfrommid.surveyJson
        // console.log('SurveyEditortest -> SurveyfromId -> this.state.Surveyfrommid.surveyJson', this.state.Surveyfrommid.surveyJson)

        // let new_array = []
        let status = data.responseData.status
        let color = ''
        if (status === 'Draft') {
          color = 'warning'
        } else {
          color = 'success'
        }
        this.setState({
          questionCounter: this.surveyCreator.getAllQuestions().length,
          color: color,
        })
        $(document).ready(() => {
          this.RealDomchanges()
          this.AddedDomchanges()
        })

        if (this.state.backState === true) {
          this.surveyCreator.JSON = this.props.state.SurveyOptionReducer.json
        }
        this.Loader_hide()
        return true
      }
    )

    return true
  }
  Loader_visible = () => {
    this.props.dispatch(LoaderActions_loadingtrue())
  }

  Loader_hide = () => {
    setTimeout(() => {
      this.props.dispatch(LoaderActions_loadingfalse())
    }, 1500)
  }

  RealDomchanges = () => {
    // document.querySelectorAll(".svd_container *").forEach((container) => {
    //   container.style.fontFamily = variable.font;
    // });
    $(document).ready(() => {
      if (this.surveyCreator.JSON.logo === undefined) {
        if (document.querySelector('.svda-title-actions')) {
          var parent_element_add_logo = document.querySelector('.svda-title-actions').parentElement
          parent_element_add_logo.appendChild(document.querySelector('.svg_icon_add_logo'))
          document.querySelector('.svda-title-actions').style.display = 'none' //removing the add icon default icon
          document.querySelectorAll('.svda_current_type').forEach((e) => {
            e.style.display = 'none'
          })
        }
      }

      document.querySelectorAll('.svd-designer-tabbed-container__tab-header').forEach((e, i) => {
        if (i === 0) {
          e.innerText = 'Question Toolbox'
        }
        if (i === 1) {
          e.innerText = 'Settings'
        }
      })
      if (document.querySelector('.svd-designer-tabbed-container__tab-header')) {
        if (document.querySelector('.svd-designer-tabbed-container__tab-header').innerText === 'Add Question') {
          document.querySelector('.svd-designer-tabbed-container__tab-header').innerText = 'Question Toolbox'
        }
      }

      document.querySelectorAll('.btn-primary').forEach((e) => {
        e.style.borderRadius = '0px'
        // e.style.backgroundColor = "#3185d6";
        e.style.border = '0.5px solid #3185d6'
      })
      // document.querySelectorAll('.svda_question_action')[0].click() //file option to add logo
      document.querySelector('svd-pages-editor').appendChild(document.querySelector('#special_button'))
      document.querySelector('.svd_toolbar').appendChild(document.querySelector('#Coupon_code_button'))
      document.querySelectorAll('.svd-page-selector').forEach((e, i) => {
        if (e.id !== 'special_button') {
          e.style.display = 'none'
        }
      })
      document.querySelector('.app-main__inner').style.padding = '0px 0px 0px 0px'
      document.querySelector('.svd_commercial_container').style.display = 'none'
      document.querySelector('.svd_container .svd_content').style.padding = '0px 0px 0px 0px'
      document.querySelector('.svd-designer-container').style.width = '100%'
      document.querySelector('.svd-designer-container--left-side .svd_toolbox').style.margin = '0px'
      document.querySelector('.svd-designer-tabbed-container__tab-header').innerHTML = 'Add Question'
      document.querySelector(
        '.svd-designer-container--left-side .svd-icon-container--left-open, .svd-designer-container--left-side .svd-icon-container--left-close'
      ).style.display = 'none'
      document.querySelector('.svd-icon-container--left-close').style.display = 'none'
      document.querySelector('svd-splitter div.svd-splitter').style.display = 'none'
      document.querySelector('.svd-icon-container--right-close').style.display = 'none'
      document.querySelector('.svd-svg-icon').style.display = 'none'
      document.querySelector('.svd_editors').style.padding = '90px 0px 0px 0px'
      document.querySelector('.svd-vertical-container__cell-content').style.overflowY = 'hidden'
      document.querySelector('.svd-designer-container').style.display = 'block'
      var new_side_container = document.querySelector('.side_question_navbar')
      var side_container = document.querySelector('.svd_container .svd_toolbox')
      document.querySelector('.svd_editors').style.width = '50%'
      document.querySelector('.svd_editors').style.maxWidth = '50%'
      var side_container_parent = side_container.parentElement
      side_container.style.display = 'none'
      side_container_parent.appendChild(new_side_container)
      document.querySelector('svd-splitter').style.width = '0px'
      var vertical_container = document.querySelectorAll('.svd-vertical-container')
      for (var i = 0; i < vertical_container.length; i++) {
        vertical_container[1].style.backgroundColor = 'white'
        vertical_container[2].style.backgroundColor = 'white'
        vertical_container[3].style.backgroundColor = 'transparent'
        vertical_container[3].style.padding = '0px 30px 0px 30px'
      }
      document.querySelector('.svd_selected_page').style.backgroundColor = 'white'
      document.querySelectorAll('.svd_container [draggable]').forEach((span) => {
        span.style.padding = '10px 20px'
      })
      // let ListDiv = document.querySelector('.navbar-default')
      // document.querySelector(".navbar-default").style.display = "flex";
      // document.querySelector(".navbar-default").style.justifyContent =
      //   "space-between";
      document.querySelector('svd-splitter').remove()
      // document
      //   .querySelector(".navbar-default")
      //   .appendChild(document.querySelector(".Inside_list_div"));
      // document.querySelector(".Inside_list_div").style.display = "flex";
      // document.querySelector(".Inside_list_div").style.justifyContent =
      //   "flex-start";
      document.querySelector('#svd-survey-settings').style.display = 'none'
      // document
      //   .querySelector(".Inside_list_div")
      //   .append(ListDiv.children[0], ListDiv.children[1]);
      // document
      //   .querySelector(".navbar-default")
      //   .appendChild(document.querySelector(".Save_button"));
    })
  }

  fetchCall = async () => {
    let API_END_POINT = `${ProjectSettings.baseURL}/api/survey/details/${this.props.match.params.id}`

    const response = await fetch(API_END_POINT)
    if (response.status === 200) {
      const res1 = await response.json()
      const response1 = await this.SurveyfromId(res1)
      console.log(response1)
      return true
    } else return false

    // fetch(API_END_POINT)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     this.SurveyfromId(data)

    //     return data
    //   })
  }
  AddedDomchanges = () => {}
  componentWillMount() {
    this.Loader_visible()
    if (this.props.state.LoginReducer11 === null) {
      this.props.history.push('/login')
    }
    this.fetchCall()
  }

  getlogofromdb = () => {
    this.setState(
      {
        ButtonLoadingLogo: true,
      },
      async () => {
        const API = ProjectSettings.baseURL + `/api/survey/GetLogoimg/${this.props.match.params.id}`
        fetch(API, {
          method: 'GET',
          headers: {
            // 'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
          },
        })
          .then((response) => {
            return response.json()
          })
          .then((response) => {
            console.log('SurveyEditortest -> getlogofromdb -> response', response)
            if (response.success) {
              this.setState(
                {
                  LogoState: 'Add',
                },
                () => {
                  let ImgUrl = response.message.logo.base64
                  let FinalUrl = ImgUrl.replace('public', 'static')
                  this.toDataUrl(`${ProjectSettings.baseURL}${FinalUrl}`, (base64) => {
                    console.log('SurveyEditortest -> getlogofromdb -> base64', base64.length)
                    console.log('SurveyEditortest -> getlogofromdb -> base64', base64.slice(-2))
                    let y
                    base64.slice(-2) === '==' ? (y = 2) : (y = 1)
                    let Size = base64.length * (3 / 4) - y
                    this.surveyCreator.JSON = {
                      ...this.surveyCreator.JSON,
                      logoPosition: 'top',
                      logo: base64,
                    }
                    if (this.surveyCreator.JSON.logo.length > 1) {
                      this.setState({
                        ButtonLoadingLogo: false,
                        FileSize: Size,
                      })
                    }
                  })
                }
              )
            } else {
              this.setState({
                ButtonLoadingLogo: false,
              })
            }
          })
      }
    )
  }
  componentDidMount() {
    let options = {
      showEmbededSurveyTab: false,
      showJSONEditorTab: false,
      showTestSurveyTab: false,
      showSurveyTitle: 'always',
      showPageTitle: 'never',
      showPageDescription: 'never',
    }
    SurveyKo.Serializer.findProperty('page', 'title').visible = false
    SurveyKo.Serializer.findProperty('page', 'description').visible = false

    SurveyKo.Serializer.addProperty('rating', {
      name: 'tag11:number',
      default: 1,
      category: 'general',
      edit: false,
      visibleIf: function (obj) {
        let returnVariable
        if (obj.getType() === 'rating') {
          if (obj.tag11 === 200) {
            returnVariable = true
          } else {
            returnVariable = false
          }
        } else {
          returnVariable = false
        }
        return returnVariable
      },
    })

    this.surveyCreator = new SurveyJSCreator.SurveyCreator(null, options)

    // this.surveyCreator.JSON = {
    //   pages: [
    //     {
    //       name: 'page1',
    //       elements: [
    //         { type: 'text', name: 'Question1' },
    //         { type: 'checkbox', name: 'Question2', choices: ['item1', 'item2', 'item3'] },
    //         {
    //           type: 'radiogroup',
    //           name: 'Question3',
    //           title: 'The company made it easy for me to handle my issue.',
    //           isRequired: true,
    //           choices: [' Strongly Agree', ' Agree', 'Somewhat Agree', 'Neutral', 'Somewhat Disagree', 'Disagree', 'Strongly Disagree'],
    //         },
    //       ],
    //     },
    //     { name: 'last_page', elements: [{ type: 'text', name: 'Question5', title: 'Email', isRequired: true, inputType: 'email' }] },
    //   ],
    // }
    // const something = await this.fetchCall()

    // this.surveyCreator.toolbox.addProperty("question", {
    //   name: "tag:number",
    //   default: 1,
    //   category: "general",
    //   enabled: false,
    // });
    // this.surveyCreator.saveSurveyFunc = this.saveMySurvey;
    this.surveyCreator.showToolbox = 'left'
    this.surveyCreator.showPropertyGrid = 'left'
    this.surveyCreator.rightContainerActiveItem('toolbox')
    this.surveyCreator.survey.getPropertyValue('showQuestionNumbers', 'off')
    this.surveyCreator.inplaceEditForValues = 'true'
    this.surveyCreator.survey.cssValue.checkbox = {
      ...this.surveyCreator.survey.cssValue.checkbox,
      item: this.surveyCreator.survey.cssValue.checkbox.item + ' sv_checkbox_css_dashboard',
    }
    this.surveyCreator.survey.cssValue.rating = {
      ...this.surveyCreator.survey.cssValue.rating,
      item: this.surveyCreator.survey.cssValue.rating.item + ' sv_rating_css_dashboard',
      itemText: this.surveyCreator.survey.cssValue.rating.itemText + ' sv-rating__item-text_dashboard',
    }
    this.surveyCreator.survey.cssValue.radiogroup = {
      ...this.surveyCreator.survey.cssValue.radiogroup,
      item: this.surveyCreator.survey.cssValue.radiogroup.item + ' sv-radio_dashboard',
    }

    this.surveyCreator.survey.css.checkbox = {
      ...this.surveyCreator.survey.css.checkbox,
      item: this.surveyCreator.survey.css.checkbox.item + ' sv_checkbox_css_dashboard',
    }
    this.surveyCreator.survey.css.rating = {
      ...this.surveyCreator.survey.css.rating,
      item: this.surveyCreator.survey.css.rating.item + ' sv_rating_css_dashboard',
      itemText: this.surveyCreator.survey.css.rating.itemText + ' sv-rating__item-text_dashboard',
    }
    this.surveyCreator.survey.css.radiogroup = {
      ...this.surveyCreator.survey.css.radiogroup,
      item: this.surveyCreator.survey.css.radiogroup.item + ' sv-radio_dashboard',
    }

    this.surveyCreator.survey.cssValue.radiogroup.item = this.surveyCreator.survey.cssValue.radiogroup.item + ' sv-radio_dashboard'
    this.surveyCreator.survey.css.radiogroup.item = this.surveyCreator.survey.css.radiogroup.item + ' sv-radio_dashboard'

    this.surveyCreator.onQuestionAdded.add((sender, option) => {
      sender.survey.cssValue.checkbox = {
        ...sender.survey.cssValue.checkbox,
        item: sender.survey.cssValue.checkbox.item + ' sv_checkbox_css_dashboard',
      }
      sender.survey.cssValue.rating = {
        ...sender.survey.cssValue.rating,
        item: sender.survey.cssValue.rating.item + ' sv_rating_css_dashboard',
        itemText: sender.survey.cssValue.rating.itemText + ' sv-rating__item-text_dashboard',
      }
      sender.survey.cssValue.radiogroup = {
        ...sender.survey.cssValue.radiogroup,
        item: sender.survey.cssValue.radiogroup.item + ' sv-radio_dashboard',
      }
      this.surveyCreator.survey.getAllQuestions().forEach((e, i) => {
        e.name = 'Question' + (i + 1)
      })
      this.setState({
        questionCounter: this.surveyCreator.survey.getAllQuestions().length + 1,
      })
      var q = option.question

      // var t = q.getType()

      if (this.state.email_question_add === true) {
        q.tag = guid()
        q.name = 'Question' + this.state.questionCounter

        q.isRequired = true
        q.inputType = 'email'
        q.title = 'Email'
        this.setState({
          email_question_add: false,
        })
      } else {
        q.name = 'Question' + this.state.questionCounter

        q.tag = guid()
        if (q.title.includes('The company made it easy for')) {
          q.SpecialProperty = true
        }
        if (q.title.includes('How would you rate your experience')) {
          q.SpecialProperty = true
        }
      }
    })

    // this.surveyCreator.toolbarItems.push({
    //   id: "custom-preview",
    //   visible: true,
    //   title: "Survey Preview",
    //   json: this.surveyCreator,
    //   action: (obj) => {
    //     var testSurveyModel = new Survey.Model(obj.json.JSON);

    //     this.setState({ SurveyPreview: testSurveyModel, showState: true });
    //   },
    // });

    // this.surveyCreator.tabs().unshift({
    //   name: "survey-templates", //the unique tab name
    //   title: "Survey Templates", //the tab title
    //   template: "custom-tab-survey-templates", //you will see the knockout template in the HTML tab
    //   action: (item) => {
    //     //change the active tab to this one on clicking the tab
    //     this.surveyCreator.makeNewViewActive("survey-templates");
    //   },
    //   data: {
    //     title: "Survey templates list",
    //     surveys: loadedSurveyTemplates(), //to make code clean, get the array of templates name and jsons from another function
    //     load: (item) => {
    //       console.log(
    //         "SurveyCreator -> componentDidMount -> typeof item.json",
    //         typeof item.json
    //       );
    //       //load the item json into creator
    //       this.surveyCreator.JSON = item.json;
    //       //make the 'designer' tab active
    //       this.surveyCreator.makeNewViewActive("designer");
    //     },
    //   },
    // });
    // function loadedSurveyTemplates() {
    //   return [
    //     {
    //       name: "NPS",
    //       json: getNPSJSON(),
    //     },
    //     {
    //       name: "Dummy checkbox survey",
    //       json: getDummyCheckboxJSON(),
    //     },
    //     {
    //       name: "Empty Survey",
    //       json: {},
    //     },
    //   ];
    // }

    // function getNPSJSON() {
    //   return {
    //     completedHtml:
    //       "<h3>Thank you for your feedback.</h3><h5>Your thoughts and ideas will help us to create a great product!</h5>",
    //     completedHtmlOnCondition: [
    //       {
    //         expression: "{nps_score} > 8",
    //         html:
    //           "<h3>Thank you for your feedback.</h3><h5>We glad that you love our product. Your ideas and suggestions will help us to make our product even better!</h5>",
    //       },
    //       {
    //         expression: "{nps_score} < 7",
    //         html:
    //           "<h3>Thank you for your feedback.</h3><h5> We are glad that you share with us your ideas.We highly value all suggestions from our customers. We do our best to improve the product and reach your expectation.</h5>\n",
    //       },
    //     ],
    //     pages: [
    //       {
    //         name: "page1",
    //         elements: [
    //           {
    //             type: "rating",
    //             name: "nps_score",
    //             title:
    //               "On a scale of zero to ten, how likely are you to recommend our product to a friend or colleague?",
    //             isRequired: true,
    //             rateMin: 0,
    //             rateMax: 10,
    //             minRateDescription: "(Most unlikely)",
    //             maxRateDescription: "(Most likely)",
    //           },
    //           {
    //             type: "checkbox",
    //             name: "promoter_features",
    //             visibleIf: "{nps_score} >= 9",
    //             title: "What features do you value the most?",
    //             isRequired: true,
    //             validators: [
    //               {
    //                 type: "answercount",
    //                 text: "Please select two features maximum.",
    //                 maxCount: 2,
    //               },
    //             ],
    //             hasOther: true,
    //             choices: [
    //               "Performance",
    //               "Stability",
    //               "User Interface",
    //               "Complete Functionality",
    //             ],
    //             otherText: "Other feature:",
    //             colCount: 2,
    //           },
    //           {
    //             type: "comment",
    //             name: "passive_experience",
    //             visibleIf: "{nps_score} > 6  and {nps_score} < 9",
    //             title: "What is the primary reason for your score?",
    //           },
    //           {
    //             type: "comment",
    //             name: "disappointed_experience",
    //             visibleIf: "{nps_score} notempty",
    //             title:
    //               "What do you miss and what was disappointing in your experience with us?",
    //           },
    //         ],
    //       },
    //     ],
    //     showQuestionNumbers: "off",
    //   };
    // }

    // function getDummyCheckboxJSON() {
    //   return {
    //     questions: [
    //       {
    //         type: "checkbox",
    //         name: "car",
    //         title: "What car are you driving?",
    //         isRequired: true,
    //         hasNone: true,
    //         colCount: 4,
    //         choices: [
    //           "Ford",
    //           "Vauxhall",
    //           "Volkswagen",
    //           "Nissan",
    //           "Audi",
    //           "Mercedes-Benz",
    //           "BMW",
    //           "Peugeot",
    //           "Toyota",
    //           "Citroen",
    //         ],
    //       },
    //     ],
    //   };
    // }

    // function DefaultQuesrtion() {
    //   return {
    //     pages: [
    //       {
    //         elements: [
    //           {
    //             title: "Please enter your name and e-mail",
    //             questions: [
    //               {
    //                 type: "text",
    //                 name: "name",
    //                 title: "Name:",
    //                 isRequired: true,
    //                 popupdescription:
    //                   "Please, type your name as 'Given Name' 'Family Name'.",
    //               },
    //               {
    //                 type: "text",
    //                 name: "email",
    //                 title: "Your e-mail",
    //                 isRequired: true,
    //                 popupdescription:
    //                   "Please, make sure you do not misspell your e-mail.",
    //               },
    //             ],
    //           },
    //         ],
    //       },
    //     ],
    //   };
    // }

    // this.surveyCreator.toolbarItems.push({
    //   id: "Coupon-add",
    //   visible: true,
    //   title: "Add Coupon",
    //   Default_question: DefaultQuesrtion(),
    //   json: this.surveyCreator,

    //   action: (obj) => {
    //     this.setState({ addCoupon: true });

    //     let Default_question = {
    //       pages: [
    //         {
    //           name: "page3",
    //           elements: [
    //             {
    //               title: "Please enter your name and e-mail",
    //               questions: [
    //                 {
    //                   type: "text",
    //                   name: "name",
    //                   title: "Name:",
    //                   isRequired: true,
    //                   popupdescription:
    //                     "Please, type your name as 'Given Name' 'Family Name'.",
    //                 },
    //                 {
    //                   type: "text",
    //                   name: "email",
    //                   title: "Your e-mail",
    //                   isRequired: true,
    //                   popupdescription:
    //                     "Please, make sure you do not misspell your e-mail.",
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //       ],
    //     };

    //     this.surveyCreator.survey.getAllQuestions().forEach((e) => {
    //       // // console.log("SurveyCreator -> componentDidMount -> e", e);
    //     });
    //     console.log(
    //       "SurveyCreator -> componentDidMount ->  this.surveyCreator.survey.getQuestionByName('Name:')",
    //       this.surveyCreator.survey.getQuestionByName("Name:")
    //     );
    //     if (this.surveyCreator.survey.getQuestionByName("Name:") === null) {
    //       this.surveyCreator.survey.addNewPage("last_page");
    //       this.surveyCreator.survey.pages.forEach((e) => {
    //         if (e.name === "last_page") {
    //           e.addNewQuestion("text", "Your e-mail:");
    //           e.addNewQuestion("text", "Name:");
    //         }
    //       });
    //     } else {
    //     }

    // console.log(
    //   "SurveyCreator -> componentDidMount ->  this.surveyCreator.survey.getQuestionByName('Name:')",
    //   this.surveyCreator.survey.getQuestionByName("Name:")
    // );
    // console.log(
    //   "SurveyCreator -> componentDidMount ->  this.surveyCreator.survey.getQuestionByName('Name:')",
    //   this.surveyCreator.survey.getQuestionByName("Your e-mail:")
    // );

    // console.log(
    //   "SurveyCreator -> componentDidMount -> this.surveyCreator.survey.getAllQuestions",
    //   this.surveyCreator.survey.pages
    // );
    //   },
    // });

    // function createButtonForPopup(options, showPopup) {
    //   //Return if there is no description to show in popup
    //   if (!options.question.popupdescription) return

    //   //Add a button;
    //   var btn = document.createElement('button')
    //   btn.type = 'button'
    //   btn.className = 'btn btn-info btn-xs'

    //   btn.style.position = 'absolute'
    //   btn.style.marginLeft = '20px'

    //   btn.innerHTML = 'More Info'
    //   if (showPopup) {
    //     var popupdescription = options.question.popupdescription
    //     btn.onclick = function () {
    //       alert(popupdescription)
    //     }
    //   }
    //   var header = options.htmlElement.querySelector('h5')
    //   var span = document.createElement('span')
    //   span.innerHTML = '  '
    //   header.appendChild(span)
    //   header.appendChild(btn)
    // }
    // function renderQuestionInDesigner(survey, options) {
    //   createButtonForPopup(options, false)
    // }
    // function renderQuestionInTestSurvey(survey, options) {
    //   createButtonForPopup(options, true)
    // }

    // this.surveyCreator.onSurveyInstanceCreated.add((sender, options) => {
    // console.log("SurveyCreator -> componentDidMount -> options", options)
    //   //If we are creating a surface for designer surface
    //   if (options.reason == "designer") {
    //     options.survey.onAfterRenderQuestion.add(renderQuestionInDesigner);
    //   }
    //   //If we are creating a surface for "Test Survey" tab
    //   if (options.reason == "test") {
    //     options.survey.onAfterRenderQuestion.add(renderQuestionInTestSurvey);
    //   }
    // });

    this.surveyCreator.render('surveyCreatorContainer')
    // this.SurveyfromDashboard()

    // this.surveyCreator.onUploadFile.add((sender, option) => {
    //   console.log("SurveyCreator -> componentDidMount -> sender", sender);
    //   console.log("SurveyCreator -> componentDidMount -> option", option);

    //   console.log(
    //     "SurveyCreator -> componentDidMount -> option.value",
    //     option.value
    //   );
    //   option.files.forEach((e) => {
    //     console.log("SurveyCreator -> componentDidMount -> e", e);

    //     if (e.size > 100000) {
    //       this.setState({
    //         FileUploadMessage: "the file uplpoaded should be less than 100kb",
    //         FileUploadError: true,
    //       });
    //     } else {
    //       // option.callback("sucess", this.surveyCreator.JSON.logo = option.files)
    //       return;
    //     }
    //   });
    //   option.callback("sucess", () => {
    //     return;
    //   });
    // });
    // var questionCounter = 1
    this.surveyCreator.onModified.add((sender, options) => {
      sender.survey.cssValue.checkbox = {
        ...sender.survey.cssValue.checkbox,
        item: sender.survey.cssValue.checkbox.item + ' sv_checkbox_css_dashboard',
      }
      if (options.type === 'DO_DROP') {
        this.surveyCreator.survey.getAllQuestions().forEach((e, i) => {
          e.name = 'Question' + (i + 1)
        })
      }
      if (options.type === 'OBJECT_DELETED' && options.target !== undefined) {
        this.setState({ questionCounter: this.state.questionCounter - 1 })
      }

      if (options.type === 'PROPERTY_CHANGED' && options.name === 'firstPageIsStarted') {
        if (options.oldValue === false) {
        }
      }

      if (options.type === 'PROPERTY_CHANGED' && options.name === 'logo') {
        console.log('SurveyEditortest -> componentDidMount -> options', options)
        this.setState(
          {
            ButtonLoadingLogo: true,
          },
          () => {
            //Add new logo
            if (options.newValue !== '' || options.oldValue !== '') {
              if (options.newValue !== undefined || options.newValue !== null) {
                if (options.oldValue === '' && options.newValue !== undefined) {
                  console.log('SurveyEditortest -> componentDidMount -> Add')
                  let fileSize = (options.newValue.length * 3) / 4 - 2
                  console.log('SurveyEditortest -> componentDidMount -> fileSize', fileSize)
                  this.setState({
                    ButtonLoadingLogo: false,
                  })
                  if (fileSize > 50000) {
                    Swal.fire({
                      title: 'Warning',
                      text: 'the file uploaded should be less than 50kb for better performance',
                      showCancelButton: true,
                      confirmButtonText: `It's ok`,
                      customClass: {
                        cancelButton: `swal-button swal-button--cancel swal_button_cancel`,
                        confirmButton: `swal-button swal-button--catch swal_button_accept`,
                      },
                    }).then(async (e) => {
                      console.log('SurveyEditortest -> componentDidMount -> e', e)
                      if (e.isConfirmed) {
                        if (e.isConfirmed === true) {
                          this.setState({
                            FileSize: fileSize,
                            LogoState: 'Edit',
                          })
                          // let ext = this.surveyCreator.JSON.logo.split(',')[0].split(';')[0].split('/')[1]
                          // let date = Date.now()
                          // date = date + '_' + 'secret' + '.' + ext
                          // const file = await this.dataURLtoFile(this.surveyCreator.JSON.logo, date)
                          // let Form = new FormData()
                          // Form.append('surveyId', this.state.Surveyfrommid.surveyId)
                          // Form.append('profileImage', file)
                          // for (let pair of Form) {
                          //   console.log('updateProfile -> data', pair)
                          // }
                          // const API = ProjectSettings.baseURL + '/api/survey/CreatelogoImg'
                          // fetch(API, {
                          //   method: 'POST',
                          //   headers: {
                          //     // 'Content-type': 'multipart/form-data',
                          //     Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                          //   },
                          //   body: Form,
                          // })
                          //   .then((response) => {
                          //     return response.json()
                          //   })
                          //   .then((response) => {
                          //     console.log('SurveyEditortest -> componentDidMount -> response', response)
                          //   })
                        }
                      } else {
                        this.setState(
                          {
                            FileSize: 0,
                          },
                          () => {
                            this.surveyCreator.JSON = {
                              ...this.surveyCreator.JSON,
                              logoPosition: 'top',
                              logo: '',
                            }
                          }
                        )
                      }
                    })
                  }
                }

                //Edit logo
                if (options.newValue !== '' && options.oldValue !== '') {
                  if (options.newValue !== options.oldValue) {
                    if (options.newValue !== undefined) {
                      console.log('SurveyEditortest -> componentDidMount -> Edit')
                      let fileSize = (options.newValue.length * 3) / 4 - 2
                      console.log('SurveyEditortest -> componentDidMount -> fileSize', fileSize)
                      this.setState({
                        ButtonLoadingLogo: false,
                      })
                      if (fileSize > 50000) {
                        Swal.fire({
                          title: 'Warning',
                          text: 'the file uploaded should be less than 50kb for better performance',
                          showCancelButton: true,
                          confirmButtonText: `It's ok`,
                          customClass: {
                            cancelButton: `swal-button swal-button--cancel swal_button_cancel`,
                            confirmButton: `swal-button swal-button--catch swal_button_accept`,
                          },
                        }).then(async (e) => {
                          if (e.isConfirmed) {
                            this.setState({
                              FileSize: fileSize,
                              LogoState: 'Edit',
                            })
                            // if (e.isConfirmed === true) {
                            // let ext = this.surveyCreator.JSON.logo.split(',')[0].split(';')[0].split('/')[1]
                            // let date = Date.now()
                            // date = date + '_' + 'secret' + '.' + ext
                            // const file = await this.dataURLtoFile(this.surveyCreator.JSON.logo, date)
                            // let Form = new FormData()
                            // Form.append('surveyId', this.state.Surveyfrommid.surveyId)
                            // Form.append('profileImage', file)
                            // for (let pair of Form) {
                            //   console.log('updateProfile -> data', pair)
                            // }
                            // const API = ProjectSettings.baseURL + `/api/survey/DeleteLomoimg/${this.state.Surveyfrommid.surveyId}`
                            // const DELETE_API = ProjectSettings.baseURL + `/api/survey/DeleteLomoimg/${this.state.Surveyfrommid.surveyId}`
                            // fetch(API, {
                            //   method: 'PUT',
                            //   headers: {
                            //     // 'Content-type': 'multipart/form-data',
                            //     Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                            //   },
                            // })
                            //   .then((response) => {
                            //     return response.json()
                            //   })
                            //   .then((response) => {
                            //     console.log('SurveyEditortest -> componentDidMount -> response', response)
                            //     const API = ProjectSettings.baseURL + '/api/survey/CreatelogoImg'
                            //     fetch(API, {
                            //       method: 'POST',
                            //       headers: {
                            //         // 'Content-type': 'multipart/form-data',
                            //         Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                            //       },
                            //       body: Form,
                            //     })
                            //       .then((response) => {
                            //         return response.json()
                            //       })
                            //       .then((response) => {
                            //         console.log('SurveyEditortest -> componentDidMount -> response', response)
                            //       })
                            //   })
                          } else {
                            this.setState(
                              {
                                FileSize: 0,
                              },
                              () => {
                                this.surveyCreator.JSON = {
                                  ...this.surveyCreator.JSON,
                                  logoPosition: 'top',
                                  logo: '',
                                }
                              }
                            )
                          }
                          // }
                          // else{
                          //         const API = ProjectSettings.baseURL + `/api/survey/DeleteLomoimg/${this.state.Surveyfrommid.surveyId}`
                          // fetch(API, {
                          //   method: 'PUT',
                          //   headers: {
                          //     // 'Content-type': 'multipart/form-data',
                          //     Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                          //   },
                          // })
                          //   .then((response) => {
                          //     return response.json()
                          //   })
                          //   .then((response) => {
                          //     console.log('SurveyEditortest -> componentDidMount -> response', response)
                          //   })
                          // }
                        })
                      }
                    }
                  }
                }

                //Delete logo

                if (options.newValue === undefined || options.newValue === null || options.newValue === '') {
                  if (options.newValue === undefined && options.oldValue === '') {
                    console.log('SurveyEditortest -> componentDidMount -> Delete')
                    this.setState({
                      ButtonLoadingLogo: false,
                      FileSize: 0,
                      LogoState: 'Delete',
                    })
                  }
                }
                if (document.querySelector('.svda-title-actions')) {
                  document.querySelector('.svda-title-actions').style.display = 'inline-block'
                }
                if (document.querySelector('.svg_icon_add_logo')) {
                  document.querySelector('.svg_icon_add_logo').style.display = 'none'
                }
              }
            }
          }
        )
      }
    })
    // this.props.dispatch(SurveySave(this.surveyCreator.JSON));

    //Set the name property different from the default value
    //and set the tag property to a generated GUID value.

    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    }
    this.surveyCreator.toolbox.addItem({
      name: 'NPS',
      isCopied: true,
      iconName: 'icon-default',
      title: 'NPS Question',
      json: {
        type: 'rating',
        name: 'nps_score',
        title: 'On a scale of zero to ten, how likely are you to recommend our product to a friend or colleague?',

        isRequired: true,
        tag11: 200,
        rateValues: [
          {
            value: '10',
            text: '10',
          },
          {
            value: '9',
            text: '9 ',
          },
          {
            value: '8',
            text: '8',
          },
          {
            value: '7',
            text: '7',
          },
          {
            value: '6',
            text: '6',
          },
          {
            value: '5',
            text: '5',
          },
          {
            value: '4',
            text: '4',
          },
          {
            value: '3',
            text: '3',
          },
          {
            value: '2',
            text: '2',
          },
          {
            value: '1',
            text: '1',
          },
          {
            value: '0',
            text: '0',
          },
        ],
        rateMax: 11,
        minRateDescription: 'Extremely Likely ',
        maxRateDescription: 'Not At All Likely',
      },

      // json: {
      //   type: "rating",
      //   name: "nps_score",
      //   title:
      //     "On a scale of zero to ten, how likely are you to recommend our product to a friend or colleague?",
      //   isRequired: true,
      //   tag11: 200,
      //   rateMin: 0,
      //   rateMax: 10,
      //   minRateDescription: "(Extremely Likely)",
      //   maxRateDescription: "(Not At All Likely)",
      // },
    })
    this.surveyCreator.toolbox.addItem({
      name: 'CSC',
      isCopied: true,
      iconName: 'icon-csc',
      title: 'CSC Question',
      json: {
        type: 'radiogroup',
        name: 'customer_satisfaction',
        title: 'How would you rate your experience?',
        isRequired: true,
        choices: [
          {
            value: 'Very Satisfied',
          },
          {
            value: 'Satisfied',
          },
          {
            value: 'Neutral',
          },
          {
            value: 'Unsatisfied',
          },
          {
            value: 'Very Unsatisfied',
          },
        ],
      },
    })
    this.surveyCreator.toolbox.addItem({
      name: 'CES',
      isCopied: true,
      iconName: 'icon-ces',
      title: 'CES Question',
      json: {
        type: 'radiogroup',
        name: 'customer_satisfaction',
        title: 'The company made it easy for me to handle my issue.',
        uxclassname: 'special_CES',
        isRequired: true,
        choices: [
          {
            value: ' Strongly Agree',
          },
          {
            value: ' Agree',
          },
          {
            value: 'Somewhat Agree',
          },
          {
            value: 'Neutral',
          },
          {
            value: 'Somewhat Disagree',
          },
          {
            value: 'Disagree',
          },
          {
            value: 'Strongly Disagree',
          },
        ],
        rateMax: 10,
      },
    })

    this.surveyCreator.tabs().pop()
    this.surveyCreator.tabs().pop()

    this.Loader_hide()
    this.getlogofromdb()
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.LogoUploaded === prevState.LogoUploaded) {
    //   if (this.surveyCreator.JSON.hasOwnProperty('logo')) {
    //     console.log('SurveyEditortest -> componentDidUpdate -> includesLogo')
    //     if (this.surveyCreator.JSON.logo !== this.state.LogoUploaded) {
    //       console.log('SurveyEditortest -> componentDidUpdate -> logouploadtest')
    //       if (this.surveyCreator.JSON.logo !== prevState.LogoUploaded) {
    //         console.log('SurveyEditortest -> componentDidUpdate -> prevStateTest')
    //       }
    //     } else {
    //       if (this.surveyCreator.JSON.logo !== prevState.LogoUploaded) {
    //         console.log('SurveyEditortest -> componentDidUpdate -> prevStateTest')
    //       }
    //       console.log('SurveyEditortest -> componentDidUpdate -> logoUploadedSuccess')
    //     }
    //   } else {
    //     if (this.state.LogoUploaded) {
    //       //deleted uploaded logo

    //       console.log('SurveyEditortest -> componentDidUpdate -> noLogofound')
    //     }
    //   }
    // }

    // console.log("SurveyCreator -> componentDidUpdate -> prevState", prevState);

    // if (this.surveyCreator.JSON.logo === undefined) {
    //   $(document).ready(() => {
    //     var parent_element_add_logo = document.querySelector('.svda-title-actions').parentElement
    //     parent_element_add_logo.appendChild(document.querySelector('.svg_icon_add_logo'))
    //     document.querySelector('.svda-title-actions').style.display = 'none' //removing the add icon default icon
    //     document.querySelectorAll('.svda_current_type').forEach((e) => {
    //       e.style.display = 'none'
    //     })
    //   })
    // }

    if (this.state.InitialCouponCode !== prevState.InitialCouponCode) {
      if (this.state.InitialCouponCode.length === 0) {
        this.setState({
          Checked: false,
        })
      }
    }

    if (this.state.SurveyEventListener !== prevState.SurveyEventListener) {
      // this.setState({ Checked: false })
    }
    if (this.state.Checked !== prevState.Checked) {
      if (this.state.Checked === true) {
        this.setState({ InsideModalState: true })
      }
    }
    if (this.props.state.SurveyOptionReducer.state_change !== prevProps.state.SurveyOptionReducer.state_change) {
      this.setState({ backState: true })
      this.surveyCreator.JSON = this.props.state.SurveyOptionReducer.json
    }

    // console.log(
    //   "SurveyCreator -> componentDidMount -> this.props.state",
    //   this.props.state
    // );
    // const { Coupon_code_reducer } = this.props.state;
    // console.log(
    //   "SurveyCreator -> componentDidUpdate -> Coupon_code_reducer",
    //   Coupon_code_reducer
    // );
  }

  SurveyfromDashboard = () => {
    try {
      if (this.state.Surveyfrommid.json !== undefined || this.state.Surveyfrommid.json !== null) {
        // var testing12 = JSON.parse(JSON.stringify(this.state.Surveyfrommid.json));

        this.surveyCreator.JSON = this.state.Surveyfrommid.surveyJson

        // console.log(
        //   "SurveyfromDashboard -> this.surveyCreator.JSON",
        //   this.surveyCreator.JSON
        // );
      }
    } catch (e) {
      // console.log("SurveyCreator -> componentDidMount -> e", e);
    }
  }

  EdgecaseTesting = () => {
    if (this.surveyCreator.survey.getAllQuestions().length > 0) {
      var QuestionChecker = 0
      this.setState({
        error_case_count: 0,
      })
      this.surveyCreator.survey.getAllQuestions().forEach((e) => {
        let email = e.title.toLowerCase()

        if (email.includes('email')) {
          var testing123 = e.getType()

          if (testing123 === 'text') {
            QuestionChecker = QuestionChecker + 1
          }
        }
      })

      if (QuestionChecker === 0) {
        swal({
          title: 'Warning',
          text:
            //"The survey does not have a question to support Coupon Code delivery, kindly add a question with name Email",
            'This survey does not have a required question requesting an email address. A required question requesting an email address will be added to the end of the survey.',
          icon: 'warning',
          buttons: {
            cancel: 'Cancel',
            catch: {
              text: 'Add Question',
              value: 'Add',
            },
            default: {
              text: 'Edit Survey',
              value: 'edit',
            },
          },
        }).then((value) => {
          if (value === 'Add') {
            this.setState({
              email_question_add: true,
            })
            this.surveyCreator.survey.addNewPage('last_page')
            this.surveyCreator.survey.pages.forEach((e, i) => {
              if (e.name === 'last_page') {
                e.addNewQuestion('text', 'Email')
              }
            })
            this.setState({
              error_case_count: 0,
              Coupon_state: true,
            })
          }
          if (value === 'edit') {
            this.setState({ saveSurveyName: false })
          }
        })
      } else {
        this.setState({
          Checked: !this.state.Checked,
        })
      }

      // if (this.state.QuestionChecker === 0) {
      //   console.log("EdgecaseTesting -> this.state", this.state);
      //   this.setState({
      //     EdgeCaseError:
      //       "the survey does not have a question to support Coupon Code delivery kindly add a question with name Email",
      //     EdgeCaseErrorState: true,
      //   });
      // } else {
      //   this.setState({
      //     Checked: !this.state.Checked,
      //   });
      // }
    } else {
      swal({
        icon: 'error',
        title: 'No questions found',
        buttons: {
          catch: {
            text: 'Edit',
            value: 'ok',
          },
          cancel: 'Cancel',
        },
      }).then((e) => {
        if (e) {
          this.setState({
            EdgeCaseErrorState: false,
            Coupon_state: false,
            saveSurveyName: false,
          })
        }
      })
      // this.setState({
      //   EdgeCaseError: "No question found",
      //   EdgeCaseErrorState: true,
      // });
    }
  }

  render() {
    return (
      <>
        <Loader11 />
        <Button
          outline
          id="Coupon_code_button"
          className="btn-transition"
          color="primary"
          style={{
            float: 'right',
            marginTop: '0.5em',
            width: '140px',
          }}
          onClick={() => {
            this.setState({ addCoupon: true })
          }}
        >
          Add Coupon Code
        </Button>
        <SweetAlert
          title={this.state.FileUploadMessage}
          confirmButtonColor=""
          show={this.state.FileUploadError}
          text=""
          type="error"
          onConfirm={() =>
            this.setState({ FileUploadError: false }, () => {
              this.surveyCreator.JSON = {
                ...this.surveyCreator.JSON,
                logo: '',
              }
            })
          }
        />
        <SweetAlert
          {...this.state.aarraay}
          title="Something went wrong"
          confirmButtonColor=""
          show={this.state.EdgeCaseErrorState}
          showCancelButton={true}
          confirmButtonText={'Edit'}
          text={this.state.EdgeCaseError}
          type="error"
          onCancel={() => {
            this.setState({ EdgeCaseErrorState: false, Checked: false })
          }}
          onConfirm={() =>
            this.setState({
              EdgeCaseErrorState: false,
              Checked: false,
              saveSurveyName: false,
            })
          }
        />
        <Button
          id="special_button"
          className="svd-page-selector btn-primary sv-btn btn btn-special-primary-hover"
          style={{
            width: '140px',
          }}
          onClick={() => {
            this.setState({
              // savedSurveyjson: this.surveyCreator.JSON,
              status: false,
              saveSurveyName: true,
              SurveyQuestionNumber: this.surveyCreator.survey.getAllQuestions().length,
            })
            // this.setState({ status: false })
            // this.setState({
            //   saveSurveyName: true,
            //   SurveyQuestionNumber: this.surveyCreator.survey.getAllQuestions().length,
            // })
          }}
        >
          Save Survey
        </Button>
        <div className="Inside_list_div"></div>
        {/* <SweetAlert
          title={this.state.Error_Message}
          confirmButtonColor=""
          show={this.state.ErrorState}
          text=""
          type="error"
          onConfirm={() => this.setState({ ErrorState: false, Checked: false })}
        /> */}
        <div>
          <ToastContainer
            position="bottom-right"
            color="error"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Modal
            modalClassName="Modal_edit_create"
            isOpen={this.state.saveSurveyName}
            toggle={() => this.setState({ saveSurveyName: false })}

            // className={this.props.className}
          >
            <ModalHeader toggle={this.toggle} className="Modal_header Modal_header1 Modal_header_special">
              {' '}
              <img src={save_icon} alt={''} /> Save Survey
            </ModalHeader>

            <ModalBody className="Modal_body Modal_body1">
              <div className="modal_form">
                <div className="create_survey_form">
                  <div className="Survey_left_part">
                    <div className="Survey_left_part_inner">
                      <div className="Survey_left_part_inner1">
                        <label>Save as:</label>
                      </div>
                    </div>
                  </div>
                  <div className="Survey_right_part">
                    <FormGroup>
                      <Input
                        valid={this.state.surveyNameValid}
                        invalid={this.state.surveyNameInvalid}
                        style={{
                          Background: '#f8f9fa',
                          width: '100%',
                          height: '40px',
                          fontSize: '14px',
                        }}
                        type="text"
                        placeholder="Survey Name"
                        value={this.state.surveyName}
                        onChange={(e) => {
                          this.setState({ surveyName: e.target.value }, () => {
                            if (this.state.surveyName === this.state.surveyNameTemp) {
                              this.setState({
                                surveyNameValid: true,
                                surveyNameInvalid: false,
                              })
                            } else {
                              let str = this.state.surveyName.trim()
                              if (str === '') {
                                this.setState({
                                  surveyNameInvalid: false,
                                  surveyNameValid: false,
                                })
                              } else {
                                let API_END_POINT = ProjectSettings.baseURL + '/api/survey/checkSurveyName'
                                let bodyData = JSON.stringify({
                                  surveyName: str,
                                })
                                fetch(API_END_POINT, {
                                  method: 'POST',
                                  headers: {
                                    Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                    'Content-type': 'application/json',
                                  },
                                  body: bodyData,
                                })
                                  .then((e) => {
                                    return e.json()
                                  })
                                  .then((e) => {
                                    if (e.success === true) {
                                      this.setState(
                                        {
                                          surveyNameValid: true,
                                          surveyNameInvalid: false,
                                        },
                                        () => {
                                          if (this.state.surveyName.trim().length === 0) {
                                            this.setState({
                                              surveyNameValid: false,
                                              surveyNameInvalid: false,
                                            })
                                          }
                                        }
                                      )
                                    } else {
                                      this.setState(
                                        {
                                          surveyNameValid: false,
                                          surveyNameInvalid: true,
                                        },
                                        () => {
                                          if (this.state.surveyName.trim().length === 0) {
                                            this.setState({
                                              surveyNameValid: false,
                                              surveyNameInvalid: false,
                                            })
                                          }
                                        }
                                      )
                                    }
                                  })
                                  .catch((e) => {
                                    console.log('error', e)
                                  })
                              }
                            }
                          })
                        }}
                      />
                      <FormFeedback
                        style={{
                          display: this.state.surveyNameInvalid ? `block` : `none`,
                          position: 'absolute',
                        }}
                      >
                        Survey name already exists
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </div>
                <div className="create_survey_form">
                  <div className="create_survey_form_inner">
                    <div className="Survey_left_part">Survey status:</div>
                    <div className="Survey_right_part">
                      <UncontrolledButtonDropdown
                        style={{
                          width: '100%',
                        }}
                        isOpen={this.state.Dropdown}
                        onMouseEnter={() => {
                          this.setState({
                            Hover_state: true,
                          })
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            Hover_state: false,
                          })
                        }}
                        toggle={() => {
                          this.setState({ Dropdown: !this.state.Dropdown })
                        }}
                      >
                        {/* <ToolTip
                          props={this.state.Hover_state}
                          message={'Open Surveys can receive responses Closed surveys can not receive responses'}
                        /> */}

                        <DropdownToggle
                          data-tip="Open surveys can receive responses closed surveys can not receive responses"
                          data-for="sadFace"
                          className="btn-icon btn-icon-only btn btn-link"
                          color={this.state.color}
                          caret
                        >
                          {this.state.SurveyState === 'Draft' ? `Closed` : `Open`}
                        </DropdownToggle>
                        <ReactTooltip id="sadFace" place="bottom" effect="solid"></ReactTooltip>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={(e) => {
                              this.setState({
                                SurveyState: `Draft`,
                                color: 'warning',
                              })
                            }}
                          >
                            Closed
                          </DropdownItem>

                          <DropdownItem
                            onClick={(e) => {
                              this.setState({
                                SurveyState: `Active`,
                                color: 'success',
                              })
                            }}
                          >
                            Open
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                  </div>
                </div>
                <div className="create_survey_form">
                  <div className="create_survey_form_inner">
                    <div className="Survey_left_part">Coupon code status:</div>
                    <div className="Survey_right_part">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            diaplay: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                // checked={this.state.Checked}
                                color="primary"
                                checked={this.state.Checked}
                                size="small"
                                onChange={() => {
                                  if (this.state.InitialCouponCode === null || this.state.InitialCouponCode.length === 0) {
                                    this.setState(
                                      {
                                        NoCouponCodeError: {
                                          state: true,
                                          error: 'No Coupon Code Available ',
                                          count: 1,
                                          // addCoupon: true,
                                        },
                                        addCoupon: true,
                                      },
                                      () => {
                                        if (this.state.NoCouponCodeError.count === 1) {
                                          setTimeout(() => {
                                            this.setState({
                                              NoCouponCodeError: {
                                                count: 0,
                                                state: false,
                                              },
                                            })
                                          }, 1000)
                                        }
                                      }
                                    )
                                    // toast("No coupon code Available");

                                    this.setState({
                                      Checked: false,
                                    })
                                  } else {
                                    if (this.state.Checked === true) {
                                      this.setState({
                                        Checked: !this.state.Checked,
                                      })
                                    } else {
                                      this.setState({
                                        NoCouponCodeError: {
                                          state: false,
                                          error: 'No Coupon Code Available',
                                        },
                                      })
                                      this.EdgecaseTesting()
                                    }
                                  }
                                }}
                              />
                            }
                            // label="Allow Coupon Code"
                            label={this.state.Checked === true ? `Active` : `Inactive`}
                          />
                        </div>
                        <div className="warning_span">
                          {this.state.NoCouponCodeError.state === true ? `${this.state.NoCouponCodeError.error}` : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="create_survey_form" style={{ paddingBottom: '0px' }}>
                  <div className="create_survey_form_inner">
                    <div
                      className="Survey_left_part"
                      style={{
                        opacity:
                          this.state.InitialCouponCode === null || this.state.InitialCouponCode === [] || this.state.InitialCouponCode === 0
                            ? `0.4`
                            : `1`,
                      }}
                    >
                      Coupon codes available:
                    </div>
                    <div className="Survey_right_part special_wrapper">
                      {this.state.InitialCouponCode === null || this.state.InitialCouponCode === [] || this.state.InitialCouponCode === 0 ? (
                        <div
                          style={{
                            fontWeight: '300',
                            opacity:
                              this.state.InitialCouponCode === null || this.state.InitialCouponCode === [] || this.state.InitialCouponCode === 0
                                ? `0.4`
                                : `1`,
                          }}
                        >
                          {' '}
                          {` 0`}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontWeight: '300',
                            opacity: this.state.InitialCouponCode === 0 ? `0.4` : `1`,
                          }}
                        >
                          {' '}
                          {` ${this.state.InitialCouponCode.length}`}
                        </div>
                      )}
                      <div>
                        <Button
                          outline
                          color="primary"
                          onClick={() => {
                            this.setState({ addCoupon: true })
                          }}
                        >
                          Add +
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button color="link" className="btn-cancel-modal" onClick={() => this.setState({ saveSurveyName: false })}>
                Cancel
              </Button>
              <div
                onMouseEnter={() => {
                  this.setState({
                    Hover_state_button: true,
                  })
                }}
                onMouseLeave={() => {
                  this.setState({
                    Hover_state_button: false,
                  })
                }}
              >
                <LaddaButton
                  color="primary"
                  data-style={ZOOM_IN}
                  className="btn btn-primary btn-accept-modal"
                  loading={this.state.ButtonLoading}
                  disabled={
                    this.state.SurveyQuestionNumber !== 0 && this.state.surveyNameInvalid === false && this.state.surveyName !== '' ? false : true
                  }
                  onClick={() => {
                    this.setState({
                      showState: false,
                    })

                    if (this.state.Checked === true) {
                      this.surveyCreator.getAllQuestions().forEach((e) => {
                        if (e.title.toLowerCase().includes('email')) {
                          this.state.error_case_count = 1
                          this.setState({
                            error_case_count: this.state.error_case_count + 1,
                          })
                        }
                      })
                      if (this.state.error_case_count > 0) {
                        this.Save_survey()
                      } else {
                        this.EdgecaseTesting()
                      }
                    } else {
                      this.Save_survey()
                    }
                  }}
                >
                  Save
                </LaddaButton>
                {this.state.SurveyQuestionNumber !== 0 && this.state.surveyName !== '' ? null : (
                  <ToolTip props={this.state.Hover_state_button} message={'No questions found'} />
                )}
              </div>
            </ModalFooter>
          </Modal>
        </div>

        <LaddaButton
          className="svg_icon_add_logo btn btn-primary btn-accept-modal"
          style={{
            position: 'absolute',
            maxWidth: '90%',
            top: '5px',
            right: '50%',
            cursor: 'pointer',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            width: '110px',
            justifyContent: 'space-around',
            borderRadius: '4px',
          }}
          data-style={ZOOM_IN}
          loading={this.state.ButtonLoadingLogo}
          onClick={() => {
            document.querySelectorAll('.svda_question_action')[0].click()
          }}

          // onClick={() => document.querySelector('#image').click()}
        >
          <AddCircleIcon />
          <span>Add logo</span>
        </LaddaButton>

        <Questions />

        <Modal isOpen={this.state.showState} toggle={() => this.setState({ showState: false })} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
          <ModalBody>
            {this.state.SurveyPreview === null ? (
              <div>SHOW THIS {this.state.showState}</div>
            ) : (
              <Survey.Survey model={this.state.SurveyPreview} StylesManager="modern" />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="btn-cancel-modal" onClick={() => this.setState({ showState: false })}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => this.setState({ showState: false })}>
              Do Something
            </Button>{' '}
          </ModalFooter>
        </Modal>

        {/* Survey Preview div */}
        {/* <Modal
          size="lg"
          show={this.state.showState}
          onHide={() => this.setState({ showState: false })}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <ModalHeader closeButton>
            <ModalTitle id="example-custom-modal-styling-title">Custom Modal Styling</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {this.state.SurveyPreview === null ? (
              <div>SHOW THIS {this.state.showState}</div>
            ) : (
              <Survey.Survey model={this.state.SurveyPreview} StylesManager="modern" />
            )}
          </ModalBody>
        </Modal> */}

        {/* coupon modal div */}
        <Modal isOpen={this.state.addCoupon} toggle={() => this.setState({ addCoupon: false })} className={this.props.className}>
          <ModalHeader className="Modal_header Modal_header1" toggle={this.toggle}>
            <img style={{ width: '100px', height: '70px' }} src={tag_icon} alt={''} />
            <span
              style={{
                fontWeight: '600',
              }}
            >
              Manage Coupon Codes
              {/* {JSON.stringify(this.state.Surveyfrommid.surveyJson)} */}
            </span>
          </ModalHeader>

          <ModalBody className="Modal_body">
            <Reader
              CouponCode={this.state.Surveyfrommid.couponCode}
              Info={(this.state.InitialCouponCode, this.state.Coupon_code_details)}
              Coupon_code_afer_upload={this.state.InitialCouponCode}
              Top_array={this.state.InitialCouponCode}
              sent_survey={this.state.Surveyfrommid.couponCodeUsed}
              total_coupon_code_left={this.state.Surveyfrommid.couponCode}
              Info_sending={(e) => {
                this.setState({ Coupon_code_details: e })
              }}
              default_upload={this.state.default_upload_state_out}
              top_state={this.state.top_delete_state}
              bottom_state={this.state.bottom_delete_state}
              default_state_display={this.state.intial_display_state}
              default_upload_state={() => {
                this.setState({
                  default_upload_state_out: true,
                })
              }}
              top_state_send={() => {
                this.setState({
                  top_delete_state: true,
                })
              }}
              bottom_delete={() => {
                this.setState({
                  bottom_delete_state: true,
                })
              }}
              default_state={() => {
                this.setState({
                  intial_display_state: false,
                })
              }}
              Top_info_send={(e) => {
                this.setState({ Coupon_code_details_top: e })
              }}
              coupon_delete={(e) => {
                this.setState(
                  {
                    _InitialCouponCode: [],
                    Coupon_code_details: {},
                  },
                  () => {
                    if (this.state.Checked === true) {
                      this.setState({
                        Checked: false,
                      })
                    }
                  }
                )
              }}
              Array_sending={(e) => {
                if (e === null) {
                  // this.props.Error = "kindly upload a valid file";
                  this.setState({
                    AddCouponError: 'kindly upload a valid file',
                    _InitialCouponCode: [],
                  })
                } else {
                  this.setState({ _InitialCouponCode: e })
                }
              }}
              // Array_sending={(e) => {
              //   console.log("render -> Array_sending", e);

              //   if (e === null) {
              //     // this.props.Error = "kindly upload a valid file";
              //     this.setState({
              //       AddCouponError: "kindly upload a valid file",
              //     });
              //   } else {
              //     // console.log("render -> e", e);
              //     this.setState({ InitialCouponCode: e });
              //   }
              // }}
              ButtonCLickedState={this.state.AddCouponClicked}
            />
          </ModalBody>
          <ModalFooter className="Modal_footer">
            <Button
              color="link"
              className="btn-cancel-modal btn"
              onClick={() => {
                let coupon_codes =
                  this.state._InitialCouponCode === [] || this.state._InitialCouponCode === null
                    ? this.state.Surveyfrommid.couponCode
                    : Object.keys(this.state.Coupon_code_details_top).length === 0
                    ? this.state.InitialCouponCode
                    : this.state.Coupon_code_details_top.total_coupon_code
                this.setState({
                  addCoupon: false,
                  Coupon_code_details: {},
                  intial_display_state: true,
                  InitialCouponCode: coupon_codes,
                  default_upload_state_out: false,
                })
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn-accept-modal"
              disabled={this.state.InitialCouponCode === null || this.state.InitialCouponCode === [] ? true : false}
              onClick={() => {
                this.setState({
                  addCoupon: false,
                  Coupon_code_details: {},
                  AddCouponClicked: !this.state.AddCouponClicked,
                  InitialCouponCode: this.state._InitialCouponCode,
                })
              }}
              style={{
                // padding: "10px 35px",
                // fontSize: "18px",
                width: '65px',
              }}
            >
              OK
            </Button>{' '}
          </ModalFooter>
        </Modal>

        <script type="text/html" id="custom-tab-survey-templates">
          {`
        <div id='test'>TEST</div>
        <h3 data-bind='text: title'></h3>
        <table class='table' style='width:300px'>
      <thead>
          <th>Template Name</th>
          <th></th>
      </thead>
      <tbody data-bind='foreach: surveys'>
      <tr>
              <td data-bind='text:name'></td>
              <td>
              <button data-bind='click:$parent.load($data)'>Load...</button>

              </td>
          </tr>
  
        
  
      </tbody>
      </table>
        
        `}
        </script>

        <div
          id="surveyCreatorContainer"
          onClick={(e) => {
            // // console.log("SurveyCreator -> render -> e", e);
            this.setState({
              SurveyEventListener: !this.state.SurveyEventListener,
            })
          }}
        />
      </>
    )
  }
  // saveMySurvey = () => {
  //   // fetch("http://localhost:3004/", {
  //   //   method: "GET",
  //   //   headers: { "Content-Type": "application/json" },
  //   // })
  //   //   .catch((error) => console.error("Error:", error));

  //   // fetch("http://localhost:3004/something", {
  //   //   method: "POST",
  //   //   body: JSON.stringify(this.surveyCreator.JSON),
  //   //   headers: { "Content-Type": "application/json" },
  //   // })
  //   //   .then((res) => res.json())
  //   //   .catch((error) => console.error("Error:", error));
  //   this.setState({ savedSurveyjson: this.surveyCreator.JSON })
  //   this.setState({ status: false })
  //   this.setState({ saveSurveyName: true })
  // }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyEditortest))
