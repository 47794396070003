import ThemeOptions from './ThemeOptions'
import { combineReducers } from 'redux'
import { SaveSurvey } from './reducers/Survey'
import { LoginReducer } from './reducers/Login1'
// import { Coupon_code_reducer } from "./reducers/Coupon_code";
import { Survey_creatorreducer } from './reducers/Create_surveyr'
import { LoginReducer11 } from './reducers/Login11'
// import { UserReducer } from "./reducers/User";
import { SurveyEditReducer } from './reducers/SurveyEdit'
import { Coupon_code_reducer2 } from './reducers/Coupon_Code2'
import { Loader_reducer } from './reducers/Loader'
import { UserReducer11 } from './reducers/UserDetailsreducer'
import { SurveyOptionReducer } from './reducers/SurveyOptionreducer'
import { PageKeyReducer } from './reducers/PageKeyreducer'
export const rootReducer = combineReducers({
  ThemeOptions,
  SaveSurvey,
  LoginReducer,
  // Coupon_code_reducer,
  Survey_creatorreducer,
  LoginReducer11,

  SurveyEditReducer,
  Coupon_code_reducer2,
  Loader_reducer,
  UserReducer11,
  SurveyOptionReducer,
  PageKeyReducer,
})
