import { takeEvery } from "redux-saga/effects";
import { registerSaga } from "./authenticationsaga";
import { CouponSaga } from "./CouponSaga";
import { AddSuvreySaga } from "./AddSuvreySaga";
import { LoginSage11 } from "./SignupSaga";
import { LoginSaga111 } from "./LoginSaga1.js";

export function* watchUserAuthentication() {
  yield takeEvery("REGISTER_USER", registerSaga);
}

export function* watcherAddCoupon() {
  yield takeEvery("ADD_COUPON_CODE", CouponSaga);
}
export function* watcherAddSurvey() {
  yield takeEvery("CREATE_NEW_SURVEY", AddSuvreySaga);
}

export function* watcherLoginUser() {
  yield takeEvery("LOGIN_ACTION", LoginSaga111);
}

export function* watcherSignupUser() {
  yield takeEvery("REGISTER_USER", LoginSage11);
}
