import { put, call } from 'redux-saga/effects'

const AddSurveyfrombutton = (info) => {
  return info
}

export function* AddSuvreySaga(payload) {
  try {
    const response = yield call(AddSurveyfrombutton, payload)
    yield put({ type: 'CREATE_SURVEY_FROM_BUTTON', response })
  } catch (e) {}
}
