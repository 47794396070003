export const Loginaction = (email, password) => {
  return {
    type: "LOGIN_ACTION",
    email,
    password,
  };
};
export const LogoutAction = (payload) => {
  return {
    type: "LOGOUT_ACTION",
    payload,
  };
};

export const updateToken = (payload) => {
  return {
    type: "UPDATE_TOKEN",
    payload,
  };
};