import validator from 'validator'
const validPassword = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/)
export const handleValidation = (formfields) => {
  let err = {}
  for (let [name, value] of Object.entries(formfields)) {
    switch (name) {
      case 'Name':
        let WhiteSpace = value.trim()
        err.Name = !value ? 'This field is required' : WhiteSpace === '' ? 'field is required' : ''
        break

      case 'Email':
        err.Email = !value ? 'This field is required' : !validator.isEmail(value) ? 'Invalid Email' : ''
        break
      case 'Password':
        err.Password = !value
          ? 'This field is required'
          : validPassword.test(value)
          ? ''
          : 'Password must have 7 characters, and must contain lower case letter, upper case letter, and number, and special character.'
        break
      case 'RepeatPassword':
        err.RepeatPassword = !value ? 'This field is required' : formfields.RepeatPassword !== formfields.Password ? 'Password does not match' : ''
        break
      case 'businessName':
        err.businessName = !value ? 'This field is required' : ''
        break

      case 'Checkbox':
        err.Checkbox = !value ? 'Kindly accept our terms and conditions' : ''
        break
      default:
    }
  }

  let valid = true
  for (const [key, value] of Object.entries(err)) {
    console.log(key)
    if (value) {
      if (value.length > 0) {
        valid = false
      }
    }
  }
  return !valid ? err : false
}
