import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CSVReader from 'react-csv-reader'
// import SweetAlert from 'sweetalert-react'
import { Progress } from 'reactstrap'
import {
  CouponCodeaction2,
  CouponCodeaction21,
  CouponCodeaction_remove_codes_top,
  CouponCodeaction_remove_codes_bottom,
} from '../../redux/actions/Coupon_code2'
// import Cloud_image from '../../assets/cloud-computing.svg'
import Cloud_icon from '../../assets/cloud_icon.png'
// import Tick from '../../assets/checked.svg'
import tick_icon from '../../assets/tick_icon.png'
import swal from 'sweetalert'
import delete_image from '../../assets/delete.svg'
import ReactTooltip from 'react-tooltip'

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
}

class Reader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Duplicate_removed: 0,
      status: null,
      Error: false,
      Error_Message: null,
      CSV_data: null,
      FileInfo: null,
      TotalNumberArray: 0,
      AvailableCode: 0,
      progress_value: 0,
      present_code: [],
      Present_code_sent: 0,
      delete_top_state: false,
    }
  }

  progress_value = () => {
    setTimeout(() => {
      this.setState(
        {
          progress_value: this.state.progress_value + 50,
        },
        () => {
          setTimeout(() => {
            this.setState({
              progress_value: this.state.progress_value + 50,
            })
          }, 1000)
        }
      )
    }, 1000)
  }
  progress_function = (trigger) => {
    if (trigger) {
      this.setState({ progress_value: 0 }, () => {
        this.progress_value()
      })
    }
  }

  componentDidMount() {
    // this.setState({ AvailableCode: this.props.CouponCode_available.length });

    if (this.props.CouponCode_available !== null && this.props.CouponCode_available !== undefined) {
      this.setState({
        present_code: this.props.CouponCode_available,
        progress_value: 0,
        Present_code_sent: this.props.sent_survey.length,
      })
      this.props.dispatch(CouponCodeaction2(0, 0, this.props.CouponCode_available, 0, '', 0))
    } else {
      this.setState({
        present_code: this.props.state.Coupon_code_reducer2.PresentCouponCode,
        progress_value: 0,
        Present_code_sent: this.props.sent_survey.length,
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.state.Coupon_code_reducer2.fileName !== prevProps.state.Coupon_code_reducer2.fileName) {
    }
  }

  // shouldComponentUpdate(PrevProps, PrevState) {
  //   console.log("Reader -> shouldComponentUpdate -> PrevProps", PrevProps);
  //   console.log("Reader -> shouldComponentUpdate -> PrevState", PrevState);

  //   if (PrevState.Error === false && PrevState.TotalNumberArray !== 0) {
  //     return false;
  //   } else return true;
  // }
  delete_function = () => {
    // this.props.Delete_check(true);
    // this.props.Delete_present_code(this.props.SURVEY);

    // this.props.coupon_delete_top(this.props);
    swal({
      icon: 'error',
      title: 'Are you sure',
      text: `All the present coupon code will be deleted`,
      buttons: {
        catch: {
          text: 'Yes',
          value: 'ok',
        },
        cancel: 'Cancel',
      },
    }).then((e) => {
      if (e) {
        this.setState(
          {
            present_code: [],
            Present_code_sent: 0,
            delete_top_state: true,
          },
          () => {
            // this.props.Delete_check_top(true);

            let temp = {
              state: true,
              properties: this.props.SURVEY,
            }
            this.props.Delete_check(temp)
            this.props.Delete_present_code(this.props.SURVEY)

            this.props.coupon_delete_top(this.props)
            this.props.dispatch(
              CouponCodeaction_remove_codes_top(
                this.props.state.Coupon_code_reducer2.totalNumber,
                this.props.state.Coupon_code_reducer2.duplicate_removed,
                [],
                this.props.state.Coupon_code_reducer2.TotalCouponCode,
                this.props.state.Coupon_code_reducer2.fileName,
                this.props.state.Coupon_code_reducer2.Final_duplicate_removed
              )
            )
          }
        )
      }
    })
  }
  delete_function_bottom = () => {
    // this.props.Delete_check(true);
    this.props.coupon_delete()
    this.props.dispatch(CouponCodeaction_remove_codes_bottom(this.props.CouponCode_available))
  }

  // handleForce = (data, fileInfo) => {
  //   console.log(data, fileInfo)
  //   console.log('Reader -> handleForce -> fileInfo', fileInfo.name)

  //   let FIleINFO = fileInfo.name
  //   this.setState({ CSV_data: data })
  //   var couponcodeArray = []
  //   var newCouponArray = []

  //   var User_input_csv_header = Object.keys(data[0])[0]
  //   var User_input_csv_header_lower_case = User_input_csv_header.toLowerCase()

  //   if (User_input_csv_header_lower_case !== 'coupon_code') {
  //     this.setState({
  //       Error: true,
  //       Error_Message: `First column titile ${User_input_csv_header} is not equal to Coupon_Code as instructed`,
  //     })
  //     swal({
  //       icon: 'error',
  //       title: 'Error',
  //       text: `First column titile ${User_input_csv_header} is not equal to Coupon_Code as instructed`,
  //     })
  //   } else {
  //     this.setState({ FileInfo: fileInfo.name }, () => {
  //       this.progress_function('trigger')
  //     })
  //     data.forEach((e, i) => {
  //       couponcodeArray.push(e.coupon_code)
  //     })

  //     let temp = new Set(couponcodeArray)

  //     this.props.state.Coupon_code_reducer2.PresentCouponCode.forEach((e) => {
  //       temp.add(e)
  //     })
  //     let temp31 = this.props.state.Coupon_code_reducer2.PresentCouponCode.concat(couponcodeArray)
  //     let temp4 = new Set(temp31)
  //     console.log('handleForce -> temp4', temp4)
  //     let temp1 = Array.from(temp)
  //     console.log('handleForce -> temp1', temp1)

  //     console.log('handleForce -> this.state', this.state)
  //     if (this.state.delete_top_state === false) {
  //       this.props.sent_survey.forEach((e) => {
  //         if (temp1.indexOf(e) !== -1) {
  //           let temp56 = temp1.splice(temp1.indexOf(e), 1)
  //         }
  //       })
  //     }

  //     let temp3 = this.props.state.Coupon_code_reducer2.PresentCouponCode.length + couponcodeArray.length - temp1.length

  //     let array_without_duplicates = couponcodeArray.filter((value, index) => couponcodeArray.indexOf(value) === index)
  //     console.log('handleForce -> array_without_duplicates', array_without_duplicates)
  //     console.log('handleForce -> this.props.state.Coupon_code_reducer2.PresentCouponCode', this.props.state.Coupon_code_reducer2.PresentCouponCode)

  //     let array51 = array_without_duplicates.filter((obj) => {
  //       return this.props.state.Coupon_code_reducer2.PresentCouponCode.indexOf(obj) == -1
  //     })
  //     console.log('handleForce -> array51', array51)

  //     let final_filter = array51.filter((value, index) => array51.indexOf(value) === index)

  //     newCouponArray = array_without_duplicates.concat(this.props.state.Coupon_code_reducer2.PresentCouponCode)
  //     console.log('handleForce -> newCouponArray', newCouponArray)
  //     let array_without_duplicates11 = newCouponArray.filter((value, index) => newCouponArray.indexOf(value) === index)

  //     let array3 = array_without_duplicates11.filter(function (obj) {
  //       return array_without_duplicates.indexOf(obj) == -1
  //     })
  //     this.setState({
  //       Duplicate_removed: couponcodeArray.length - array_without_duplicates.length,
  //     })

  //     var Duplicate_removeed = temp3
  //     // array_without_duplicates11.length - final_filter.length;
  //     this.setState({ TotalNumberArray: array_without_duplicates.length })
  //     var Duplicate_removed_original_uploaded = couponcodeArray.length - temp1.length

  //     this.props.dispatch(
  //       CouponCodeaction21(
  //         couponcodeArray.length,
  //         Duplicate_removeed,
  //         this.props.state.Coupon_code_reducer2.PresentCouponCode,
  //         temp1.length,
  //         FIleINFO,
  //         Duplicate_removed_original_uploaded
  //       )
  //     )
  //     this.props.Array_sending(temp1)
  //   }
  // }

  handlefileUpload = (file) => {
    let _filename = file.target.files[0].name

    var reader = new FileReader()

    if (file.target.files.length > 0) {
      reader.readAsText(file.target.files[0])
      reader.onloadstart = (event) => {}
      reader.onprogress = (event) => {}
      reader.onloadend = (event) => {
        var allTextLines = event.target.result.split(/\r\n|\n/)
        var lines = allTextLines.map((data) => data.split(';'))

        let fileHeader = lines[0].toString()

        if (fileHeader.toLowerCase().trim() !== 'coupon code') {
          swal({
            icon: 'error',
            title: 'Error',
            text: `CSV file should have only one column with the title Coupon Code.`,
          })
        } else {
          this.setState({ FileInfo: _filename }, () => {
            this.progress_function('trigger')
          })
          let CouponcodeUploaded = lines.flat()

          CouponcodeUploaded.shift()
          CouponcodeUploaded = CouponcodeUploaded.filter((e) => e !== '')

          let temp = new Set(CouponcodeUploaded)
          this.props.state.Coupon_code_reducer2.PresentCouponCode.forEach((e) => {
            temp.add(e)
          })
          // let temp31 = this.props.state.Coupon_code_reducer2.PresentCouponCode.concat(CouponcodeUploaded)
          // let temp4 = new Set(temp31)
          let temp1 = Array.from(temp)
          if (this.state.delete_top_state === false) {
            this.props.sent_survey.forEach((e) => {
              if (temp1.indexOf(e) !== -1) {
                // let temp56 = temp1.splice(temp1.indexOf(e), 1)
              }
            })
          }
          let temp3 = this.props.state.Coupon_code_reducer2.PresentCouponCode.length + CouponcodeUploaded.length - temp1.length
          let Duplicate_removed_original_uploaded = CouponcodeUploaded.length - temp1.length
          this.props.dispatch(
            CouponCodeaction21(
              CouponcodeUploaded.length, //coupon code uploaded length
              temp3, //Duplicate_removeed
              this.props.state.Coupon_code_reducer2.PresentCouponCode, //already uploaded coupon code
              temp1.length, //removing the already uploaded coupon code from the present uploaded coupon codes
              _filename, // name of the file uploaded
              Duplicate_removed_original_uploaded //duplicate removed from uploaded coupon codes.
            )
          )
          this.props.Array_sending(temp1)
        }
      }
    }
  }

  render() {
    return (
      <div className="container" style={{ flexDirection: 'column' }}>
        <div
          className="row_container first"
          style={{
            fontWeight: '550',
            marginBottom: '20px',
          }}
        >
          {this.props.sent_survey.length !== 0 ? (
            <>
              <div>Coupon codes available:</div>{' '}
              <span
                style={{
                  color: '#000000',
                  marginLeft: '5px',
                }}
              >
                {`${this.state.Present_code_sent} / ${this.state.present_code.length + this.state.Present_code_sent}`}
              </span>
            </>
          ) : (
            <>
              <div>Coupon codes available:</div>
              <span
                style={{
                  color: '#000000',
                  marginLeft: '5px',
                }}
              >{`0 / ${this.state.present_code.length}`}</span>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: '6px',
            }}
          >
            {this.state.present_code.length > 0 ? (
              <img
                className="delete_icon"
                onClick={() => {
                  this.delete_function()
                }}
                src={delete_image}
                alt={''}
              />
            ) : null}
          </div>
        </div>
        <div className="row_container outer1">
          <div className="row_container1 ">
            <div>
              <button
                size="lg"
                color="primary"
                data-tip="click to upload coupon code"
                data-for="testing"
                onClick={() => {
                  document.querySelector("input[type='file']").click()
                }}
                className="Font_style special_button manage_coupon_code"
                style={{
                  width: '111%',
                }}
              >
                <img
                  style={{ height: '40px', width: '60px' }}
                  src={`https://api-dev.carwashsurveys.com/var/www/dev/carwash-backend/public/profile/1615995803069_checked.svg`}
                  alt={''}
                />
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: '1000',
                    paddingBottom: '10px',
                  }}
                >
                  Select CSV file to upload
                </span>
              </button>
              <input
                type="file"
                data-tip="React-tooltip"
                data-for="sadFace"
                style={{
                  display: 'none',
                }}
                onChange={(e) => this.handlefileUpload(e)}
                onClick={(e) => {
                  e.target.value = null
                }}
                accept=".csv"
              />
              <ReactTooltip id="testing" place="bottom" effect="solid"></ReactTooltip>
              <CSVReader
                cssClass="react-csv-input"
                cssInputClass="input_class "
                cssLabelClass="Label_class"
                onFileLoaded={this.handleForce}
                onClick={(e) => {
                  e.target.value = null
                }}
                parserOptions={papaparseOptions}
              />
            </div>
          </div>
          <div className="row_container1 ">
            <div
              className="wrapper-div"
              style={{
                marginLeft: '45px',
              }}
            >
              <div
                className="Font_style11"
                style={{
                  paddingLeft: '5px',
                }}
              >
                Coupon codes processed:{' '}
                <span
                  style={{
                    color: '#000000',
                  }}
                >
                  {this.props.state.Coupon_code_reducer2.totalNumber}
                </span>
              </div>

              {this.props.state.Coupon_code_reducer2.duplicate_removed > 0 && this.state.progress_value === 100 ? (
                <div className="Font_style11 second_padding">
                  Duplicates removed:{' '}
                  <span
                    style={{
                      color: '#000000',
                    }}
                  >
                    {this.props.state.Coupon_code_reducer2.duplicate_removed}
                  </span>
                </div>
              ) : (
                <div></div>
              )}

              {this.props.state.Coupon_code_reducer2.duplicate_removed > 0 && this.state.progress_value === 100 ? (
                <div className="Font_style11 second_padding" style={{ paddingBottom: '5px' }}>
                  Total new codes added:{' '}
                  <span
                    style={{
                      color: '#000000',
                    }}
                  >
                    {this.props.state.Coupon_code_reducer2.totalNumber - this.props.state.Coupon_code_reducer2.duplicate_removed}
                  </span>
                </div>
              ) : (
                <div></div>
              )}

              {/* {this.props.state.Coupon_code_reducer2.TotalCouponCode > 0 && */}
              {this.state.progress_value === 100 ? (
                <div className="Font_style11 button_style">
                  Total codes available:{' '}
                  <span
                    style={{
                      color: '#000000',
                    }}
                  >
                    {this.props.state.Coupon_code_reducer2.TotalCouponCode}
                  </span>
                  {/* { 
                    this.props.state.Coupon_code_reducer2
                      .array_without_duplicates11.length
                  } */}
                  {/* {JSON.stringify(
                    this.props.state.Coupon_code_reducer2.totalNumber -
                      this.props.state.Coupon_code_reducer2.duplicate_removed
                  )} */}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <div className="row_container ">
          <div
            style={{
              display: 'flex',
            }}
          >
            {this.props.state.Coupon_code_reducer2.fileName === undefined || this.props.state.Coupon_code_reducer2.fileName === '' ? (
              <span className="Font_style left-align">No file chosen</span>
            ) : (
              <>
                <span className="Font_style left-align" style={{ fontSize: '13px' }}>
                  {this.state.progress_value === 100 ? (
                    <text>
                      {this.props.state.Coupon_code_reducer2.fileName.length > 17
                        ? 'File: ' + this.props.state.Coupon_code_reducer2.fileName.substring(0, 15) + '....'
                        : 'File: ' + this.props.state.Coupon_code_reducer2.fileName}
                    </text>
                  ) : (
                    'Uploading'
                  )}
                </span>
                <div style={{ position: 'absolute', right: '46%' }}>
                  {this.state.progress_value === 100 ? (
                    <img
                      className="delete_icon"
                      onClick={() => {
                        this.delete_function_bottom()
                      }}
                      src={delete_image}
                      alt={''}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row_container">
          <div
            className="inner progress-inner"
            style={{
              display: this.props.state.Coupon_code_reducer2.fileName
                ? this.props.state.Coupon_code_reducer2.fileName.length > 0
                  ? `block`
                  : `none`
                : `none`,
              marginTop: '5px',

              position: 'relative',
            }}
          >
            <Progress className="progress_special" color="warning" value={this.state.progress_value} />
            <img
              style={{
                display: this.state.progress_value === 100 ? `block` : `none`,
                height: '15px',
                width: '20px',
                paddingBottom: '5px',
              }}
              src={tick_icon}
              alt={''}
            />
          </div>
        </div>

        {/* <div className="Font_style bottom_padding">Add Coupon Code</div>
        <div className="Font_style bottom_padding">
          Coupon Code Available{" "}
          {this.props.state.Coupon_code_reducer2.PresentCouponCode.length}
        </div>
        <div className="wrapper-div bottom_padding">
          <Button
            size="lg"
            color="primary"
            onClick={() => {
              document.querySelector(".input_class").click();
            }}
            className="Font_style special_button"
            style={{
              padding: "15px 40px",
            }}
          >
            Upload CSV file
          </Button>
          {this.props.state.Coupon_code_reducer2.fileName === null ||
          this.props.state.Coupon_code_reducer2.fileName === "" ? (
            <span className="Font_style center-align">No file Chosen</span>
          ) : (
            <span className="Font_style center-align">
              {this.props.state.Coupon_code_reducer2.fileName}
            </span>
          )}
        </div>

        <CSVReader
          cssClass="react-csv-input"
          cssInputClass="input_class "
          cssLabelClass="Label_class"

          onFileLoaded={this.handleForce}
          parserOptions={papaparseOptions}
        />
        <p className="Font_style Font_Style1 bottom_padding">
          The CSV Should have one column with title as Coupon_code
        </p>
        <div className="wrapper-div">
          <div className="Font_Style1">
            Codes Uploaded {this.props.state.Coupon_code_reducer2.totalNumber}
          </div>
          {this.props.state.Coupon_code_reducer2.duplicate_removed > 0 ? (
            <div className="Font_Style1">
              Duplicate removed{" "}
              {JSON.stringify(
                this.props.state.Coupon_code_reducer2.duplicate_removed
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="wrapper-div">
          <div className="Font_Style1">
            Totol Coupon Code Available{" "}
            {JSON.stringify(
              this.props.state.Coupon_code_reducer2.TotalCouponCode
            )}
          </div>
          {this.props.state.Coupon_code_reducer2.Final_duplicate_removed > 0 ? (
            <div className="Font_Style1">
              Duplicate removed{" "}
              {JSON.stringify(
                this.props.state.Coupon_code_reducer2.Final_duplicate_removed
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div> */}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reader))
