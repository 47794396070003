export const SurveyOptionReducer = (
  state = { state_change: false, json: null },
  action
) => {
  switch (action.type) {
    case "SAVE_SURVEY":
      let json = action.json;
      return {
        ...state,
        json: json,
        state_change: !state.state_change,
      };

    case "REMOVE_SURVEY":
      return {};
    default:
      return state;
  }
};
