import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { connect } from 'react-redux'
// import bg3 from '../../assets/utils/images/originals/citynights.jpg'
import SweetAlert from 'sweetalert-react'
import { Col, Row, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { registerUserAction } from '../../redux/actions/Login1'
// import validator from 'validator'
import swal from 'sweetalert'
import * as swal1 from '@sweetalert/with-react'
import { handleValidation } from '../_component/validation'
import ReactTooltip from 'react-tooltip'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import './index.css'
// import ToolTip from '../tooltip'
import LaddaButton, {
  // EXPAND_LEFT,
  EXPAND_RIGHT,
  // EXPAND_UP,
  // EXPAND_DOWN,
  // SLIDE_LEFT,
  // SLIDE_RIGHT,
  // SLIDE_UP,
  // SLIDE_DOWN,
  // ZOOM_IN,
  // ZOOM_OUT,
} from 'react-ladda'
class SignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Email: '',
      Name: '',
      Password: '',
      RepeatPassword: '',
      Checkbox: false,
      CheckboxError: null,
      PasswordChecker: false,
      ErrorMessage: null,
      ErrorMessageChecker: null,
      EmailErrorChecker: null,
      EmailError: null,
      NameErrorChecker: null,
      NameError: null,
      businessName: '',
      businessNameError: null,
      businessNameErrorChecker: false,
      PasswordError: null,
      PasswordErrorChecker: null,
      RepeatPassworderrorChecker: null,
      PasswordSwitch: true,
      PasswordSwitch1: true,

      RepeatPassworderror: null,
      UseralreadyExisterror: { state: null, message: '', type: null },
      UsercreatedSuccesfully: null,
      ButtonLoading: false,
      white_space_counter: 0,
      Hover_state: true,
      err: {},
    }
  }
  componentDidMount() {}

  componentDidUpdate(Prevprops, PrevState) {
    if (Prevprops.state.LoginReducer !== this.props.state.LoginReducer) {
      var { LoginReducer } = this.props.state

      if (LoginReducer !== null) {
        if (LoginReducer.response.success === true) {
          swal({
            title: 'Thank You!',
            text: LoginReducer.response.message,
            icon: 'success',
            buttons: {
              catch: {
                text: 'Login Now',
                value: 'ok',
              },
            },
          }).then((e) => {
            if (e) {
              this.props.history.push('/login')
            }
          })
          this.setState({
            ButtonLoading: false,
            Email: '',
            Name: '',
            Password: '',
            RepeatPassword: '',
            Checkbox: false,
            // UseralreadyExisterror: {
            //   state: true,
            //   message: LoginReducer.response.message,
            //   type: "success",

            // },
          })
        } else {
          // var span = document.createElement("div");
          // span.innerHTML = `<Link to="/login"className="text-primary">Sign in</Link>` ;
          // swal({
          //   title: LoginReducer.response.message,//"Something went wrong yoyo",
          //   content: span
          //     //`<Link to="/login"className="text-primary">Sign in</Link>`
          //   ,     //LoginReducer.response.message,
          //   icon: "error",
          // });
          swal1({
            content: (
              <div>
                <div className="swal-icon swal-icon--warning">
                  <span className="swal-icon--warning__body">
                    <span className="swal-icon--warning__dot"></span>
                  </span>
                </div>
                <div className="swal-title">
                  <br></br>
                  {LoginReducer.response.message}
                </div>
                {/* <div className="swal-title">
                  {data.original.Name}
                </div> */}
                <div>
                  <br></br>
                </div>
                <div className="swal-text">
                  Please <a href="/login">Sign in</a>
                </div>
                {/* <div className="swal-footer">
                <div className="swal-button-container">
                  <button className="swal-button swal-button--cancel">
                    CANCEL
                  </button>
                </div>
                <div className="swal-button-container">
                  <button className="swal-button swal-button--catch">
                    OKAY
                  </button>
                </div>
              </div> */}
              </div>
            ),
            buttons: {
              cancel: 'Cancel',
              // catch: {
              //   text: "Confirm",
              //   value: "delete",
              // },
            },
          })
          this.setState({
            ButtonLoading: false,
            // UseralreadyExisterror: {
            //   state: true,
            //   message: LoginReducer.response.message,
            //   type: "error",
            // },
          })
        }
      }
    }
  }

  conditionChecker = () => {
    let { Name, RepeatPassword, Email, Password, businessName, Checkbox } = this.state
    let formFileds = {
      Name,
      RepeatPassword,
      Email,
      Password,
      businessName,
      Checkbox,
    }
    let test = handleValidation(formFileds)
    if (!test) {
      this.props.dispatch(registerUserAction(this.state.Name, this.state.Email, this.state.Password, this.state.businessName))
    } else {
      this.setState(
        {
          err: test,
          ButtonLoading: false,
        },
        () => {}
      )
    }

    // console.log("SignUp -> conditionChecker -> this.state", this.state);
    // if (
    //   this.state.RepeatPassword === '' &&
    //   this.state.Email === '' &&
    //   this.state.Password === '' &&
    //   this.state.Name === '' &&
    //   this.state.businessName === '' &&
    //   this.state.Checkbox === false
    // ) {
    //   this.setState({
    //     EmailError: 'email is required',
    //     PasswordError: 'password is required ',
    //     NameError: 'name is required ',
    //     businessNameError: 'business name is required',
    //     businessNameErrorChecker: true,
    //     EmailErrorChecker: true,
    //     NameErrorChecker: true,
    //     PasswordErrorChecker: true,
    //     RepeatPasswordChecker: true,
    //     CheckboxError: 'kindly accept our terms and condition',
    //     ButtonLoading: false,
    //   })
    // }
    // if (this.state.Email === '') {
    //   this.setState({
    //     EmailErrorChecker: true,
    //     EmailError: 'This field is required',
    //     ButtonLoading: false,
    //   })
    // }
    // if (this.state.Password === '') {
    //   this.setState({
    //     PasswordErrorChecker: true,
    //     PasswordError: 'This field is required',
    //     ButtonLoading: false,
    //   })
    // }
    // if (this.state.Name === '') {
    //   this.setState({
    //     NameErrorChecker: true,
    //     NameError: 'This field is required ',
    //     ButtonLoading: false,
    //   })
    // }
    // if (this.state.businessName === '') {
    //   console.log('business name is empty')
    //   this.setState(
    //     {
    //       businessNameErrorChecker: true,
    //       businessNameError: 'This field is required ',
    //       ButtonLoading: false,
    //     },
    //     async () => {
    //       console.log('your state', this.state)
    //     }
    //   )
    //   // console.log("you state",this.state)
    // }
    // if (this.state.RepeatPassword === '') {
    //   this.setState({
    //     RepeatPasswordChecker: true,
    //     RepeatPassworderror: 'This field is required ',
    //     ButtonLoading: false,
    //   })
    // }
    // if (this.state.Checkbox === false) {
    //   this.setState({
    //     CheckboxError: 'Kindly accept our terms and conditions',
    //     ButtonLoading: false,
    //   })
    // }
    // if (this.state.Email !== '') {
    //   if (!validator.isEmail(this.state.Email)) {
    //     this.setState({
    //       EmailErrorChecker: true,
    //       EmailError: 'invalid Email',
    //       ButtonLoading: false,
    //     })
    //   }
    // }

    // if (this.state.Password !== '' && this.state.RepeatPassword !== '') {
    //   if (this.state.Password !== this.state.RepeatPassword) {
    //     this.setState({
    //       PasswordErrorChecker: true,
    //       Password: '',
    //       RepeatPassword: '',
    //       PasswordError: 'Password does not match',
    //       ButtonLoading: false,
    //     })
    //   } else if (this.state.Password.length <= 6) {
    //     this.setState({
    //       PasswordErrorChecker: true,
    //       PasswordError: 'pasword should be minimum 7 characters ',
    //       ButtonLoading: false,
    //     })
    //   }
    // }

    // // if (
    // //   this.state.Password === this.state.RepeatPassword &&
    // //   this.state.Checkbox === true
    // // )

    // // console.log("SignUp -> conditionChecker -> this.state", this.state);
    // console.log('business name', this.state.businessNameErrorChecker)
    // if (
    //   this.state.EmailErrorChecker === false &&
    //   this.state.NameErrorChecker === false &&
    //   this.state.PasswordErrorChecker === false &&
    //   this.state.businessNameErrorChecker === false &&
    //   this.state.RepeatPasswordChecker === false &&
    //   this.state.Checkbox === true
    // ) {
    //   // console.log("SignUp -> conditionChecker -> this.state", this.state);
    //   if (this.state.Password !== '' && this.state.RepeatPassword !== '') {
    //     // console.log("SignUp -> conditionChecker -> this.state", this.state);
    //     if (this.state.Password !== this.state.RepeatPassword) {
    //       // console.log("SignUp -> conditionChecker -> this.state", this.state);
    //       this.setState({
    //         PasswordErrorChecker: true,
    //         Password: '',
    //         RepeatPassword: '',
    //         PasswordError: 'Password does not match',
    //         ButtonLoading: false,
    //       })
    //     } else if (this.state.Password.length <= 6) {
    //       // console.log("SignUp -> conditionChecker -> this.state", this.state);
    //       this.setState({
    //         PasswordErrorChecker: true,
    //         PasswordError: 'pasword should be minimum 7 characters ',
    //         ButtonLoading: false,
    //       })
    //     } else if (this.state.Password.length > 6) {
    //       // console.log("SignUp -> conditionChecker -> this.state", this.state);
    //       if (this.state.Password !== this.state.RepeatPassword) {
    //         // console.log("SignUp -> conditionChecker -> this.state", this.state);
    //         this.setState({
    //           PasswordErrorChecker: true,
    //           Password: '',
    //           RepeatPassword: '',
    //           PasswordError: 'Password does not match',
    //           ButtonLoading: false,
    //         })
    //       } else {
    //         let whiteSpaceChecker = this.state.Name.trim()

    //         console.log('SignUp -> conditionChecker -> whiteSpaceChecker', whiteSpaceChecker)
    //         console.log('SignUp -> conditionChecker -> whiteSpaceChecker', whiteSpaceChecker.length)

    //         // console.log("SignUp -> conditionChecker -> this.state", this.state);

    //         if (whiteSpaceChecker === '') {
    //           console.log('SignUp -> conditionChecker -> this.state', this.state)
    //           this.setState({
    //             NameErrorChecker: true,
    //             NameError: 'field is required',
    //             ButtonLoading: false,
    //           })
    //         } else {
    //           console.log('SignUp -> conditionChecker -> this.state', this.state)
    //           this.props.dispatch(registerUserAction(this.state.Name, this.state.Email, this.state.Password, this.state.businessName))
    //         }

    //         // console.log("SignUp -> conditionChecker -> this.state", this.state);
    //       }
    //     }
    //   }
    //   // else {
    //   // //   console.log("SignUp -> conditionChecker -> this.state", this.state);
    //   //   this.props.dispatch(
    //   //     registerUserAction(
    //   //       this.state.Name,
    //   //       this.state.Email,
    //   //       this.state.Password
    //   //     )
    //   //   );
    //   // }
    // }
  }

  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true,
      ErrorMessage: null,
    }
    return (
      <Fragment>
        <SweetAlert
          title={this.state.UseralreadyExisterror.message}
          confirmButtonColor=""
          show={this.state.UseralreadyExisterror.state}
          text=""
          type={this.state.UseralreadyExisterror.type}
          onConfirm={() => {
            if (this.state.UseralreadyExisterror.type === 'error') {
              this.setState({
                Email: '',
                Name: '',
                Password: '',
                RepeatPassword: '',
                Checkbox: false,
                UseralreadyExisterror: { state: false },
              })
            } else {
              this.setState({ UseralreadyExisterror: { state: false } })

              this.props.history.push('/login')
            }
          }}
        />

        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="5" className="d-lg-flex d-xs-none">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage:
                          'url(' +
                          ` https://images.pexels.com/photos/4665707/pexels-photo-4665707.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940` +
                          ')',
                      }}
                    />
                    <div className="slider-content">
                      <h3>Scalable, Modular, Consistent</h3>
                      <p>
                        Easily exclude the components you don't require. Lightweight, consistent Bootstrap based styles across all elements and
                        components
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col lg="7" md="12" className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <h4>
                  <div>Welcome,</div>
                  <span>
                    It only takes a <span className="text-success">few seconds</span> to create your account
                  </span>
                </h4>
                <div>
                  <Row
                  // form
                  >
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          <span className="text-danger">*</span> Email
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          id="exampleEmail"
                          value={this.state.Email}
                          invalid={
                            Object.keys(this.state.err).length > 0
                              ? this.state.err.Email
                                ? this.state.err.Email.length > 0
                                  ? true
                                  : false
                                : false
                              : false
                          }
                          placeholder="Email here..."
                          onChange={(e) => {
                            let obj = this.state.err
                            obj.Email = ''
                            this.setState({ Email: e.target.value, err: obj, ButtonLoading: false })
                          }}
                          onClick={() => {
                            let obj = this.state.err
                            obj.Email = ''
                            this.setState({
                              err: obj,
                              ButtonLoading: false,
                            })
                            // this.setState({
                            //   EmailError: false,
                            //   NameErrorChecker: false,
                            //   PasswordErrorChecker: false,
                            //   RepeatPasswordChecker: false,
                            //   EmailErrorChecker: false,
                            // })
                          }}
                        />
                        <div>
                          {Object.keys(this.state.err).length > 0 ? (
                            this.state.err.Email ? (
                              this.state.err.Email.length > 0 ? (
                                <div style={{ color: 'red' }}>{this.state.err.Email}</div>
                              ) : (
                                <div></div>
                              )
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                        {/* <div>{this.state.EmailErrorChecker === true ? <div style={{ color: 'red' }}>{this.state.EmailError}</div> : <div></div>}</div> */}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleName1">
                          <span className="text-danger">*</span> Name
                        </Label>
                        <Input
                          type="text"
                          name="text"
                          id="exampleName1"
                          placeholder="Name here..."
                          value={this.state.Name}
                          invalid={
                            Object.keys(this.state.err).length > 0
                              ? this.state.err.Name
                                ? this.state.err.Name.length > 0
                                  ? true
                                  : false
                                : false
                              : false
                          }
                          onChange={(e) => {
                            let obj = this.state.err
                            obj.Name = ''
                            this.setState({ Name: e.target.value, err: obj, ButtonLoading: false })
                          }}
                          onClick={() => {
                            let obj = this.state.err
                            obj.Name = ''
                            this.setState({
                              err: obj,
                              ButtonLoading: false,
                            })
                          }}
                        />
                        <div>
                          {Object.keys(this.state.err).length > 0 ? (
                            this.state.err.Name ? (
                              this.state.err.Name.length > 0 ? (
                                <div style={{ color: 'red' }}>{this.state.err.Name}</div>
                              ) : (
                                <div></div>
                              )
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                        {/* <div>{this.state.NameErrorChecker === true ? <div style={{ color: 'red' }}>{this.state.NameError}</div> : <div></div>}</div> */}
                      </FormGroup>
                    </Col>
                    {/* New changes, added Business name field */}

                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleName">
                          <span className="text-danger">*</span> Business Name
                        </Label>
                        <Input
                          type="text"
                          name="text"
                          id="exampleName"
                          placeholder="Business name here..."
                          value={this.state.businessName}
                          invalid={
                            Object.keys(this.state.err).length > 0
                              ? this.state.err.businessName
                                ? this.state.err.businessName.length > 0
                                  ? true
                                  : false
                                : false
                              : false
                          }
                          onChange={(e) => {
                            let obj = this.state.err
                            obj.businessName = ''
                            this.setState({ businessName: e.target.value, err: obj, ButtonLoading: false })
                          }}
                          onClick={() => {
                            let obj = this.state.err
                            obj.businessName = ''
                            this.setState({
                              err: obj,
                              ButtonLoading: false,
                            })
                          }}
                        />
                        <div>
                          {Object.keys(this.state.err).length > 0 ? (
                            this.state.err.businessName ? (
                              this.state.err.businessName.length > 0 ? (
                                <div style={{ color: 'red' }}>{this.state.err.businessName}</div>
                              ) : (
                                <div></div>
                              )
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="examplePassword">
                          <span className="text-danger">*</span> Password
                        </Label>
                        <InputGroup>
                          <Input
                            type={this.state.PasswordSwitch ? 'Password' : 'text'}
                            name="password"
                            data-tip="React-tooltip"
                            data-for="sadFace"
                            id="examplePassword"
                            data-class="tooltip_customize"
                            placeholder="Password here..."
                            value={this.state.Password}
                            invalid={
                              Object.keys(this.state.err).length > 0
                                ? this.state.err.Password
                                  ? this.state.err.Password.length > 0
                                    ? true
                                    : false
                                  : false
                                : false
                            }
                            onChange={(e) => {
                              let obj = this.state.err
                              obj.Password = ''
                              this.setState({ Password: e.target.value, err: obj, ButtonLoading: false })
                            }}
                            onClick={() => {
                              let obj = this.state.err
                              obj.Password = ''
                              this.setState({
                                err: obj,
                                ButtonLoading: false,
                              })
                            }}
                          />
                          <InputGroupAddon
                            addonType="append"
                            onClick={() => {
                              this.setState({
                                PasswordSwitch: !this.state.PasswordSwitch,
                              })
                            }}
                          >
                            <InputGroupText>{this.state.PasswordSwitch ? <VisibilityIcon /> : <VisibilityOffIcon />}</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <ReactTooltip
                          id="sadFace"
                          place="bottom"
                          type="warning"
                          effect="solid"
                          style={{
                            border: '1px solid black',
                          }}
                        >
                          <div
                            style={{
                              width: '250px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>As a minimum your password must contain 7 characters</span>
                              <span>Tips for generating a secure password</span>
                            </div>
                            <ul
                              style={{
                                width: 'fit-content',
                                margin: '0px',
                                paddingLeft: '30px',
                              }}
                            >
                              <li>Include upper case letter</li>
                              <li>Include lower case letter</li>

                              <li>Include a number</li>

                              <li>Include special character</li>
                            </ul>
                          </div>
                        </ReactTooltip>

                        {/* <ToolTip
                          props={this.state.Hover_state}
                          message={'Open Surveys can receive responses Closed surveys can not receive responses'}
                          location="Singup"
                        /> */}
                        <div>
                          {Object.keys(this.state.err).length > 0 ? (
                            this.state.err.Password ? (
                              this.state.err.Password.length > 0 ? (
                                <div style={{ color: 'red' }}>{this.state.err.Password}</div>
                              ) : (
                                <div></div>
                              )
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div></div>
                          )}
                          {/* {this.state.PasswordErrorChecker === true ? <div style={{ color: 'red' }}>{this.state.PasswordError}</div> : <div></div>} */}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="examplePasswordRep">
                          <span className="text-danger">*</span> Repeat Password
                        </Label>
                        <InputGroup>
                          <Input
                            type={this.state.PasswordSwitch1 ? 'Password' : 'text'}
                            name="passwordrep"
                            id="examplePasswordRep"
                            placeholder="Repeat Password here..."
                            invalid={
                              Object.keys(this.state.err).length > 0
                                ? this.state.err.RepeatPassword
                                  ? this.state.err.RepeatPassword.length > 0
                                    ? true
                                    : false
                                  : false
                                : false
                            }
                            value={this.state.RepeatPassword}
                            // onClick={() => {
                            //   this.setState({
                            //     PasswordChecker: false,
                            //     RepeatPasswordChecker: false,
                            //     EmailError: false,
                            //     NameErrorChecker: false,
                            //     PasswordErrorChecker: false,
                            //   })
                            // }}
                            onClick={() => {
                              let obj = this.state.err
                              obj.RepeatPassword = ''
                              this.setState({
                                err: obj,
                                ButtonLoading: false,
                              })
                            }}
                            onChange={(e) => {
                              let obj = this.state.err
                              obj.RepeatPassword = ''
                              this.setState({
                                RepeatPassword: e.target.value,
                                err: obj,
                                ButtonLoading: false,
                              })
                            }}
                          />
                          <InputGroupAddon
                            addonType="append"
                            onClick={() => {
                              this.setState({
                                PasswordSwitch1: !this.state.PasswordSwitch1,
                              })
                            }}
                          >
                            <InputGroupText>{this.state.PasswordSwitch1 ? <VisibilityIcon /> : <VisibilityOffIcon />}</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <div>
                          {Object.keys(this.state.err).length > 0 ? (
                            this.state.err.RepeatPassword ? (
                              this.state.err.RepeatPassword.length > 0 ? (
                                <div style={{ color: 'red' }}>{this.state.err.RepeatPassword}</div>
                              ) : (
                                <div></div>
                              )
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div></div>
                          )}

                          {/* {this.state.RepeatPasswordChecker === true ? (
                            <div style={{ color: 'red' }}>{this.state.RepeatPassworderror}</div>
                          ) : (
                            <div></div>
                          )} */}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="mt-3" check>
                    <Input
                      type="checkbox"
                      name="check"
                      id="exampleCheck"
                      onChange={() => {
                        let obj = this.state.err
                        obj.Checkbox = ''
                        this.setState({ Checkbox: !this.state.Checkbox, err: obj, ButtonLoading: false })
                      }}
                      checked={this.state.Checkbox}
                    />
                    <Label for="exampleCheck" check>
                      Accept our{' '}
                      <a
                        href="/signup"
                        // onClick={(e) => e.preventDefault()}
                      >
                        Terms and Conditions
                      </a>
                    </Label>
                    {Object.keys(this.state.err).length > 0 ? (
                      this.state.err.Checkbox ? (
                        this.state.err.Checkbox.length > 0 ? (
                          <div style={{ color: 'red' }}>{this.state.err.Checkbox}</div>
                        ) : (
                          <div></div>
                        )
                      ) : (
                        <div></div>
                      )
                    ) : (
                      <div></div>
                    )}
                    {/* {this.state.Checkbox !== true ? <div style={{ color: 'red' }}>{this.state.CheckboxError}</div> : <div></div>} */}
                  </FormGroup>
                  <div className="mt-4 d-flex align-items-center">
                    <h5 className="mb-0">
                      Already have an account?{' '}
                      <Link
                        // href="https://colorlib.com/"
                        to="/login"
                        // onClick={(e) => e.preventDefault()}
                        className="text-primary"
                      >
                        Sign in
                      </Link>
                    </h5>
                    <div className="ml-auto">
                      <LaddaButton
                        data-style={EXPAND_RIGHT}
                        loading={this.state.ButtonLoading}
                        color="primary"
                        className="btn-wide btn btn-primary btn-pill btn-shadow btn-hover-shine btn-wide btn btn-primary btn-pill btn-shadow btn-hover-shine btn btn-primary btn-lg"
                        size="lg"
                        onClick={() => {
                          this.setState({ ButtonLoading: true }, () => {
                            this.conditionChecker()
                          })
                        }}
                      >
                        Create Account
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
// export default SignUp;
