export const SurveyEditReducer = (state = [], action) => {
  switch (action.type) {
    case "SURVEYEDIT":
      return {
        state: action.JSON,
      };
    default:
      return {
        state: state,
      };
  }
};
