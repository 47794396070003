import React from 'react'
import Header from './header'
import Loader11 from '../../Loader'
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Button,
} from 'reactstrap'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { LoaderActions_loadingtrue, LoaderActions_loadingfalse } from '../../../redux/actions/Loader'
import ProjectSettings from '../../../global/Global'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import SweetAlert from 'sweetalert-react'
// import { DeleteSurvey } from '../../../redux/actions/Survey'
import { withRouter } from 'react-router-dom'
// import { LineChart, Line, ResponsiveContainer } from 'recharts'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './index.css'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { useTable, useSortBy, ReactTable } from "react-table";
// import { useSelector, useDispatch } from "react-redux";

import MoreVertIcon from '@material-ui/icons/MoreVert'

import DeleteIcon from '@material-ui/icons/Delete'

class Options extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showState: false,
      DeleteNotification: false,
      UpdateNotification: false,
      DeleteUserDetails: null,
      UpdateUserDetails: {},
      url: window.location.href,
      token: this.props.state.LoginReducer11.response.token,
      UserList: [],
      searchText: '',
      tempUserList: [],
      copied: false,
      Error: null,
      checked: false,
      columnsssss: [],
      columnsss: [
        {
          Header: 'User Name',
          accessor: 'name',
          Cell: (row) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ height: '100%', width: '100%' }}>{row.original.name}</div>
              {/* <div>{row.original.updatedAt}</div> */}
            </div>
          ),
        },
        {
          Header: 'Business Name',
          accessor: 'businessName',

          Cell: (row) => {
            return (
              <div>
                <div>{row.original.businessName}</div>
              </div>
            )
          },
        },
        {
          Header: 'Email',
          accessor: 'email',

          Cell: (row) => {
            return (
              <div>
                <div>{row.original.email}</div>
              </div>
            )
          },
        },
        {
          Header: 'Status',
          accessor: 'status',

          Cell: (row) => {
            // function ActiveInactive(state) {
            //   ACtiveInactiveTrueFalse(state)
            // }

            const ACtiveInactiveTrueFalse = (state) => {
              let id = row.original.id
              let name = row.original.name
              let email = row.original.email
              // let businessName = row.original.businessName

              const Oppositestatus = (s) => {
                if (s.includes('Active')) {
                  return 'Inactive'
                } else return 'Active'
              }
              let body1 = {
                id: id,
                name: name,
                email: email,
                status: Oppositestatus(JSON.parse(row.original.status)),
              }
              fetch(ProjectSettings.baseURL + '/api/user', {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                },

                body: JSON.stringify(body1),
              })
                .then((r) => {
                  return r.json()
                })
                .then((e) => {
                  this.getUserist()
                  this.setState({ UpdateNotification: false })
                })
            }

            var state123 = TrueFalse(row.original.status)
            function TrueFalse(s) {
              return s.includes('Active')
            }
            // const changeState = () => {
            //   let id = row.original.id
            //   let name = row.original.name
            //   let email = row.original.email
            //   let status = row.original.status
            //   let body1 = {
            //     id: id,
            //     name: name,
            //     email: email,
            //     status: JSON.parse(row.original.status),
            //   }
            // }

            return (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state123}
                      onChange={() => {
                        ACtiveInactiveTrueFalse(row.original.status)
                      }}
                    />
                  }
                  label={JSON.parse(row.original.status)}
                />
              </div>
            )
          },
        },
        {
          Header: 'Actions',
          accessor: 'actions',
          Cell: (data) => (
            <div className="d-block w-100 text-center">
              <UncontrolledButtonDropdown>
                <DropdownToggle className="btn-icon btn-icon-only btn btn-link" color="link">
                  <MoreVertIcon style={{ color: 'black' }} />
                </DropdownToggle>
                <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
                  <DropdownItem
                    onClick={(e) => {
                      this.props.history.push(`/User-View/${data.original.id}/1`)
                      // this.props.history.push(
                      //   `/edit-survey/${data.original.id}`
                      // );
                      // setSurveyId(data.original.id);
                    }}
                  >
                    <AssignmentIcon className="dropdown-icon" />
                    <span> View Survey</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={(e) => {
                      // this.setState({ DeleteNotification: true });

                      this.props.history.push(`/User-edit/${data.original.id}`)
                    }}
                  >
                    <AccountCircleIcon className="dropdown-icon" />
                    <span>Update User</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={(e) => {
                      this.setState({
                        DeleteUserDetails: {
                          id: data.original.id,
                        },
                      })
                      this.setState({ DeleteNotification: true })
                    }}
                  >
                    <DeleteIcon className="dropdown-icon" />
                    <span>Delete User</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          ),
        },
      ],

      Survey: [],
      SurveyFetch: [],
      ShareSurvey: null,
    }
  }
  Loader_visible = () => {
    this.props.dispatch(LoaderActions_loadingtrue())
  }

  Loader_hide = () => {
    console.log('Options -> Loader_hide -> Loader_hide')

    setTimeout(() => {
      this.props.dispatch(LoaderActions_loadingfalse())
    }, 500)
  }
  getUserist = () => {
    let API_END_POINT = ProjectSettings.baseURL + '/api/user/admins'

    fetch(API_END_POINT, {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
      },
    })
      .then((r) => {
        return r.json()
      })
      .then((r) => {
        this.UsertListCreator([...r.responseData])
      })
      .catch((e) => {})
  }

  sendingSurveyListtodb = (token) => {
    // var API_END_POINT = 'https://api.carwashsurveys.com/api/user/admins'
    fetch(ProjectSettings.baseURL + '/api/user/admins', {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SurveyFetch: [...data.responseData] })
        this.SurveyListCreator(this.state.SurveyFetch)
      })
  }
  UsertListCreator = (User) => {
    let User1 = []
    User.forEach((e) => {
      User1.push({
        status: JSON.stringify(e.status),
        role: e.role,
        email: e.email,
        name: e.name,
        id: e._id,
        businessName: e.businessName,
      })
    })

    this.setState(
      {
        UserList: User1,
        tempUserList: User1,
      },
      () => {
        this.Loader_hide()
      }
    )
  }

  SurveyListCreator = (survey) => {
    var Survey1 = []
    // var { Coupon_code_reducer } = this.props.state

    survey.forEach((e) => {
      if (e.id !== null) {
        Survey1.push(
          {
            Name: e.surveyName,
            updatedAt: e.updatedAt,
            status: e.status,
            Responses: e.userResponse,
            Codes_redeemed: 1,
            json: e.userResponse,
            id: e.surveyId,
            totalCode: e.couponCode,
          },
          () => {}
        )
      }
    })
    this.setState({ Survey: Survey1 }, () => {
      this.Loader_hide()
    })
  }

  componentDidUpdate() {
    if (this.props.state.LoginReducer11 === null) {
      this.props.history.push('/login')
    }
  }
  // componentWillMount() {

  //   // this.SurveyListCreator();
  // }
  componentDidMount() {
    this.Loader_visible()
    if (this.props.state.LoginReducer11 === null) {
      this.props.history.push('/login')
    }
    // this.getUserist()

    // this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token);

    // var Survey = useSelector((state) => state.SaveSurvey);
  }

  render() {
    return (
      <>
        <Loader11 />
        <div>
          {/* <button onClick={toast("wow")} /> */}
          <ToastContainer
            position="bottom-right"
            color="success"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <Modal isOpen={this.state.showState} toggle={() => this.setState({ showState: false })} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Share Survey</ModalHeader>
          <ModalBody>
            <div>Link</div>

            <div>{this.state.url.replace('dashboard', `survey/${this.state.ShareSurvey}`)}</div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={() => this.setState({ showState: false })}>
              Cancel
            </Button>
            <CopyToClipboard
              text={this.state.url.replace('dashboard', `survey/${this.state.ShareSurvey}`)}
              onCopy={() => {
                toast('Copied to Clipboard')

                this.setState({ copied: true })
              }}
            >
              <Button color="primary" onClick={() => this.setState({ showState: false })}>
                Copy
              </Button>
            </CopyToClipboard>
          </ModalFooter>
        </Modal>
        {/* <Loader11 /> */}
        <SweetAlert
          title="Are you Sure"
          confirmButtonColor=""
          show={this.state.UpdateNotification}
          onOutsideClick={() => {
            this.setState({ UpdateNotification: false })
          }}
          onConfirm={() => {
            fetch(ProjectSettings.baseURL + '/api/user', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
              },

              body: JSON.stringify(this.state.UpdateUserDetails.body1),
            })
              .then((r) => {
                return r.json()
              })
              .then((e) => {
                this.getUserist()
                this.setState({ UpdateNotification: false })
              })
          }}
        />

        <SweetAlert
          title="Are you Sure"
          confirmButtonColor=""
          show={this.state.DeleteNotification}
          onOutsideClick={() => {
            this.setState({ DeleteNotification: false })
          }}
          // onClick={(e) => {
          //   this.setState({ DeleteNotification: false });
          //   console.log("Options -> render -> e", e);
          // }}
          onConfirm={(e) => {
            // const body11 = {
            //   id: this.state.DeleteUserDetails.id,
            // }
            // fetch("https://api.carwashsurveys.com/api/user/delete", {
            //   method: "POST",
            //   headers: {
            //     Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
            //     "Content-type": "application/json",
            //   },
            //   body: JSON.stringify(body11),
            // })
            //   .then((response) => {
            //     response.json();
            //   })
            //   .then((json) => {
            //     console.log("Options -> sendingSurveyListtodb -> json", json);
            //   })
            //   .then(() => {
            //     this.sendingSurveyListtodb(
            //       this.props.state.LoginReducer11.response.token
            //     );
            //     this.setState({ DeleteNotification: false });
            //   })
            //   .catch((e) => {
            //     console.log("Options -> render -> e", e);
            //   });
            // this.props.dispatch(
            //   DeleteSurvey(
            //     this.state.DeleteSuveyDetails.id,
            //     this.state.DeleteSuveyDetails.json,
            //     this.state.DeleteSuveyDetails.status,
            //     this.state.DeleteSuveyDetails.Name
            //   )
            // );
            // this.sendingSurveyListtodb();
            // this.updateTableondelete();
            // this.setState({ DeleteNotification: false });
          }}
        />
        <div className="option-header">
          <Header />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '20%',
              // border:"2px solid",
              alignItems: 'center',
              position: 'relative',
              justifyContent: 'space-around',
              left: '79%',
              marginBottom: '1rem',
              // height:"3rem"
            }}
          >
            <div
              style={{
                fontSize: '16px',
                paddingBottom: '10px',
              }}
            >
              Search:{' '}
            </div>
            <div>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    {/* <Label for="exampleEmail">Email</Label> */}
                    <Input
                      type="text"
                      name="search-text"
                      id="searchText"
                      placeholder="Business Name...."
                      onChange={(e) => {
                        // console.log("changing")
                        this.setState(
                          {
                            searchText: e.target.value,
                            UserList: this.state.tempUserList,
                          },
                          () => {
                            let newList = []
                            let User = this.state.UserList
                            User.forEach((e) => {
                              let str1 = e.businessName.toLowerCase()
                              let str2 = this.state.searchText.trim().toLowerCase()
                              if (str1.includes(str2))
                                newList.push({
                                  status: e.status,
                                  role: e.role,
                                  email: e.email,
                                  name: e.name,
                                  id: e.id,
                                  businessName: e.businessName,
                                })
                            })
                            this.setState(
                              {
                                UserList: newList,
                              },
                              () => {
                                this.Loader_hide()
                              }
                            )
                          }
                        )
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
          <div className="sticky-table">
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={this.state.UserList}
                    columns={this.state.columnsss}
                    defaultPageSize={7}
                    manual
                    onFetchData={this.getUserist}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Options))
