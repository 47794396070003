import React, { Component } from 'react'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import CommentIcon from '@material-ui/icons/Comment'
import StarsIcon from '@material-ui/icons/Stars'
import ImageIcon from '@material-ui/icons/Image'
import BallotIcon from '@material-ui/icons/Ballot'
import TuneIcon from '@material-ui/icons/Tune'
import CodeIcon from '@material-ui/icons/Code'
import AttachFileIcon from '@material-ui/icons/AttachFile'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import FavoriteIcon from '@material-ui/icons/Favorite'
export default class IconComponent extends Component {
  render() {
    switch (this.props.iconClass.iconClass) {
      case 'CheckBoxIcon':
        return (
          <div>
            <CheckBoxIcon />
          </div>
        )

      case 'TextFieldsIcon':
        return (
          <div>
            <TextFieldsIcon />
          </div>
        )

      case 'ArrowDropDownCircleIcon':
        return (
          <div>
            <ArrowDropDownCircleIcon />
          </div>
        )

      case 'CommentIcon':
        return (
          <div>
            <CommentIcon />
          </div>
        )
      case 'FitnessCenterIcon':
        return (
          <div>
            <FitnessCenterIcon />
          </div>
        )

      case 'StarsIcon':
        return (
          <div>
            <StarsIcon />
          </div>
        )
      case 'FavoriteIcon':
        return (
          <div>
            <FavoriteIcon />
          </div>
        )
      case 'ImageIcon':
        return (
          <div>
            <ImageIcon />
          </div>
        )

      case 'BallotIcon':
        return (
          <div>
            <BallotIcon />
          </div>
        )

      case 'EmojiEmotionsIcon':
        return (
          <div>
            <EmojiEmotionsIcon />
          </div>
        )

      case 'TuneIcon':
        return (
          <div>
            <TuneIcon />
          </div>
        )

      case 'CodeIcon':
        return (
          <div>
            <CodeIcon />
          </div>
        )
      case 'AttachFileIcon':
        return (
          <div>
            <AttachFileIcon />
          </div>
        )
      case 'Signature':
        return <div></div>
      default:
        return <div></div>
    }
  }
}
