export const CouponCodeaction2 = (totalNumber, duplicate_removed, PresentCouponCode, TotalCouponCode, fileName, Final_duplicate_removed) => {
  return {
    type: 'ADD_COUPON_CODE_FROM_DASHBOARD',
    totalNumber: totalNumber,
    duplicate_removed: duplicate_removed,
    PresentCouponCode: PresentCouponCode,
    TotalCouponCode: TotalCouponCode,
    fileName: fileName,
    Final_duplicate_removed: Final_duplicate_removed,
  }
}

export const CouponCodeaction21 = (totalNumber, duplicate_removed, PresentCouponCode, TotalCouponCode, fileName, Final_duplicate_removed) => {
  return {
    type: 'UPLOADED_COUPON_CODE_FROM_DASHBOARD',
    totalNumber: totalNumber,
    duplicate_removed: duplicate_removed,
    PresentCouponCode: PresentCouponCode,
    TotalCouponCode: TotalCouponCode,
    fileName: fileName,
    Final_duplicate_removed: Final_duplicate_removed,
  }
}

export const CouponCodeaction_remove_codes = () => {
  return {
    fileName: '',
    type: 'UPLOADED_COUPON_CODE_REMOVE_FROM_DASHBOARD',
  }
}
export const CouponCodeaction_remove_codes_top = (
  totalNumber,
  duplicate_removed,
  PresentCouponCode,
  TotalCouponCode,
  fileName,
  Final_duplicate_removed
) => {
  return {
    totalNumber: totalNumber,
    duplicate_removed: duplicate_removed,
    PresentCouponCode: [],
    TotalCouponCode: TotalCouponCode,

    fileName: fileName,
    Final_duplicate_removed: Final_duplicate_removed,
    type: 'DELETE_FROM_TOP_ICON',
  }
}

export const CouponCodeaction_remove_codes_bottom = (PresentCouponCode) => {
  return {
    PresentCouponCode: PresentCouponCode,
    type: 'DELETE_FROM_BOTTOM_ICON',
  }
}
