import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MySurvey from './MySurvey'
import ProjectSettings from '../../../global/Global'
class SuperAdminUseerList extends Component {
  componentDidMount() {
    // let API_END_POINT = `https://api.carwashsurveys.com/api/survey/admin/${this.props.state.LoginReducer11.response.user._id}`;
    let API_END_POINT = `${ProjectSettings.baseURL}/api/survey/admin/${this.props.match.params.id}`
    // let API_END_POINT = `https://api.carwashsurveys.com/api/user`;
    fetch(API_END_POINT, {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((r) => {
        return r.json()
      })
      .then((e) => {})
      .catch((e) => {
        console.log('SuperAdminUseerList -> componentDidMount -> e', e)
      })
  }

  render() {
    return (
      <div>
        <MySurvey />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SuperAdminUseerList))
