import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import Login from './login'
import SignUp from './Signup'
import Mysurvey from './MySurvey'
import Dashboard from './Dashboard'
import Surveyeditcomponent from './MysurveyEdit'
import SurveyeditcomponentSuper from './SuperAdmin/UserView/MysurveyEdit'
import SurveyPublic from './SurveyPublic'
// import { useSelector, useDispatch } from 'react-redux'
import SuperAdminUseerList from './SuperAdmin/UserView'
import MySurvey from './SuperAdmin'
import UserEdit from './SuperAdmin/UserEdit'
import UserSetting from './UserSettings'
import Profile from './Profile'
import UserCreate from './SuperAdmin/UserCreate'
import Result from '../Component/Result'
import SurveyCreatecomponentSuper from './SuperAdmin/UserView/Survey'
export default function Component() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup" component={SignUp} />
      <Route path="/my-survey" />
      <Route path="/edit-survey/:id" component={Surveyeditcomponent}></Route>
      <Route path="/survey/:code">
        <SurveyPublic />
      </Route>

      <Route path="/create-survey" component={Dashboard}></Route>

      <Route path="/super-admin" component={MySurvey}></Route>
      <Route path="/dashboard/:id" component={Mysurvey} />
      <Route path="/User-View/:id/:pageId" component={SuperAdminUseerList} />
      <Route path="/User-edit/:id" component={UserEdit} />
      <Route path="/User-Create" component={UserCreate} />
      <Route path="/User-survey-edit/:id/:id1" component={SurveyeditcomponentSuper} />
      <Route path="/User-survey-create/:id" component={SurveyCreatecomponentSuper}></Route>
      <Route path="/result" component={Result}></Route>
      <Route path="/User-settings/:id" component={UserSetting} />
      <Route path="/User-Profile" component={Profile} />
      <Route path="/coupon-code"></Route>
      <Route path="/" component={Login} />
      <Redirect from="*" to="/" />
    </Switch>
  )
}
