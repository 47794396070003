import { put, call } from 'redux-saga/effects'

// import {
//   REGISTER_USER,
//   REGISTER_USER_SUCCESS,
//   REGISTER_USER_ERROR,
// } from "../contants/Login1";
import ProjectSettings from '../../global/Global'
const registerUserService = (request) => {
  let User = {
    name: request.Name,
    email: request.Email,
    password: request.Password,
    businessName: request.businessName,
  }
  const REGISTER_API_ENDPOINT = ProjectSettings.baseURL + '/api/auth/signup'

  const parameter = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(User),
  }

  return fetch(REGISTER_API_ENDPOINT, parameter)
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      return json
    })
}

export function* registerSaga(payload) {
  try {
    const response = yield call(registerUserService, payload)

    yield put({ type: 'REGISTER_USER_SUCCESS', response })
  } catch (error) {
    put({ type: 'REGISTER_USER_ERROR', error })
  }
}
