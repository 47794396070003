import uniqid from "uniqid";

const initialState = {
  id: null,
};

export const Survey_creatorreducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_SURVEY_FROM_BUTTON":
      return {
        ...state,
        id: uniqid(),
      };

    default:
      return { ...state };
  }
};
