import React, { Fragment } from "react";

import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import Options from "./options";

export default function Component() {
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Options />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
