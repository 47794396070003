import React from 'react'
import Header from './header'
import Loader11 from '../../../../Loader'
import { Col, Card, CardBody, UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress, Button } from 'reactstrap'
import * as swal1 from '@sweetalert/with-react'
import * as Survey from 'survey-react'
// import tag from '../../../../../assets/tag.svg'
import tag_icon from '../../../../../assets/tag_icon.png'
import LaddaButton, { ZOOM_IN } from 'react-ladda'
import Swal from 'sweetalert2'
import { LoaderActions_loadingtrue, LoaderActions_loadingfalse } from '../../../../../redux/actions/Loader'
import { CouponCodeaction_remove_codes } from '../../../../../redux/actions/Coupon_code2'
import { pageKeyAction } from '../../../../../redux/actions/PageKey'
import AssessmentIcon from '@material-ui/icons/Assessment'
import DraftsIcon from '@material-ui/icons/Drafts'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
// import DoneAllIcon from '@material-ui/icons/DoneAll'
import FileCopyIcon from '@material-ui/icons/FileCopy'
// import { CSVLink, CSVDownload } from 'react-csv'
// import * as SurveyKo from 'survey-knockout'
import * as SurveyJSCreator from 'survey-creator'
import SweetAlert from 'sweetalert-react'
// import { DeleteSurvey } from "../../../redux/actions/Survey";
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withRouter } from 'react-router-dom'
import { LineChart, Line, ResponsiveContainer } from 'recharts'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './index.css'
import { connect } from 'react-redux'
//demo.dashboardpack.com/architectui-html-pro/components-notifications.html
import ReactTable from 'react-table'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import CSVReader from 'react-csv-reader'
// import { useTable, useSortBy, ReactTable } from "react-table";
// import { useSelector, useDispatch } from "react-redux";
import Reader from '../../../../Manage_coupon-code_dashboard'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import swal from 'sweetalert'
import ProjectSettings from '../../../../../global/Global'

// const papaparseOptions = {
//   header: true,
//   dynamicTyping: true,
//   skipEmptyLines: true,
//   transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
// };
// class Reader extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       Duplicate_removed: 0,
//       status: null,
//       Error: false,
//       Error_Message: null,
//       CSV_data: null,
//       FileInfo: null,
//       TotalNumberArray: 0,
//     };
//   }

//   componentDidUpdate(prevProps, prevState) {
// //     console.log("Reader -> componentDidUpdate -> prevState", prevState);
// //     // console.log("Reader -> componentDidUpdate -> prevProps", prevProps);
// //     console.log("Reader -> componentDidUpdate -> this.props", this.props);

// //     console.log("Reader -> componentDidUpdate -> this.state", this.state);
//     // if(prevProps.ButtonCLickedState !== this.props.ButtonCLickedState){
//     // }
//   }
//   shouldComponentUpdate(PrevProps, PrevState) {
// //     console.log("Reader -> shouldComponentUpdate -> PrevState", PrevState);

// //     console.log("Reader -> shouldComponentUpdate -> this.state", this.state);
//     return true;
//   }

//   handleForce = (data, fileInfo) => {
//     console.log(data, fileInfo);
// //     console.log("Reader -> handleForce -> fileInfo", fileInfo.name);
//     this.setState({ FileInfo: fileInfo.name });

//     this.setState({ CSV_data: data });
//     var couponcodeArray = [];

//     console.log(
//       "Reader -> handleForce ->  Object.keys(data)[0]",
//       Object.keys(data[0])[0]
//     );
//     var User_input_csv_header = Object.keys(data[0])[0];
//     var User_input_csv_header_lower_case = User_input_csv_header.toLowerCase();

//     if (User_input_csv_header_lower_case !== "coupon_code") {
//       this.setState({
//         Error: true,
//         Error_Message: `First column titile ${User_input_csv_header} is not equal to Coupon_Code as instructed`,
//       });
//     } else {
//       data.forEach((e, i) => {
//         couponcodeArray.push(e.coupon_code);
//       });
// //       // // console.log("Reader -> handleForce -> couponcodeArray", couponcodeArray);

//       let array_without_duplicates = couponcodeArray.filter(
//         (value, index) => couponcodeArray.indexOf(value) === index
//       );
//       console.log(
//         "Reader -> handleForce -> array_without_duplicates",
//         array_without_duplicates
//       );

//       this.setState({
//         Duplicate_removed:
//           couponcodeArray.length - array_without_duplicates.length,
//       });

//       console.log(
//         "Reader -> handleForce -> this.state.Duplicate_removed",
//         this.state.Duplicate_removed
//       );
//       this.setState({ TotalNumberArray: array_without_duplicates.length });
//       var TotalNumberArray  =  array_without_duplicates.length
//       var Duplicate_removed = couponcodeArray.length - array_without_duplicates.length
//       this.props.Array_sending(array_without_duplicates);
//     }
//   };

//   render() {
//     return (
//       <div
//         className="container"
//         style={{
//           flexDirection: "column",
//         }}
//       >
//         <SweetAlert
//           title={this.state.Error_Message}
//           confirmButtonColor=""
//           show={this.state.Error}
//           text=""
//           type="error"
//           onConfirm={() => this.setState({ Error: false })}
//         />
//         <div className="Font_style bottom_padding">Add Coupon code</div>

//         <div className="wrapper-div bottom_padding">
//           <Button
//             size="lg"
//             color="primary"
//             onClick={() => {
//               document.querySelector(".input_class").click();
//             }}
//             className="Font_style special_button"
//             style={{
//               padding: "15px 40px",
//             }}
//           >
//             Upload CSV file
//           </Button>
//           {this.state.FileInfo === null || this.state.FileInfo === "" ? (
//             <span className="Font_style center-align">No file Chosen</span>
//           ) : (
//             <span className="Font_style center-align">
//               {this.state.FileInfo}
//             </span>
//           )}
//         </div>

//         <CSVReader
//           cssClass="react-csv-input"
//           cssInputClass="input_class "
//           cssLabelClass="Label_class"
//           // label="Label__class"
//           // inputStyle="input__style"
//           onFileLoaded={this.handleForce}
//           parserOptions={papaparseOptions}
//         />
//         <p className="Font_style Font_Style1 bottom_padding">
//           The CSV Should have one column with title as Coupon_code
//         </p>
//         <div className="wrapper-div">
//           <div className="Font_Style1">
//             Codes Uploaded {TotalNumberArray}
//           </div>
//           <div className="Font_Style1">
//             duplicate removed {Duplicate_removed}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
class Options extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showState: false,
      DeleteNotification: false,
      DeleteSuveyDetails: null,
      url: window.location.href,
      copied: false,
      UpdateNotification: false,
      UpdateUserDetails: {},
      ResultSurvey: [],
      EdgeCaseError: null,
      EdgeCaseErrorState: false,
      QuestionChecker: 0,
      addCoupon: false,
      AddCouponSurveyDetails: {},
      Coupon_codeNumber: [],
      AddCouponClicked: false,
      PageNumber: 0,
      pages: 0,
      PageSize: 10,
      ResponseLength: 0,
      columnss: [
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  width: 'fit-content',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  // paddingLeft: '39px',
                  marginLeft: '13px',
                  fontSize: '14px',

                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                }}
              >
                Survey Name
              </div>
            </div>
          ),
          accessor: 'name',
          Cell: (row) => {
            let date = row.original.updatedAt.split('T')[0].split('-').reverse()
            let NumberIndex = Math.floor(date.splice(1, 1))
            let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

            date.push(month[JSON.parse(NumberIndex) - 1])

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    fontSize: '15px',
                    color: '#757575',
                    paddingBottom: '5px',
                  }}
                >
                  {row.original.Name}
                </div>
                <div style={{ fontSize: '12px', opacity: '0.8' }}>
                  {/* {`Last Modified:${date[0]}-${date[2]}-${date[1]}`} */}
                  <div>Last Modified:</div>
                  <div>{`${date[0]}-${date[2]}-${date[1]}`}</div>
                </div>
              </div>
            )
          },
        },
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontSize: '14px',
                  textAlign: 'center',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                  // paddingLeft: "50px",
                }}
              >
                Codes Sent
              </div>
            </div>
          ),
          accessor: 'codesredeemed',

          Cell: (row) => {
            return (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    width: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  {row.original.totalCode && row.original.totalCode.length > 1 ? (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          let TotalCode = row.original.totalCode.concat(row.original.Codes_redeemed)
                          this.setState(
                            {
                              addCoupon: true,
                              AddCouponSurveyDetails: {
                                id: row.original.id,
                                name: row.original.Name,
                                json: row.original.surveyJson,
                                CouponCode: TotalCode,
                                Sent_code: row.original.Codes_redeemed,
                                Coupon_code_status: row.original.CouponCodeStatus,
                              },
                            },
                            () => {
                              console.log('Options -> constructor ->  this.state', this.state)
                            }
                          )
                        }}
                      >
                        <div>{row.original.Codes_redeemed === null ? 0 : row.original.Codes_redeemed.length}</div>

                        <div>{row.original.totalCode.length + row.original.Codes_redeemed.length}</div>
                      </div>
                      <Progress
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          let TotalCode = row.original.totalCode.concat(row.original.Codes_redeemed)
                          this.setState(
                            {
                              addCoupon: true,
                              AddCouponSurveyDetails: {
                                id: row.original.id,
                                name: row.original.Name,
                                json: row.original.surveyJson,
                                CouponCode: TotalCode,
                                Sent_code: row.original.Codes_redeemed,
                                Coupon_code_status: row.original.CouponCodeStatus,
                              },
                            },
                            () => {
                              console.log('Options -> constructor ->  this.state', this.state)
                            }
                          )
                        }}
                        className="mb-3 progress-bar-animated-alt"
                        value={row.original.Codes_redeemed.length}
                        max={row.original.totalCode.length + row.original.Codes_redeemed.length}
                      >
                        {Math.floor(
                          (row.original.Codes_redeemed.length / (row.original.Codes_redeemed.length + row.original.totalCode.length)) * 100
                        ) > 40 ? (
                          <div>
                            {Math.floor(
                              (row.original.Codes_redeemed.length / (row.original.Codes_redeemed.length + row.original.totalCode.length)) * 100
                            )}{' '}
                            %
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Progress>
                    </div>
                  ) : (
                    <div
                      style={{ textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState(
                          {
                            addCoupon: true,
                            AddCouponSurveyDetails: {
                              id: row.original.id,
                              name: row.original.Name,
                              json: row.original.surveyJson,
                              Sent_code: [],
                              CouponCode: [],
                            },
                          },
                          () => {
                            // console.log(
                            //   "Options -> constructor ->  this.state",
                            //   this.state
                            // );
                          }
                        )
                      }}
                    >
                      No Coupon Codes
                    </div>
                  )}
                </div>
              </div>
            )
          },
        },
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                }}
              >
                Coupon Code Status
              </div>
            </div>
          ),
          accessor: 'Couponcodestatus',
          Cell: (row) => {
            // function ActiveInactive(state) {
            //   ACtiveInactiveTrueFalse(state)
            // }

            const ACtiveInactiveTrueFalse = (state) => {
              const Oppositestatus = (s) => {
                if (s.includes('Active')) {
                  return 'Inactive'
                } else return 'Active'
              }
              // console.log(
              //   "Options -> ACtiveInactiveTrueFalse -> row.original.totalCode",
              //   row.original.totalCode
              // );

              if (row.original.totalCode !== null && row.original.totalCode.length !== 0) {
                let totalCouponcode = row.original.Codes_redeemed.concat(row.original.totalCode)
                let body1 = {
                  surveyId: row.original.id,
                  surveyName: row.original.Name,
                  surveyJson: row.original.surveyJson,
                  CouponCodeStatus: Oppositestatus(row.original.CouponCodeStatus),
                  couponCode: totalCouponcode,
                }
                // console.log(
                //   "Options -> ACtiveInactiveTrueFalse -> body1",
                //   body1
                // );

                var QuestionChecker = 0
                if (row.original.CouponCodeStatus === 'Inactive') {
                  // console.log(
                  //   "Options -> ACtiveInactiveTrueFalse -> row.original.json",
                  //   row.original.json
                  // );
                  let model = new Survey.Model(row.original.surveyJson)

                  model.getAllQuestions().forEach((e, i) => {
                    let email = e.title.toLowerCase()
                    if (email.includes('email')) {
                      QuestionChecker = QuestionChecker + 1
                    }
                  })

                  // console.log(
                  //   "Options -> ACtiveInactiveTrueFalse -> model",
                  //   model
                  // );

                  // console.log(
                  //   "Options -> ACtiveInactiveTrueFalse -> QuestionChecker",
                  //   QuestionChecker
                  // );

                  if (QuestionChecker === 0) {
                    // console.log(
                    //   "EdgecaseTesting -> QuestionChecker",
                    //   QuestionChecker
                    // );
                    swal({
                      title: 'Warning',
                      text:
                        //"the survey does not have a question to support Coupon Code delivery kindly add a question with name Email",
                        'This survey does not have a required question requesting an email address. A required question requesting an email address will be added to the end of the survey.',
                      icon: 'warning',
                      buttons: {
                        cancel: 'Cancel',
                        catch: {
                          text: 'Add Question',
                          value: 'Add',
                        },
                        default: {
                          text: 'Edit Survey',
                          value: 'edit',
                        },
                      },
                    })
                      .then((value) => {
                        // console.log(
                        //   "Options -> ACtiveInactiveTrueFalse -> value",
                        //   value
                        // );
                        if (value === 'Add') {
                          // console.log(
                          //   "Options -> ACtiveInactiveTrueFalse -> model",
                          //   model
                          // );
                          let Model11 = new SurveyJSCreator.SurveyCreator(null)
                          // console.log(
                          //   "Options -> ACtiveInactiveTrueFalse -> Model11",
                          //   Model11
                          // );
                          Model11.JSON = row.original.surveyJson
                          Model11.survey.addNewPage('last_page')
                          Model11.survey.pages.forEach((e, i) => {
                            if (e.name === 'last_page') {
                              e.addNewQuestion('text', 'Email')
                              // console.log(
                              //   "Options -> ACtiveInactiveTrueFalse -> e",
                              //   e
                              // );
                            }
                          })
                          Model11.getAllQuestions().forEach((e, i) => {
                            if (e.title.toLowerCase().includes('email')) {
                              e.isRequired = true
                              e.inputType = 'email'
                              e.title = 'Email'

                              e.name = 'Email' + (i + 1)
                            }
                          })

                          // console.log(
                          //   "Options -> ACtiveInactiveTrueFalse -> row.original",
                          //   row.original
                          // );
                          let body11 = {
                            surveyId: row.original.id,
                            surveyName: row.original.Name,
                            surveyJson: Model11.JSON,
                            CouponCodeStatus: Oppositestatus(row.original.CouponCodeStatus),
                            couponCode: totalCouponcode,
                          }
                          // console.log(
                          //   "Options -> ACtiveInactiveTrueFalse -> body1111111",
                          //   body11
                          // );

                          let body1212 = JSON.stringify({ ...body11 })
                          fetch(ProjectSettings.baseURL + '/api/survey', {
                            method: 'PUT',
                            headers: {
                              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                              'Content-type': 'application/json',
                            },
                            body: body1212,
                          })
                            .then((e) => {
                              return e.json()
                            })
                            .then((e) => {
                              // console.log(
                              //   "Options -> ACtiveInactiveTrueFalse -> e",
                              //   e
                              // );
                            })
                            .then(() => {
                              this.UpdatingSurvey('ADD_QUESTION', body11)

                              // this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token)
                            })
                            .catch((e) => {
                              swal({
                                title: 'Warning',
                                text: 'Something went wrong',
                                icon: 'warning',
                                timer: 2000,
                              })
                              // console.log(
                              //   "Options -> ACtiveInactiveTrueFalse -> e",
                              //   e
                              // );
                            })
                        }
                        if (value === 'edit') {
                          this.props.history.push(`/User-survey-edit/${row.original.id}/${this.props.match.params.id}`)
                        }
                      })
                      .catch((e) => {
                        swal({
                          title: 'Warning',
                          text: 'Something went wrong',
                          icon: 'warning',
                          timer: 2000,
                        })
                        // console.log(
                        //   "Options -> ACtiveInactiveTrueFalse -> e",
                        //   e
                        // );
                      })
                  } else {
                    // console.log(
                    //   "EdgecaseTesting -> QuestionChecker",
                    //   QuestionChecker
                    // );
                    // swal({
                    //   text: "Are you sure",

                    //   icon: "warning",
                    //   buttons: {
                    //     catch: {
                    //       text: "Yes",
                    //       value: "Yes",
                    //     },

                    //     cancel: "No",
                    //   },
                    // }).then((e) => {
                    //   if (e === "Yes") {
                    fetch(ProjectSettings.baseURL + '/api/survey', {
                      method: 'PUT',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                      },

                      body: JSON.stringify(body1),
                    })
                      .then((r) => {
                        return r.json()
                      })
                      .then((e) => {
                        this.UpdatingSurvey('COUPON_CODE_STATUS_UPDATE', body1)
                        this.setState({ UpdateNotification: false })
                      })
                      .catch((e) => {})

                    // fetch("https://api.carwashsurveys.com/api/survey", {
                    //   method: "PUT",
                    //   headers: {
                    //     "Content-Type": "application/json",
                    //     Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                    //   },

                    //   body: JSON.stringify(body1),
                    // })
                    //   .then((r) => {
                    //     return r.json();
                    //   })
                    //   .then((e) => {
                    //     this.sendingSurveyListtodb(
                    //       this.props.state.LoginReducer11.response.token
                    //     );
                    //     this.setState({ UpdateNotification: false });
                    //   })
                    //   .catch((e) => {});
                    // }
                    // });
                    // this.setState({
                    //   UpdateUserDetails: {
                    //     body1,
                    //   },
                    //   UpdateNotification: true,
                    // });
                  }
                } else {
                  // console.log(
                  //   "EdgecaseTesting -> QuestionChecker",
                  //   QuestionChecker
                  // );

                  // this.setState({
                  //   UpdateUserDetails: {
                  //     body1,
                  //   },
                  //   UpdateNotification: true,
                  // });

                  fetch(ProjectSettings.baseURL + '/api/survey', {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                    },

                    body: JSON.stringify(body1),
                  })
                    .then((r) => {
                      return r.json()
                    })
                    .then((e) => {
                      this.UpdatingSurvey('COUPON_CODE_STATUS_UPDATE', body1)
                      this.setState({ UpdateNotification: false })
                    })
                    .catch((e) => {})
                }
              } else {
                this.setState({
                  addCoupon: true,
                  AddCouponSurveyDetails: {
                    id: row.original.id,
                    name: row.original.Name,
                    json: row.original.surveyJson,
                    Sent_code: [],
                    CouponCode: [],
                  },
                })
              }
            }

            var state123 = TrueFalse(row.original.CouponCodeStatus)
            function TrueFalse(s) {
              return s.includes('Active')
            }

            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={state123}
                      // disabled={
                      //   row.original.totalCode &&
                      //   row.original.totalCode.length > 1
                      //     ? false
                      //     : true
                      // }
                      onChange={() => {
                        ACtiveInactiveTrueFalse(row.original.CouponCodeStatus)
                      }}
                    />
                  }
                  label={row.original.CouponCodeStatus}
                />
              </div>
            )
          },
        },

        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                }}
              >
                Survey Status
              </div>
            </div>
          ),
          accessor: 'status',
          Cell: (row) => {
            // let Status = row.original.status

            let color
            if (row.original.status === 'Active') {
              color = 'success'
            }
            if (row.original.status === 'Draft') {
              color = 'warning'
            }
            if (row.original.status === 'Completed') {
              color = 'info'
            }

            return (
              <div
                style={{
                  height: '65%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <UncontrolledButtonDropdown style={{ width: '60%', paddingTop: '0px', margin: '0px' }}>
                  <DropdownToggle className="btn-icon btn-icon-only btn btn-link" color={color} caret>
                    {row.original.status === 'Active' ? `Open` : `Closed`}
                  </DropdownToggle>
                  <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
                    <DropdownItem
                      onClick={(e) => {
                        let body11
                        body11 = {
                          surveyId: row.original.id,
                          status: 'Draft', //'Draft', 'Completed','Active', 'Inactive'
                        }

                        fetch(ProjectSettings.baseURL + '/api/survey/status', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                          },
                          body: JSON.stringify(body11),
                        })
                          .then((e) => {
                            return e.json()
                          })
                          .then((e) => {
                            this.UpdatingSurvey('SURVEY_STATE_UPDATE_CLOSE', body11)
                            // this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token)
                          })
                          .catch((e) => {
                            console.log('here is error', e)
                          })
                      }}
                    >
                      <DraftsIcon className="dropdown-icon" />
                      <span> Closed</span>
                    </DropdownItem>

                    <DropdownItem
                      onClick={(e) => {
                        let body11
                        body11 = {
                          surveyId: row.original.id,
                          status: 'Active', //'Draft', 'Completed','Active', 'Inactive'
                        }

                        fetch(ProjectSettings.baseURL + '/api/survey/status', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                          },
                          body: JSON.stringify(body11),
                        })
                          .then((e) => {
                            return e.json()
                          })
                          .then((e) => {
                            this.UpdatingSurvey('SURVEY_STATE_UPDATE_OPEN', body11)
                          })
                          .catch((e) => {})
                      }}
                    >
                      <NotificationsActiveIcon className="dropdown-icon" />
                      <span> Open</span>
                    </DropdownItem>

                    {/* <DropdownItem
                      onClick={(e) => {
                        let body11;
                        body11 = {
                          surveyId: row.original.id,
                          status: "Completed", //'Draft', 'Completed','Active', 'Inactive'
                        };

                        fetch(
                          "https://api.carwashsurveys.com/api/survey/status",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                            },
                            body: JSON.stringify(body11),
                          }
                        )
                          .then((e) => {
                            return e.json();
                          })
                          .then((e) => {
                            this.sendingSurveyListtodb(
                              this.props.state.LoginReducer11.response.token
                            );
                          })
                          .catch((e) => {});
                      }}
                    >
                      <DoneAllIcon className="dropdown-icon" />
                      <span> Completed</span>
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            )
          },
        },
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                  paddingLeft: '15px',
                  textAlign: 'initial',
                }}
              >
                Responses
              </div>
            </div>
          ),
          accessor: 'responses',
          Cell: (data) => {
            // console.log(
            //   "Options -> constructor -> ResponseNumber",
            //   ResponseNumber
            // );

            // const data11 = [
            //   { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
            //   { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
            //   { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
            //   { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
            //   { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
            //   { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
            //   { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
            //   { name: "Page C", uv: 2000, pv: 6800, amt: 2290 },
            //   { name: "Page D", uv: 4780, pv: 7908, amt: 2000 },
            //   { name: "Page E", uv: 2890, pv: 9800, amt: 2181 },
            //   { name: "Page F", uv: 1390, pv: 3800, amt: 1500 },
            //   { name: "Page G", uv: 3490, pv: 4300, amt: 1000 },
            // ];

            // let RespnseData = {
            //   responseData: [],
            //   responseDate: null,
            // }
            let Returndata = []
            const ResponseCollection = (e) => {
              // console.log("Options -> ResponseCollection -> e", e);
              let data111 = { surveyResponse: [], SurveyresponseDate: [] }
              e.forEach((e1) => {
                data111.surveyResponse.push(e1)
                let ResponseDate = new Date(e1.updatedAt)
                data111.SurveyresponseDate.push(ResponseDate)
              })

              let last = { name: [], uv: [] }
              for (var i = 0; i < 8; i++) {
                last.name.push(new Date(Date.now() - i * 24 * 60 * 60 * 1000))
                last.uv.push(0)
              }

              data111.SurveyresponseDate.forEach((e, i1) => {
                last.name.forEach((e1, i) => {
                  let ResponseDate = new Date(e)
                  let LoopDate = new Date(e1)

                  if (ResponseDate.getDate() === LoopDate.getDate()) {
                    last.uv[i] = last.uv[i] + 1
                  }
                })
              })

              // last.name.forEach((e, i) => {
              //   Returndata.push({ name: e });
              // });
              // console.log(
              //   "Options -> ResponseCollection -> Returndata",
              //   Returndata
              // );

              // last.uv.forEach((e, i) => {
              //   Returndata.push({ name: last.name[i], uv: e });
              // });
              let Reverselastname = last.name.reverse()

              let Reverselastuv = last.uv.reverse()

              Reverselastuv.forEach((e, i) => {
                Returndata.push({ name: Reverselastname[i], uv: e })
              })

              return Returndata
            }
            // let API_END_POINT = `https://api.carwashsurveys.com/api/survey/results/${data.original.id}`;

            // fetch(API_END_POINT, {
            //   method: "GET",
            //   headers: {
            //     Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
            //     "Content-type": "application/json",
            //   },
            // })
            //   .then((e) => {
            //     return e.json();
            //   })
            //   .then((e) => {
            // //     console.log("Options -> constructor -> e", e);
            //     RespnseData = {
            //       responseData: e.responseData,
            //     };
            //   })
            //   .then(() => {
            //     this.setState({});
            //   })
            //   .catch((e) => {
            // //     console.log("Options -> constructor -> e", e);
            //   });

            // const data1 = [
            //   { name: "Page A", uv: 4000 },
            //   { name: "Page B", uv: 3000 },
            //   { name: "Page C", uv: 2000 },
            //   { name: "Page D", uv: 2780 },
            //   { name: "Page E", uv: 1890 },
            //   { name: "Page F", uv: 2390 },
            //   { name: "Page G", uv: 3490 },
            //   { name: "Page C", uv: 2000 },
            //   { name: "Page D", uv: 4780 },
            //   { name: "Page E", uv: 2890 },
            //   { name: "Page F", uv: 1390 },
            //   { name: "Page G", uv: 3490 },
            // ];

            // const data1 = [
            //   { name: 'Page A', uv: 4 },
            //   { name: 'Page B', uv: 3 },
            //   { name: 'Page C', uv: 2 },
            //   { name: 'Page D', uv: 2 },
            //   { name: 'Page E', uv: 1 },
            //   { name: 'Page F', uv: 2 },
            //   { name: 'Page G', uv: 3 },
            //   { name: 'Page C', uv: 2 },
            //   { name: 'Page D', uv: 4 },
            //   { name: 'Page E', uv: 2 },
            //   { name: 'Page F', uv: 1 },
            //   { name: 'Page G', uv: 3 },
            // ]
            var something11 = ResponseCollection(data.original.Resultjson)
            // console.log(
            //   "Options -> constructor -> data.original",
            //   data.original
            // );
            // function JSON2CSV(objArray) {
            //   // // console.log("objArray", typeof objArray);
            //   var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
            //   var str = ''
            //   let line = ''
            //   // // console.log("279", array);

            //   for (var i = 0; i < array.length; i++) {
            //     line = ''

            //     // // console.log("is checked212121212");
            //     for (var index in array[i]) {
            //       line += array[i][index] + ','
            //     }

            //     line = line.slice(0, -1)
            //     str += line + '\r\n'
            //   }
            //   return str
            // }

            function specialTransform(object) {
              // // console.log("Options -> specialTransform -> object", object);
              // console.log(
              //   "Options -> specialTransform -> object",
              //   typeof object
              // );
              if (typeof object === 'object') {
                // // console.log("Options -> specialTransform -> object", object);
                // let prelimilary_string = JSON.stringify(object)
                // let tessting = JSON.stringify(object)

                return JSON.stringify(object)
                  .split(',')
                  .join(';')
                  .split('[')
                  .join('')
                  .split(']')
                  .join('')
                  .split(`"`)
                  .join('')
                  .split('{')
                  .join('')
                  .split('}')
                  .join('')
              } else {
                // // console.log("Options -> specialTransform -> object", object);

                let prelimilary_string = JSON.stringify(object)

                prelimilary_string.split(',').join('')
                prelimilary_string.replace('[')
                prelimilary_string.replace(']')
                // console.log(
                //   "Options -> specialTransform -> prelimilary_string",
                //   prelimilary_string
                // );
                return JSON.stringify(object)
                  .split(',')
                  .join(';')
                  .split('[')
                  .join('')
                  .split(']')
                  .join('')
                  .split(`"`)
                  .join('')
                  .split('{')
                  .join('')
                  .split('}')
                  .join('')
              }
            }
            function convertToCSV(objArray) {
              // console.log(
              //   "Options -> convertToCSV -> objArray",
              //   typeof objArray
              // );
              var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
              var str = ''
              // // console.log("Options -> convertToCSV -> array", array);
              for (var i = 0; i < array.length; i++) {
                var line = ''
                for (var index in array[i]) {
                  // // // console.log("Options -> convertToCSV -> index", index);
                  // console.log(
                  //   "Options -> convertToCSV -> index",
                  //   JSON.stringify(index)
                  // );
                  if (line !== '') line += ','
                  // // // console.log("Options -> convertToCSV -> line", line);
                  // console.log(
                  //   "Options -> convertToCSV -> array[i][index]",
                  //   array[i][index]
                  // );

                  // console.log(
                  //   "Options -> convertToCSV ->   typeof  array[i][index]",
                  //   typeof array[i][index]
                  // );
                  array[i][index] = specialTransform(array[i][index])
                  // typeof array[i][index] === "object"
                  //   ? JSON.stringify(array[i][index])
                  //   : array[i][index];

                  line += array[i][index]
                  // // console.log("Options -> convertToCSV -> line", line);
                  // console.log(
                  //   "Options -> convertToCSV -> line",
                  //   JSON.stringify(line)
                  // );
                }

                str += line + '\r\n'
                // // console.log("Options -> convertToCSV -> str", str);
              }

              return str
            }

            function exportCSVFile(headers, items, fileTitle) {
              // if (headers) {
              //   items.unshift(headers);
              // // //   console.log("Options -> exportCSVFile -> items", items);
              // }

              // Convert Object to JSON
              var jsonObject = JSON.stringify(items)
              // console.log("Options -> exportCSVFile -> jsonObject", jsonObject);

              var csv = convertToCSV(jsonObject)
              // // console.log("Options -> exportCSVFile -> csv", csv);
              // csv.replace("]");
              // csv.replace("[");
              // csv.replace("{");
              // csv.replace("}");
              // console.log(
              //   "Options -> exportCSVFile -> csv",
              //   JSON.stringify(csv)
              // );
              // var csv1 = JSON2CSV(jsonObject)
              // // console.log("Options -> exportCSVFile -> csv1", csv1);

              var exportedFilenmae = fileTitle + '.csv' || 'export.csv'

              var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
              if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae)
              } else {
                var link = document.createElement('a')
                if (link.download !== undefined) {
                  // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob)
                  link.setAttribute('href', url)
                  link.setAttribute('download', exportedFilenmae)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
              }
            }

            // // console.log("Options -> constructor -> RespnseData", RespnseData);
            // // console.log("Options -> constructor -> something11", something11);
            var data11 = []
            var headers11 = []
            return (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginLeft: '20px',
                }}
                onClick={() => {
                  if (data.original.Resultjson.length > 0) {
                    swal({
                      title: 'Download Responses?',
                      // text: "save as",
                      // content: "input",
                      icon: 'warning',
                      buttons: {
                        cancel: 'Cancel',
                        catch: {
                          text: 'OK',
                          value: 'ok',
                        },
                      },
                    }).then((value) => {
                      // // console.log("Options -> constructor -> value", value);
                      if (value === 'ok') {
                        // // console.log("Options -> constructor -> value", value);

                        // console.log(
                        //   "Options -> constructor -> data.original.Resultjson",
                        //   data.original.Resultjson
                        // );
                        data.original.Resultjson.forEach((e) => {
                          // console.log(
                          //   "Options -> constructor ->  e.resultJson",
                          //   e.resultJson
                          // );
                          // console.log(
                          //   "Options -> constructor -> e.updatedAt,",
                          //   e.updatedAt
                          // );

                          data11.push({
                            date: e.updatedAt,
                            result: e.resultJson,
                          })
                          headers11.push(
                            {
                              label: 'result',
                              key: e.resultJson,
                            },
                            {
                              label: 'date',
                              key: e.updatedAt,
                            }
                          )
                        })

                        let finalResult = []
                        // let couter = 0
                        let dateArray = []
                        let ResultArray = []

                        data11.forEach((e1, i2) => {
                          // // console.log("Options -> constructor -> e1", e1);
                          Object.keys(e1).forEach((e) => {
                            // // console.log("Options -> constructor -> e", e);
                            if (e === 'date') {
                              let Key = e

                              if (finalResult.length > 0) {
                              } else {
                                finalResult.push({
                                  key: Key,
                                })
                              }
                            }
                            if (e === 'result') {
                              e1.result.forEach((e2, i1) => {
                                if (i2 === 0) {
                                }
                                finalResult[0][`question${i1}`] = e2.Title
                              })
                            }
                          })

                          // console.log("Options -> constructor -> i2 % 2", i2 % 2);
                          let date = e1.date
                          dateArray.push({
                            date,
                          })
                          let result = e1.result
                          ResultArray.push({
                            result,
                          })
                        })

                        dateArray.forEach((e, i) => {
                          finalResult.push({
                            key: e.date,
                          })
                        })
                        ResultArray.forEach((e, i2) => {
                          e.result.forEach((e1, i1) => {
                            let result = e1.result
                            finalResult[i2 + 1][`question${i1}`] = result
                          })
                        })

                        exportCSVFile([], finalResult, 'download')
                      }
                    })
                  } else {
                    swal({
                      title: 'There are no survey responses',
                      icon: 'warning',
                      timer: 10000,
                    })
                  }
                }}
              >
                <div
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '35px',
                      height: '22px',
                      borderRadius: '14px',
                      justifyContent: 'center',
                      backgroundColor: '#f0f4f6',
                      fontWeight: 'normal',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                      color: '#5f5f5f',
                      paddingTop: '2px',
                    }}
                  >
                    {data.original.Responses}
                  </div>
                </div>

                <ResponsiveContainer height="100%" width="60%">
                  <LineChart data={something11} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                    <Line type="monotone" dataKey="uv" stroke="#3ac47d" dot={false} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )
          },
        },
        {
          Header: () => (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  // paddingRight: "39px",
                  fontSize: '14px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  color: '#4a4a4a',
                }}
              >
                More Actions
              </div>
            </div>
          ),
          accessor: 'actions',
          Cell: (data) => {
            // function JSON2CSV(objArray) {
            //   // // console.log("objArray", typeof objArray);
            //   var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
            //   var str = ''
            //   let line = ''
            //   // // console.log("279", array);

            //   for (var i = 0; i < array.length; i++) {
            //     line = ''

            //     // // console.log("is checked212121212");
            //     for (var index in array[i]) {
            //       line += array[i][index] + ','
            //     }

            //     line = line.slice(0, -1)
            //     str += line + '\r\n'
            //   }
            //   return str
            // }

            function specialTransform(object) {
              // // console.log("Options -> specialTransform -> object", object);
              // console.log(
              //   "Options -> specialTransform -> object",
              //   typeof object
              // );
              if (typeof object === 'object') {
                // // console.log("Options -> specialTransform -> object", object);
                // let prelimilary_string = JSON.stringify(object)
                // let tessting = JSON.stringify(object)

                return JSON.stringify(object)
                  .split(',')
                  .join(';')
                  .split('[')
                  .join('')
                  .split(']')
                  .join('')
                  .split(`"`)
                  .join('')
                  .split('{')
                  .join('')
                  .split('}')
                  .join('')
              } else {
                // // console.log("Options -> specialTransform -> object", object);

                let prelimilary_string = JSON.stringify(object)

                prelimilary_string.split(',').join('')
                prelimilary_string.replace('[')
                prelimilary_string.replace(']')
                // console.log(
                //   "Options -> specialTransform -> prelimilary_string",
                //   prelimilary_string
                // );
                return JSON.stringify(object)
                  .split(',')
                  .join(';')
                  .split('[')
                  .join('')
                  .split(']')
                  .join('')
                  .split(`"`)
                  .join('')
                  .split('{')
                  .join('')
                  .split('}')
                  .join('')
              }
            }
            function convertToCSV(objArray) {
              // console.log(
              //   "Options -> convertToCSV -> objArray",
              //   typeof objArray
              // );
              var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
              var str = ''
              // // console.log("Options -> convertToCSV -> array", array);
              for (var i = 0; i < array.length; i++) {
                var line = ''
                for (var index in array[i]) {
                  // // // console.log("Options -> convertToCSV -> index", index);
                  // console.log(
                  //   "Options -> convertToCSV -> index",
                  //   JSON.stringify(index)
                  // );
                  if (line !== '') line += ','
                  // // // console.log("Options -> convertToCSV -> line", line);
                  // console.log(
                  //   "Options -> convertToCSV -> array[i][index]",
                  //   array[i][index]
                  // );

                  // console.log(
                  //   "Options -> convertToCSV ->   typeof  array[i][index]",
                  //   typeof array[i][index]
                  // );
                  array[i][index] = specialTransform(array[i][index])
                  // typeof array[i][index] === "object"
                  //   ? JSON.stringify(array[i][index])
                  //   : array[i][index];

                  line += array[i][index]
                  // // console.log("Options -> convertToCSV -> line", line);
                  // console.log(
                  //   "Options -> convertToCSV -> line",
                  //   JSON.stringify(line)
                  // );
                }

                str += line + '\r\n'
                // // console.log("Options -> convertToCSV -> str", str);
              }

              return str
            }

            function exportCSVFile(headers, items, fileTitle) {
              // if (headers) {
              //   items.unshift(headers);
              // // //   console.log("Options -> exportCSVFile -> items", items);
              // }

              // Convert Object to JSON
              var jsonObject = JSON.stringify(items)
              // console.log("Options -> exportCSVFile -> jsonObject", jsonObject);

              var csv = convertToCSV(jsonObject)
              // // console.log("Options -> exportCSVFile -> csv", csv);
              // csv.replace("]");
              // csv.replace("[");
              // csv.replace("{");
              // csv.replace("}");
              // console.log(
              //   "Options -> exportCSVFile -> csv",
              //   JSON.stringify(csv)
              // );
              // var csv1 = JSON2CSV(jsonObject)
              // // console.log("Options -> exportCSVFile -> csv1", csv1);

              var exportedFilenmae = fileTitle + '.csv' || 'export.csv'

              var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
              if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae)
              } else {
                var link = document.createElement('a')
                if (link.download !== undefined) {
                  // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob)
                  link.setAttribute('href', url)
                  link.setAttribute('download', exportedFilenmae)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
              }
            }
            var data11 = []
            var headers11 = []

            return (
              <div className="d-block w-100 text-center">
                <UncontrolledButtonDropdown>
                  <DropdownToggle className="btn-icon btn-icon-only btn btn-link" color="link">
                    <MoreVertIcon style={{ color: 'black' }} />
                  </DropdownToggle>
                  <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
                    <DropdownItem
                      onClick={(e) => {
                        Swal.fire({
                          title: 'Duplicate survey',
                          input: 'text',
                          inputLabel: 'Survey name',
                          inputPlaceholder: 'Enter survey name',
                          showCancelButton: true,
                          reverseButtons: true,
                          showLoaderOnConfirm: true,
                          buttonsStyling: false,
                          confirmButtonText: 'Confirm',
                          customClass: {
                            cancelButton: `swal-button swal-button--cancel swal_button_cancel`,
                            confirmButton: `swal-button swal-button--catch swal_button_accept`,
                          },
                          preConfirm: (name) => {
                            if (name.trim().length === 0) {
                              Swal.showValidationMessage(`Please ixwnput a name`)
                            } else {
                              let API_END_POINT = ProjectSettings.baseURL + '/api/survey/checkSurveyName'
                              let bodyData = JSON.stringify({
                                surveyName: name,
                                userId: this.props.match.params.id,
                              })
                              return fetch(API_END_POINT, {
                                method: 'POST',
                                headers: {
                                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                  'Content-type': 'application/json',
                                },
                                body: bodyData,
                              })
                                .then((e) => e.json())
                                .then((e) => {
                                  console.log('Options -> constructor -> e', e)
                                  if (e.success === false) {
                                    Swal.showValidationMessage(`Survey name already exist`)
                                  } else {
                                    return e
                                  }
                                })

                                .catch((e) => {
                                  console.log('Options -> constructor -> e', e.response.data)
                                })
                            }
                          },
                        }).then((e) => {
                          if (e.value) {
                            if (e.value.success === true) {
                              // let CouponCode
                              // if (data.original.totalCode === null) {
                              //   CouponCode = []
                              // } else {
                              //   CouponCode = data.original.totalCode.concat(data.original.Codes_redeemed)
                              // }
                              let Date_local = new Date()
                              let body1 = {
                                surveyName: e.value.name,
                                surveyId: data.original.id,
                                surveyJson: data.original.json,
                                CouponCodeStatus: 'Inactive',
                                status: 'Draft',
                                couponCode: [],
                                TimeStampLocal: Date_local,
                                Resultjson: [],
                              }
                              let API_END_POINT = ProjectSettings.baseURL + '/api/survey'
                              fetch(API_END_POINT, {
                                method: 'POST',
                                headers: {
                                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                  'Content-type': 'application/json',
                                },
                                body: JSON.stringify(body1),
                              })
                                .then((response) => {
                                  return response.json()
                                })
                                .then((json) => {
                                  Swal.fire({
                                    title: `Survey Duplicated Succesfully`,

                                    icon: 'success',
                                  })

                                  this.UpdatingSurvey('DUPLICATE_DASBOARD', json.responseData, body1.surveyId)
                                  // this.sendingSurveyListtodb()
                                })
                            }
                          }
                        })
                      }}
                    >
                      <FileCopyIcon className="dropdown-icon" />
                      <span>Duplicate</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={(e) => {
                        this.props.history.push(`/User-survey-edit/${data.original.id}/${this.props.match.params.id}`)
                        // setSurveyId(data.original.id);
                      }}
                    >
                      <EditIcon className="dropdown-icon" />
                      <span>Edit</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={(e) => {
                        this.setState({
                          showState: true,
                          ShareSurvey: {
                            id: data.original.id,
                            surveyState: data.original.status,
                          },
                        })
                      }}
                    >
                      <ShareIcon className="dropdown-icon" />
                      <span>Copy Url</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      // onClick={(e) => {
                      //   console.log(
                      //     "Options -> constructor -> data.orginal",
                      //     data
                      //   );
                      //   let CouponCode = [];
                      //   if (data.original.totalCode === null) {
                      //     CouponCode = [];
                      //   } else {
                      //     CouponCode = data.original.totalCode.concat(
                      //       data.original.Codes_redeemed
                      //     );
                      //   }

                      //   let body1 = {
                      //     surveyName: data.original.Name,
                      //     couponCode: CouponCode,
                      //     surveyId: data.original.id,
                      //     surveyJson: data.original.json,
                      //     CouponCodeStatus: "Inactive",
                      //     status: data.original.status,
                      //   };

                      //   let API_END_POINT =
                      //     "https://api.carwashsurveys.com/api/survey";

                      //   fetch(API_END_POINT, {
                      //     method: "POST",
                      //     headers: {
                      //       Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                      //       "Content-type": "application/json",
                      //     },
                      //     body: JSON.stringify(body1),
                      //   })
                      //     .then((response) => {
                      //       console.log(
                      //         "Options -> sendingSurveyListtodb -> response",
                      //         response
                      //       );
                      //       return response.json();
                      //     })
                      //     .then((json) => {
                      //       console.log(
                      //         "Options -> sendingSurveyListtodb -> json",

                      //         json
                      //       );
                      //       this.sendingSurveyListtodb();
                      //     });
                      // }}
                      onClick={(e) => {
                        if (data.original.Resultjson.length > 0) {
                          // swal({
                          //   title: "Download Responses?",
                          //   // text: "save as",
                          //   // content: "input",
                          //   icon:"warning",
                          //   buttons: {
                          //   cancel: "Cancel",
                          //     catch: {
                          //       text: "Okay",
                          //       value: "ok",
                          //     },

                          //   },
                          // }).then((value) => {
                          //   if (value === "ok") {
                          data.original.Resultjson.forEach((e) => {
                            data11.push({
                              date: e.updatedAt,
                              result: e.resultJson,
                            })
                            headers11.push(
                              {
                                label: 'result',
                                key: e.resultJson,
                              },
                              {
                                label: 'date',
                                key: e.updatedAt,
                              }
                            )
                          })

                          let finalResult = []
                          // let couter = 0
                          let dateArray = []
                          let ResultArray = []

                          data11.forEach((e1, i2) => {
                            Object.keys(e1).forEach((e) => {
                              if (e === 'date') {
                                let Key = e

                                if (finalResult.length > 0) {
                                } else {
                                  finalResult.push({
                                    key: Key,
                                  })
                                }
                              }
                              if (e === 'result') {
                                e1.result.forEach((e2, i1) => {
                                  if (i2 === 0) {
                                  }
                                  finalResult[0][`question${i1}`] = e2.Title
                                })
                              }
                            })

                            let date = e1.date
                            dateArray.push({
                              date,
                            })
                            let result = e1.result
                            ResultArray.push({
                              result,
                            })
                          })

                          dateArray.forEach((e, i) => {
                            finalResult.push({
                              key: e.date,
                            })
                          })
                          ResultArray.forEach((e, i2) => {
                            e.result.forEach((e1, i1) => {
                              let result = e1.result
                              finalResult[i2 + 1][`question${i1}`] = result
                            })
                          })

                          exportCSVFile([], finalResult, 'download')
                          // }
                          // console.log(
                          //   "Options -> constructor -> headers11",
                          //   headers11
                          // );
                          // });
                        } else {
                          swal({
                            title: 'There are no survey responses',
                            icon: 'warning',
                            timer: 20000,
                          })
                        }
                      }}
                    >
                      <AssessmentIcon className="dropdown-icon" />
                      <span>Download</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={(e) => {
                        this.setState({
                          DeleteSuveyDetails: {
                            id: data.original.id,
                          },
                        })
                        // this.setState({ DeleteNotification: true });
                        // swal({
                        //   title: "Do you want to continiue",
                        //   text:
                        //     "Survey and all associated responses and report will be deleted",
                        //   icon: "warning",
                        //   buttons: {
                        //     catch: {
                        //       text: "Ok",
                        //       value: "delete",
                        //     },
                        //     cancel: "Cancel",
                        //   },
                        // })
                        swal1({
                          content: (
                            <div>
                              <div className="swal-icon swal-icon--warning">
                                <span className="swal-icon--warning__body">
                                  <span className="swal-icon--warning__dot"></span>
                                </span>
                              </div>
                              <div className="swal-title">Are you sure you want to delete the survey?</div>
                              <div className="swal-title">{data.original.Name}</div>
                              <div className="swal-text">The survey and all associated responses and reports will be deleted.</div>
                              {/* <div className="swal-footer">
                              <div className="swal-button-container">
                                <button className="swal-button swal-button--cancel">
                                  CANCEL
                                </button>
                              </div>
                              <div className="swal-button-container">
                                <button className="swal-button swal-button--catch">
                                  OKAY
                                </button>
                              </div>
                            </div> */}
                            </div>
                          ),
                          buttons: {
                            cancel: 'Cancel',
                            catch: {
                              text: 'Confirm',
                              value: 'delete',
                            },
                          },
                        }).then((value) => {
                          if (value === 'delete') {
                            const body11 = {
                              surveyId: this.state.DeleteSuveyDetails.id,
                            }
                            fetch(ProjectSettings.baseURL + '/api/survey/delete', {
                              method: 'POST',
                              headers: {
                                Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                'Content-type': 'application/json',
                              },
                              body: JSON.stringify(body11),
                            })
                              .then((response) => {
                                response.json()
                              })
                              .then((json) => {})
                              .then(() => {
                                this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token)
                                this.setState({ DeleteNotification: false })
                              })
                              .catch((e) => {
                                // console.log("Options -> constructor -> e", e);
                              })
                          }
                        })
                      }}
                    >
                      <DeleteIcon className="dropdown-icon" />
                      <span>Delete</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            )
          },
        },
      ],
      Survey: [],
      SurveyFetch: [],
      ShareSurvey: {},
      NoSurveyFound: false,
    }
  }
  surveyCreator

  Loader_visible = () => {
    this.props.dispatch(LoaderActions_loadingtrue())
  }

  Loader_hide = () => {
    // console.log("Options -> Loader_hide -> Loader_hide");

    this.props.dispatch(LoaderActions_loadingfalse())
  }

  sendingSurveyListtodb = (token) => {
    this.Loader_visible()
    var API_END_POINT = ProjectSettings.baseURL + '/api/survey/admin'
    this.setState(
      {
        PageNumber: this.props.match.params.pageId,
        PageSize: !token ? this.state.PageSize : token.pageSize,
      },
      () => {
        fetch(`${API_END_POINT}/${this.props.match.params.id}/${this.props.match.params.pageId}?pagesize=${this.state.PageSize}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdGF0dXMiOiJBY3RpdmUiLCJyb2xlIjoic3VwZXJhZG1pbiIsIl9pZCI6IjVmODQ1YzhmZjBlMThiN2JhNjhmMjQ1MyIsImVtYWlsIjoic3VwZXJhZG1pbkBjYXJ3YXNoc3VydmV5cy5jb20iLCJuYW1lIjoiU3VwZXIgQWRtaW4iLCJwYXNzd29yZCI6IiQyYSQxMCQyR1ZUNlJucmZERC5DSFQxSVdyVTYuT3lIZm5DUEZUQ1JCUmtoUnRNcjBEbWVMNHBRbnYyYSIsImNyZWF0ZWRBdCI6IjIwMjAtMTAtMTJUMTM6Mzk6MjcuMzAyWiIsInVwZGF0ZWRBdCI6IjIwMjAtMTEtMTlUMTU6MzE6NDAuMjI0WiIsIl9fdiI6MCwicHJvZmlsZUltYWdlIjoiaHR0cHM6Ly9hcGkuY2Fyd2FzaHN1cnZleXMuY29tL3Byb2ZpbGUvMTYwMzQzOTAxODMzNV8zLmpwZyIsImlhdCI6MTYxMjI2NDk2NywiZXhwIjoxNjEyODY5NzY3LCJhdWQiOiJjYXJ3YXNoLWFsbC1hcGkiLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjMwMDAifQ.T4OgFJp_XIJZBfyJmEQy6pmJkjEP05AgnZ08_zhYJ7E`,
            Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log("Options -> sendingSurveyListtodb -> data", data);
            this.setState({ SurveyFetch: [...data.responseData], pages: data.TotalPage, ResponseLength: data.Totalresponse }, () => {
              if ([...data.responseData].length === 0) {
                this.setState({ NoSurveyFound: true })
              }
            })
            this.SurveyListCreator(this.state.SurveyFetch)
          })

          .catch((e) => {
            swal({
              icon: 'warning',
              title: 'Something went wrong',
            }).then((e) => {
              if (e) {
                this.props.history.push('/')
              }
            })
          })
      }
    )
  }

  SurveyListCreator = (survey, updatesurveyList) => {
    var Survey1 = []

    if (updatesurveyList === true) {
      survey.forEach((e, i) => {
        Survey1.push({
          Name: e.Name,
          updatedAt: e.updatedAt,
          status: e.status,
          Responses: e.Responses,
          Codes_redeemed: e.Codes_redeemed === null ? [] : e.Codes_redeemed,
          surveyJson: e.surveyJson,
          id: e.id,
          totalCode: e.totalCode === null ? [] : e.totalCode,
          CouponCodeStatus: e.CouponCodeStatus,
          Resultjson: e.Resultjson,
          TimeStampLocal: e.TimeStampLocal,
          delete_check: false,
        })
      })
    } else {
      survey.forEach((e, i) => {
        // var ResultNumber = 0
        if (e.id !== null) {
          if (e.TimeStampLocal) {
            Survey1.push({
              Name: e.surveyName,
              updatedAt: e.updatedAt,
              status: e.status,
              Responses: e.responseLength,
              Codes_redeemed: e.couponCodeUsed === null ? [] : e.couponCodeUsed,
              surveyJson: e.surveyJson,
              id: e.surveyId,
              totalCode: e.couponCode === null ? [] : e.couponCode,
              CouponCodeStatus: e.CouponCodeStatus,
              Resultjson: e.surveyResults,
              TimeStampLocal: e.TimeStampLocal,
              delete_check: false,
            })
          } else {
            Survey1.push({
              Name: e.surveyName,
              updatedAt: e.updatedAt,
              status: e.status,
              Responses: e.responseLength,
              Codes_redeemed: e.couponCodeUsed === null ? [] : e.couponCodeUsed,
              surveyJson: e.surveyJson,
              id: e.surveyId,
              totalCode: e.couponCode,
              CouponCodeStatus: e.CouponCodeStatus,
              Resultjson: e.surveyResults,
              delete_check: false,
            })
          }
        }
      })
    }
    this.setState({ Survey: Survey1 }, () => {
      this.Loader_hide()
    })
  }

  UpdatingSurvey = (action, survey_body, _id) => {
    let NewData = []

    if (action === 'DELETE_DASHBOARD') {
      NewData = this.state.Survey
      NewData.forEach((e, i) => {
        if (e.id === _id) {
          NewData.splice(i, 1)
        }
      })

      this.SurveyListCreator(NewData, true)
    }

    if (action === 'DUPLICATE_DASBOARD') {
      NewData = this.state.Survey
      NewData.forEach((e, i) => {
        if (e.id === _id) {
          NewData.splice(i + 1, 0, {
            Name: survey_body.surveyName,
            updatedAt: survey_body.updatedAt,
            status: survey_body.status,
            Responses: 0,
            Codes_redeemed: survey_body.couponCodeUsed === null ? [] : survey_body.couponCodeUsed,
            surveyJson: survey_body.surveyJson,
            id: survey_body.surveyId,
            totalCode: survey_body.couponCode === null ? [] : survey_body.couponCode,
            CouponCodeStatus: survey_body.CouponCodeStatus,
            Resultjson: [],
            TimeStampLocal: survey_body.TimeStampLocal,
            delete_check: false,
          })
        }
      })

      // this.setState({
      //   Survey: NewData,
      // })
      this.SurveyListCreator(NewData, true)
    }
    if (action === 'COUPON_CODE_UPDATE_ADMIN') {
      NewData = this.state.Survey

      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          // e.status = e.status
          e.totalCode = survey_body.couponCode
          e.CouponCodeStatus = survey_body.CouponCodeStatus
          e.Codes_redeemed = survey_body.couponCodeUsed === undefined ? e.Codes_redeemed : survey_body.couponCodeUsed
        }
      })
      this.setState({
        Survey: NewData,
      })
    }

    if (action === 'SURVEY_STATE_UPDATE_CLOSE') {
      NewData = this.state.Survey
      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          e.status = survey_body.status
        }
      })
      this.setState({
        Survey: NewData,
      })
    }
    if (action === 'SURVEY_STATE_UPDATE_OPEN') {
      NewData = this.state.Survey
      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          e.status = survey_body.status
        }
      })
      this.setState({
        Survey: NewData,
      })
    }
    if (action === 'ADD_QUESTION') {
      NewData = this.state.Survey

      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          e.surveyJson = survey_body.surveyJson
          e.CouponCodeStatus = survey_body.CouponCodeStatus
        }
      })
      this.setState({
        Survey: NewData,
      })
    }
    if (action === 'COUPON_CODE_STATUS_UPDATE') {
      NewData = this.state.Survey
      NewData.forEach((e) => {
        if (e.id === survey_body.surveyId) {
          e.CouponCodeStatus = survey_body.CouponCodeStatus
        }
      })

      this.setState({
        Survey: NewData,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.state.PageKeyReducer.key !== this.props.location.key) {
      this.props.dispatch(pageKeyAction(this.props.location.key))
    }

    if (this.props.state.LoginReducer11 === null || this.props.state.LoginReducer11.length === 0) {
      this.props.history.push('/login')
    }
  }

  // componentWillMount() {
  //   if (this.props.state.PageKeyReducer.key !== this.props.location.key) {
  //     this.props.dispatch(pageKeyAction(this.props.location.key))
  //   }
  //   if (this.props.state.LoginReducer11 === null) {
  //     this.props.history.push('/login')
  //   }

  //   // this.SurveyListCreator();
  // }
  componentDidMount() {
    if (this.props.state.LoginReducer11.length === 0) {
      this.props.history.push('/')
    }
    this.surveyCreator = new SurveyJSCreator.SurveyCreator(null)

    // var Survey = useSelector((state) => state.SaveSurvey);
  }

  render() {
    if (this.state.NoSurveyFound === true) {
      return (
        <>
          <Header />
          <div
            style={{
              height: 'auto',
              width: '100%',
              // margin: "auto",
              // display: "flex",
              // justifyContent: "center",
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div>You dont have any survey, create a new survey</div>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <Loader11 />
          <div>
            {/* <button onClick={toast("wow")} /> */}
            <Modal isOpen={this.state.addCoupon} toggle={() => this.setState({ addCoupon: false })} className={this.props.className}>
              <ModalHeader className="Modal_header Modal_header1" toggle={this.toggle}>
                <img style={{ width: '100px', height: '70px' }} src={tag_icon} alt={''} />
                <span
                  style={{
                    fontWeight: '600',
                  }}
                >
                  Manage Coupon Codes
                </span>
              </ModalHeader>
              <ModalBody className="Modal_body Modal_body1">
                <Reader
                  SURVEY={this.state.AddCouponSurveyDetails.id}
                  sent_survey={this.state.AddCouponSurveyDetails.Sent_code}
                  // Delete_check_top  = {(e)=>{
                  //   this.setState({Delete_check_top : e})
                  // }}
                  Delete_check={(e) => {
                    let tempSurvey = this.state.Survey

                    let filterstate = this.state.Survey.filter((val) => e.properties === val.id)

                    let tempObj = filterstate[0]

                    tempObj.delete_check = true
                    tempSurvey[tempSurvey.findIndex((i) => filterstate[0] === i)] = tempObj
                    this.setState({ Survey: tempSurvey }, () => {})
                    // this.setState({ Delete_check: e });
                  }}
                  coupon_delete={(e) => {
                    this.setState({ Coupon_codeNumber: [] })
                  }}
                  coupon_delete_top={(e) => {
                    this.setState({
                      Coupon_codeNumber: [],
                    })
                  }}
                  Delete_present_code={(e) => {}}
                  Array_sending={(e) => {
                    if (e === null) {
                      // this.props.Error = "kindly upload a valid file";
                      this.setState({
                        AddCouponError: 'kindly upload a valid file',
                      })
                    } else {
                      this.setState({ Coupon_codeNumber: e })
                    }
                  }}
                  CouponCode_available={this.state.AddCouponSurveyDetails.CouponCode}
                  ButtonCLickedState={this.state.AddCouponClicked}
                />
              </ModalBody>
              <ModalFooter className="Modal_footer Modal_footer1">
                <Button
                  color="link"
                  className="btn-cancel-modal btn"
                  onClick={() =>
                    this.setState({ addCoupon: false, Coupon_codeNumber: [] }, () => {
                      this.props.dispatch(CouponCodeaction_remove_codes())
                    })
                  }
                >
                  Cancel
                </Button>
                <LaddaButton
                  color="primary"
                  data-style={ZOOM_IN}
                  className="btn btn-primary btn-accept-modal"
                  loading={this.state.ButtonLoading}
                  style={{
                    fontSize: '13px',
                    width: '65px',
                  }}
                  onClick={() => {
                    this.setState({
                      ButtonLoading: true,
                    })
                    let CouponCodeStatus = ''
                    let coupon_code = []

                    this.state.Survey.forEach((e) => {
                      if (e.id === this.state.AddCouponSurveyDetails.id) {
                        CouponCodeStatus = e.CouponCodeStatus
                      }
                    })
                    let deleteCheckStatus = false
                    for (let e of this.state.Survey) {
                      // console.log("🚀 ~ file: index.js ~ line 2227 ~ Options ~ //this.state.Survey.forEach ~ this.state.AddCouponSurveyDetails.id", this.state.AddCouponSurveyDetails.id)
                      if (e.id === this.state.AddCouponSurveyDetails.id) {
                        if (e.delete_check === true) {
                          deleteCheckStatus = true
                          if (this.state.Coupon_codeNumber.length === 0) {
                            coupon_code = []
                            CouponCodeStatus = 'Inactive'
                          } else if (this.state.Coupon_codeNumber.length !== 0) {
                            coupon_code = this.state.Coupon_codeNumber
                          }
                          // break;
                        } else {
                          if (this.state.Coupon_codeNumber.length !== 0) {
                            coupon_code = this.state.Coupon_codeNumber
                          }
                          if (this.state.Coupon_codeNumber.length === 0) {
                            coupon_code = this.props.state.Coupon_code_reducer2.PresentCouponCode
                          }
                        }
                      }
                    }

                    // if (
                    //   this.state.Delete_check === true &&
                    //   this.state.Coupon_codeNumber.length === 0
                    // ) {
                    //   console.log("render -> case1");
                    //   coupon_code = [];
                    //   CouponCodeStatus = "Inactive";
                    // }
                    // if (
                    //   this.state.Delete_check === true &&
                    //   this.state.Coupon_codeNumber.length !== 0
                    // ) {
                    //   console.log("render -> case2");
                    //   coupon_code = this.state.Coupon_codeNumber;
                    // }
                    let date = new Date()
                    let finalDate = date.toISOString()
                    let body = {
                      surveyId: this.state.AddCouponSurveyDetails.id,
                      surveyName: this.state.AddCouponSurveyDetails.name,
                      couponCode: coupon_code,
                      TimeStampLocal: finalDate,

                      surveyJson: this.state.AddCouponSurveyDetails.json,
                      CouponCodeStatus: CouponCodeStatus,
                    }
                    if (deleteCheckStatus === true) {
                      body.couponCodeUsed = []
                    }
                    let API_END_POINT = ProjectSettings.baseURL + '/api/survey'

                    fetch(API_END_POINT, {
                      method: 'PUT',
                      headers: {
                        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                        'Content-type': 'application/json',
                      },
                      body: JSON.stringify(body),
                    })
                      .then((response) => {
                        return response.json()
                      })
                      .then((e) => {
                        if (e.success) {
                          this.UpdatingSurvey('COUPON_CODE_UPDATE_ADMIN', body)
                          this.setState(
                            {
                              addCoupon: false,
                              AddCouponClicked: !this.state.AddCouponClicked,
                              ButtonLoading: false,
                              Coupon_codeNumber: [],
                            },
                            () => {
                              this.props.dispatch(CouponCodeaction_remove_codes())
                            }
                          )
                        } else {
                          this.setState({
                            ButtonLoading: false,
                          })
                          swal({
                            icon: 'error',
                            title: 'File size exceeded',

                            buttons: {
                              cancel: 'Cancel',
                            },
                          })
                        }
                      })
                  }}
                >
                  OK
                </LaddaButton>
              </ModalFooter>
            </Modal>

            {/* <Modal
              isOpen={this.state.addCoupon}
              toggle={() => this.setState({ addCoupon: false })}
              className={this.props.className}
            >
              <ModalHeader className="Modal_header" toggle={this.toggle}>
                Manage coupon codes
              </ModalHeader>
              <ModalBody className="Modal_body">
                <Reader
                  Array_sending={(e) => {
                    // console.log("render -> Array_sending", e);

                    if (e === null) {
                      // this.props.Error = "kindly upload a valid file";
                      this.setState({
                        AddCouponError: "kindly upload a valid file",
                      });
                    } else {
                      // // console.log("render -> e", e);
                      this.setState({ Coupon_codeNumber: e });
                    }
                  }}
                  CouponCode_available={
                    this.state.AddCouponSurveyDetails.CouponCode
                  }
                  ButtonCLickedState={this.state.AddCouponClicked}
                />
              </ModalBody>
              <ModalFooter className="Modal_footer">
                <Button
                  color="link"
                  onClick={() =>
                    this.setState({ addCoupon: false }, () => {
                      this.props.dispatch(CouponCodeaction_remove_codes());
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={
                    this.state.Coupon_codeNumber === null ? true : false
                  }
                  onClick={() => {
                    let body = {
                      surveyId: this.state.AddCouponSurveyDetails.id,
                      surveyName: this.state.AddCouponSurveyDetails.name,
                      couponCode: this.state.Coupon_codeNumber,
                      surveyJson: this.state.AddCouponSurveyDetails.json,
                      CouponCodeStatus: "Inactive",
                    };
                    // console.log("Options -> render -> body", body);
                    let API_END_POINT =
                      "https://api.carwashsurveys.com/api/survey";

                    fetch(API_END_POINT, {
                      method: "PUT",
                      headers: {
                        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                        "Content-type": "application/json",
                      },
                      body: JSON.stringify(body),
                    })
                      .then((response) => {
                        return response.json();
                      })
                      .then((e) => {
                        // console.log("Options -> render -> e", e);
                        this.sendingSurveyListtodb();
                        this.setState(
                          {
                            addCoupon: false,
                            AddCouponClicked: !this.state.AddCouponClicked,
                          },
                          () => {
                            this.props.dispatch(
                              CouponCodeaction_remove_codes()
                            );
                          }
                        );
                      });
                  }}
                  style={{
                    padding: "10px 35px",
                    fontSize: "18px",
                  }}
                >
                  Done
                </Button>{" "}
              </ModalFooter>
            </Modal> */}

            <SweetAlert
              title="Something went wrong"
              confirmButtonColor=""
              show={this.state.EdgeCaseErrorState}
              showCancelButton={true}
              confirmButtonText={'Edit'}
              text={this.state.EdgeCaseError}
              type="error"
              onCancel={() => {
                this.setState({ EdgeCaseErrorState: false, Checked: false })
              }}
              onConfirm={() => {
                this.setState({ EdgeCaseErrorState: false, Checked: false })
                this.props.history.push(`/edit-survey/${this.state.UpdateUserDetails.body1.surveyId}`)
              }}
            />
            <SweetAlert
              title="Are you sure"
              confirmButtonColor=""
              show={this.state.UpdateNotification}
              onOutsideClick={() => {
                this.setState({ UpdateNotification: false })
              }}
              onConfirm={() => {
                fetch(ProjectSettings.baseURL + '/api/survey', {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                  },

                  body: JSON.stringify(this.state.UpdateUserDetails.body1),
                })
                  .then((r) => {
                    return r.json()
                  })
                  .then((e) => {
                    this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token)
                    this.setState({ UpdateNotification: false })
                  })
                  .catch((e) => {})
              }}
            />
            <ToastContainer
              position="bottom-right"
              color="success"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          <Modal
            style={{
              display: 'flex',
            }}
            isOpen={this.state.showState}
            centered={true}
            toggle={() => this.setState({ showState: false })}
            modalClassName={'Share_modal'}
          >
            <ModalHeader toggle={this.toggle}>Share Survey</ModalHeader>
            <ModalBody>
              <div>Link</div>

              <div>
                {window.location.href.replace(
                  `User-View/${this.props.match.params.id}/${this.props.match.params.pageId}`,
                  `survey/${this.state.ShareSurvey.id}`
                )}
              </div>
              {this.state.ShareSurvey.surveyState === 'Draft' ? <div>Kindly change the State to Active</div> : <div></div>}
            </ModalBody>
            <ModalFooter>
              <Button color="link" onClick={() => this.setState({ showState: false })}>
                Cancel
              </Button>
              <CopyToClipboard
                text={window.location.href.replace(`User-View/${this.props.match.params.id}`, `survey/${this.state.ShareSurvey.id}`)}
                onCopy={() => {
                  toast('Copied to Clipboard')

                  this.setState({ copied: true })
                }}
              >
                <Button color="primary" onClick={() => this.setState({ showState: false })}>
                  Copy
                </Button>
              </CopyToClipboard>
            </ModalFooter>
          </Modal>
          {/* <Loader11 /> */}
          <SweetAlert
            title="The survey and all associated responses and reports will be deleted."
            confirmButtonColor=""
            show={this.state.DeleteNotification}
            showCancelButton={true}
            cancelButtonText={'Cancel'}
            confirmButtonText={'Confirm'}
            onCancel={() => {
              this.setState({ DeleteNotification: false })
            }}
            onOutsideClick={() => {
              this.setState({ DeleteNotification: false })
            }}
            // onClick={(e) => {
            //   this.setState({ DeleteNotification: false });
            // }}
            onConfirm={(e) => {
              const body11 = {
                surveyId: this.state.DeleteSuveyDetails.id,
              }
              fetch(ProjectSettings.baseURL + '/api/survey/delete', {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                  'Content-type': 'application/json',
                },
                body: JSON.stringify(body11),
              })
                .then((response) => {
                  response.json()
                })
                .then((json) => {
                  return json
                })
                .then((json) => {
                  this.UpdatingSurvey('DELETE_DASHBOARD', json, body11.surveyId)

                  // this.sendingSurveyListtodb(
                  //   this.props.state.LoginReducer11.response.token
                  // );
                  this.setState({ DeleteNotification: false })
                })
                .catch((e) => {})

              // this.props.dispatch(
              //   DeleteSurvey(
              //     this.state.DeleteSuveyDetails.id,
              //     this.state.DeleteSuveyDetails.json,
              //     this.state.DeleteSuveyDetails.status,
              //     this.state.DeleteSuveyDetails.Name
              //   )
              // );
              // this.sendingSurveyListtodb();
              // this.updateTableondelete();
              // this.setState({ DeleteNotification: false });
            }}
          />
          <div className="option-header">
            <Header />
            <div className="sticky-table">
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <ReactTable
                      data={this.state.Survey}
                      columns={this.state.columnss}
                      defaultPageSize={10}
                      pageSizeOptions={[10, 15]}
                      resizable={false}
                      pages={this.state.pages}
                      manual
                      page={parseInt(this.props.match.params.pageId) - 1}
                      onFetchData={this.sendingSurveyListtodb}
                      // onFetchData={() => {
                      // if (this.props.state.LoginReducer11 === null) {
                      //   this.props.history.push('/login')
                      // } else {
                      //   try {
                      //     this.sendingSurveyListtodb(this.props.state.LoginReducer11.response.token, this.state.PageNumber)
                      //   } catch (e) {}
                      // }
                      // }}
                      onPageChange={(pageInedx) => {
                        console.log('pageInedx', pageInedx)
                        this.props.history.push(`/User-View/${this.props.match.params.id}/${pageInedx + 1}`)
                        this.setState(
                          {
                            PageNumber: pageInedx + 1,
                          },
                          () => {
                            this.sendingSurveyListtodb()
                          }
                        )
                      }}
                      // onFetchData={this.Fetchdata}
                      className="-striped -highlight"
                    />
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </>
      )
    }
  }
}
const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Options))
