/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
// import { format } from 'date-fns'
import { useIdleTimer } from 'react-idle-timer'
// import swal from 'sweetalert'
// import { withRouter } from 'react-router-dom'
// import { connect } from 'react-redux'
// import { LogoutAction } from '../../redux/actions/Login2'
import setting from '../../global'
const SessioTimeoutModal = (props) => {
  const timeout = setting.timeout
  const [, setRemaining] = useState(timeout)
  const [, setElapsed] = useState(0)
  const [, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => setIsIdle(true)

  const { getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  })

  // const handleReset = () => reset()
  // const handlePause = () => {
  //   swal({
  //     icon: 'warning',
  //     title: `The current session is about to expire in ${remaining}`,
  //     buttons: {
  //       catch: {
  //         text: 'Continue',
  //         value: 'ok',
  //       },
  //       default: {
  //         text: 'Logout',
  //         value: 'logout',
  //       },
  //     },
  //   }).then((e) => {})

  //   pause()
  // }
  // const handleResume = () => resume()

  useEffect(() => {
    setRemaining(getRemainingTime())
    setLastActive(getLastActiveTime())
    setElapsed(getElapsedTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
    }, 1000)
  }, [])

  return (
    <div>
      {/* <div>
        <h1>Timeout: {timeout}ms</h1>
        <h1>Time Remaining: {remaining}</h1>
        <h1>Time Elapsed: {elapsed}</h1>
        <h1>Last Active: {format(lastActive, "MM-dd-yyyy HH:MM:ss.SSS")}</h1>
        <h1>Idle: {isIdle.toString()}</h1>
      </div>
      <div>
        <button onClick={handleReset}>RESET</button>
        <button onClick={handlePause}>PAUSE</button>
        <button onClick={handleResume}>RESUME</button>
      </div> */}
      {isIdle.toString() === 'false' ? props.send_data(true) : props.send_data(false)}
    </div>
  )
}

// const mapStateToProps = (state) => {
//   return { state };
// };
// const mapDispatchToProps = (dispatch) => {
//   return { dispatch };
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(SessioTimeoutModal));
export default SessioTimeoutModal
