export const LoaderActions_loadingtrue = (payload) => {
  return {
    type: 'LOADING_TRUE',
    payload,
  }
}

export const LoaderActions_loadingfalse = (payload) => {
  return {
    type: 'LOADING_FALSE',
    payload,
  }
}
