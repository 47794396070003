import React, { Component } from 'react'
// import { Elastic } from 'react-burgers'
import Loader11 from '../Loader'
import {
  // TabContent,
  // TabPane,
  // DropdownItem,
  CardBody,
  // Collapse,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  // FormText,
  Card,
  Col,
  Row,
  CardHeader,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import LaddaButton, {
  // EXPAND_LEFT,
  // EXPAND_RIGHT,
  // EXPAND_UP,
  // EXPAND_DOWN,
  // SLIDE_LEFT,
  // SLIDE_RIGHT,
  // SLIDE_UP,
  // SLIDE_DOWN,
  ZOOM_IN,
  // ZOOM_OUT,
} from 'react-ladda'
import './index.css'
import ReactTooltip from 'react-tooltip'

import { UserDeailsactions } from '../../redux/actions/UserDetails'
import { LoaderActions_loadingtrue, LoaderActions_loadingfalse } from '../../redux/actions/Loader'
import { updateToken } from '../../redux/actions/Login2'
import $ from 'jquery'
import swal from 'sweetalert'
// import { InputLabel, FormControl } from '@material-ui/core'
// import EditIcon from '@material-ui/icons/Edit'
// import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { connect } from 'react-redux'
// import InputAdornment from '@material-ui/core/InputAdornment'
import { withRouter } from 'react-router-dom'
// import Feedback from 'react-bootstrap/esm/Feedback'
import ProjectSettings from '../../global/Global'

const validPassword = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/)

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      User: this.props.state.LoginReducer11,
      token: this.props.state.LoginReducer11.response.token,
      Name: '',
      email: '',
      tempEmail: '',
      emailInvalid: false,
      emailValid: false,
      businessName: '',
      businessNameState: true,
      NameState: true,
      EmailState: true,
      SavebuttondisableState: true,
      CurrentPasswordvalid: false,
      CurrentPassworinvalid: false,
      InputPassword: '',
      LoadingState: false,
      PasswordReadState: true,
      Newpassword: '',
      Newpasswordconfirm: '',
      passwordMatchErrorState: false,
      ButtonLoading: false,
      passwordmatcholdpasswordstate: false,
      Newpasswordlengthstate: false,
      passwordMatchErrormessageState: false,
      Name_error_state: false,
      businessNameInvalid: false,
      businessNameInvalidtext: '',
    }
  }
  Loader_visible = () => {
    this.props.dispatch(LoaderActions_loadingtrue())
  }

  Loader_hide = () => {
    setTimeout(() => {
      this.props.dispatch(LoaderActions_loadingfalse())
    }, 500)
  }
  nameChange_api = () => {
    let body1 = {
      id: this.state.User.response.user._id,
      name: this.state.Name,
      email: this.state.email,
    }

    fetch(ProjectSettings.baseURL + '/api/user/profile', {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        body1,
      }),
    })
      .then((e) => {
        return e.json()
      })
      .then((e) => {
        if (e.success === false) {
          this.setState({
            ButtonLoading: false,
          })
          swal({
            title: 'something went wrong11',
            icon: 'error',
          }).then((e) => {
            if (e) {
              this.props.history.go(0)
            }
          })
        }
      })
      .then((e) => {})
      .catch((e) => {
        this.setState({
          ButtonLoading: false,
        })
        swal({
          title: 'something went wrong',
          icon: 'error',
        }).then((e) => {
          if (e) {
            this.props.history.go(0)
          }
        })
      })
  }

  UserDetails = () => {
    this.Loader_visible()
    this.setState({
      NameState: true,
    })
    fetch(`${ProjectSettings.baseURL}/api/user/profile/${this.state.User.response.user._id}`, {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
        'Content-type': 'application/json',
      },
    })
      .then((e) => {
        return e.json()
      })
      .then((e) => {
        this.props.dispatch(UserDeailsactions(e.user.name, e.user.email, e.user.businessName))
        // this.setState({
        //   Name: e.user.name,
        //   email: e.user.email,
        // });
        // console.log("here are the props",this.props)
      })
      .then((e) => {
        this.setState(
          {
            Name: this.props.state.UserReducer11.name,
            email: this.props.state.UserReducer11.email,
            tempEmail: this.props.state.UserReducer11.email,
            businessName: this.props.state.UserReducer11.businessName,
          },
          () => {
            this.Loader_hide()
          }
        )
      })
      .catch((e) => {
        swal({
          title: 'Something Went Wrong',
          icon: 'error',
        }).then((e) => {
          if (e) {
            this.props.history.push('/dashboard/1')
          }
        })
      })
  }
  componentDidMount() {
    if (this.state.User === null) {
      this.props.history.push('/login')
    } else {
      this.UserDetails()
      $(document).ready(() => {
        document.querySelectorAll('.card-body').forEach((e) => {
          e.classList.add('card-body-user')
        })
      })
    }
  }
  render() {
    return (
      <>
        <Loader11 />
        <div className="outer-div">
          <div className="inner-div">
            <Card className="col-md-12 app-inner-layout__content">
              <CardHeader id="headingOne">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    width: '100%',
                  }}
                >
                  <div block color="link" className="text-left m-0 p-0" aria-controls="collapseOne">
                    <h3 className="form-heading">
                      Account Information
                      <p></p>
                    </h3>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <FormGroup>
                  <Row form style={{}}>
                    <Col md={12}>
                      <Label for="Name">Name</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          name="Name"
                          id="Name"
                          value={this.state.Name}
                          invalid={this.state.Name_error_state}
                          readOnly={this.state.NameState}
                          onChange={(e) => {
                            this.setState({
                              Name: e.target.value,
                              Name_error_state: false,
                            })
                          }}
                        ></Input>
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({
                                SavebuttondisableState: false,
                                NameState: false,
                              })
                            }}
                          >
                            edit
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback display={this.state.Name_error_state === true ? `block` : `none`}>Cannot be eympty </FormFeedback>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>

                {/* added business name change below */}
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="exampleEmail2">Business Name</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          name="businessName"
                          id="exampleEmail2"
                          value={this.state.businessName}
                          readOnly={this.state.businessNameState}
                          onChange={(e) => {
                            this.setState({
                              businessName: e.target.value,
                            })
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (this.state.businessNameState === true) {
                                this.setState({
                                  SavebuttondisableState: false,
                                  businessNameState: false,
                                })
                              }
                            }}
                          >
                            edit
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback
                          style={{
                            display: this.state.businessNameInvalid ? `block` : `none`,
                          }}
                        >
                          {this.state.businessNameInvalidtext}
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="exampleEmail2">Email</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          name="email"
                          id="exampleEmail2"
                          value={this.state.email}
                          valid={this.state.emailValid}
                          invalid={this.state.emailInvalid}
                          readOnly={this.state.EmailState}
                          onChange={(e) => {
                            this.setState(
                              {
                                // SavebuttondisableState: false,
                                email: e.target.value,
                              },
                              () => {
                                if (this.state.email === this.state.tempEmail) {
                                  this.setState({
                                    emailInvalid: false,
                                    emailValid: true,
                                    SavebuttondisableState: false,
                                  })
                                } else {
                                  let str = this.state.email.trim()
                                  if (str === '') {
                                    this.setState({
                                      emailInvalid: false,
                                      emailValid: false,
                                      SavebuttondisableState: true,
                                    })
                                  } else {
                                    let API_END_POINT = ProjectSettings.baseURL + '/api/user/checkEmail'
                                    let bodyData = JSON.stringify({
                                      email: str,
                                    })
                                    fetch(API_END_POINT, {
                                      method: 'POST',
                                      headers: {
                                        Authorization: `Bearer ${this.props.state.LoginReducer11.response.token}`,
                                        'Content-type': 'application/json',
                                      },
                                      body: bodyData,
                                    })
                                      .then((e) => {
                                        return e.json()
                                      })
                                      .then((e) => {
                                        if (e.success === true) {
                                          this.setState(
                                            {
                                              emailValid: true,
                                              emailInvalid: false,
                                              SavebuttondisableState: false,
                                            },
                                            () => {
                                              if (this.state.email.trim().length === 0) {
                                                this.setState({
                                                  emailValid: false,
                                                  emailInvalid: false,
                                                  SavebuttondisableState: true,
                                                })
                                              }
                                            }
                                          )
                                        } else {
                                          this.setState(
                                            {
                                              emailValid: false,
                                              emailInvalid: true,
                                              SavebuttondisableState: true,
                                            },
                                            () => {
                                              if (this.state.email.trim().length === 0) {
                                                this.setState({
                                                  emailValid: false,
                                                  emailInvalid: false,
                                                  SavebuttondisableState: true,
                                                })
                                              }
                                            }
                                          )
                                        }
                                      })
                                      .catch((e) => {})
                                  }
                                }
                              }
                            )
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (this.state.EmailState === true) {
                                this.setState({
                                  SavebuttondisableState: false,
                                  EmailState: false,
                                })
                              }
                            }}
                          >
                            edit
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback
                          style={{
                            display: this.state.emailInvalid ? `block` : `none`,
                          }}
                        >
                          Invalid Email
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="examplePassword555">Current Password</Label>
                      <InputGroup>
                        <Input
                          valid={this.state.CurrentPasswordvalid}
                          invalid={this.state.CurrentPassworinvalid}
                          type="password"
                          name="password"
                          id="examplePassword"
                          placeholder="Password "
                          value={this.state.InputPassword}
                          onChange={(e) => {
                            this.setState({ InputPassword: e.target.value }, () => {
                              setTimeout(() => {
                                this.setState({ LoadingState: true }, () => {
                                  let User = {
                                    email: this.state.tempEmail,
                                    password: this.state.InputPassword,
                                  }

                                  fetch(ProjectSettings.baseURL + '/api/auth/signin', {
                                    method: 'POST',
                                    headers: {
                                      'Content-type': 'application/json',
                                    },
                                    body: JSON.stringify(User),
                                  })
                                    .then((e) => {
                                      return e.json()
                                    })
                                    .then((e) => {
                                      if (e.success !== undefined) {
                                        this.setState({
                                          CurrentPasswordvalid: true,
                                          CurrentPassworinvalid: false,
                                          LoadingState: false,
                                          PasswordReadState: false,
                                        })
                                      } else {
                                        this.setState({
                                          CurrentPasswordvalid: false,
                                          CurrentPassworinvalid: true,
                                          LoadingState: false,
                                          PasswordReadState: true,
                                        })
                                      }
                                    })
                                    .catch((e) => {
                                      swal({
                                        title: 'something went wrong',
                                        icon: 'warning',
                                      })
                                    })
                                })
                              }, 750)
                            })
                          }}
                        />

                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{
                              display: this.state.LoadingState === true ? `block` : `none`,
                            }}
                          >
                            <Spinner color="primary" className="spinner_special" size="sm" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback>Incorrect password</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="examplePassword555">New Password</Label>
                      <Input
                        valid={false}
                        invalid={this.state.passwordMatchErrorState}
                        value={this.state.Newpassword}
                        type="password"
                        name="password"
                        data-tip="React-tooltip"
                        data-for="sadFace"
                        id="examplePassword"
                        data-class="tooltip_customize"
                        placeholder="New Password"
                        readOnly={this.state.PasswordReadState}
                        onChange={(e) => {
                          this.setState({
                            Newpassword: e.target.value,
                            SavebuttondisableState: false,
                          })
                        }}
                      />
                      <ReactTooltip
                        id="sadFace"
                        place="bottom"
                        type="warning"
                        effect="solid"
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        <div
                          style={{
                            width: '250px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <span>As a minimum your password must contain 7 characters</span>
                            <span>Tips for generating a secure password</span>
                          </div>
                          <ul
                            style={{
                              width: 'fit-content',
                              margin: '0px',
                              paddingLeft: '30px',
                            }}
                          >
                            <li>Include upper case letter</li>
                            <li>Include lower case letter</li>

                            <li>Include a number</li>

                            <li>Include special character</li>
                          </ul>
                        </div>
                      </ReactTooltip>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="examplePassword555">Confirm New Password</Label>
                      <Input
                        type="password"
                        name="password"
                        invalid={this.state.passwordMatchErrorState}
                        id="examplePassword555"
                        value={this.state.Newpasswordconfirm}
                        placeholder="Confirm New Password"
                        readOnly={this.state.PasswordReadState}
                        onChange={(e) => {
                          this.setState({
                            Newpasswordconfirm: e.target.value,
                            SavebuttondisableState: false,
                          })
                        }}
                      />
                      <FormFeedback
                        style={{
                          display: this.state.passwordMatchErrormessageState ? `block` : `none`,
                        }}
                      >
                        Password does not match
                      </FormFeedback>
                      <FormFeedback
                        style={{
                          display: this.state.passwordmatcholdpasswordstate ? `block` : `none`,
                        }}
                      >
                        Password match the old password
                      </FormFeedback>
                      <FormFeedback
                        style={{
                          display: this.state.Newpasswordlengthstate ? `block` : `none`,
                        }}
                      >
                        Password must have 7 characters, and must contain lower case letter, upper case letter, and number, and special character.
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      // border:"2px black solid",
                    }}
                  >
                    <Button
                      style={{}}
                      color="link"
                      className="btn-cancel-modal btn-wide btn btn-cancel-account"
                      onClick={() => {
                        this.props.history.push('/dashboard/1')
                      }}
                    >
                      Cancel
                    </Button>
                    <LaddaButton
                      data-style={ZOOM_IN}
                      loading={this.state.ButtonLoading}
                      color="primary"
                      disabled={this.state.SavebuttondisableState}
                      className="btn-wide  btn-icon btn btn-primary btn-accept-modal btn-accept-account"
                      onClick={() => {
                        this.setState({ ButtonLoading: true })
                        //Only name
                        if (this.state.PasswordReadState === true) {
                          if (this.state.Name === '') {
                            this.setState({
                              Name_error_state: true,
                              ButtonLoading: false,
                            })
                          } else {
                            if (this.state.businessName.trim() === '') {
                              this.setState({
                                businessNameInvalid: true,
                                ButtonLoading: false,
                                businessNameInvalidtext: 'Business name cannot be empty',
                              })
                            } else {
                              let body1 = {
                                id: this.state.User.response.user._id,
                                name: this.state.Name,
                                email: this.state.email,
                                businessName: this.state.businessName,
                              }

                              fetch(ProjectSettings.baseURL + '/api/user/profile', {
                                method: 'PUT',
                                headers: {
                                  Authorization: `Bearer ${this.state.token}`,
                                  'Content-type': 'application/json',
                                },
                                body: JSON.stringify(body1),
                              })
                                .then((e) => {
                                  return e.json()
                                })
                                .then((e) => {
                                  if (e.success === false) {
                                    swal({
                                      title: 'Something Went Wrong',
                                      icon: 'error',
                                    }).then((e) => {
                                      if (e) {
                                        this.props.history.push('/signin')
                                      }
                                    })
                                  }
                                  // this.props.dispatch(UserDeailsactions(e.user.name, e.user.email, e.user.businessName))
                                  if (this.state.tempEmail !== this.state.email) {
                                    this.props.dispatch(updateToken(e.token))
                                  }
                                  swal({
                                    title: 'User Updated Succesfully',
                                    icon: 'success',
                                  }).then((e) => {
                                    if (e) {
                                      this.props.history.push('/dashboard/1')
                                    }
                                  })
                                  this.UserDetails()

                                  this.setState({ ButtonLoading: false }, () => {})
                                })
                                .catch((e) => {
                                  this.setState({ ButtonLoading: false })
                                  swal({
                                    title: 'Something Went Wrong',
                                    icon: 'error',
                                  }).then((e) => {
                                    if (e) {
                                      this.props.history.push('/signin')
                                    }
                                  })
                                })
                            }
                          }
                        } else {
                          if (this.state.Name === '') {
                            this.setState({
                              Name_error_state: true,
                              ButtonLoading: false,
                            })
                          } else {
                            if (this.state.businessName.trim() === '') {
                              this.setState({
                                businessNameInvalid: true,
                                ButtonLoading: false,
                                businessNameInvalidtext: 'Business name cannot be empty',
                              })
                            } else {
                              if (this.state.Newpasswordconfirm !== this.state.Newpassword) {
                                this.setState({
                                  passwordMatchErrorState: true,
                                  ButtonLoading: false,
                                  passwordMatchErrormessageState: true,
                                })
                              } else {
                                if (this.state.Newpasswordconfirm === this.state.InputPassword) {
                                  this.setState({
                                    passwordmatcholdpasswordstate: true,
                                    passwordMatchErrorState: true,
                                    ButtonLoading: false,
                                    passwordMatchErrormessageState: false,
                                    Newpasswordlengthstate: false,
                                  })
                                } else {
                                  if (!validPassword.test(this.state.Newpasswordconfirm)) {
                                    this.setState({
                                      Newpasswordlengthstate: true,
                                      passwordMatchErrorState: true,
                                      passwordmatcholdpasswordstate: false,
                                      ButtonLoading: false,
                                      passwordMatchErrormessageState: false,
                                    })
                                  } else {
                                    // this.nameChange_api();
                                    this.setState(
                                      {
                                        Newpasswordlengthstate: false,
                                        passwordMatchErrorState: false,
                                        passwordmatcholdpasswordstate: false,

                                        passwordMatchErrormessageState: false,
                                      },
                                      () => {
                                        let body1 = {
                                          id: this.state.User.response.user._id,
                                          name: this.state.Name,
                                          businessName: this.state.businessName,
                                          // email: this.state.email,
                                        }

                                        fetch(ProjectSettings.baseURL + '/api/user/profile', {
                                          method: 'PUT',
                                          headers: {
                                            Authorization: `Bearer ${this.state.token}`,
                                            'Content-type': 'application/json',
                                          },
                                          body: JSON.stringify(body1),
                                        })
                                          .then((e) => {
                                            return e.json()
                                          })
                                          .then((e) => {
                                            this.UserDetails()
                                            if (e.success === false) {
                                              swal({
                                                title: 'Something Went Wrong',
                                                icon: 'error',
                                              })
                                            }
                                            this.setState({ ButtonLoading: false }, () => {})
                                          })
                                          .catch((e) => {
                                            this.setState({
                                              ButtonLoading: false,
                                            })
                                            swal({
                                              title: 'Something Went Wrong',
                                              icon: 'error',
                                            })
                                          })

                                        fetch(ProjectSettings.baseURL + '/api/user/password', {
                                          method: 'POST',
                                          headers: {
                                            Authorization: `Bearer ${this.state.token}`,
                                            'Content-type': 'application/json',
                                          },
                                          body: JSON.stringify({
                                            password: this.state.Newpasswordconfirm,
                                            user_id: this.state.User.response.user._id,
                                          }),
                                        })
                                          .then((e) => {
                                            return e.json()
                                          })
                                          .then((e) => {
                                            this.setState(
                                              {
                                                ButtonLoading: false,
                                                InputPassword: '',
                                                Newpassword: '',
                                                Newpasswordconfirm: '',
                                                CurrentPasswordvalid: false,
                                              },
                                              () => {
                                                swal({
                                                  title: 'User Updated Succesfully',
                                                  icon: 'success',
                                                }).then((e) => {
                                                  if (e) {
                                                    this.props.history.push('/dashboard/1')
                                                  }
                                                })

                                                this.UserDetails()
                                              }
                                            )
                                          })
                                          .then((e) => {})
                                          .catch((e) => {
                                            swal({
                                              title: 'Something Went Wrong',
                                              icon: 'error',
                                            }).then((e) => {
                                              if (e) {
                                                this.props.history.go(0)
                                              }
                                            })
                                          })
                                      }
                                    )
                                  }
                                }
                              }
                            }
                          }
                        }
                      }}
                    >
                      {' '}
                      Save
                    </LaddaButton>
                    {/* <Button
                      color="link"
                      className="btn-cancel-modal"
                      onClick={() => {
                        this.props.history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button> */}
                  </Col>
                </Row>

                {/* <FormGroup>
              <Label for="exampleAddress">Address</Label>
              <Input
                type="text"
                name="address"
                id="exampleAddress"
                placeholder="1234 Main St"
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAddress2">Address 2</Label>
              <Input
                type="text"
                name="address2"
                id="exampleAddress2"
                placeholder="Apartment, studio, or floor"
              />
            </FormGroup>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleCity">City</Label>
                  <Input type="text" name="city" id="exampleCity" />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="exampleState">State</Label>
                  <Input type="text" name="state" id="exampleState" />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="exampleZip">Zip</Label>
                  <Input type="text" name="zip" id="exampleZip" />
                </FormGroup>
              </Col>
            </Row> */}
              </CardBody>
            </Card>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main))
