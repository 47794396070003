import './special_css/css.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'

import Component from './Component/index'
import * as serviceWorker from './serviceWorker'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/base.scss'
import configureStore from './config'

import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
const store = configureStore()

Sentry.init({ dsn: 'https://51fd1668e8c34245869c7327b2f0fe0a@o652759.ingest.sentry.io/5761644' })
ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <Provider store={store}>
      <Router>
        <Component />
      </Router>
    </Provider>
  </Sentry.ErrorBoundary>,

  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
