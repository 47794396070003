import React, { Fragment } from 'react'

// import Ionicon from 'react-ionicons';

// import { IoIosCalendar } from 'react-icons/io'

import PerfectScrollbar from 'react-perfect-scrollbar'
import SessioTimeoutModal from '../../../Component/SessioTimeoutModal'
import ProjectSettings from '../../../global/Global'
import { DropdownToggle, DropdownMenu, Nav, Button, NavItem, NavLink, UncontrolledButtonDropdown } from 'reactstrap'
import { UserDeailsactions } from '../../../redux/actions/UserDetails'
import { toast, Bounce } from 'react-toastify'
import swal from 'sweetalert'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg'
// import avatar1 from '../../../assets/utils/images/avatars/1.jpg'
import { LogoutAction } from '../../../redux/actions/Login2'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
// import { fromUnixTime } from 'date-fns'
class UserBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      // Name: this.props.state.UserReducer11.name,
      // User: this.props.state.LoginReducer11,
      // token: this.props.state.LoginReducer11.response.token,

      Name: '',
      User: '',
      token: '',
    }
  }

  notify2 = () =>
    (this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: 'bottom-center',
      type: 'success',
    }))

  componentDidUpdate() {
    if (this.props.state.LoginReducer11.length === 0) {
      this.props.history.push('/login')
    } else {
      if (this.state.Name !== this.props.state.UserReducer11.name) {
        this.UserDetails()
      }
    }
    //  else {
    //   if (
    //     this.props.state.LoginReducer11.response.user !== null ||
    //     this.props.state.LoginReducer11.response.user !== undefined
    //   ) {
    //     this.setState({
    //       Name: this.props.state.LoginReducer11.response.user.name,
    //     });
    //   }
    // }
  }
  UserDetails = () => {
    // this.setState({
    //   NameState: true,
    // });
    fetch(`${ProjectSettings.baseURL}/api/user/profile/${this.state.User.response.user._id}`, {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${this.state.token}`,
        'Content-type': 'application/json',
      },
    })
      .then((e) => {
        return e.json()
      })
      .then((e) => {
        this.props.dispatch(UserDeailsactions(e.user.name, e.user.email))
        // this.setState({
        //   Name: e.user.name,

        // });
      })
      .then(() => {
        this.setState({
          Name: this.props.state.UserReducer11.name,
        })
      })
      .catch((e) => {
        swal({
          title: 'Something Went Wrong',
          icon: 'error',
        }).then((e) => {
          if (e) {
            this.props.history.push('/dashboard/1')
          }
        })
      })
  }
  componentDidMount() {
    if (this.props.state.LoginReducer11.length === 0) {
      this.props.history.push('/login')
    } else {
      if (this.props.state.LoginReducer11.response.user !== null || this.props.state.LoginReducer11.response.user !== undefined) {
        this.setState(
          {
            Name: this.props.state.UserReducer11.name,
            User: this.props.state.LoginReducer11,
            token: this.props.state.LoginReducer11.response.token,
          },
          () => {
            this.UserDetails()
          }
        )

        // this.setState({
        //   Name: this.props.state.LoginReducer11.response.user.name,
        // });
      }
    }
  }

  render() {
    return (
      <Fragment>
        <SessioTimeoutModal
          style={{
            display: 'none',
          }}
          send_data={(e) => {
            if (e === false) {
              this.props.dispatch(LogoutAction())
            }
          }}
        />
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    <AccountCircleIcon style={{ width: '42px', height: '42px' }} />
                    {/* <img
                      width={42}
                      className="rounded-circle"
                      src={avatar1}
                      alt=""
                    /> */}
                    <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                  </DropdownToggle>
                  <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-info">
                        <div
                          className="menu-header-image opacity-2"
                          style={{
                            backgroundImage: 'url(' + city3 + ')',
                          }}
                        />
                        <div className="menu-header-content text-left">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <AccountCircleIcon style={{ width: '42px', height: '42px' }} />
                                {/* <img
                                  width={42}
                                  className="rounded-circle"
                                  src={avatar1}
                                  alt=""
                                /> */}
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">{this.state.Name}</div>
                                <div className="widget-subheading opacity-8"></div>
                              </div>
                              <div className="widget-content-right mr-2">
                                <Button
                                  className="btn-pill btn-shadow btn-shine"
                                  color="focus"
                                  onClick={() => {
                                    this.props.dispatch(LogoutAction())
                                  }}
                                >
                                  Logout
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="scroll-area-xs"
                      style={{
                        height: '150px',
                      }}
                    >
                      <PerfectScrollbar>
                        <Nav vertical>
                          {/* <NavItem className="nav-item-header">
                            Activity
                          </NavItem> */}
                          {/* <NavItem>
                            <NavLink href="#">
                              Chat
                              <div className="ml-auto badge badge-pill badge-info">
                                8
                              </div>
                            </NavLink>
                          </NavItem> */}
                          {/* <NavItem>
                            <NavLink href="#">Recover Password</NavLink>
                          </NavItem> */}
                          <NavItem className="nav-item-header">My Account</NavItem>
                          <NavItem>
                            <NavLink href="#">
                              Settings
                              {/* <div className="ml-auto badge badge-success">
                                New
                              </div> */}
                            </NavLink>
                          </NavItem>
                          {/* <NavItem>
                            <NavLink href="#">
                              Messages
                              <div className="ml-auto badge badge-warning">
                                512
                              </div>
                            </NavLink>
                          </NavItem> */}
                          {/* <NavItem>
                            <NavLink href="#">Logs</NavLink>
                          </NavItem> */}
                        </Nav>
                      </PerfectScrollbar>
                    </div>
                    <Nav vertical>
                      <NavItem className="nav-item-divider mb-0" />
                    </Nav>
                    {/* <div className="grid-menu grid-menu-2col">
                      <Row className="no-gutters">
                        <Col sm="6">
                          <Button
                            className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                            outline
                            color="warning"
                          >
                            <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2">
                              {" "}
                            </i>
                            Message Inbox
                          </Button>
                        </Col>
                        <Col sm="6">
                          <Button
                            className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                            outline
                            color="danger"
                          >
                            <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                              {" "}
                            </i>
                            <b>Support Tickets</b>
                          </Button>
                        </Col>
                      </Row>
                    </div> */}
                    {/* <Nav vertical>
                      <NavItem className="nav-item-divider" />
                      <NavItem className="nav-item-btn text-center">
                        <Button size="sm" className="btn-wide" color="primary">
                          Open Messages
                        </Button>
                      </NavItem>
                    </Nav> */}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ml-3 header-user-info">
                <div className="widget-heading"> {this.state.Name}</div>
                {/* <div className="widget-subheading">VP People Manager</div> */}
              </div>
              {/* <div className="widget-content-right header-user-info ml-3">
                <Button
                  className="btn-shadow p-1"
                  size="sm"
                  onClick={this.notify2}
                  color="info"
                  id="Tooltip-1"
                >
                  <IoIosCalendar color="#ffffff" fontSize="20px" />
                </Button>
                <UncontrolledTooltip placement="bottom" target={"Tooltip-1"}>
                  Click for Toastify Notifications!
                </UncontrolledTooltip>
              </div> */}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { state }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserBox))
